import { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Title,
  Tabs,
  Box,
  TextInput,
  Autocomplete,
} from "@mantine/core";
import BookingTable from "./BookingTable";
import CustomLoader from "../../Components/CustomLoader";
import BookingAPI from "../../Redux/Booking/Services";
import { useAppSelector } from "../../Services/hooks/hooks";
import PermissionCheck from "../../Components/Permission";
import BlockAPI from "../../Redux/Configuration/Block/Services";
import DistrictAPI from "../../Redux/Configuration/District/Services";
import StateAPI from "../../Redux/Configuration/State/Services";

export default function BookingManagement() {
  const [tableData, setTableData] = useState([]);
  const [activeTab, setActiveTab] = useState<string | null>("all");
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    skip: 0,
    limit: 10,
  });
  const [pageSelection, setPageSelection] = useState(1);
  const [totalCount, setTotalCount] = useState(10);
  const [searchText, setSearchText] = useState("");
  const { data } = useAppSelector((state) => state.auth);
  const permissionList = data.data.permission[0].permission_list;

  const [stateList, setStateList] = useState<any>([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [districtList, setDistrictList] = useState<any>([]);
  const [blockList, setBlockList] = useState<any>([]);
  const [selectedBlock, setSelectedBlock] = useState("");

  async function getStateList() {
    let data = await StateAPI.getStateAllList();
    let tempList: { value: any; label: any }[] = [];
    data?.data.map((val: any) => {
      tempList.push({
        value: val.name,
        label: val.name,
      });
    });
    setStateList(tempList);
  }

  async function getDistrictListBasedOnState(state: any) {
    let data = await DistrictAPI.getDistrictAllList({
      state: state,
    });
    let tempList: { value: any; label: any }[] = [];
    data?.data.map((val: any) => {
      tempList.push({
        value: val.name,
        label: val.name,
      });
    });
    setDistrictList(tempList);
  }

  async function getBlockListBasedOnState(state: any, district: any) {
    let data = await BlockAPI.getBlocAllList({
      state: state,
      district: district,
    });
    let tempList: { value: any; label: any }[] = [];
    data?.data.map((val: any) => {
      tempList.push({
        value: val.name,
        label: val.name,
      });
    });
    setBlockList(tempList);
  }

  useEffect(() => {
    getStateList();
  }, []);

  async function operatorListFunction() {
    try {
      let payload = {
        ...pagination,
        status: activeTab,
        filter: searchText,
        state: selectedState,
        district: selectedDistrict,
        block: selectedBlock,
      };
      setIsLoading(true);
      let data = (await BookingAPI.getBookingListByBookingStatus(payload)).data;
      setTableData(data[0].data);
      setTotalCount(data[0]?.count?.count ? data[0]?.count?.count : 0);
      setIsLoading(false);
    } catch (e) {
      setTableData([]);
      setTotalCount(0);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    operatorListFunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeTab,
    pagination,
    searchText,
    selectedState,
    selectedDistrict,
    selectedBlock,
  ]);

  const Styles = {
    paper: {
      width: "100%",
      backgroundColor: "#FFFFFF",
      padding: 20,
      borderRadius: 20,
    },
  };

  return (
    <div>
      <Grid>
        <Grid.Col span={12}>
          <Paper style={Styles.paper}>
            <Grid>
              <Grid.Col xs={12} sm={12} md={4} lg={4}>
                <Title
                  style={{
                    fontSize: 30,
                    fontWeight: 300,
                    color: "#4C9C4F",
                  }}
                >
                  Order Management
                </Title>
              </Grid.Col>
              {PermissionCheck(permissionList, "Order_Management", "read") ? (
                <>
                  <Grid.Col xs={12} sm={12} md={2} lg={2}>
                    <Autocomplete
                      placeholder="Filter by State"
                      sx={{ maxHeight: 200, overflow: "auto" }}
                      data={stateList}
                      value={selectedState}
                      nothingFound="No options"
                      onChange={(e) => {
                        setSelectedState(e);
                        setSelectedBlock("");
                        setSelectedDistrict("");
                        getDistrictListBasedOnState(e);
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col xs={12} sm={12} md={2} lg={2}>
                    <Autocomplete
                      placeholder="Filter by District"
                      sx={{ maxHeight: 200, overflow: "auto" }}
                      data={districtList}
                      value={selectedDistrict}
                      nothingFound="No options"
                      onChange={(e) => {
                        setSelectedDistrict(e);
                        setSelectedBlock("");
                        getBlockListBasedOnState(selectedState, e);
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col xs={12} sm={12} md={2} lg={2}>
                    <Autocomplete
                      placeholder="Filter by Block"
                      sx={{ maxHeight: 200, overflow: "auto" }}
                      data={blockList}
                      nothingFound="No options"
                      value={selectedBlock}
                      onChange={(e) => {
                        setSelectedBlock(e);
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col xs={12} sm={12} md={2} lg={2}>
                    <TextInput
                      placeholder="Filter by Name"
                      value={searchText}
                      withAsterisk
                      onChange={(val) => {
                        setSearchText(val.target.value);
                      }}
                    />
                  </Grid.Col>
                </>
              ) : (
                <></>
              )}
            </Grid>
            {PermissionCheck(permissionList, "Order_Management", "read") ? (
              <Box style={{ marginTop: 30 }}>
                <Tabs
                  variant="outline"
                  value={activeTab}
                  onTabChange={(val) => {
                    setPagination({
                      skip: 0,
                      limit: 10,
                    });
                    setPageSelection(1);
                    setActiveTab(val);
                  }}
                >
                  <Tabs.List>
                    <Tabs.Tab value="all">All</Tabs.Tab>
                    <Tabs.Tab value="pending">Pending</Tabs.Tab>
                    <Tabs.Tab value="approved">Assigned</Tabs.Tab>
                    <Tabs.Tab value="started">Started</Tabs.Tab>
                    <Tabs.Tab value="pause">Paused</Tabs.Tab>
                    <Tabs.Tab value="completed">Completed</Tabs.Tab>
                    <Tabs.Tab value="rejected">Rejected</Tabs.Tab>
                  </Tabs.List>

                  <Tabs.Panel value="all" pt="xs">
                    {isLoading ? (
                      <CustomLoader />
                    ) : (
                      <BookingTable
                        data={tableData}
                        count={totalCount}
                        setPagination={setPagination}
                        pagination={pagination}
                        page={pageSelection}
                        setPage={setPageSelection}
                        setTableData={setTableData}
                        setTotalCount={setTotalCount}
                        activeTab={activeTab}
                        searchText={searchText}
                        permissionList={permissionList}
                      />
                    )}
                  </Tabs.Panel>

                  <Tabs.Panel value="pending" pt="xs">
                    {isLoading ? (
                      <CustomLoader />
                    ) : (
                      <BookingTable
                        data={tableData}
                        count={totalCount}
                        setPagination={setPagination}
                        pagination={pagination}
                        page={pageSelection}
                        setPage={setPageSelection}
                        setTableData={setTableData}
                        setTotalCount={setTotalCount}
                        activeTab={activeTab}
                        searchText={searchText}
                        permissionList={permissionList}
                      />
                    )}
                  </Tabs.Panel>

                  <Tabs.Panel value="approved" pt="xs">
                    {isLoading ? (
                      <CustomLoader />
                    ) : (
                      <BookingTable
                        data={tableData}
                        count={totalCount}
                        setPagination={setPagination}
                        pagination={pagination}
                        page={pageSelection}
                        setPage={setPageSelection}
                        setTableData={setTableData}
                        setTotalCount={setTotalCount}
                        activeTab={activeTab}
                        searchText={searchText}
                        permissionList={permissionList}
                      />
                    )}
                  </Tabs.Panel>

                  <Tabs.Panel value="started" pt="xs">
                    {isLoading ? (
                      <CustomLoader />
                    ) : (
                      <BookingTable
                        data={tableData}
                        count={totalCount}
                        setPagination={setPagination}
                        pagination={pagination}
                        page={pageSelection}
                        setPage={setPageSelection}
                        setTableData={setTableData}
                        setTotalCount={setTotalCount}
                        activeTab={activeTab}
                        searchText={searchText}
                        permissionList={permissionList}
                      />
                    )}
                  </Tabs.Panel>

                  <Tabs.Panel value="pause" pt="xs">
                    {isLoading ? (
                      <CustomLoader />
                    ) : (
                      <BookingTable
                        data={tableData}
                        count={totalCount}
                        setPagination={setPagination}
                        pagination={pagination}
                        page={pageSelection}
                        setPage={setPageSelection}
                        setTableData={setTableData}
                        setTotalCount={setTotalCount}
                        activeTab={activeTab}
                        searchText={searchText}
                        permissionList={permissionList}
                      />
                    )}
                  </Tabs.Panel>

                  <Tabs.Panel value="completed" pt="xs">
                    {isLoading ? (
                      <CustomLoader />
                    ) : (
                      <BookingTable
                        data={tableData}
                        count={totalCount}
                        setPagination={setPagination}
                        pagination={pagination}
                        page={pageSelection}
                        setPage={setPageSelection}
                        setTableData={setTableData}
                        setTotalCount={setTotalCount}
                        activeTab={activeTab}
                        searchText={searchText}
                        permissionList={permissionList}
                      />
                    )}
                  </Tabs.Panel>

                  <Tabs.Panel value="rejected" pt="xs">
                    {isLoading ? (
                      <CustomLoader />
                    ) : (
                      <BookingTable
                        data={tableData}
                        count={totalCount}
                        setPagination={setPagination}
                        pagination={pagination}
                        page={pageSelection}
                        setPage={setPageSelection}
                        setTableData={setTableData}
                        setTotalCount={setTotalCount}
                        activeTab={activeTab}
                        searchText={searchText}
                        permissionList={permissionList}
                      />
                    )}
                  </Tabs.Panel>
                </Tabs>
              </Box>
            ) : (
              <></>
            )}
          </Paper>
        </Grid.Col>
      </Grid>
    </div>
  );
}
