import {
  Modal,
  Text,
  Box,
  Title,
  Image,
} from "@mantine/core";
import { IconPhotoExclamation } from "@tabler/icons-react";

type Props = {
  id: string;
  show: boolean;
  url: string;
  handleClose: () => void;
};
const ImageModal: React.FC<Props> = ({ show, url, handleClose }) => {
  return (
    <Modal
      centered
      radius="lg"
      padding="xl"
      opened={show}
      onClose={handleClose}
      withCloseButton={true}
      // scrollAreaComponent={ScrollArea.Autosize}
      title={
        <Title
          style={{
            fontSize: 30,
            fontWeight: 300,
            color: "#4C9C4F",
          }}
        >
          Image Preview
        </Title>
      }
    >
      <Box>
        {url === "" || url === undefined || url === null ? (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconPhotoExclamation size={80} color="#4C9C4F" />
            <Text
              style={{
                margin: 10,
                fontSize: 18,
                color: "#4C9C4F",
                fontWeight: 500,
              }}
            >
              No image found
            </Text>
          </Box>
        ) : (
          <Image src={url} />
        )}
      </Box>
    </Modal>
  );
};

export default ImageModal;
