import {
  Button,
  Modal,
  Title,
  Box,
  ScrollArea,
  Select,
  ActionIcon,
  Autocomplete,
  Loader,
} from "@mantine/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { IconChevronDown, IconCircleX, IconLocation } from "@tabler/icons-react";
import React, { useEffect, useRef, useState } from "react";
import { TextInput } from "@mantine/core";
import { Grid } from "@mantine/core";
import * as _ from "lodash";
import { CreateDrone } from "../../Redux/Drone/Interface";
import DroneAPI from "../../Redux/Drone/Services";
import MapModal from "./MapModal";
import OperatorAPI from "../../Redux/Operator/Services";
import BookingAPI from "../../Redux/Booking/Services";

const OperatorSchema = Yup.object().shape({
  assigned_operator: Yup.string().required("Operator name is required"),
});

type Props = {
  id?: string;
  show: boolean;
  data?: any;
  type: string;
  handleClose: () => void;
};

const AddOperatorModal: React.FC<Props> = ({
  id,
  show,
  data,
  type,
  handleClose,
}) => {
  const [initialValues, setInitialValues] = useState<CreateDrone>(
    _.isEmpty(data)
      ? {
        assigned_operator: ""
      }
      : {
        assigned_operator: data[0]?.assigned_operator,
      }
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: data[0]?._id ? Yup.object().shape({}) : OperatorSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      handleSubmit(values);
    },
  });

  const [searchValue, onSearchChange] = useState("");
  const [height, setHeight] = useState(100);
  const [operatorList, setOperatorList] = useState<
    { value: any; label: any }[]
  >([]);
  const [selectedOperator, setSelectedOperator] = useState<string | any>("");
  const [isLoading, setIsLoading] = useState(false);
  const [allOperator, setAllOperator] = useState([]);
  const [operatorStatus, setOperatorStatus] = useState<any>("");

  async function getOperatorList() {
    let dataSet = (await OperatorAPI.getOperatorListAll()).data;
    let droneList = (await BookingAPI.getAllDroneList()).data;
    let tempList: { value: any; label: any }[] = [];
    setAllOperator(dataSet);

    const result = dataSet.filter((f: any) =>
      !droneList.some((d: any) => d.assigned_operator == f._id)
    );

    result.map((res: any) => {

      tempList.push({
        value: res._id,
        label: res.name,
      });
    });
    setOperatorList(tempList);
  }

  useEffect(() => {
    getOperatorList();
  }, []);

  async function bookingsBasedOperatorId() {
    try {
      let res = await OperatorAPI.bookingsBasedOperatorId({id: data[0]?.assigned_operator});
      if (res.status) {
        setOperatorStatus("");
      } else {
        setOperatorStatus("");
      }
    } catch (e: any) {
      setOperatorStatus(e.message)
    }
  }

  useEffect(() => {
    if (data[0]?.assigned) {
      bookingsBasedOperatorId();
    }
  })

  async function handleSubmit(params: any) {
    if (!isLoading) {
      setIsLoading(true);
      let payload = initialValues;
      if (!data[0]?.assigned) {
        payload = {
          ...payload,
          id: data[0]?._id,
          assigned_operator: selectedOperator,
        };
      }
      else {
        payload = {
          id: data[0]?._id,
          assigned: false,
          assigned_operator: "",
        };
      }
      await DroneAPI.updateDrone(payload);
      handleClose();
      setIsLoading(false);
    }
  }

  return (
    <Modal
      centered
      radius="lg"
      opened={show}
      onClose={handleClose}
      withCloseButton={true}
      scrollAreaComponent={ScrollArea.Autosize}
      title={
        <Title style={{ fontSize: 25, fontWeight: 300, color: "#4C9C4F" }}>
          Assign Operator
        </Title>
      }
    >
      {operatorStatus !== "" ? (
        <div
          style={{ backgroundColor: "red", color: "white", padding: "5px", marginBottom: 5, display: 'flex', fontSize: '13px' }}
        >
          <IconCircleX size="20px" style=
            {{ marginRight: '10px' }} /> {operatorStatus}
        </div>
      ) : (
        ""
      )}

      <Box style={{ height: height }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid>
            <Grid.Col xs={12} sm={12} md={12} lg={12}>
              {
                data[0]?.assigned ? <>
                  Assigned Operator : {allOperator.find((res: any) => res._id === data[0]?.assigned_operator)?.["name"]}
                </> :

                  <Select
                    //  label="Operator Name"
                    placeholder="Select Operator"
                    //withAsterisk
                    //rightSection={<IconChevronDown size="1rem" />}
                    // rightSectionWidth={30}
                    searchable
                    clearable
                    value={initialValues?.assigned_operator}
                    onChange={(val) => {
                      setSelectedOperator(val);
                      setInitialValues({
                        ...initialValues,
                        assigned_operator: val,
                      });
                    }}
                    onClickCapture={(val) => {
                      setHeight(200);
                    }}
                    onDropdownClose={() => {
                      setHeight(100);
                    }}
                    //    onSearchChange={onSearchChange}
                    //  searchValue={searchValue}
                    nothingFound="No options"
                    data={operatorList}
                    maxDropdownHeight={150}
                  />
              }

              {formik.touched.assigned_operator &&
                formik.errors.assigned_operator && (
                  <span role="alert" style={{ color: "red", fontSize: 12 }}>
                    {formik.errors.assigned_operator}
                  </span>
                )}
            </Grid.Col>

            <Grid.Col span={12}>
              <Box style={{ display: "flex", justifyContent: "center" }}>
                {isLoading ? (
                  <Button style={{ backgroundColor: "#2C752F", width: 100 }}>
                    <Loader size="xs" />
                  </Button>
                ) : (
                  data[0]?.assigned ?
                    <Button type="submit" disabled={operatorStatus !== ""} style={{ backgroundColor: "#2C752F" }}>
                      Un Assign
                    </Button> :
                    <Button type="submit" style={{ backgroundColor: "#2C752F" }}>
                      {type}
                    </Button>
                )}
              </Box>
            </Grid.Col>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
};

export default AddOperatorModal;
