import {
  Box,
  Button,
  Grid,
  Paper,
  Tabs,
  TextInput,
  Title,
} from "@mantine/core";
import { useState, useMemo, useEffect } from "react";
import ModuleAPI from "../../../Redux/Module/service";
import CustomLoader from "../../../Components/CustomLoader";
import ModuleTable from "../Table/moduleTable";
import { IconPlus } from "@tabler/icons-react";
import AddModuleModal from "../Modals/addModuleModal";
import { useAppSelector } from "../../../Services/hooks/hooks";
import PermissionCheck from "../../../Components/Permission";

function Modules(props: any) {
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const [addModal, setAddModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const [activeTab, setActiveTab] = useState<string | null>("active");
  const [pagination, setPagination] = useState({
    skip: 0,
    limit: 10,
  });
  const [pageSelection, setPageSelection] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const { data } = useAppSelector(
    (state) => state.auth
  );
  const permissionList = data.data.permission[0].permission_list;

  function handleAddModal() {
    getModuleList();
    setAddModal(false);
  }

  const Styles = {
    paper: {
      width: "100%",
      backgroundColor: "#FFFFFF",
      padding: 20,
      borderRadius: 20,
    },
  };

  async function getModuleList() {
    try {
      setIsLoading(true);
      let payload = {
        ...pagination,
        status: activeTab,
        filter: searchText
      };
      let data = (await ModuleAPI.getModuleList(payload)).data;
      setTableData(data?.data ? data.data : []);
      setTotalCount(data?.count ? data?.count : 0);
      setIsLoading(false);
    } catch (e) {
      setTableData([]);
      setTotalCount(0);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getModuleList();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, pagination, searchText]);

  return (
    <>
      <Box m={10} mt={30}>
        <div style={containerStyle}>
          <div>
            <Box>
              <Grid>
                <Grid.Col span={12}>
                  <Paper style={Styles.paper}>
                    <Grid>
                      <Grid.Col xs={12} sm={8} md={8} lg={8}>
                        <Title
                          style={{
                            fontSize: 30,
                            fontWeight: 300,
                            color: "#4C9C4F",
                          }}
                        >
                          Module Details
                        </Title>
                      </Grid.Col>
                      <Grid.Col xs={12} sm={2} md={2} lg={2}>
                        <TextInput
                          placeholder="Filter by Name"
                          value={searchText}
                          withAsterisk
                          onChange={(val) => {
                            setSearchText(val.target.value);
                          }}
                        />
                      </Grid.Col>
                      {
                        PermissionCheck(permissionList, "User_Management", "write") ?
                          <Grid.Col xs={12} sm={2} md={2} lg={2}>
                            <Button
                              fullWidth
                              style={{ background: "#4CAF50" }}
                              onClick={() => {
                                setAddModal(true);
                              }}
                            >
                              <IconPlus size={18} />
                              Add Module
                            </Button>
                          </Grid.Col> : <></>
                      }
                    </Grid>
                    <Box style={{ marginTop: 0 }}>
                      <Tabs
                        variant="outline"
                        value={activeTab}
                        onTabChange={(val) => {
                          setPagination({
                            skip: 0,
                            limit: 10,
                          });
                          setPageSelection(1);
                          setActiveTab(val);
                        }}
                      >
                        <Tabs.List>
                          <Tabs.Tab value="active">Active</Tabs.Tab>
                          <Tabs.Tab value="deactive">De-Active</Tabs.Tab>
                        </Tabs.List>

                        <Tabs.Panel value="active" pt="xs">
                          {isLoading ? (
                            <CustomLoader />
                          ) : (
                            <ModuleTable
                              data={tableData}
                              value={activeTab}
                              count={totalCount}
                              setPagination={setPagination}
                              pagination={pagination}
                              page={pageSelection}
                              setPage={setPageSelection}
                              setTableData={setTableData}
                              setTotalCount={setTotalCount}
                              permissionList={permissionList}
                            />
                          )}
                        </Tabs.Panel>

                        <Tabs.Panel value="deactive" pt="xs">
                          {isLoading ? (
                            <CustomLoader />
                          ) : (
                            <ModuleTable
                              data={tableData}
                              value={activeTab}
                              count={totalCount}
                              setPagination={setPagination}
                              pagination={pagination}
                              page={pageSelection}
                              setPage={setPageSelection}
                              setTableData={setTableData}
                              setTotalCount={setTotalCount}
                              permissionList={permissionList}
                            />
                          )}
                        </Tabs.Panel>
                      </Tabs>
                    </Box>
                  </Paper>
                </Grid.Col>
              </Grid>
            </Box>
          </div>
        </div>
        {addModal ? (
          <AddModuleModal
            id={""}
            show={addModal}
            type="Add"
            handleClose={handleAddModal}
          />
        ) : null}
      </Box>
    </>
  );
}

export default Modules;
