import {
  Button,
  Modal,
  Title,
  Box,
  ScrollArea,
  Loader,
  Select,
  PasswordInput,
} from "@mantine/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { TextInput } from "@mantine/core";
import { Grid } from "@mantine/core";
import * as _ from "lodash";
import AdminAPI from "../../../Redux/Admin/service";
import PermissionAPI from "../../../Redux/Permission/service";
import { IconChevronDown, IconCircleX } from "@tabler/icons-react";

const UserSchema = Yup.object().shape({
  name: Yup.string().required("Name id is required"),
  password: Yup.string().required("Password is required"),
  email: Yup.string().email("Invalid email address").required("Email is required"),
  permission_id: Yup.string().required("Permission is required"),
});

type Props = {
  id?: string;
  show: boolean;
  data?: any;
  type: string;
  handleClose: () => void;
};

const AddUserModal: React.FC<Props> = ({
  id,
  show,
  data,
  type,
  handleClose,
}) => {
  const [initialValues, setInitialValues] = useState<any>(
    _.isEmpty(data)
      ? {
          name: "",
          password: "",
          email: "",
          permission_id: "",
        }
      : {
          name: data[0]?.name,
          password: atob(data[0]?.password),
          email: data[0]?.email,
          permission_id: data[0]?.permission_id,
        }
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: UserSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      handleSubmit(values);
    },
  });

  const [searchValue, onSearchChange] = useState("");
  const [permissionList, setPermissionList] = useState<
    { value: any; label: any }[]
  >([]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>("");

  async function getPermissionList() {
    let data = (
      await PermissionAPI.getPermissionList({ skip: 0, limit: 0, filter: "" })
    ).data;
    let tempList: { value: any; label: any }[] = [];
    data.data.map((res: any) => {
      tempList.push({
        value: res._id,
        label: res.name,
      });
      return "";
    });

    setPermissionList(tempList);
  }

  useEffect(() => {
    getPermissionList();
  }, []);

  async function handleSubmit(Params: any) {
    if (!isLoading) {
      setIsLoading(true);
      let payload = initialValues;
      payload = {
        ...payload,
        name: payload?.name.trimEnd(),
        email: payload?.email,
        password: btoa(payload?.password),
        permissionList: payload?.ppermission_id,
      };

      if (type === "Add") {
        try{
          await AdminAPI.createAdmin(payload);
          handleClose();
        }catch(e:any){
          setError(e.message);
        }
       
      } else {
        payload = {
          ...payload,
          id: data[0]?._id,
        };
        try{
          await AdminAPI.updateAdmin(payload);
          handleClose();
        }catch(e:any){
          setError(e.message);
        }
       
      }
     
      setIsLoading(false);
    }
  }

  return (
    <Modal
      centered
      radius="lg"
      opened={show}
      onClose={handleClose}
      withCloseButton={true}
      scrollAreaComponent={ScrollArea.Autosize}
      title={
        <Title style={{ fontSize: 25, fontWeight: 300, color: "#4C9C4F" }}>
          {type} User
        </Title>
      }
    >
        {error !== "" ? (
        <div
          style={{ backgroundColor: "red", color: "white", padding: "5px", marginBottom: 5, display: 'flex', fontSize: '13px' }}
        >
          <IconCircleX size="20px" style=
            {{ marginRight: '10px' }} /> {error}
        </div>
      ) : (
        ""
      )}

      <Box>
        <form onSubmit={formik.handleSubmit}>
          <Grid>
            <Grid.Col xs={12} sm={12} md={12} lg={12}>
              <TextInput
                label="Name"
                placeholder="Enter the Name"
                withAsterisk
                value={initialValues?.name}
                onChange={(val) => {
                  setInitialValues({
                    ...initialValues,
                    name: val.target.value.replace(/\s+/g, ' ').trimStart(),
                  });
                }}
              />
              {formik.touched.name && formik.errors.name && (
                <span role="alert" style={{ color: "red", fontSize: 12 }}>
                  {formik.errors.name.toString()}
                </span>
              )}
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={12} lg={12}>
              <TextInput
                label="Email"
                placeholder="Enter the Email"
                withAsterisk
                value={initialValues?.email}
                onChange={(val) => {
                  setError("");
                  setInitialValues({
                    ...initialValues,
                    email: val.target.value.trimStart(),
                  });
                }}
              />
              {formik.touched.email && formik.errors.email && (
                <span role="alert" style={{ color: "red", fontSize: 12 }}>
                  {formik.errors.email.toString()}
                </span>
              )}
            </Grid.Col>
            {
              type === "Add"?
            
            <Grid.Col xs={12} sm={12} md={12} lg={12}>
              <PasswordInput
                label="Password"
                placeholder="Enter the Password"
                withAsterisk
                value={initialValues?.password}
                onChange={(val) => {
                  setInitialValues({
                    ...initialValues,
                    password: val.target.value.trimStart(),
                  });
                }}
              />
              {formik.touched.password && formik.errors.password && (
                <span role="alert" style={{ color: "red", fontSize: 12 }}>
                  {formik.errors.password.toString()}
                </span>
              )}
            </Grid.Col> :<></>
}

            <Grid.Col xs={12} sm={12} md={12} lg={12}>
              {/* <Autocomplete
                label="Permission"
                placeholder="Select Permission"
                withAsterisk
                sx={{ maxHeight: 200, overflow: "auto" }}
                //   value={value}
                //   onChange={setValue}
                data={permissionList.filter((f: any) =>
                  droneDetails.some((d: any) => d.assigned_operator == f.id)
                )}
                onChange={(val) => {
                  setSelectedpermission(val);
                }}
                //   data={operatorList.filter((f: any) =>
                //     droneDetails.some((d: any) => d.assigned_operator == f.id)
                //   )}
              /> */}
              <Select
                label="Permission"
                placeholder="Select Permission"
                withAsterisk
                rightSection={<IconChevronDown size="1rem" />}
                rightSectionWidth={30}
                searchable
                value={initialValues?.permission_id}
                onChange={(val) => {
                //  setSelectedPermission(val);
                  setInitialValues({
                    ...initialValues,
                    permission_id: val,
                  });
                }}
                onSearchChange={onSearchChange}
                searchValue={searchValue}
                nothingFound="No options"
                data={permissionList}
              />
              {formik.touched.permission_id && formik.errors.permission_id && (
                <span role="alert" style={{ color: "red", fontSize: 12 }}>
                  {formik.errors.permission_id.toString()}
                </span>
              )}
            </Grid.Col>

            <Grid.Col span={12}>
              <Box style={{ display: "flex", justifyContent: "center" }}>
                {isLoading ? (
                  <Button style={{ backgroundColor: "#2C752F", width: 100 }}>
                    <Loader size="xs" />
                  </Button>
                ) : (
                  <Button type="submit" style={{ backgroundColor: "#2C752F" }}>
                    {type}
                  </Button>
                )}
              </Box>
            </Grid.Col>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
};

export default AddUserModal;
