import { updateKycData } from "./Interface";

export const Update_Kyc = "updateBooking";

export interface updateKyc {
  type: typeof Update_Kyc;
  data: updateKycData;
}


export type AuthAction = updateKyc;
