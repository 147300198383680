import { useState, useEffect } from "react";
import {
  createStyles,
  Box,
  Blockquote,
  Grid,
  Text,
  TextInput,
  PasswordInput,
  Button,
  Group,
  Image,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import bg from "../../assets/loginbg.png";
import { getLoginDetailsAction } from "../../Redux/Auth/LoginActions";
import { UserLogin } from "../../Redux/Auth/Interface";
import { useAppDispatch, useAppSelector } from "../../Services/hooks/hooks";
import * as _ from "lodash";
import { setNotifyFalse } from "../../Redux/Auth/LoginSlice";
import img from "../../assets/leaf.png";
import logo from "../../assets/iffco_logo.png";
import "./Login.css";
import TabData from "../../const.js";

const useStyles = createStyles((theme) => ({
  hiddenMobile: {
    [theme.fn.smallerThan("780")]: {
      display: "none",
    },
  },
  hiddenDesktop: {
    [theme.fn.largerThan("780")]: {
      display: "none",
    },
  },
}));
const Styles = {
  container: {
    width: "100%",
    height: "100vh",
    backgroundImage: `url(${bg})`,
    backgroundSize: "cover",
    margin: 0,
  },
  grid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    backgroundImage:
      "linear-gradient(to bottom right, rgba(255,255,255,0.1), rgba(255,255,255,0.1))",
    backdropFilter: "blur(5px)",
    backgroundColor: "rgba(0,0,0,0.05)",
    border: "1px solid rgba(248, 248, 248, 1)",
    borderRadius: 35,
    // margin: 60,
    // padding:60
  },
};
const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid Email")
    .min(3, "User doesn't exist")
    .max(50, "User doesn't exist")
    .required("Email is required"),
  password: Yup.string()
    // .min(8, "Password is Incorrect")
    // .max(50, "Password is Incorrect")
    .required("Password is required"),
});


export default function Login() {
  const { classes } = useStyles();
  const { data, loading, error, notify } = useAppSelector(
    (state) => state.auth
  );
  const [isLoading, setLoading] = useState(true);

  const dispatch = useAppDispatch();
  const [initialValues, setInitialValues] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      handleSubmit(values);
    },
  });

  // const [windowDimenion, detectHW] = useState({
  //   winWidth: window.innerWidth,
  //   winHeight: window.innerHeight,
  // });

  // const detectSize = () => {
  //   detectHW({
  //     winWidth: window.innerWidth,
  //     winHeight: window.innerHeight,
  //   });
  // };
  // useEffect(() => {
  //   console.log("------->> chec");
  //   console.log("-------> in funct", data);
  //   const permissionList = data?.data?.permission?.[0]?.permission_list ? data?.data?.permission?.[0]?.permission_list : [];
  //   console.log("------------->> test", permissionList);
  //   console.log(permissionList.findIndex((res: any) => res.name === "Dashboard")?.["read"], permissionList.length !== 0);
  //   if (!permissionList.findIndex((res: any) => res.name === "Dashboard")?.["read"] && permissionList.length !== 0) {
  //     console.log("------------> tab data", TabData);

  //     let redirectLink = TabData.find((res: any) => res?.componentName === permissionList?.[0]?.["name"])?.["link"];
  //     console.log("redirectLink : ", redirectLink);
  //     if (redirectLink) {
  //       console.log("------->>> in");
  //       navigate(redirectLink.toString());
  //     }

  //   }
  //   window.addEventListener("resize", detectSize);

  //   return () => {
  //     window.removeEventListener("resize", detectSize);
  //     dispatch(setNotifyFalse({}));
  //   };
  // }, [windowDimenion, navigate, data]);

  async function handleSubmit(value: UserLogin) {
    const data = value;
    let payload = {
      email: data?.email,
      password: btoa(data?.password),
      //password: data?.password
    };

    dispatch(getLoginDetailsAction(payload));
  }

  useEffect(() => {
    if (data?.status && notify) {
      showNotification({
        title: "Success",
        message: `${data?.message}`,
        icon: <IconCheck />,
        color: "green",
      });
      localStorage.setItem("auth", data.data.token || "");
      localStorage.setItem("token_status", "true");
      dispatch(setNotifyFalse({}));
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, notify]);

  useEffect(() => {

    if(localStorage.getItem("token_status") === "false"){
      showNotification({
        title: "Failed",
        message: `Token Expried`,
        icon: <IconX />,
        color: "red",
      });
    }
    localStorage.setItem("token_status", "true");


    if (!_.isEmpty(data.data)) {
      const permissionList = data?.data?.permission?.[0]?.permission_list ? data?.data?.permission?.[0]?.permission_list : [];
      let tempPermissionList = permissionList.filter((res: any) => res.read).reverse();

      if (!permissionList.find((res: any) => res.name === "Dashboard")?.["read"] && tempPermissionList.length !== 0) {

        let redirectLink = TabData.find((res: any) => res?.componentName === tempPermissionList?.[0]?.["name"])?.["link"];

        if (redirectLink) {
          navigate(redirectLink.toString());
        }
      } else {
        if (data.status) {
          navigate("/admin/dashboard");
        } else {
          navigate("/");
        }
      }
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  useEffect(() => {
    if (!_.isEmpty(error) && notify) {
      showNotification({
        title: "Failed",
        message: `${error?.message}`,
        icon: <IconX />,
        color: "red",
      });
      dispatch(setNotifyFalse({}));
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    isLoading ? <></> :
      <Box>
        <Grid style={Styles.container}>
          <Grid.Col lg={6} md={6} xs={12} style={Styles.grid}>
            <Box style={Styles.paper}>
              <Text
                style={{
                  fontSize: 25,
                  fontWeight: 600,
                  marginBottom: 8,
                  color: "#FFFFFF",
                  display: "flex",
                  paddingLeft: "64px",
                  paddingTop: "32px",
                }}
              >
                Welcome Back!
                <div className="leaf">
                  <img src={img} width="40px" alt="" />
                </div>
              </Text>
              <br />

              <form onSubmit={formik.handleSubmit} style={{ margin: "30px" }}>
                <div
                  style={{
                    marginBottom: 20,
                  }}
                >
                  <TextInput
                    label={<Text style={{ color: "#FFFFFF" }}>Email</Text>}
                    placeholder="Enter your email address"
                    size="sm"
                    // {...formik.getFieldProps("email")}
                    onChange={(e) =>
                      setInitialValues({
                        ...initialValues,
                        email: e.target.value,
                      })
                    }
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div>
                      <span role="alert" style={{ color: "red" }}>
                        {formik.errors.email}
                      </span>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    marginBottom: 10,
                  }}
                >
                  <PasswordInput
                    label={
                      <Text style={{ color: "#FFFFFF", width: 300 }}>
                        Password
                      </Text>
                    }
                    placeholder="Enter your Password"
                    size="sm"
                    // {...formik.getFieldProps("password")}
                    onChange={(e) =>
                      setInitialValues({
                        ...initialValues,
                        password: e.target.value,
                      })
                    }
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div>
                      <span role="alert" style={{ color: "red" }}>
                        {formik.errors.password}
                      </span>
                    </div>
                  )}
                </div>

                <Group
                  style={{
                    marginBottom: "10%",
                    justifyContent: "space-between",
                  }}
                ></Group>
                <Button
                  fullWidth
                  disabled={loading}
                  type="submit"
                  style={{ background: "#4C9C4F" }}
                >
                  {!loading && <span>Login</span>}
                  {loading && (
                    <span style={{ display: "block", color: "#fff" }}>
                      Please wait...
                    </span>
                  )}
                </Button>
                <br />
              </form>
            </Box>
          </Grid.Col>
          <Grid.Col
            lg={6}
            md={6}
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}>
            <Box
              style={{ width: "70%", marginTop: "20%" }}
              className={classes.hiddenMobile}
            >
              <Group style={{ display: "flex", justifyContent: "center" }}>
                <Image src={logo} />

              </Group>
              <Blockquote
                color="green"
                style={{ fontSize: 22, color: "#FFFFFF", fontWeight: 600 }}
              >
                Revolutionize your crop management with the efficiency and
                precision of drone spraying.
              </Blockquote>
            </Box>
          </Grid.Col>
        </Grid>
      </Box>
  );
}
