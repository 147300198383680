import { useState } from "react";
import { IconTrash, IconEye, IconMap } from "@tabler/icons-react";
import {
  ActionIcon,
  Box,
  Table,
  ScrollArea,
  Text,
  Tooltip,
} from "@mantine/core";
import DeleteModal from "../../Components/DeleteModal";
import { useNavigate } from "react-router-dom";
import BookingAPI from "../../Redux/Booking/Services";
import capitalizeFirstLowercaseRest from "../../Components/Capitalize";
import CustomPagination from "../../Components/CustomPagination";
import PermissionCheck from "../../Components/Permission";

export default function BookingTable(props: any) {
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [id, setId] = useState("");

  const handleDelete = () => {
    operatorListFunction();
    setShowDeleteModal(false);
  };


  async function operatorListFunction() {
    let payload = {
      ...props.pagination,
      status: props.activeTab,
      filter: props.searchText ? props.searchText : "",
    };

    let data = (await BookingAPI.getBookingListByBookingStatus(payload)).data;
    props.setTableData(data[0].data);
    props.setTotalCount(data[0]?.count?.count ? data[0]?.count?.count : 0);
  }

  const rows = props.data?.map((item: any) => {
    let url = `https://www.google.com/maps?q=${item?.farm_land_location[0]},${item?.farm_land_location[1]}`;
    return (
      <tr key={item?._id}>
             <td>{item?.booking_id}</td>
        <td>{item?.user?.name}</td>
        {/* <td>{item?.user?.address}</td> */}
        <td>{item?.operatorReport[0]?.cultivation_details.farm_land_area? item?.operatorReport[0]?.cultivation_details.farm_land_area : "-"}</td>
        <td>{item?.operatorReport[0]?.cultivation_details.total_tanks? item?.operatorReport[0]?.cultivation_details.total_tanks : "-"}</td>
        <td>{item?.fertilizer}</td>
        <td>{item?.pesticide ? item?.pesticide : "-"}</td>
        <td>
          {item?.booking_status === "approved" ||
            item?.booking_status === "completed" ||
            item?.booking_status === "started" ||
            item?.booking_status === "pause" ||
            item?.booking_status === "request_cancel" ||
            item?.booking_status === "Cancelled" 
            ? `${item?.operator?.name ? "" : "deactivated"} ${item?.operator?.name ? item?.operator?.name : ""
            }`
            : "Not Assigned"}
        </td>
        <td>{capitalizeFirstLowercaseRest(item?.booking_status)}</td>
        <td style={{ display: "flex", justifyContent: "space-evenly" }}>
          <ActionIcon
            variant="outline"
            onClick={() => {
              navigate("order-verify", { state: { item: item, permissionList: props.permissionList } });
            }}
          >
            <Tooltip label="View">
              <IconEye size={20} />
            </Tooltip>
          </ActionIcon>
          {
            // PermissionCheck(props.permissionList, "Order_Management", "write") ?

            //   <ActionIcon
            //     variant="outline"
            //     onClick={() => {
            //       setShowDeleteModal(true);
            //       setId(item?._id);
            //     }}
            //   >
            //     <Tooltip label="Delete">
            //       <IconTrash size={20} />
            //     </Tooltip>
            //   </ActionIcon> : <></>
          }
          {
            PermissionCheck(props.permissionList, "Order_Management", "read") ?

              <ActionIcon variant="outline">
                <Tooltip label="Map">
                  <a href={url} target="_blank"  rel="noreferrer">
                    {" "}
                    <IconMap size={20} />
                  </a>
                </Tooltip>
              </ActionIcon> : <></>
          }
        </td>
      </tr>
    );
  });

  return (
    <>
      <ScrollArea>
        {props.data.length === 0 ? (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: window.innerHeight - 285
            }}
          >
            <Text>No Data</Text>
          </Box>
        ) : (
          <div style={{ height: window.innerHeight - 330, overflowY: "scroll" }}>
          <Table verticalSpacing="sm" style={{ tableLayout: "fixed" }}>
            <thead>
              <tr>
                <th>Order Id</th>
                <th>Farmer Name</th>
                <th>Farm Land Area Sprayed (Acres)</th>
                <th>Total Tanks</th>
                <th>Fertilizer</th>
                <th>Pesticide</th>
                <th>Assigned Operator</th>
                <th>Status</th>
                <th style={{ textAlign: "center" }}>Actions</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
          </div>
        )}
      </ScrollArea>
      {props.count > 0 ? (
        <CustomPagination
          setPage={props.setPage}
          pagination={props.pagination}
          setPagination={props.setPagination}
          page={props.page}
          count={props.count}
        />
      ) : (
        <></>
      )}
      {showDeleteModal ? (
        <DeleteModal
          id={id}
          show={showDeleteModal}
          type="Order"
          handleClose={handleDelete}
        />
      ) : null}
    </>
  );
}
