import apiInstance from "../../Services";
import { moduleData, moduleDelete } from "./interface";

const ModuleAPI = {
  // eslint-disable-next-line class-methods-use-this

  createModule: async (payload: moduleData) => {
    try {
      const res = await apiInstance.post<any>(`/modules/create`, payload);
      return res;
    } catch (error) {
      throw error;
    }
  },

  getModuleList: async (payload: any) => {
    try {
      const res = await apiInstance.get<any>(
        `/modules/getallList?skip=${payload.skip}&limit=${payload.limit}&filter=${payload.filter}&status=${payload.status}`
      );
      return res;
    } catch (error) {
      throw error;
    }
  },

  updateModule: async (payload: moduleData) => {
    try {
      const res = await apiInstance.put<any>(
        `/modules/update?id=${payload?.id}`,
        payload
      );
      return res;
    } catch (error) {
      throw error;
    }
  },

  deleteModule: async (payload: moduleDelete) => {
    try {
      const res = await apiInstance.put<moduleDelete>(
        `/modules/delete?id=${payload.id}`
      );
      return res;
    } catch (error) {
      throw error;
    }
  },
};

export default ModuleAPI;
