import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { createStyles, Navbar, getStylesRef, Text } from "@mantine/core";
import {
  IconUsers,
  IconDrone,
  IconBrandBooking,
  IconFingerprint,
  IconSettings,
  IconChevronDown,
  IconChevronUp,
  IconHome,
  IconLiveView,
  IconBrandOpenvpn,
  IconShieldLock,
} from "@tabler/icons-react";
import { useAppSelector } from "../Services/hooks/hooks";
import TabData from "../const.js";

const useStyles = createStyles((theme) => ({
  link: {
    ...theme.fn.focusStyles(),
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    fontSize: theme.fontSizes.sm,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[1]
        : theme.colors.gray[0],
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    borderRadius: theme.radius.sm,
    fontWeight: 500,
    cursor: "pointer",

    "&:hover": {
      backgroundColor: "#3A9343",
      backgroundImage:
        "linear-gradient(to bottom right, rgba(255,255,255,0.1), rgba(255,255,255,0.1))",
      backdropFilter: "blur(5px)",
      color: "#FFFFFF",
      [`& .${getStylesRef("icon")}`]: {
        color: "#FFFFFF",
      },
    },
  },

  linkIcon: {
    ref: getStylesRef("icon"),
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[2]
        : theme.colors.gray[0],
    marginRight: theme.spacing.sm,
  },

  linkActive: {
    "&, &:hover": {
      backgroundColor: "#3A9343",
      backgroundImage:
        "linear-gradient(to bottom right, rgba(255,255,255,0.1), rgba(255,255,255,0.1))",
      backdropFilter: "blur(5px)",
      color: "#FFFFFF",
      [`& .${getStylesRef("icon")}`]: {
        color: "#FFFFFF",
      },
    },
  },
}));


function PermissionCheck(component: any) {
  const { data, loading, error, notify, notifymsg } = useAppSelector(
    (state) => state.auth
  );
  const permissionList = data?.data?.permission?.[0]?.permission_list
    ? data?.data?.permission?.[0]?.permission_list
    : [];
  let index = permissionList.findIndex((res: any) => res.name === component);
  if (index === -1) {
    return false;
  } else {
    return permissionList[index]["read"] || permissionList[index]["write"];
  }
}

function Sidebar() {
  const { classes, cx } = useStyles();
  const [active, setActive] = useState("Dashboard");
  const navigate = useNavigate();
  const [childOpen, setChildOpen] = useState(false);
  const { pathname } = useLocation();
  const { data, loading, error, notify, notifymsg } = useAppSelector(
    (state) => state.auth
  );
  const permissionList = data?.data?.permission?.[0]?.permission_list
  ? data?.data?.permission?.[0]?.permission_list
  : [];

  function activeBar() {
    if (pathname === "/admin/dashboard") {
      setActive("Dashboard");
    } else if (pathname === "/admin/live-track") {
      setActive("Live Track");
    } else if (pathname === "/admin/farmer-management") {
      setActive("Farmer Management");
    } else if (pathname === "/admin/operator-management") {
      setActive("Operator Management");
    } else if (pathname === "/admin/drone-management") {
      setActive("Drone Management");
    } else if (pathname === "/admin/booking-management") {
      setActive("Order Management");
    } else if (pathname === "/admin/kyc-details") {
      setActive("KYC Details");
    } else if (pathname === "/admin/configuration") {
      setActive("Configuration");
    } else if (pathname === "/admin/user-management") {
      setActive("User Management");
    } else if (pathname === "/admin/report-management") {
      setActive("Report_Management");
    }
    else if (pathname === "/admin/dealer-management") {
      setActive("Report_Management");
    }

  }

  useEffect(() => {
    activeBar();
  }, [pathname]);

  function handleChildStatus() {
    setChildOpen(!childOpen);
  }

  const links = TabData.map((item:any) =>
    PermissionCheck(item.componentName) ? (
      <div>
        <a
          className={cx(classes.link, {
            [classes.linkActive]: item.label === active,
          })}
          key={item.label}
          onClick={(event) => {
            event.preventDefault();

            if (item?.link) {
              setActive(item.label);
              navigate(item.link);
            } else {
              handleChildStatus();
            }
          }}
        >
          <item.icon className={classes.linkIcon} />
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Text>{item.label}</Text>
            {item.children.length > 0 ? (
              childOpen ? (
                <IconChevronUp
                  size={20}
                  onClick={() => {
                    handleChildStatus();
                  }}
                />
              ) : (
                <IconChevronDown
                  size={20}
                  onClick={() => {
                    handleChildStatus();
                  }}
                />
              )
            ) : (
              <></>
            )}
          </div>
        </a>
        {childOpen ? (
          item.children?.map((childItem: any) => {
            return (
              <div>
                <a
                  className={cx(classes.link, {
                    [classes.linkActive]: childItem["label"] === active,
                  })}
                  key={childItem["label"]}
                  onClick={(event) => {
                    event.preventDefault();
                    setActive(childItem["label"]);
                    navigate(childItem["link"]);
                  }}
                >
                  <div style={{ paddingLeft: 20, display: "flex" }}>
                    {/* <childItem.icon className={classes.linkIcon} size={20} /> */}
                    <Text>{childItem["label"]}</Text>
                  </div>
                </a>
              </div>
            );
          })
        ) : (
          <></>
        )}
      </div>
    ) : (
      <></>
    )
  );

  return (
    <Navbar
      width={{ sm: 260, lg: 260, base: 150 }}
      style={{ backgroundColor: "#2C752F", overflow: "auto" }}
      p="md"
    >
      <Navbar.Section grow>{links}</Navbar.Section>
    </Navbar>
  );
}

export default Sidebar;
