import { IconEdit, IconPlus, IconPlugConnected } from "@tabler/icons-react";
import {
  ActionIcon,
  Box,
  Table,
  ScrollArea,
  Text,
  Tooltip,
} from "@mantine/core";
import { useState } from "react";
import DeleteModal from "../../../Components/DeleteModal";
import CustomPagination from "../../../Components/CustomPagination";
import ModuleAPI from "../../../Redux/Module/service";
import AddModuleModal from "../Modals/addModuleModal";
import PermissionCheck from "../../../Components/Permission";

export default function ModuleTable(props: any) {
  const [editModuleModal, setEditModuleModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [id, setId] = useState("");
  const [editableData, setEditableData] = useState([]);

  const handleEditModal = () => {
    moduleListFunction();
    setEditModuleModal(false);
  };

  const handleDelete = () => {
    moduleListFunction();
    setShowDeleteModal(false);
  };

  async function moduleListFunction() {
    let params = {
      ...props.pagination,
      filter: props.searchText ? props.searchText : "",
      status: props.value,
    };
    try {
      let data = (await ModuleAPI.getModuleList(params)).data;
      props.setTableData(data?.data ? data.data : []);
      props.setTotalCount(data?.count ? data?.count : 0);
    } catch (e) {
      props.setTableData([]);
      props.setTotalCount(0);
    }
  }

  const rows = props.data?.map((item: any) => {
    return (
      <tr key={item?._id}>
        <td>{item?.name}</td>
        <td style={{ display: "flex", justifyContent: "space-evenly" }}>
          {PermissionCheck(
            props?.permissionList,
            "User_Management",
            "write"
          ) ? (
            <ActionIcon
              variant="outline"
              onClick={() => {
                const edata = props.data?.filter(
                  (e: any) => e?._id === item?._id
                );
                setEditableData(edata);
                setEditModuleModal(true);
              }}
            >
              <Tooltip label="Edit">
                <IconEdit size={20} />
              </Tooltip>
            </ActionIcon>
          ) : (
            <></>
          )}
          {PermissionCheck(
            props?.permissionList,
            "User_Management",
            "write"
          ) ? (
            <ActionIcon
              variant="outline"
              onClick={() => {
                setShowDeleteModal(true);
                setId(item?._id);
              }}
            >
              <Tooltip
                label={props.value === "active" ? "De-Active" : "Active"}
              >
                {props.value === "active" ? (
                  <IconPlugConnected size={20} />
                ) : (
                  <IconPlus size={20} />
                )}
              </Tooltip>
            </ActionIcon>
          ) : (
            <></>
          )}
        </td>
      </tr>
    );
  });

  return (
    <>
      <ScrollArea>
        {props.data.length === 0 ? (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "50vh",
            }}
          >
            <Text>No Data</Text>
          </Box>
        ) : (
          <div style={{ height: window.innerHeight - 380, overflowY: "scroll" }}>
            <Table verticalSpacing="sm" style={{ tableLayout: "fixed" }}>
              <thead>
                <tr>
                  <th>Module Name</th>
                  <th style={{ textAlign: "center" }}>Actions</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          </div>
        )}
      </ScrollArea>
      {props.count > 0 ? (
        <CustomPagination
          setPage={props.setPage}
          pagination={props.pagination}
          setPagination={props.setPagination}
          page={props.page}
          count={props.count}
        />
      ) : (
        <></>
      )}
      {showDeleteModal ? (
        <DeleteModal
          id={id}
          show={showDeleteModal}
          type="Module"
          handleClose={handleDelete}
        />
      ) : null}
      {editModuleModal ? (
        <AddModuleModal
          show={editModuleModal}
          data={editableData}
          type="Update"
          handleClose={handleEditModal}
        />
      ) : null}
    </>
  );
}
