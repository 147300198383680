import { useState } from "react";
import {
  ActionIcon,
  Box,
  Table,
  ScrollArea,
  Text,
  Tooltip,
} from "@mantine/core";
import {
  IconEdit,
  IconEye,
  IconPlugConnected,
  IconPlus,
  IconUserCheck,
} from "@tabler/icons-react";
import DeleteModal from "../../Components/DeleteModal";
import ViewModal from "../../Components/ViewModal";
import AddDroneModal from "./AddDroneModal";
import DroneAPI from "../../Redux/Drone/Services";
import CustomPagination from "../../Components/CustomPagination";
import AddOperatorModal from "./AddOperatorModal";
import PermissionCheck from "../../Components/Permission";
import { IconUserCancel } from "@tabler/icons-react";

export default function DroneTable(props: any) {
  const [showViewModal, setShowViewModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [id, setId] = useState("");
  const [editableData, setEditableData] = useState([]);
  const [showAddOperatoeModal, setShowAddOperatorModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(false);

  const handleView = () => {
    setShowViewModal(false);
  };

  const handleAddOperatorModal = () => {
    droneListFunction();
    setShowAddOperatorModal(false);
  };

  const handleEdit = () => {
    droneListFunction();
    setShowEditModal(false);
  };

  const handleDelete = () => {
    droneListFunction();
    setShowDeleteModal(false);
  };

  async function droneListFunction() {
    let params = {
      ...props.pagination,
      status:
        props.value === "all" ? "all" : props.value === "active" ? true : false,
      filter: props.searchText,
    };

    try {
      let data = (await DroneAPI.getDroneList(params)).data;

      props.setTableData(data?.data);
    } catch (e) {
      props.setTableData([]);
    }
  }

  const rows = props.data?.map((item: any) => {
    return (
      <tr key={item?._id} style={{ color: !item?.status ? "grey" : "black" }}>
        <td>{item?.drone_id}</td>
        <td>{item?.modal}</td>
        <td>{item?.operator ? item?.operator?.name : "Not Assigned"}</td>
        <td>{item?.operator ? item?.operator?.operator_id : "Not Assigned"}</td>
        <td>
          {item?.orderreport.length > 0
            ? item?.orderreport.reduce(
              (a: any, v: any) =>
                (a = a + parseInt(v.cultivation_details.farm_land_area)),
              0
            )
            : "0"}
        </td>
        <td>
          {" "}
          {item?.orderreport.length > 0
            ? item?.orderreport?.reduce(
              (a: any, v: any) =>
                (a = a + (v?.cultivation_details?.total_tanks? parseInt(v?.cultivation_details?.total_tanks) : 0)),
              0
            )
            : "0"}
        </td>
        {/* <td>{item?.status ? "Active" : "Deactive"}</td> */}
        <td style={{ display: "flex", justifyContent: "space-evenly" }}>
          <ActionIcon
            variant="outline"
            onClick={() => {
              const edata = props.data?.filter(
                (e: any) => e?._id === item?._id
              );
              setEditableData(edata);
              setShowViewModal(true);
            }}
          >
            <Tooltip label="View">
              <IconEye size={20} />
            </Tooltip>
          </ActionIcon>
          {PermissionCheck(
            props.permissionList,
            "Drone_Management",
            "write"
          ) ? (
            <ActionIcon
              variant="outline"
              onClick={() => {
                setShowEditModal(true);
                setId(item?._id);
                const edata = props.data?.filter(
                  (e: any) => e?._id === item?._id
                );
                setEditableData(edata);
              }}
            >
              <Tooltip label="Update">
                <IconEdit size={20} />
              </Tooltip>
            </ActionIcon>
          ) : (
            <></>
          )}
          {PermissionCheck(
            props.permissionList,
            "Drone_Management",
            "write"
          ) ? (
            <ActionIcon
              variant="outline"
              onClick={() => {
                const edata = props.data?.filter(
                  (e: any) => e?._id === item?._id
                );
                setEditableData(edata);
                setShowAddOperatorModal(true);
                // setShowDeleteModal(true);
                //setId(item?._id);
              }}
            >
              <Tooltip label={item.assigned ? "Un Assign Operator" : "Assign Operator"}>
                {
                  item.assigned ? <IconUserCancel size={20} /> : <IconUserCheck size={20} />
                }

              </Tooltip>
            </ActionIcon>
          ) : (
            <></>
          )}
          {PermissionCheck(
            props.permissionList,
            "Drone_Management",
            "write"
          ) ? (
            <ActionIcon
              variant="outline"
              onClick={() => {
                setShowDeleteModal(true);
                setId(item?._id);
                setSelectedStatus(item.status);
              }}
            >
               <Tooltip label={item?.status ? "De-Active" : "Active"}>
                  {item?.status ? (
                    <IconPlugConnected size={20} />
                  ) : (
                    <IconPlus size={20} />
                  )}
                </Tooltip>
            </ActionIcon>
          ) : (
            <></>
          )}
        </td>
      </tr>
    );
  });

  return (
    <>
      <ScrollArea>
        {props.data.length > 0 ? (

          <div style={{ height: window.innerHeight - 330, overflowY: "scroll" }}>
            <Table verticalSpacing="sm" style={{ tableLayout: "fixed" }}>
              <thead>
                <tr>
                  <th>Drone Id</th>
                  <th>Model</th>
                  <th>Assigned Operator</th>
                  <th>Operator Id</th>
                  <th>Total Area (Acres)</th>
                  <th>Total number of tanks sprayed</th>
                  {/* <th>Status</th> */}
                  <th style={{ textAlign: "center" }}>Actions</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          </div>
        ) : (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: window.innerHeight - 285
            }}
          >
            <Text>No Data</Text>
          </Box>
        )}
      </ScrollArea>

      {props.count > 0 ? (
        <CustomPagination
          setPage={props.setPage}
          pagination={props.pagination}
          setPagination={props.setPagination}
          page={props.page}
          count={props.count}
        />
      ) : (
        <></>
      )}
      {showDeleteModal ? (
        <DeleteModal
          id={id}
          show={showDeleteModal}
          type="Drone"
          handleClose={handleDelete}
          error={props.setError}
          status={!selectedStatus}
        />
      ) : null}

      {showViewModal ? (
        <ViewModal
          id={id}
          show={showViewModal}
          type="Drone"
          data={editableData}
          handleClose={handleView}
        />
      ) : null}

      {showEditModal ? (
        <AddDroneModal
          id={id}
          show={showEditModal}
          type="Update"
          data={editableData}
          handleClose={handleEdit}
        />
      ) : null}

      {showAddOperatoeModal ? (
        <AddOperatorModal
          id={id}
          show={showAddOperatoeModal}
          type="Assign"
          data={editableData}
          handleClose={handleAddOperatorModal}
        />
      ) : null}
    </>
  );
}
