import {
  ActionIcon,
  createStyles,
  Header,
  Group,
  UnstyledButton,
  Text,
  ThemeIcon,
  Divider,
  Box,
  Burger,
  Drawer,
  rem,
  Image,
  Tooltip,
  Popover,
  Menu,
  Button,
} from "@mantine/core";
import { useDisclosure, useClickOutside } from "@mantine/hooks";
import {
  IconFingerprint,
  IconSettings,
  IconUser,
  IconUsers,
  IconBrandBooking,
  IconDrone,
  IconHome,
  IconLiveView,
  IconBell,
  IconReport,
  IconShieldLock,
  IconUsersPlus,
  IconBrandOpenvpn,
} from "@tabler/icons-react";
import logo from "../assets/iffco_logo.png";
import Settings from "../Views/Settings";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../Services/hooks/hooks";
import { useEffect, useState } from "react";
import BookingAPI from "../Redux/Booking/Services";

const useStyles = createStyles((theme) => ({
  link: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    textDecoration: "none",
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontWeight: 500,
    fontSize: theme.fontSizes.sm,

    [theme.fn.smallerThan("sm")]: {
      height: rem(42),
      display: "flex",
      alignItems: "center",
      width: "100%",
    },

    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    }),
  },

  subLink: {
    width: "100%",
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    borderRadius: theme.radius.md,

    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[7]
          : theme.colors.gray[0],
    }),

    "&:active": theme.activeStyles,
  },

  dropdownFooter: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[7]
        : theme.colors.gray[0],
    margin: `calc(${theme.spacing.md} * -1)`,
    marginTop: theme.spacing.sm,
    padding: `${theme.spacing.md} calc(${theme.spacing.md} * 2)`,
    paddingBottom: theme.spacing.xl,
    borderTop: `${rem(1)} solid ${theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[1]
      }`,
  },

  hiddenMobile: {
    [theme.fn.smallerThan("780")]: {
      display: "none",
    },
  },

  hiddenDesktop: {
    [theme.fn.largerThan("780")]: {
      display: "none",
    },
  },
}));

const drawerdata = [
  {
    icon: IconHome,
    title: "Dashboard",
    link: "/admin/dashboard",
    componentName: "Dashboard",
  },
  {
    icon: IconLiveView,
    title: "Live Track",
    link: "/admin/live-track",
    componentName: "Live_Track",
  },
  {
    icon: IconUsers,
    title: "Farmer Management",
    link: "/admin/farmer-management",
    componentName: "Farmer_Management",
  },
  {
    icon: IconBrandOpenvpn,
    title: "Operator Management",
    link: "/admin/operator-management",
    componentName: "Operator_Management",
  },
  {
    link: "/admin/drone-management",
    title: "Drone Management",
    icon: IconDrone,
    componentName: "Drone_Management",
  },
  {
    icon: IconBrandBooking,
    title: "Order Management",
    link: "/admin/booking-management",
    componentName: "Order_Management",
  },
  {
    icon: IconFingerprint,
    title: "KYC Details",
    link: "/admin/kyc-details",
    componentName: "KYC_Details",
  },
  {
    icon: IconSettings,
    title: "Configuration",
    link: "/admin/configuration",
    componentName: "Configuration",
  },
  {
    icon: IconShieldLock,
    title: "User Management",
    link: "/admin/user-management",
    componentName: "User_Management",
  },
  {
    icon: IconReport,
    title: "Report Management",
    link: "/admin/report-management",
    componentName: "User_Management",
  },
  {
    icon: IconUsersPlus,
    title: "Dealer Management",
    link: "/admin/dealer-management",
    componentName: "User_Management",
  },
];

function TopBar() {
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const { classes, theme } = useStyles();
  const navigate = useNavigate();

  function PermissionCheck(component: any) {
    const { data } = useAppSelector((state) => state.auth);
    const permissionList = data?.data?.permission?.[0]?.permission_list
      ? data?.data?.permission?.[0]?.permission_list
      : [];
    let index = permissionList.findIndex((res: any) => res.name === component);
    if (index === -1) {
      return false;
    } else {
      return permissionList[index]["read"] || permissionList[index]["write"];
    }
  }

  const links = drawerdata.map((item) => {
    return PermissionCheck(item.componentName) ? (
      <UnstyledButton
        className={classes.link}
        key={item.title}
        onClick={(event) => {
          event.preventDefault();
          navigate(item.link);
          closeDrawer();
        }}
      >
        <Group noWrap align="center">
          <ThemeIcon size={30} variant="default" radius="md">
            <item.icon size={rem(20)} color="#2C752F" />
          </ThemeIcon>
          <div>
            <Text>{item.title}</Text>
          </div>
        </Group>
      </UnstyledButton>
    ) : (
      <></>
    );
  });

  const [notificationData, setNotificationData] = useState<any>([]);

  async function getSosList() {
    const data = (await BookingAPI.getAllSosList()).data;
    let tempData = data.filter((res: any) => {
      let len = res.orderreport.pause_details.length;
      return res.orderreport.pause_details[len - 1].is_sos
    })
    setNotificationData(tempData);
  }
  useEffect(() => {
    getSosList();
  }, []);

  // const notificationData = [
  //   {
  //     bookingId: "#001",
  //     date: "27-04-2023",
  //     operatorId: "#123456",
  //     description:
  //       "There was a sudden rain, so I can't able to complete my order.",
  //   },
  //   {
  //     bookingId: "#002",
  //     date: "28-04-2023",
  //     operatorId: "#789012",
  //     description: "Delivery delayed due to traffic congestion.",
  //   },
  //   {
  //     bookingId: "#003",
  //     date: "29-04-2023",
  //     operatorId: "#345678",
  //     description: "Package lost during transit, investigating the issue.",
  //   },
  //   {
  //     bookingId: "#004",
  //     date: "30-04-2023",
  //     operatorId: "#901234",
  //     description: "Order successfully delivered. Thank you for your business.",
  //   },
  //   {
  //     bookingId: "#005",
  //     date: "01-05-2023",
  //     operatorId: "#567890",
  //     description:
  //       "Delivery rescheduled for tomorrow due to recipient unavailability.",
  //   },
  // ];

  return (
    <Box>
      <Header
        height={90}
        px="md"
        style={{
          backgroundColor: "#2C752F",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Burger
          opened={drawerOpened}
          onClick={toggleDrawer}
          className={classes.hiddenDesktop}
        />
        <Group position="apart">
          <Group>
            <Image src={logo} width={220} height={80} fit="fill" />
          </Group>
        </Group>

        <Group spacing="xs">
          <Menu shadow="md" width={200}>
            <Menu.Target>
              <Tooltip label="Notification">
                <div>
                  <Group position="center" my="xl">
                    <ActionIcon
                      variant="outline"
                      sx={{
                        width: 32,
                        height: 32,
                        borderRadius: 30,
                        backgroundColor: "#3A9343",
                        backgroundImage:
                          "linear-gradient(to bottom right, rgba(255,255,255,0.1), rgba(255,255,255,0.1))",
                        color: "#FFFFFF",
                      }}
                    >
                      <IconBell size={20} />
                    </ActionIcon>
                  </Group>
                </div>
              </Tooltip>
            </Menu.Target>

            <Menu.Dropdown style={{ maxHeight: 300, overflow: "auto", maxWidth: 500, minWidth: 350 }} className="no_scroller">
              {
                notificationData.length > 0 ?
                  <>
                    {notificationData.map((notification: any, index: any) => {
                      return <div
                        key={index}
                        style={{
                          marginTop: 10,
                          padding: 10,
                          backgroundColor: "#F5F5F5",
                          borderRadius: 16,
                        }}
                      >
                        <Group position="apart">
                          <Text size="sm" fw={700}>
                            Booking Id: <span>{notification.booking_id}</span>
                          </Text>
                          <Text size="sm">{new Date(notification.date).getDate()}-{new Date(notification.date).getMonth()}-{new Date(notification.date).getFullYear()}</Text>
                        </Group>
                        <Text size="sm">
                          Assigned Operator : <span>{notification.operator?.name}</span>
                        </Text>
                        <Text size="sm">Reason : {notification.orderreport.sos[notification.orderreport.sos.length - 1]?.reason}</Text>
                      </div>
                    })}
                  </> :
                  <div style={{ height: 100, textAlign: "center", padding: 30 }}>
                    No Data
                  </div>
              }
            </Menu.Dropdown>
          </Menu>

          <Settings>
            <Tooltip label="Profile">
              <div>
                <Group position="center" my="xl">
                  <ActionIcon
                    variant="outline"
                    sx={{
                      width: 32,
                      height: 32,
                      borderRadius: 30,
                      backgroundColor: "#3A9343",
                      backgroundImage:
                        "linear-gradient(to bottom right, rgba(255,255,255,0.1), rgba(255,255,255,0.1))",
                      color: "#FFFFFF",
                    }}
                  >
                    <IconUser size={20} />
                  </ActionIcon>
                </Group>
              </div>
            </Tooltip>
          </Settings>
        </Group>

        {/* </Group> */}
      </Header>

      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        size="70%"
        padding="md"
        title={
          <Text style={{ color: "#2C752F", fontWeight: 500 }}>
            IFFCO DRON-Ai
          </Text>
        }
        className={classes.hiddenDesktop}
        zIndex={1000000}
      >
        {/* <ScrollArea h={`calc(100vh - ${rem(60)})`} mx="-md"> */}
        <Box mx="-md">
          <Divider
            mb="sm"
            color={theme.colorScheme === "dark" ? "dark.5" : "gray.3"}
          />
          {links}
          {/* <UnstyledButton className={classes.link} onClick={toggleLinks}>
            <Group noWrap align="center">
              <ThemeIcon size={30} variant="default" radius="md">
                <IconSettings size={rem(20)} color="#2C752F" />
              </ThemeIcon>
              <div>
                <Text>Configuration</Text>
              </div>
              <IconChevronDown size={16} color={theme.fn.primaryColor()} />
            </Group>
          </UnstyledButton>
          <Collapse in={linksOpened}>{configLinks}</Collapse> */}
        </Box>
        {/* </ScrollArea> */}
      </Drawer>
    </Box>
  );
}

export default TopBar;
