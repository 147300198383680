import { put, all, fork, call, takeLatest } from "redux-saga/effects";
import { loginResponseI } from "./Interface";
import * as actions from "./actionTypes";
import { setUpdateKycFailed, setUpdateKycLoading, setUpdateKycSuccess } from "./kycSlice";
import KycAPI from "./Services";

function* updateKyc(payload: actions.updateKyc) {
  try {
    yield put(setUpdateKycLoading());
    console.log("=====Update Kyc=====", payload);
    const data: loginResponseI = yield call(KycAPI.updateKyc, payload?.data);
    yield put(setUpdateKycSuccess(data));
  } catch (error) {
    console.log("Failed to Update Kyc", error);
    yield put(setUpdateKycFailed(error));
  }
}

function* watchOnUpdateKyc() {
  yield takeLatest(actions.Update_Kyc, updateKyc);
}

export default function* kycSaga() {
  yield all([fork(watchOnUpdateKyc)]);
}
