import { useState } from "react";
import {
  Button,
  Modal,
  Group,
  Text,
  Box,
  Title,
  Grid,
  Paper,
  ActionIcon,
} from "@mantine/core";
import ImageModal from "../../Components/ImageModal";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Services/hooks/hooks";
import { updateKycAction } from "../../Redux/Kyc/kycActions";
import KycAPI from "../../Redux/Kyc/Services";
import { IconArrowLeft } from "@tabler/icons-react";
import capitalizeFirstLowercaseRest from "../../Components/Capitalize";
import DeleteModal from "../../Components/DeleteModal";
import ApproveModal from "../../Components/ApproveModal";
import PermissionCheck from "../../Components/Permission";
export default function KycVerification() {
  const stateData = useLocation().state;
  const [previewImage, setPreviewImage] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [id, setId] = useState("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handlePreview = () => {
    setPreviewImage(false);
  };
  const handleApprove = () => {
    setShowApproveModal(false);
  };

  const handleReject = () => {
    setShowRejectModal(false);
  };
  const Styles = {
    paper: {
      width: "100%",
      backgroundColor: "#FFFFFF",
      padding: 20,
      borderRadius: 20,
    },
  };
  async function updateKycDetails(status: string) {
    let params = {
      _id: stateData?.item?.kyc?._id,
      kyc_status: status,
    };
    await KycAPI.updateKyc(params);
    navigate(-1);
  }
  return (
    <Grid>
      <Grid.Col span={12}>
        <Paper style={Styles.paper}>
          <Grid>
            <Grid.Col span={12}>
              <Group>
                <ActionIcon
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <IconArrowLeft size={50} color="black" />
                </ActionIcon>
                <Title
                  style={{
                    fontSize: 30,
                    fontWeight: 300,
                    color: "#4C9C4F",
                  }}
                >
                  KYC Verification
                </Title>
              </Group>
            </Grid.Col>
            <Grid.Col span={5}>Farmer Name</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}>{stateData?.item?.name}</Grid.Col>

            <Grid.Col span={5}>Mobile</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}>{stateData?.item?.phone_no}</Grid.Col>

            <Grid.Col span={5}>KYC Document Name</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}>{stateData?.item?.kyc?.kyc_name}</Grid.Col>

            <Grid.Col span={5}>KYC Document Number</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}>{stateData?.item?.kyc?.kyc_number}</Grid.Col>

            <Grid.Col span={5}>KYC Document Status</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}>
              {capitalizeFirstLowercaseRest(stateData?.item?.kyc?.kyc_status)}
            </Grid.Col>

            <Grid.Col span={5}>KYC Document</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}>
              <Button
                variant="subtle"
                compact
                uppercase
                onClick={() => {
                  setPreviewImage(true);
                }}
                style={{ backgroundColor: "#3A9343", color: "#fff" }}
              >
                Preview
              </Button>
            </Grid.Col>

            <Grid.Col span={12}>
              <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                {/* <Button
                  style={{ marginTop: 20, background: "#FA5252" }}
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Back
                </Button> */}
                {stateData?.item?.kyc?.kyc_status === "pending" &&   PermissionCheck(stateData.permissionList, "KYC_Details", "write") ? (
                  <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      style={{ marginTop: 20, background: "#FA5252" }}
                      onClick={() => {
                        //updateKycDetails("rejected");
                        setShowRejectModal(true);
                      }}
                    >
                      Reject
                    </Button>

                    <Button
                      style={{
                        marginLeft: 20,
                        marginTop: 20,
                        background: "#2C752F",
                      }}
                      onClick={() => {
                        // updateKycDetails("approved");
                        setShowApproveModal(true);
                      }}>
                      Approve
                    </Button>
                  </Box>
                ) : (
                  <></>
                )}
              </Box>
            </Grid.Col>
          </Grid>
        </Paper>
      </Grid.Col>
      {previewImage ? (
        <ImageModal
          id={id}
          show={previewImage}
          url={stateData?.item?.kyc?.upload_document_url}
          handleClose={handlePreview}
        />
      ) : null}
      {showApproveModal ? (
        <ApproveModal
          id={id}
          show={showApproveModal}
          type="KYC"
          statusType="approve"
          handleClose={handleApprove}
          payload={{
            _id: stateData?.item?.kyc?._id,
            kyc_status: "approved",
          }}
        />
      ) : null}
      {showRejectModal ? (
        <ApproveModal
          id={id}
          show={showRejectModal}
          type="KYC"
          statusType="Reject"
          handleClose={handleReject}
          payload={{
            _id: stateData?.item?.kyc?._id,
            kyc_status: "rejected",
          }}
        />
      ) : null}
    </Grid>
  );
}
