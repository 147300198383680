import {
  Group,
  Title,
  Grid,
  Paper,
  ActionIcon,
} from "@mantine/core";
import { useLocation, useNavigate } from "react-router-dom";
import { IconArrowLeft } from "@tabler/icons-react";

export default function DealerView() {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;

  const Styles = {
    paper: {
      width: "100%",
      backgroundColor: "#FFFFFF",
      padding: 20,
      borderRadius: 20,
      // boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.20)",
    },
  };

  return (
    <Grid>
      <Grid.Col span={12}>
        <Paper style={Styles.paper}>
          <Grid>
            <Grid.Col span={12}>
              <Group>
                <ActionIcon
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <IconArrowLeft size={50} color="black" />
                </ActionIcon>
                <Title
                  style={{
                    fontSize: 30,
                    fontWeight: 300,
                    color: "#4C9C4F",
                  }}
                >
                  Dealer Details
                </Title>
              </Group>
            </Grid.Col>
            {/* <Grid.Col span={12}>
              <Text style={{ fontSize: 20, fontWeight: 600 }}>
                User Details
              </Text>
            </Grid.Col> */}
            <Grid.Col span={5}>Dealer ID</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}>{data?.dealer_id}</Grid.Col>

            <Grid.Col span={5}>Dealer Name</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}>{data?.name}</Grid.Col>

            <Grid.Col span={5}>Mobile Number</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}>{data?.phone_no}</Grid.Col>

            <Grid.Col span={5}>Email</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}>{data?.email ? data?.email : "-----"}</Grid.Col>

            <Grid.Col span={5}>Company Name</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}>{data?.company_name}</Grid.Col>

            
            <Grid.Col span={5}>GST</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}>{data?.gst? data?.gst : "-----"}</Grid.Col>
            
            <Grid.Col span={5}>Product Name</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}>{data?.product_name}</Grid.Col>

            <Grid.Col span={5}>Brand Name</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}>{data?.brand_name}</Grid.Col>

            
            <Grid.Col span={5}>Crop</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}>{data?.crop.toString()}</Grid.Col>

            <Grid.Col span={5}>Address</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}>{data?.address}</Grid.Col>

            <Grid.Col span={5}>Block</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}>{data?.block ? data?.block : "-----"}</Grid.Col>

            <Grid.Col span={5}>District</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}>
              {data?.district ? data?.district : "-----"}
            </Grid.Col>

            <Grid.Col span={5}>State</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}>{data?.state ? data?.state : "-----"}</Grid.Col>         
           
        
          </Grid>
        </Paper>
      </Grid.Col>
 
      
    </Grid>
  );
}
