import { Modal, Title, Box, ScrollArea, Button, Select } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { TextInput } from "@mantine/core";
import { Grid } from "@mantine/core";
import * as _ from "lodash";
import { IconChevronDown, IconCircleX, IconSquareRoundedX } from "@tabler/icons-react";
import { useFormik } from "formik";
import * as Yup from "yup";
import StateAPI from "../../../Redux/Configuration/State/Services";
import DistrictAPI from "../../../Redux/Configuration/District/Services";
import BlockAPI from "../../../Redux/Configuration/Block/Services";
import { Loader } from "@mantine/core";
import { get } from "lodash";
type Props = {
  id?: string;
  show: boolean;
  data?: any;
  type: string;
  handleClose: () => void;
};
const AddCropTypeModal: React.FC<Props> = ({
  id,
  show,
  data,
  type,
  handleClose,
}) => {
  const [initialValues, setInitialValues] = useState<any>(
    _.isEmpty(data)
      ? {
          name: "",
          state: "",
          district: "",
        }
      : {
          name: data[0]?.name,
          state: data[0]?.state,
          district: data[0]?.district,
        }
   );
  const [stateList, setStateList] = React.useState<any>([]);
  const [districtList, setDistrictList] = React.useState<any>([]);
  const [isError, setError] = useState<any>(false);
  const [isErrorMsg, setErrorMsg] = useState<any>("");
  const [isloading, setloading] = useState<any>(false);
  async function handleSubmit(params: any) {
    if (!isloading) {

      setloading(true);

      switch (type) {
        case "Create":
          try {
           let data= await BlockAPI.createBlock(params);
            if (get(data, "code") === 400) {
            setError(true);
            setErrorMsg("Block already exits");
          } else {
            setError(false);
            handleClose();
          }
          } catch (e) {}
          break;
        case "Update":
          try {
            let paramsData = {
              ...params,
              id: data[0]._id,
            };
            await BlockAPI.updateBlock(paramsData);
            handleClose();
          } catch (e) {}
          break;
      }
      setloading(false);
    }
  }

  const districtSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    state: Yup.string().required("State is required"),
    district: Yup.string().required("District is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: districtSchema,
    onSubmit: (values: any, { setStatus, setSubmitting }: any) => {
      handleSubmit(values);
    },
  });

  async function getStateList() {
    let data = await StateAPI.getStateAllList();
    let tempList: { value: any; label: any }[] = [];
    data?.data.map((val: any) => {
      tempList.push({
        value: val.name,
        label: val.name,
      });
    });
    setStateList(tempList);
  }

  async function getDistrictListBasedOnState() {
    let data = await DistrictAPI.getDistrictAllList({
      state: initialValues.state,
    });
    let tempList: { value: any; label: any }[] = [];
    data?.data.map((val: any) => {
      tempList.push({
        value: val.name,
        label: val.name,
      });
    });
    setDistrictList(tempList);
  }

  useEffect(() => {
    getStateList();
  }, []);

  useEffect(() => {
    getDistrictListBasedOnState();
  }, [initialValues.state]);

  return (
    <Modal
      centered
      // size="xl"
      radius="lg"
      opened={show}
      onClose={handleClose}
      withCloseButton={true}
      scrollAreaComponent={ScrollArea.Autosize}
      title={
        <Title style={{ fontSize: 25, fontWeight: 300, color: "#4C9C4F" }}>
          {type} Block
        </Title>
      }
    >
      <Box>
        <form onSubmit={formik.handleSubmit}>

        {isError ? (
            <div
              style={{ backgroundColor: "red", color: "white", padding:"5px", marginBottom: 5, display:'flex' , fontSize:'13px' }}
            >
              <IconCircleX size="20px" style=
              {{marginRight:'10px'}} /> {isErrorMsg}
            </div>
          ) : (
            ""
          )}

          <Grid>
            <Grid.Col xs={12} sm={12} md={12} lg={12}>
              <Select
                label="State "
                placeholder="Select the State"
                withAsterisk
                rightSection={<IconChevronDown size="1rem" />}
                rightSectionWidth={30}
                searchable
                value={initialValues?.state}
                onChange={(val) => {
                  setError(false);
                  setInitialValues({
                    ...initialValues,
                    state: val,
                  });
                }}
                nothingFound="No options"
                data={stateList}
              />
              {formik.touched.state && formik.errors.state && (
                <span role="alert" style={{ color: "red", fontSize: 12 }}>
                  {formik?.errors?.state.toString()}
                </span>
              )}
            </Grid.Col>

            <Grid.Col xs={12} sm={12} md={12} lg={12}>
              <Select
                label="District"
                placeholder="Select the District"
                withAsterisk
                rightSection={<IconChevronDown size="1rem" />}
                rightSectionWidth={30}
                searchable
                value={initialValues?.district}
                onChange={(val) => {
                  setError(false);
                  setInitialValues({
                    ...initialValues,
                    district: val,
                  });
                }}
                nothingFound="No options"
                data={districtList}
              />
              {formik.touched.district && formik.errors.district && (
                <span role="alert" style={{ color: "red", fontSize: 12 }}>
                  {formik?.errors?.district.toString()}
                </span>
              )}
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={12} lg={12}>
              <TextInput
                label="Block"
                placeholder="Block"
                value={initialValues?.name}
                withAsterisk
                onChange={(val) => {
                  setError(false);
                  setInitialValues({
                    ...initialValues,
                    name: val.target.value.replace(/\s+/g, ' ').trimStart(),
                  });
                }}
              />
              {formik.touched.name && formik.errors.name && (
                <span role="alert" style={{ color: "red", fontSize: 12 }}>
                  {formik?.errors?.name.toString()}
                </span>
              )}
            </Grid.Col>

            <Grid.Col span={12}>
              <Box style={{ display: "flex", justifyContent: "center" }}>
                {isloading ? (
                  <Button type="submit" style={{ backgroundColor: "#2C752F" ,  width: 100 }}>
                    <Loader size="xs" />
                  </Button>
                ) : (
                  <Button type="submit" style={{ backgroundColor: "#2C752F" }}>
                    {type}
                  </Button>
                )}
              </Box>
            </Grid.Col>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
};
export default AddCropTypeModal;