import { all, fork } from "redux-saga/effects";
import authSaga from "../Redux/Auth/LoginSaga";
import userSaga from "../Redux/User/UserSaga";
import operatorSaga from "../Redux/Operator/OperatorSaga";
import bookingSaga from "../Redux/Booking/BookingSaga";
import kycSaga from "../Redux/Kyc/kycSaga";

export default function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(userSaga),
    fork(operatorSaga),
    fork(bookingSaga),
    fork(kycSaga),
  ]);
}
