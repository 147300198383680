import {
  Card,
  Grid,
  ScrollArea,
  Title,
  Text,
  Group,
  Autocomplete,
  Select,
  Badge,
  Center,
} from "@mantine/core";
import TotalDrone from "../../assets/svg/TotalDrones.svg";
import TotalFarmer from "../../assets/svg/TotalFarmers.svg";
import TotalLandRegestered from "../../assets/svg/TotalLandRegestered.svg";
import MapIcon from "../../assets/svg/Map.svg";
import { BarChart, AreaChart, PieChart } from "./Graph";
import { useEffect, useState } from "react";
import { IconFilter, IconSearch } from "@tabler/icons-react";
import { DatePickerInput } from "@mantine/dates";
import DistrictAPI from "../../Redux/Configuration/District/Services";
import BlockAPI from "../../Redux/Configuration/Block/Services";
import StateAPI from "../../Redux/Configuration/State/Services";
import BookingAPI from "../../Redux/Booking/Services";
import CustomLoader from "../../Components/CustomLoader";
import Map from "./map";
import DroneAPI from "../../Redux/Drone/Services";
import UserAPI from "../../Redux/User/Services";
import DashboardApi from "../../Redux/Dashboard/Service";

function OverallCard(props: any) {
  return (
    <>
      <div>
        <Card
          shadow="sm"
          radius="md"
          withBorder
          style={{ marginBottom: 10, backgroundColor: "#f4f4f4" }}
        >
          <div
            style={{
              height: 50,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <div style={{ fontWeight: 500, marginTop: 10 }}>
                {props?.logo}
              </div>
              <Text style={{ fontSize: 14, fontWeight: 500 }}>
                {props?.title}
              </Text>
            </div>
            <div style={{ textAlign: "end" }}>
              <Text style={{ fontSize: 21, fontWeight: 800 }}>
                {props?.count}
              </Text>
              {props?.metrics && (
                <Text style={{ fontSize: 14, fontWeight: 500 }}>
                  ({props?.metrics})
                </Text>
              )}
            </div>
          </div>
        </Card>
      </div>
    </>
  );
}

function FertilizerCard(props: any) {
  return (
    <>
      <div>
        <Card
          shadow="sm"
          radius="md"
          withBorder
          style={{ marginBottom: 10, backgroundColor: `${props?.bgcolor}` }}
        >
          <div
            style={{
              height: 96,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              color: "#fff",
            }}
          >
            <div>
              <Text style={{ fontSize: 19, fontWeight: 600 }}>
                {props?.title}
              </Text>
            </div>
            <div style={{ textAlign: "end" }}>
              <Text style={{ fontSize: 24, fontWeight: 800 }}>
                {props?.count}
              </Text>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
}

function Dashboard() {
  const cardColors = ["#FFEBC6", "#D4F6ED", "#E2DCF9"];

  const [SelectedOrder, setSelectedOrder] = useState<any>(-1);

  const [isSearch, setIsSearch] = useState(false);
  const [DateFilterval, setDateFilterval] = useState<any>([
    new Date(new Date().setDate(new Date().getDate() - 7)),
    new Date(),
  ]);

  // const [DateFilterval, setDateFilterval] = useState<any
  // >([new Date(new Date().setDate(new Date().getDate() - 7))
  //   .toISOString()
  //   .slice(0, 10), new Date().toISOString().slice(0, 10)]);

  const [stateList, setStateList] = useState<any>([]);
  const [selectedState, setSelectedState] = useState<any>("");
  const [selectedDistrict, setSelectedDistrict] = useState<any>("");
  const [districtList, setDistrictList] = useState<any>([]);
  const [blockList, setBlockList] = useState<any>([]);
  const [selectedBlock, setSelectedBlock] = useState<any>("");
  const [selectedStatus, setSelectedStatus] = useState<any>("pending");
  const [mapView, setMapView] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  const [selectBookingUrl, setSelectedBookingUrl] = useState("");
  const [toDate, setToDate] = useState<any>(new Date());
  const [startDate, setStartDate] = useState<any>(new Date());

  async function getStateList() {
    let data = await StateAPI.getStateAllList();
    let tempList: { value: any; label: any }[] = [];
    data?.data.map((val: any) => {
      tempList.push({
        value: val.name,
        label: val.name,
      });
    });
    setStateList(tempList);
  }

  async function getDistrictListBasedOnState(state: any) {
    let data = await DistrictAPI.getDistrictAllList({
      state: state,
    });
    let tempList: { value: any; label: any }[] = [];
    data?.data.map((val: any) => {
      tempList.push({
        value: val.name,
        label: val.name,
      });
    });
    setDistrictList(tempList);
  }

  async function getBlockListBasedOnState(state: any, district: any) {
    let data = await BlockAPI.getBlocAllList({
      state: state,
      district: district,
    });
    let tempList: { value: any; label: any }[] = [];
    data?.data.map((val: any) => {
      tempList.push({
        value: val.name,
        label: val.name,
      });
    });
    setBlockList(tempList);
  }

  useEffect(() => {
    getStateList();
  }, []);

  const [BookingOrder, setBookingOrder] = useState<any>([]);
  const [overallData, setOverallData] = useState<any>();
  const [FertilizerData, setFertilizerData] = useState<any>();
  const [SummaryGraph, setSummaryGraph] = useState<any>();
  const [FertilizerGraph, setFertilizerGraph] = useState<any>();
  const [CropGraph, setCropGraph] = useState<any>([]);

  const GetBooking = async () => {
    if (
      (DateFilterval[0] && DateFilterval[1]) ||
      (!DateFilterval[0] && !DateFilterval[1])
    ) {
      try {
        let payload = {
          skip: 0,
          limit: 0,
          status: selectedStatus,
          filter: "",
          from: isSearch ? startDate : "",
          to: isSearch ? DateFilterval[1] : "",
          state: selectedState,
          district: selectedDistrict,
          block: selectedBlock,
        };
        const response = await BookingAPI.getBookingListByBookingStatus(
          payload
        );
        const data = response?.data?.[0]?.data || [];
        setBookingOrder(data);
        setLocalLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setBookingOrder([]);
        setLocalLoading(false);
      }
      setLocalLoading(false);
    }
  };

  const GetOverallData = async () => {
    try {
      let payload = {
        from: isSearch ? DateFilterval[0]: "",
        to: isSearch ? toDate : "",
        state: selectedState,
        district: selectedDistrict,
        block: selectedBlock,
      };
      const res = await DashboardApi.getOveralldata(payload);
      setOverallData(res.data);
    } catch (error) {
      console.log("Error while geting the Overall data ", error);
    }
  };

  const GetFertilizer = async () => {
    try {
      let payload = {
        from: isSearch ? DateFilterval[0] : "",
        to: isSearch ? toDate : "",
        state: selectedState,
        district: selectedDistrict,
        block: selectedBlock,
      };
      let data = (await BookingAPI.totalFertilizerQuantity(payload)).data;
      setFertilizerData(data);
    } catch (err) {
      console.log("Error while geting Fertilizer ", err);
    }
  };

  const GetSummaryData = async () => {
    try {
      if (
        (DateFilterval[0] && DateFilterval[1]) ||
        (!DateFilterval[0] && !DateFilterval[1])
      ) {
        const payload = {
          start_date: DateFilterval[0],
          end_date: toDate,
          state: selectedState,
          district: selectedDistrict,
          block: selectedBlock,
        };
        const res = await DashboardApi.getLandcount(payload);
        setSummaryGraph(res);
      }
    } catch (err) {
      setSummaryGraph([]);
      console.log("Error while geting summery data[1:1]", err);
    }
  };

  const GetCropData = async () => {
    try {
      if (
        (DateFilterval[0] && DateFilterval[1]) ||
        (!DateFilterval[0] && !DateFilterval[1])
      ) {
        const payload = {
          start_date: isSearch ? startDate : "",
          end_date: isSearch ? DateFilterval[1] : "",
          state: selectedState,
          district: selectedDistrict,
          block: selectedBlock,
        };
        const res = (await DashboardApi.getCropcount(payload))?.data;
        setCropGraph(res);
      }
    } catch (err) {
      setCropGraph([]);
      console.log("Error while geting the Cropdata", err);
    }
  };

  const GetFertilizerData = async () => {
    try {
      if (
        (DateFilterval[0] && DateFilterval[1]) ||
        (!DateFilterval[0] && !DateFilterval[1])
      ) {
        const payload = {
          start_date: DateFilterval[0],
          end_date: toDate,
          state: selectedState,
          district: selectedDistrict,
          block: selectedBlock,
        };
        const res = (await DashboardApi.getFertilizercount(payload)).data;
        setFertilizerGraph(res);
      }
    } catch (err) {
      console.log("Error while geting summery data[1:1]", err);
    }
  };

  useEffect(() => {
    GetBooking();
    GetOverallData();
    GetFertilizer();
    GetSummaryData();
    GetFertilizerData();
    GetCropData();
  }, [DateFilterval, selectedState, selectedBlock, selectedDistrict]);

  useEffect(() => {
    GetBooking();
  }, [
    selectedStatus,
    DateFilterval,
    selectedState,
    selectedBlock,
    selectedDistrict,
  ]);

  const overallDetials = [
    {
      logo: (
        <img
          src={TotalFarmer}
          style={{ color: "green", width: 30, height: 30 }}
        />
      ),
      title: "Total Farmers",
      count: overallData?.activeUsersCount[0]?.count ? overallData?.activeUsersCount[0]?.count : 0,
      metrics: "",
    },
    {
      logo: (
        <img
          src={TotalLandRegestered}
          style={{ color: "blue", width: 30, height: 30 }}
        />
      ),
      title: "Total Land Registered",
      count: overallData?.TotalRegisteredLandCounts[0]?.total
        ? overallData?.TotalRegisteredLandCounts[0]?.total
        : 0,
      metrics: "Acres",
    },
    {
      logo: <img src={TotalDrone} style={{ width: 30, height: 30 }} />,
      title: "Total Drone",
      count: overallData?.activeDrones,
      metrics: "",
    },
  ];

  const FertilizerDetials = [
    {
      title: "IFFCO NU",
      count: FertilizerData?.NUCounts[0]?.total
        ? FertilizerData?.NUCounts[0]?.total
        : 0,
      bgcolor: "#2c752f",
    },
    {
      title: "IFFCO NDAP",
      count: FertilizerData?.NDAPCounts[0]?.total
        ? FertilizerData?.NDAPCounts[0]?.total
        : 0,
      bgcolor: "#fcb503",
    },
  ];

  return (
    <>
      {mapView ? (
        <Map setMapView={setMapView} />
      ) : (
        <>
          <div style={{ color: "#000", fontWeight: 700, fontSize: 30 }}>
            <Group position="apart">
              <div>Dashboard</div>
              <IconFilter
                onClick={() => {
                  setIsSearch(!isSearch);
                  if (isSearch) {
                    setSelectedBlock("");
                    setSelectedDistrict("");
                    setSelectedState("");
                    setDateFilterval([
                      new Date(new Date().setDate(new Date().getDate() - 7)),
                      new Date(),
                    ]);
                  }
                }}
              />
            </Group>
          </div>

          {isSearch ? (
            <div
              style={{
                backgroundColor: "#eee6fc",
                // height: 70,
                marginBottom: 10,
                padding: 10,
              }}
            >
              <Grid>
                <Grid.Col xs={12} sm={3} md={3} lg={3}>
                  <DatePickerInput
                    type="range"
                    placeholder="Pick dates range"
                    value={DateFilterval}
                    onChange={(val) => {                     
                      setDateFilterval(val);
                      let start = val[0] ? new Date(val[0]) :null;
                      start?.setDate(start.getDate() - 1);
                      setStartDate(start) ;
                      let to = val[1] ? new Date(val[1]) :null;
                      to?.setDate(to.getDate() + 1);
                      setToDate(to) ;
                    }}
                    //  mx="auto"
                    maw={400}
                  />
                </Grid.Col>
                <Grid.Col xs={12} sm={3} md={3} lg={3}>
                  <Autocomplete
                    placeholder="Filter by State"
                    sx={{ maxHeight: 200, overflow: "auto" }}
                    data={stateList}
                    value={selectedState}
                    nothingFound="No options"
                    onChange={(e) => {
                      setSelectedState(e);
                      setSelectedBlock("");
                      setSelectedDistrict("");
                      getDistrictListBasedOnState(e);
                    }}
                  />
                </Grid.Col>
                <Grid.Col xs={12} sm={3} md={3} lg={3}>
                  <Autocomplete
                    placeholder="Filter by District"
                    sx={{ maxHeight: 200, overflow: "auto" }}
                    data={districtList}
                    value={selectedDistrict}
                    nothingFound="No options"
                    onChange={(e) => {
                      setSelectedDistrict(e);
                      setSelectedBlock("");
                      getBlockListBasedOnState(selectedState, e);
                    }}
                  />
                </Grid.Col>
                <Grid.Col xs={12} sm={3} md={3} lg={3}>
                  <Autocomplete
                    placeholder="Filter by Block"
                    sx={{ maxHeight: 200, overflow: "auto" }}
                    data={blockList}
                    nothingFound="No options"
                    value={selectedBlock}
                    onChange={(e) => {
                      setSelectedBlock(e);
                    }}
                  />
                </Grid.Col>
              </Grid>
            </div>
          ) : null}
          <Grid>
            <Grid.Col xs={12} md={12} lg={3.5}>
              <Card
                shadow="sm"
                radius="md"
                withBorder
                style={{
                  backgroundColor: "#f4f4f4",
                  height: 340,
                }}
              >
                <AreaChart
                  setMapView={setMapView}
                  Graphdata={SummaryGraph}
                  date={DateFilterval}
                />
              </Card>
            </Grid.Col>

            <Grid.Col xs={12} md={12} lg={3.5}>
              <Card
                shadow="sm"
                radius="md"
                withBorder
                style={{
                  marginBottom: 10,
                  backgroundColor: "#f4f4f4",
                  height: 340,
                }}
              >
                <PieChart GraphData={CropGraph} />
              </Card>
            </Grid.Col>

            <Grid.Col xs={12} md={12} lg={5}>
              <Card
                shadow="sm"
                radius="md"
                withBorder
                style={{
                  marginBottom: 10,
                  backgroundColor: "#f4f4f4",
                  height: 340,
                  //  overflow: "auto",
                }}
              >
                <Group position="apart" style={{ paddingBottom: 25 }}>
                  <div
                    style={{
                      color: "#000",
                      fontSize: 20,
                      fontWeight: 700,
                    }}
                  >
                    Booking Orders
                  </div>
                  {SelectedOrder < 0 ? (
                    <Select
                      onChange={(val) => {
                        setSelectedStatus(val);
                      }}
                      value={selectedStatus}
                      data={[
                        { value: "pending", label: "Pending" },
                        { value: "approved", label: "Approved" },
                        { value: "started", label: "Started" },
                        { value: "completed", label: "Completed" },
                        { value: "rejected", label: "Rejected" },
                      ]}
                      radius="xl"
                    />
                  ) : null}
                </Group>
                <div style={{ overflow: "auto" }}>
                  {!localLoading ? (
                    BookingOrder.length > 0 ? (
                      SelectedOrder >= 0 ? (
                        <ScrollArea h={250} offsetScrollbars>
                          <div
                            style={{
                              marginBottom: 10,
                              backgroundColor:
                                cardColors[SelectedOrder % cardColors.length],
                              padding: 20,
                              borderRadius: 16,
                            }}
                            onClick={() => {
                              setSelectedOrder(-1);
                              setSelectedBookingUrl("");
                            }}
                          >
                            <div>
                              <Group position="apart">
                                <div style={{ fontSize: 14 }}>
                                  <div
                                    style={{
                                      padding: 6,
                                      backgroundColor: "#fff",
                                      borderRadius: 30,
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    { BookingOrder[SelectedOrder]?.open_slot && selectedStatus === "pending"? "Open Slot" :new Date(
                                      BookingOrder[SelectedOrder]?.date
                                    ).toLocaleDateString("en-US", {
                                      year: "numeric",
                                      month: "long",
                                      day: "numeric",
                                    })}
                                  </div>
                                  <div style={{ marginTop: 10 }}>
                                    Order ID :{" "}
                                    <span style={{ fontWeight: 600 }}>
                                      {BookingOrder[SelectedOrder]?.booking_id}
                                    </span>
                                  </div>
                                </div>
                                <div>
                                  <div style={{ textAlign: "end" }}>
                                    <a href={selectBookingUrl} target="_blank">
                                      <img
                                        src={MapIcon}
                                        style={{
                                          color: "green",
                                          width: 30,
                                          height: 30,
                                        }}
                                      />
                                    </a>
                                  </div>
                                  <div
                                    style={{
                                      fontWeight: 600,
                                      fontSize: 18,
                                      marginTop: 10,
                                    }}
                                  >
                                    {
                                      selectedStatus !== "pending" && selectedStatus !== "approved" && selectedStatus !== "rejected"?
                                        BookingOrder[SelectedOrder]?.operatorReport[0]?.cultivation_details.farm_land_area :
                                        BookingOrder[SelectedOrder]?.cultivateData
                                          ?.cultivation_area
                                    }{" "}
                                    Acres
                                  </div>
                                </div>
                              </Group>
                              <div style={{ marginTop: 10 }} onClick={() =>{ console.log("------->>> test", BookingOrder[SelectedOrder]?.cultivateData
                              .pesticide_used , BookingOrder[SelectedOrder]?.operatorReport[0]?.cultivation_details.pesticide)}} >
                                Farmer Name :{" "}
                                <span style={{ fontWeight: 600 }}>
                                  {BookingOrder[SelectedOrder]?.user?.name}
                                </span>
                              </div>
                              <div style={{ marginTop: 10 }}>
                                Location :{" "}
                                <span style={{ fontWeight: 600 }}>
                                  {
                                    BookingOrder[SelectedOrder]
                                      ?.farm_land_address
                                  }
                                </span>
                              </div>
                              <div style={{ marginTop: 10 }}>
                                Status :{" "}
                                <span style={{ fontWeight: 600 }}>
                                  {selectedStatus}
                                </span>
                              </div>
                            </div>
                          </div>

                          <Grid
                            style={{ paddingLeft: 10 }}
                            onClick={() => {
                              setSelectedOrder(-1);
                              setSelectedBookingUrl("");
                            }}
                          >
                            <Badge
                              style={{
                                padding: 6,
                                backgroundColor: "#fff",
                                borderRadius: 30,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "black",
                                height: 40,
                                marginRight: 20,
                                marginTop: 20,
                              }}
                            >
                              <div
                                style={{
                                  paddingInlineStart: 10,
                                  paddingInlineEnd: 10,
                                }}
                              >
                                {" "}
                                <Text style={{ fontSize: 14, fontWeight: 400 }}>
                                  {" "}
                                  {
                                    selectedStatus !== "pending" && selectedStatus !== "approved" && selectedStatus !== "rejected"?
                                      BookingOrder[SelectedOrder]?.operatorReport[0]?.cultivation_details.crop :
                                      BookingOrder[SelectedOrder]?.cultivateData
                                        .crop_type
                                  }
                                </Text>
                              </div>
                            </Badge>{" "}
                            <Badge
                              style={{
                                padding: 6,
                                backgroundColor: "#fff",
                                borderRadius: 30,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "black",
                                height: 40,
                                marginRight: 20,
                                marginTop: 20,
                              }}
                            >
                              <div
                                style={{
                                  paddingInlineStart: 10,
                                  paddingInlineEnd: 10,
                                }}
                              >
                                {" "}
                                <Text style={{ fontSize: 14, fontWeight: 400 }}>
                                  {
                                    selectedStatus !== "pending" && selectedStatus !== "approved" && selectedStatus !== "rejected"?
                                      BookingOrder[SelectedOrder]?.operatorReport[0]?.cultivation_details.fertilizer :
                                      BookingOrder[SelectedOrder]?.cultivateData
                                        .fertilizer_used
                                  }
                                </Text>
                              </div>
                            </Badge>{" "}
                            {( selectedStatus == "pending" && selectedStatus == "approved" && selectedStatus == "rejected" && BookingOrder[SelectedOrder]?.cultivateData
                              .pesticide_used )||  BookingOrder[SelectedOrder]?.operatorReport[0]?.cultivation_details.pesticide ? (
                              <Badge
                                style={{
                                  padding: 6,
                                  backgroundColor: "#fff",
                                  borderRadius: 30,
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  color: "black",
                                  height: 40,
                                  marginRight: 20,
                                  marginTop: 20,
                                }}
                              >
                                <div
                                  style={{
                                    paddingInlineStart: 10,
                                    paddingInlineEnd: 10,
                                  }}
                                >
                                  {" "}
                                  <Text
                                    style={{ fontSize: 14, fontWeight: 400 }}
                                  >
                                    {
                                      selectedStatus !== "pending" && selectedStatus !== "approved" && selectedStatus !== "rejected" ?
                                        BookingOrder[SelectedOrder]?.operatorReport[0]?.cultivation_details.pesticide :
                                        BookingOrder[SelectedOrder]?.cultivateData
                                          .pesticide_used
                                    }
                                  </Text>
                                </div>
                              </Badge>
                            ) : (
                              <></>
                            )}{" "}
                            {(selectedStatus == "pending" && selectedStatus == "approved" && selectedStatus == "rejected" && BookingOrder[SelectedOrder]?.cultivateData
                              .variety) || BookingOrder[SelectedOrder]?.operatorReport[0]?.cultivation_details.variety ? (
                              <Badge
                                style={{
                                  padding: 6,
                                  backgroundColor: "#fff",
                                  borderRadius: 30,
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  color: "black",
                                  height: 40,
                                  marginRight: 20,
                                  marginTop: 20,
                                }}
                              >
                                <div
                                  style={{
                                    paddingInlineStart: 10,
                                    paddingInlineEnd: 10,
                                  }}
                                >
                                  {" "}
                                  <Text
                                    style={{ fontSize: 14, fontWeight: 400 }}
                                  >
                                    {
                                      selectedStatus !== "pending" && selectedStatus !== "approved" && selectedStatus !== "rejected"?
                                        BookingOrder[SelectedOrder]?.operatorReport[0]?.cultivation_details.variety :
                                        BookingOrder[SelectedOrder]?.cultivateData
                                          .variety
                                    }
                                  </Text>
                                </div>
                              </Badge>
                            ) : (
                              <></>
                            )}{" "}
                          </Grid>
                        </ScrollArea>
                      ) : (
                        <ScrollArea h={250} offsetScrollbars>
                          {BookingOrder.map((value: any, key: any) => {
                            let url = `https://www.google.com/maps?q=${value?.farm_land_location[0]},${value?.farm_land_location[1]}`;
                            return (
                              <div key={key} style={{ overflow: "auto" }}>
                                <Card
                                  shadow="sm"
                                  radius="md"
                                  withBorder
                                  style={{
                                    marginBottom: 10,
                                    backgroundColor:
                                      cardColors[key % cardColors.length],
                                  }}
                                  onClick={() => {
                                    setSelectedOrder(key);
                                    setSelectedBookingUrl(url);
                                  }}
                                >
                                  <>
                                    <Group position="apart">
                                      <div>
                                        <div
                                          style={{
                                            padding: 6,
                                            backgroundColor: "#fff",
                                            borderRadius: 30,
                                            display: "flex",
                                            fontSize: 14,
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          {value?.open_slot && selectedStatus === "pending"? "Open Slot" :
                                          new Date(
                                            value?.date
                                          ).toLocaleDateString("en-US", {
                                            year: "numeric",
                                            month: "long",
                                            day: "numeric",
                                          })}
                                        </div>
                                        <div
                                          style={{
                                            marginTop: 10,
                                            fontSize: 14,
                                          }}
                                        >
                                          Order ID :{" "}
                                          <span style={{ fontWeight: 600 }}>
                                            {value?.booking_id}
                                          </span>
                                        </div>
                                      </div>
                                      <div>
                                        <div style={{ textAlign: "end" }}>
                                          <a href={url} target="_blank">
                                            <img
                                              src={MapIcon}
                                              style={{
                                                color: "green",
                                                width: 30,
                                                height: 30,
                                              }}
                                            />
                                          </a>
                                        </div>
                                        <div
                                          style={{
                                            fontWeight: 600,
                                            fontSize: 18,
                                            marginTop: 10,
                                          }}
                                        >
                                          {
                                            selectedStatus !== "pending" && selectedStatus !== "approved" && selectedStatus !== "rejected" ?
                                              value?.operatorReport[0]?.cultivation_details.farm_land_area :
                                              value?.cultivateData
                                                ?.cultivation_area
                                          }{" "}
                                          Acres
                                        </div>
                                      </div>
                                    </Group>
                                  </>
                                </Card>
                              </div>
                            );
                          })}
                        </ScrollArea>
                      )
                    ) : (
                      <>
                        <div
                          style={{
                            width: "100%",
                            height: "250px",
                            padding: 100,
                            textAlign: "center",
                          }}
                        >
                          No Data
                        </div>
                      </>
                    )
                  ) : (
                    <CustomLoader />
                  )}
                </div>
              </Card>
            </Grid.Col>
          </Grid>

          <Grid>
            <Grid.Col xs={12} md={3.5} lg={3.5}>
              <Title
                style={{
                  color: "#000",
                  fontSize: 20,
                  fontWeight: 700,
                  paddingBottom: 10,
                  paddingBlock: 20,
                }}
              >
                Overall Details
              </Title>
              {overallDetials.map((value, key) => (
                <div key={key}>
                  <OverallCard
                    logo={value?.logo}
                    title={value?.title}
                    count={value?.count}
                    metrics={value?.metrics}
                  />
                </div>
              ))}
            </Grid.Col>
            <Grid.Col xs={12} md={3.5} lg={3.5}>
              <Title
                style={{
                  color: "#000",
                  fontSize: 20,
                  fontWeight: 700,
                  paddingBottom: 10,
                  paddingBlock: 20,
                }}
              >
                Fertilizer Details
              </Title>
              {FertilizerDetials.map((value, key) => (
                <div key={key}>
                  <FertilizerCard
                    title={value?.title}
                    count={value?.count}
                    bgcolor={value.bgcolor}
                  />
                </div>
              ))}
            </Grid.Col>
            <Grid.Col xs={12} md={5} lg={5}>
              <Title
                style={{
                  color: "#000",
                  fontSize: 20,
                  fontWeight: 700,
                  paddingBottom: 10,
                  paddingBlock: 20,
                }}
              >
                Fertilizer Summary
              </Title>
              <BarChart GraphData={FertilizerGraph} date={DateFilterval} />
            </Grid.Col>
          </Grid>
        </>
      )}
    </>
  );
}

export default Dashboard;
