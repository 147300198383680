import { useState } from "react";
import {
  Button,
  Modal,
  Group,
  Text,
  Box,
  Title,
  Grid,
  Paper,
  ActionIcon,
} from "@mantine/core";
import ImageModal from "../../Components/ImageModal";
import { useLocation, useNavigate } from "react-router-dom";
import ApproveModal from "../../Components/ApproveModal";
import { IconArrowLeft } from "@tabler/icons-react";

export default function UserVerify() {
  const [previewImage, setPreviewImage] = useState(false);
  const [id, setId] = useState("");
  const navigate = useNavigate();
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const location = useLocation();
  const data = location.state.item;

  const handlePreview = () => {
    setPreviewImage(false);
  };

  const handleApprove = () => {
    setShowApproveModal(false);
  };

  const handleReject = () => {
    setShowRejectModal(false);
  };

  const Styles = {
    paper: {
      width: "100%",
      backgroundColor: "#FFFFFF",
      padding: 20,
      borderRadius: 20,
      // boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.20)",
    },
  };

  return (
    <Grid>
      <Grid.Col span={12}>
        <Paper style={Styles.paper}>
          <Grid>
            <Grid.Col span={12}>
              <Group>
                <ActionIcon
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <IconArrowLeft size={50} color="black" />
                </ActionIcon>
                <Title
                  style={{
                    fontSize: 30,
                    fontWeight: 300,
                    color: "#4C9C4F",
                  }}
                >
                  User Details
                </Title>
              </Group>
            </Grid.Col>
            {/* <Grid.Col span={12}>
              <Text style={{ fontSize: 20, fontWeight: 600 }}>
                User Details
              </Text>
            </Grid.Col> */}
            <Grid.Col span={5}>Farmer ID</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}>{data?.farmer_id}</Grid.Col>

            <Grid.Col span={5}>Farmer Name</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}>{data?.name}</Grid.Col>

            <Grid.Col span={5}>Mobile Number</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}>{data?.phone_no}</Grid.Col>

            <Grid.Col span={5}>Farm Land Area Registered</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}>{data?.farm_land_area}</Grid.Col>

            <Grid.Col span={5}>Farm Land Area Sprayed</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}>{data?.sparyCount}</Grid.Col>

            <Grid.Col span={5}>Address</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}>{data?.address}</Grid.Col>

            <Grid.Col span={5}>Block</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}>{data?.block ? data?.block : "-----"}</Grid.Col>

            <Grid.Col span={5}>District</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}>
              {data?.district ? data?.district : "-----"}
            </Grid.Col>

            <Grid.Col span={5}>State</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}>{data?.state ? data?.state : "-----"}</Grid.Col>

            <Grid.Col span={12}>
              <Text style={{ fontSize: 20, fontWeight: 600 }}>
                Verification Status
              </Text>
            </Grid.Col>

            <Grid.Col span={5}>OTP Verification</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}>
              {data?.otp_verification_status ? (
                <Text style={{ color: "green", fontWeight: 600 }}>Success</Text>
              ) : (
                <Text style={{ color: "red", fontWeight: 600 }}>Failed</Text>
              )}
            </Grid.Col>

            <Grid.Col span={5}>KYC Document</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}>
              {data?.kyc_verification_status ? (
                <Text style={{ color: "green", fontWeight: 600 }}>
                  Uploaded
                </Text>
              ) : (
                <Text style={{ color: "red", fontWeight: 600 }}>
                  Not Upload
                </Text>
              )}
            </Grid.Col>

            <Grid.Col span={12}>
              <Box style={{ display: "flex", justifyContent: "space-between" }}>
                {/* <Button
                  style={{ marginTop: 20, background: "#FA5252" }}
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Back
                </Button> */}
                <Box style={{ display: "flex", justifyContent: "flex-start" }}>
                  {/* {data?.account_status == "pending" ? (
                    <Button
                      style={{ marginTop: 20, background: "#FA5252" }}
                      onClick={() => {
                        setShowRejectModal(true);
                        setId(data?._id);
                      }}
                    >
                      Reject
                    </Button>
                  ) : (
                    <></>
                  )} */}
                  {/* {data?.account_status == "pending" ? (
                    <Button
                      style={{
                        marginLeft: 20,
                        marginTop: 20,
                        background: "#2C752F",
                      }}
                      onClick={() => {
                        setShowApproveModal(true);
                        setId(data?._id);
                      }}
                    >
                      Approve
                    </Button>
                  ) : (
                    <></>
                  )} */}
                </Box>
              </Box>
            </Grid.Col>
          </Grid>
        </Paper>
      </Grid.Col>
      {showApproveModal ? (
        <ApproveModal
          id={id}
          show={showApproveModal}
          type="User"
          payload={{}}
          statusType="approve"
          handleClose={handleApprove}
        />
      ) : null}
      {showRejectModal ? (
        <ApproveModal
          id={id}
          show={showRejectModal}
          type="User"
          payload={{}}
          statusType="reject"
          handleClose={handleReject}
        />
      ) : null}
    </Grid>
  );
}
