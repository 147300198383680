import apiInstance from "../../Services";

const PermissionAPI = {
  createPermission: async (payload: any) => {
    try {
      const res = await apiInstance.post<any>(`/permission/create`, payload);
      return res;
    } catch (error) {
      throw error;
    }
  },

  getPermissionList: async (payload: any) => {
    try {
      const res = await apiInstance.get<any>(
        `/permission/get?skip=${payload.skip}&limit=${payload.limit}&filter=${payload.filter}&status=${payload.status}`
      );
      return res;
    } catch (error) {
      throw error;
    }
  },

  //   getUserListByAccountStatus: async (payload: GetDroneByStatus) => {
  //     try {
  //       const res = await apiInstance.get<any>(
  //         `/user/userByAccountStaus?skip=${payload.skip}&limit=${payload.limit}&status=${payload.status}&filter=${payload.filter}`
  //       );
  //       return res;
  //     } catch (error) {
  //       throw error;
  //     }
  //   },

  updatePermission: async (payload: any) => {
    try {
      const res = await apiInstance.put<any>(
        `/permission/update?id=${payload?.id}`,
        payload
      );
      return res;
    } catch (error) {
      throw error;
    }
  },

  deletePermission: async (payload: any) => {
    try {
      const res = await apiInstance.put<any>(
        `/permission/delete?id=${payload.id}`
      );
      return res;
    } catch (error) {
      throw error;
    }
  },
};

export default PermissionAPI;
