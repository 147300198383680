import { createSlice } from "@reduxjs/toolkit";
import {  State } from "./Interface";

const initialState: State = {
  loading: false,
  error: null,
  data: {
    message: "",
    data: { count: 0, data: [] },
    status: false,
    code: 0,
  },
  notify: false,
  notifymsg: "",
};

export const operatorSlice = createSlice({
  name: "operator",
  initialState,
  reducers: {
    setCreateOperatorLoading: (state) => {
      state.loading = true;
    },
    setCreateOperatorDetailsSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload;
      state.notify = false;
      state.notifymsg = "";
    },
    setCreateOperatorSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload;
      state.notifymsg = action.payload;
      state.notify = true;
    },
    setGetOperatorListLoading: (state) => {
      state.loading = true;
    },
    setGetOperatorListSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload;
      state.notifymsg = action.payload;
      state.notify = true;
    },
    setGetOperatorListFailed: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload;
      state.notifymsg = action.payload;
      state.notify = true;
    },
    setCreateOperatorFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.notify = true;
    },
    setNotifyFalse: (state, action) => {
      state.notifymsg = "";
      state.notify = false;
      state.error = null;
    }
  },
});


export const {
  setCreateOperatorLoading,
  setCreateOperatorDetailsSuccess,
  setCreateOperatorSuccess,
  setCreateOperatorFailure,
  setGetOperatorListLoading,
  setGetOperatorListSuccess,
  setGetOperatorListFailed,
  setNotifyFalse
} = operatorSlice.actions;

export default operatorSlice.reducer;
