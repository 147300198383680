import React, { useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement,
  Filler,
} from "chart.js";
import { Bar, Doughnut, Line } from "react-chartjs-2";
import MapIcon from "../../assets/svg/Map.svg";
import { Center, Group, MultiSelect, ScrollArea, Select } from "@mantine/core";
import { IconFilter } from "@tabler/icons-react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement,
  Filler
);

interface BarChartProps {
  GraphData: any;
  date: any;
}

export const BarChart: React.FC<BarChartProps> = ({ GraphData, date }) => {
  const options: object = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        align: "end",
      },
      title: {
        display: true,
        text: "Chart.js Bar Chart",
      },
      tooltip: {
        enabled: true,
        mode: "index",
        intersect: false,
        custom: (context: any) => {
          const { chart, tooltip } = context;
          const { data } = chart.tooltip;
          if (!data || data.length === 0) {
            tooltip.dispose();
            return;
          }
          const maxIndex = data[0].data.indexOf(Math.max(...data[0].data));
          const minIndex = data[0].data.indexOf(Math.min(...data[0].data));
          tooltip.title = [data[0].labels[maxIndex], data[0].labels[minIndex]];
          tooltip.body = [
            `${data[0].dataset.label}: ${data[0].data[maxIndex]}`,
            `${data[0].dataset.label}: ${data[0].data[minIndex]}`,
          ];
        },
      },
    },
    elements: {
      point: {
        radius: 0.3,
      },
    },
    interaction: {
      mode: "nearest",
    },
    scales: {
      x: {
        display: true,
        grid: {
          display: false,
          drawBorder: true,
        },
      },
      y: {
        display: true,
        grid: {
          display: false,
          drawBorder: true,
        },
        ticks: {
          stepSize: 1
        }
      //  max: 200,
      },
    },
  };

  const NUCounts = GraphData?.NUCounts;
  const NDAPCounts = GraphData?.NDAPCounts;

  const endDate = new Date(date[1]);
  const startDate = new Date(date[0]);
  // startDate.setDate(startDate.getDate() - 6);

  const labels: string[] = [];
  const NUData: number[] = [];
  const NDAPData: number[] = [];

  const dateMap = new Map();

  if (NUCounts && NDAPCounts) {
    NUCounts.forEach((item: any) => {
      // const date = new Date(item.date).toLocaleDateString("en-US");
      const day = new Date(item.date).getDate().toString().padStart(2, "0");
      const month = (new Date(item.date).getMonth() + 1).toString().padStart(2, "0");
      const date = `${day}/${month}`;
      dateMap.set(date, {
        ...dateMap.get(date),
        date,
        NUCount: item.total_value,
      });
    });

    NDAPCounts.forEach((item: any) => {
      // const date = new Date(item.date).toLocaleDateString("en-US");
      const day = new Date(item.date).getDate().toString().padStart(2, "0");
      const month = (new Date(item.date).getMonth() + 1).toString().padStart(2, "0");
      const date = `${day}/${month}`;
      dateMap.set(date, {
        ...dateMap.get(date),
        date,
        NDAPCount: item.total_value,
      });
    });

    const currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      // const date = currentDate.toLocaleDateString("en-US");
      const day = currentDate.getDate().toString().padStart(2, "0");
      const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
      const date = `${day}/${month}`;
      const data = dateMap.get(date) || {
        date,
        NUCount: 0,
        NDAPCount: 0,
      };
      labels.push(date);
      NUData.push(data.NUCount);
      NDAPData.push(data.NDAPCount);
      currentDate.setDate(currentDate.getDate() + 1);
    }
  }

  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: "IFFCO NU",
        data: NUData || [],
        borderColor: "rgb(113, 242, 199,0)",
        backgroundColor: "#2C752F",
        lineTension: 0.4,
      },
      {
        fill: true,
        label: "NDAP",
        data: NDAPData || [],
        borderColor: "rgba(255, 99, 132, 0)",
        backgroundColor: "#FCB503",
        lineTension: 0.4,
      },
    ],
  };

  return (
    <div className="chart-container" style={{ minHeight: "270px" }}>
      <Bar
        options={options}
        data={data}
        style={{ backgroundColor: "#f4f4f4" }}
      />
    </div>
  );
};

interface AreaChartProps {
  Graphdata: any;
  setMapView: any;
  date: any;
}

export const AreaChart: React.FC<AreaChartProps> = ({
  setMapView,
  Graphdata,
  date,
}) => {
  const options: object = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: "Chart.js Line Chart",
      },
      tooltip: {
        enabled: true,
        mode: "index",
        intersect: false,
        custom: (context: any) => {
          const { chart, tooltip } = context;
          const { data } = chart.tooltip;
          if (!data || data.length === 0) {
            tooltip.dispose();
            return;
          }
          const maxIndex = data[0].data.indexOf(Math.max(...data[0].data));
          const minIndex = data[0].data.indexOf(Math.min(...data[0].data));
          tooltip.title = [data[0].labels[maxIndex], data[0].labels[minIndex]];
          tooltip.body = [
            `${data[0].dataset.label}: ${data[0].data[maxIndex]}`,
            `${data[0].dataset.label}: ${data[0].data[minIndex]}`,
          ];
        },
      },
    },
    elements: {
      point: {
        radius: 3,
        
      },
      
    },
    interaction: {
      mode: "nearest",
    },
    scales: {
      x: {
        display: true,
        grid: {
          display: false,
          drawBorder: true,
        },
      },
      y: {
        display: true,
        grid: {
          display: false,
          drawBorder: true,
        },
       // max: 200,
      },
    },
  };

  const regesteredCounts = Graphdata?.totalRegisteredLandCounts;
  const sprayedCounts = Graphdata?.sprayedCounts;

  const endDate = new Date(date[1]);
  const startDate = new Date(date[0]);
  //startDate.setDate(startDate.getDate() - 6);

  const labels: string[] = [];
  const regesteredData: number[] = [];
  const sprayedData: number[] = [];

  const dateMap = new Map();

  if (regesteredCounts && sprayedCounts) {
    regesteredCounts.forEach((item: any) => {
      // const date = new Date(item._id).toLocaleDateString("en-US");
      const day = new Date(item._id).getDate().toString().padStart(2, "0");
      const month = (new Date(item._id).getMonth() + 1).toString().padStart(2, "0");
      const date = `${day}/${month}`;
      dateMap.set(date, {
        ...dateMap.get(date),
        date,
        regesteredCounts: item.total_value,
      });
    });

    sprayedCounts.forEach((item: any) => {
      // const date = new Date(item.date).toLocaleDateString("en-US");
      const day = new Date(item.date).getDate().toString().padStart(2, "0");
      const month = (new Date(item.date).getMonth() + 1).toString().padStart(2, "0");
      const date = `${day}/${month}`;
      dateMap.set(date, {
        ...dateMap.get(date),
        date,
        sprayedCounts: item.total,
      });
    });

    const currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      // const date = currentDate.toLocaleDateString("en-US");
      const day = currentDate.getDate().toString().padStart(2, "0");
      const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
      const date = `${day}/${month}`;
      const data = dateMap.get(date) || {
        date,
        regesteredCounts: 0,
        sprayedCounts: 0,
      };
      labels.push(date);
      regesteredData.push(data.regesteredCounts);
      sprayedData.push(data.sprayedCounts);
      currentDate.setDate(currentDate.getDate() + 1);
    }
  }

  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: "Total Farm Land Registered",
        data: regesteredData,
        borderColor: "rgb(113, 242, 199,0)",
        backgroundColor: "rgb(113, 242, 199,0.5)",
        lineTension: 0.4,
        tension: 1
        
      },
      {
        fill: true,
        label: "Total Farm Land Sprayed",
        data: sprayedData,
        borderColor: "rgba(255, 99, 132, 0)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        lineTension: 0.4,
        tension:1
      },
    ],
  };

  return (
    <div className="chart-container" style={{ height: "260px", width: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: 25,
        }}
      >
        <div style={{ textAlign: "start", fontSize: 20, fontWeight: 700 }}>
          Summary
        </div>
        <div
          onClick={() => {
            setMapView(true);
          }}
        >
          <img
            src={MapIcon}
            style={{ color: "green", width: 30, height: 30 }}
          />
        </div>
      </div>
      <Line options={options} data={data} />
    </div>
  );
};

interface PiChartProps {
  GraphData: any;
}

export const PieChart: React.FC<PiChartProps> = ({ GraphData }) => {
  const MAX_DISPLAY_COUNT = 6;

  const [selectedCrops, setSelectedCrops] = useState<string[]>([]);
  const sortedData = GraphData.sort((a: any, b: any) => b.count - a.count);

  const displayData = sortedData.slice(0, MAX_DISPLAY_COUNT);
  const othersData = sortedData.slice(MAX_DISPLAY_COUNT);

  const othersCount = othersData.reduce(
    (total: number, item: any) => total + item.count,
    0
  );
  const [isSearch, setIsSearch] = useState(false);
  const labels = displayData.map((item: any) => item.crop_name);
  const counts = displayData.map((item: any) => item.count);

  if (othersCount > 0) {
    labels.push("Others");
    counts.push(othersCount);
  }

  const filteredData =
    selectedCrops.length > 0
      ? GraphData.filter((item: any) => selectedCrops.includes(item.crop_name))
      : GraphData;

  const data = {
    labels: filteredData.map((item: any) => item.crop_name),
    datasets: [
      {
        label: "Crop in Acre",
        data: filteredData.map((item: any) => item.count),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(255, 0, 0, 0.2)",
          "rgba(0, 255, 0, 0.2)",
          "rgba(0, 0, 255, 0.2)",
          "rgba(128, 128, 128, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(255, 0, 0, 1)",
          "rgba(0, 255, 0, 1)",
          "rgba(0, 0, 255, 1)",
          "rgba(128, 128, 128, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const DropDownData = GraphData.map((item: any) => ({
    value: item.crop_name,
    label: item.crop_name,
  }));

  return (
    <div className="chart-container">
      <Group position="apart" style={{ paddingBottom: 10 }}>
        <div
          style={{
            fontSize: 20,
            fontWeight: 700,
          }}
        >
          Crop Report
        </div>
        <IconFilter
          onClick={() => {
            setIsSearch(!isSearch);
            setSelectedCrops([]);
          }}
        />
      </Group>
      <ScrollArea h={290} offsetScrollbars>
        {isSearch ? (
          <MultiSelect
            searchable
            clearable
            data={DropDownData}
            value={selectedCrops}
            placeholder="Select crops"
            style={{ maxWidth: "100%" }}
            radius="xl"
            onChange={(selectedItems: any) => {
              setSelectedCrops(selectedItems);
            }}
          />
        ) : (
          <></>
        )}

        {filteredData.length > 0 ? (
          <div style={{}}>
            <Center style={{ height: "260px", paddingLeft: "20px" }}>
              <Doughnut
                data={data}
                options={{
                  responsive: true,
                  plugins: {
                    title: {
                      display: true,
                      text: "Users Gained between 2016-2020",
                    },
                    legend: {
                      display: true,
                      position: "right",
                    },
                  },
                }}
                updateMode="resize"
                style={{ maxHeight: "200px", width: "100%" }}
              />
            </Center>
          </div>
        ) : (
          <>
            <div
              style={{
                width: "100%",
                height: "200px",
                paddingTop: 110,
                textAlign: "center",
              }}
            >
              No Data
            </div>
          </>
        )}
      </ScrollArea>
    </div>
  );
};
