import  { useState } from "react";
import "./userManagement.css";
import {
  Box,
  Tabs
} from "@mantine/core";
import CustomLoader from "../../Components/CustomLoader";
import User from "./Settings/User";
import Roles from "./Settings/Roles";
import Modules from "./Settings/Modules";
import Permission from "./Settings/Permission";

const UserManagement = () => {
  const [activeTab, setActiveTab] = useState<any>("module");
  const loading = false;

  const Page = () => {
    switch (activeTab) {
      case "user":
        return (
          <Tabs.Panel value="user" pt="xs">
            {loading ? <CustomLoader /> : <User userChoice={activeTab} />}
          </Tabs.Panel>
        );
      case "module":
        return (
          <Tabs.Panel value="module" pt="xs">
            {loading ? <CustomLoader /> : <Modules userChoice={activeTab} />}
          </Tabs.Panel>
        );
      case "permission":
        return (
          <Tabs.Panel value="permission" pt="xs">
            {loading ? <CustomLoader /> : <Permission userChoice={activeTab} />}
          </Tabs.Panel>
        );
      case "roles":
        return (
          <Tabs.Panel value="roles" pt="xs">
            {loading ? <CustomLoader /> : <Roles userChoice={activeTab} />}
          </Tabs.Panel>
        );
    }
  };

  return (
    <Box>
      <Box>
        <Tabs
          variant="pills"
          value={activeTab}
          onTabChange={(val) => {
            setActiveTab(val);
          }}
        >
          <Tabs.List>
            <Tabs.Tab
              style={{
                backgroundColor: activeTab === "module" ? "#4CAF50" : "#FFFFFF",
                color: activeTab === "module" ? "#FFFFFF" : "#4CAF50",
              }}
              value="module"
            >
              Module
            </Tabs.Tab>
            <Tabs.Tab
              style={{
                backgroundColor: activeTab === "roles" ? "#4CAF50" : "#FFFFFF",
                color: activeTab === "roles" ? "#FFFFFF" : "#4CAF50",
              }}
              value="roles"
            >
              Role
            </Tabs.Tab>
            <Tabs.Tab
              style={{
                backgroundColor:
                  activeTab === "permission" ? "#4CAF50" : "#FFFFFF",
                color: activeTab === "permission" ? "#FFFFFF" : "#4CAF50",
              }}
              value="permission"
            >
              Permission
            </Tabs.Tab>
            <Tabs.Tab
              style={{
                backgroundColor: activeTab === "user" ? "#4CAF50" : "#FFFFFF",
                color: activeTab === "user" ? "#FFFFFF" : "#4CAF50",
              }}
              value="user"
            >
              User
            </Tabs.Tab>
          </Tabs.List>
          {Page()}
        </Tabs>
      </Box>
    </Box>
  );
};

export default UserManagement;
