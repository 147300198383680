import { IconEye } from "@tabler/icons-react";
import {
  ActionIcon,
  Table,
  ScrollArea,
  Group,
  Text,
  Tooltip,
  Box,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import capitalizeFirstLowercaseRest from "../../Components/Capitalize";
import CustomPagination from "../../Components/CustomPagination";

export default function KycTable(props: any) {
  const navigate = useNavigate();

  const rows = props?.data.map((item: any, index: any) => {
    return (
      <tr key={index}>
        <td>{item?.farmer_id}</td>
        <td>{item?.name}</td>
        <td>{item?.phone_no}</td>
        <td>{capitalizeFirstLowercaseRest(item?.kyc?.kyc_status)}</td>
        <td style={{ display: "flex", justifyContent: "space-evenly" }}>
          <ActionIcon
            variant="outline"
            onClick={() => {
              navigate("kyc-verification", { state: { item: item, permissionList: props.permissionList } });
            }}
          >
            <Tooltip label="View">
              <IconEye color="#333333" size={20} />
            </Tooltip>
          </ActionIcon>
        </td>
      </tr>
    );
  });

  return (
    <>
      <ScrollArea>
        {props.data.length > 0 ? (

          <div style={{ height: window.innerHeight - 330, overflowY: "scroll" }}>
            <Table verticalSpacing="sm" style={{ tableLayout: "fixed" }}>
              <thead>
                <tr>
                  <th>Farmer Id</th>
                  <th>Farmer Name</th>
                  <th>Mobile</th>
                  <th>KYC Status</th>
                  <th style={{ textAlign: "center" }}>View</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          </div>
        ) : (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: window.innerHeight - 285
            }}
          >
            <Text>No Data</Text>
          </Box>
        )}
      </ScrollArea>
      {props.count > 0 ? (
        <CustomPagination
          setPage={props.setPage}
          pagination={props.pagination}
          setPagination={props.setPagination}
          page={props.page}
          count={props.count}
        />
      ) : (
        <></>
      )}
    </>
  );
}
