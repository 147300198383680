import { Modal, Title, Box, ScrollArea, Button } from "@mantine/core";
import React, { useState } from "react";
import { TextInput } from "@mantine/core";
import { Grid } from "@mantine/core";
import * as _ from "lodash";
import { useFormik } from "formik";
import { Loader } from "@mantine/core";
import * as Yup from "yup";
import ConfigurationCropStageAPI from "../../../Redux/Configuration/CropStage/Services";
import { IconCircleX } from "@tabler/icons-react";
type Props = {
  id?: string;
  show: boolean;
  data?: any;
  type: string;
  handleClose: () => void;
};

const AddCropStageModal: React.FC<Props> = ({
  id,
  show,
  data,
  type,
  handleClose,
}) => {
  const [initialValues, setInitialValues] = useState<any>(
    _.isEmpty(data)
      ? {
          name: "",
          duration:"",
          description: "",
  
        }
      : { name: data[0]?.name, duration:data[0]?.duration, description: data[0]?.description,  }
  );

  const [isloading, setloading] = useState<any>(false);
  const [error, setError] = useState<any>("");

  async function handleSubmit(params: any) {
    if (!isloading) {
      setloading(true);
      switch (type) {
        case "Create":
          try {
            await ConfigurationCropStageAPI.createCropStage(params);
            handleClose();
          } catch (e: any) {
            setError(e?.message)
          }
          break;
        case "Update":
          try {
            let paramsData = {
              ...params,
              id: data[0]._id,
            };
            await ConfigurationCropStageAPI.updateCropStage(paramsData);
            handleClose();
          } catch (e: any) {
            setError(e?.message)
          }
          break;
      }
      setloading(false);
    }
  }

  const CropSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    duration: Yup.string().required("Duration is required"),
    description: Yup.string().required("Description is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: CropSchema,
    onSubmit: (values: any, { setStatus, setSubmitting }: any) => {
      handleSubmit(values);
    },
  });

  return (
    <Modal
      centered
      // size="xl"
      radius="lg"
      opened={show}
      onClose={handleClose}
      withCloseButton={true}
      scrollAreaComponent={ScrollArea.Autosize}
      title={
        <Title style={{ fontSize: 25, fontWeight: 300, color: "#4C9C4F" }}>
          Add Crop Stage
        </Title>
      }
    >
        {error !== "" ? (
            <div
              style={{ backgroundColor: "red", color: "white", padding:"5px", marginBottom: 5, display:'flex' , fontSize:'13px' }}
            >
              <IconCircleX size="20px" style=
              {{marginRight:'10px'}} /> {error}
            </div>
          ) : (
            ""
          )}
      <Box>
        <form onSubmit={formik.handleSubmit}>
          <Grid>
            <Grid.Col xs={12} sm={12} md={12} lg={12}>
              <TextInput
                label="Name"
                placeholder="Name"
                value={initialValues?.name}
                withAsterisk
                onChange={(val) => {
                  setError("");
                  setInitialValues({
                    ...initialValues,
                    name: val.target.value,
                  });
                }}
              />
              {formik.touched.name && formik.errors.name && (
                <span role="alert" style={{ color: "red", fontSize: 12 }}>
                  {formik?.errors?.name.toString()}
                </span>
              )}
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={12} lg={12}>
              <TextInput
                label="Duration"
                placeholder="Duration"
                value={initialValues?.duration}
                withAsterisk
                onChange={(val) => {
                  setError("");
                  setInitialValues({
                    ...initialValues,
                    duration: val.target.value.replace(/\s+/g, ' ').trimStart(),
                  });
                }}
              />
              {formik.touched.duration && formik.errors.duration && (
                <span role="alert" style={{ color: "red", fontSize: 12 }}>
                  {formik?.errors?.duration.toString()}
                </span>
              )}
            </Grid.Col>

            {/* <Grid.Col xs={12} sm={12} md={12} lg={12}>
              <TextInput
                label="age"
                placeholder="age"
                value={initialValues?.age}
                withAsterisk
                onChange={(val) => {
                  setInitialValues({
                    ...initialValues,
                    age: val.target.value,
                  });
                }}
              />
              {formik.touched.age && formik.errors.age && (
                <span role="alert" style={{ color: "red", fontSize: 12 }}>
                  {formik?.errors?.age.toString()}
                </span>
              )}
            </Grid.Col> */}

            <Grid.Col xs={12} sm={12} md={12} lg={12}>
              <TextInput
                label="Description"
                placeholder="Description"
                value={initialValues?.description}
                withAsterisk
                onChange={(val) => {
                  setError("");
                  setInitialValues({
                    ...initialValues,
                    description: val.target.value.replace(/\s+/g, ' ').trimStart(),
                  });
                }}
              />
              {formik.touched.description && formik.errors.description && (
                <span role="alert" style={{ color: "red", fontSize: 12 }}>
                  {formik?.errors?.description.toString()}
                </span>
              )}
            </Grid.Col>

            <Grid.Col span={12}>
              <Box style={{ display: "flex", justifyContent: "center" }}>
                {isloading ? (
                  <Button
                    type="submit"
                    style={{ backgroundColor: "#2C752F", width: 100 }}
                  >
                    <Loader size="xs" />
                  </Button>
                ) : (
                  <Button type="submit" style={{ backgroundColor: "#2C752F" }}>
                    {type}
                  </Button>
                )}
              </Box>
            </Grid.Col>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
};

export default AddCropStageModal;
