import React, { useEffect, useState } from 'react'
import { GoogleMap, Polygon, useJsApiLoader } from '@react-google-maps/api';
import { ActionIcon, Grid, Group, Modal, ScrollArea, Title } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';

const containerStyle = {
  // width: '1000px',
  height: '480px'
};



function MapModel(props: any) {
  const { isLoaded } = useJsApiLoader({
    // id: 'google-map-script',
    googleMapsApiKey: "AIzaSyABs90Wh7N3ug2WsnACJcuqlpHVtPCBWIs"
  })

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map: any) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds({ lat: 52.52047739093263, lng: 13.36653284549709 });
    map.fitBounds(bounds);

    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null)
  }, []);


  const [selectedLocation, setSelectedLocation] = useState<any>([]);
  const [center, setCenter] = useState<any>({});

  useEffect(() => {
    let tempLocation: any = [];
    props.location.map((data: any) => {
      let tempInnerList: { lat: any; lng: any; }[] = [];
      data.map((innerData: any)=>{
        tempInnerList.push({ lat: innerData.latitude, lng: innerData.longitude })
      })
      tempLocation.push(tempInnerList)
    });
    console.log(tempLocation);
    setSelectedLocation(tempLocation);
    setCenter(tempLocation[0][0]);
  }, [props.location]);

  const options = {
    fillColor: "lightGreen",
    fillOpacity: 0.3,
    strokeColor: "green",
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1
  }


  return (
    <>
    <Grid style={{paddingBottom: 10}}>
     <Grid.Col span={12}>
              <Group>
                <ActionIcon
                  onClick={() => {
                 props.setShowMap(false);
                  }}
                >
                  <IconArrowLeft size={50} color="black" />
                </ActionIcon>
                <Title
                  style={{
                    fontSize: 24,
                    fontWeight: 600,
                    color: "green",
                  }}
                >
                  Plot View
                </Title>
              </Group>
            </Grid.Col>
            </Grid>
      {
        isLoaded ? (
          <GoogleMap
            // options={{
            //   // styles: mapStyle,
            //   mapTypeControl: false,
            //   mapId: "7341c6b33c1f9404"
            // }}
            mapContainerStyle={containerStyle}
            center={center}
            zoom={18}
            //onLoad={onLoad}
          //  onUnmount={onUnmount}
          >
            { /* Child components, such as markers, info windows, etc. */}
            {
              selectedLocation.map((data: any) => {
                return <Polygon
                  paths={data}
                  options={options}
                />
              })
            }

            <></>
          </GoogleMap>
        ) : <></>
      }
    </>)
}

export default React.memo(MapModel)