import { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Title,
  Tabs,
  Box,
  TextInput,
  Button,
} from "@mantine/core";
import CustomLoader from "../../../Components/CustomLoader";
import { IconCircleX, IconPlus } from "@tabler/icons-react";
import AddConfigurationModal from "./addCropStage";
import ConfigurationTable from "./cropStageTable";
import { useAppSelector } from "../../../Services/hooks/hooks";
import PermissionCheck from "../../../Components/Permission";
import ConfigurationCropStageAPI from "../../../Redux/Configuration/CropStage/Services";

export default function CropStage() {
  const [addOperatorModal, setAddOperatorModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [activeTab, setActiveTab] = useState<string | null>("all");
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    skip: 0,
    limit: 10,
  });
  const [pageSelection, setPageSelection] = useState(1);
  const [totalCount, setTotalCount] = useState(10);
  const [searchText, setSearchText] = useState("");
  const { data, loading, notify, notifymsg } = useAppSelector(
    (state) => state.auth
  );
  const permissionList = data.data.permission[0].permission_list;
  const [error, setError] = useState("");

  async function configurationListFunction() {
    setIsLoading(true);
    let payload = {
      ...pagination,
      filter: searchText,
      status: true
    };
    try {
      let data = (await ConfigurationCropStageAPI.getCropStageList(payload))
        .data;
      setTableData(data?.data ? data.data : []);
      setTotalCount(data?.count ? data?.count : 0);
    } catch (e) {
      setTableData([]);
      setTotalCount(0);
    }

    setIsLoading(false);
  }

  useEffect(() => {
    configurationListFunction();
  }, [pagination, activeTab, searchText]);

  const handleOperator = () => {
    setAddOperatorModal(false);
    configurationListFunction();
  };

  useEffect(() => {
    setTimeout(() => {
      setError("");
    }, 10000);
  }, [error]);

  const Styles = {
    paper: {
      width: "100%",
      backgroundColor: "#FFFFFF",
      padding: 20,
      borderRadius: 20,
    },
  };

  return (
    <div>
      <Grid>
        <Grid.Col span={12}>
          <Paper style={Styles.paper}>
            <Grid>
              <Grid.Col xs={12} sm={7} md={7} lg={7}>
                <Title
                  style={{
                    fontSize: 30,
                    fontWeight: 300,
                    color: "#4C9C4F",
                  }}
                >
                  Crop Stage
                </Title>
              </Grid.Col>
              {
                PermissionCheck(permissionList, "Configuration", "read") ?

                  <Grid.Col xs={12} sm={3} md={3} lg={3}>
                    <TextInput
                      placeholder="Filter by Name"
                      value={searchText}
                      withAsterisk
                      onChange={(val) => {
                        setSearchText(val.target.value);
                      }}
                    />
                  </Grid.Col> : <></>
              }
              {
                PermissionCheck(permissionList, "Configuration", "write") ?

                  <Grid.Col xs={12} sm={2} md={2} lg={2}>
                    <Button
                      fullWidth
                      style={{ background: "#4CAF50" }}
                      onClick={() => {
                        setAddOperatorModal(true);
                      }}
                    >
                      <IconPlus size={18} />
                      Add Crop Stage
                    </Button>
                  </Grid.Col> : <></>
              }
            </Grid>

            {error !== "" ? <div
              style={{ backgroundColor: "red", color: "white", padding: "5px", marginBottom: 20, marginTop: 20, display: 'flex', fontSize: '13px' }}
            >
              <IconCircleX size="20px" style=
                {{ marginRight: '10px' }} /> {error}
            </div> : ""}


            <Box style={{ marginTop: 30 }}>
              <Tabs
                variant="outline"
                value={activeTab}
                onTabChange={(val) => {
                  setActiveTab(val);
                }}
              >
                <Tabs.List>
                  <Tabs.Tab value="all">All</Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="all" pt="xs">
                  {isLoading ? (
                    <CustomLoader />
                  ) : (
                    <ConfigurationTable
                      data={tableData}
                      count={totalCount}
                      setPagination={setPagination}
                      pagination={pagination}
                      page={pageSelection}
                      setPage={setPageSelection}
                      setTableData={setTableData}
                      setTotalCount={setTotalCount}
                      activeTab={activeTab}
                      permissionList= {permissionList}
                      setError={setError}
                    />
                  )}
                </Tabs.Panel>
              </Tabs>
            </Box>
          </Paper>
        </Grid.Col>
      </Grid>
      {addOperatorModal ? (
        <AddConfigurationModal
          show={addOperatorModal}
          type="Create"
          handleClose={handleOperator}
        />
      ) : null}
    </div>
  );
}
