import { UserLogin } from "./Interface";
import * as actions from "./actionTypes";

export function getLoginDetailsAction(
  payload: UserLogin
): actions.getloginDetails {
  return {
    type: actions.GET_LOGIN_DETAILS,
    data: payload,
  };
}
