import { createSlice } from "@reduxjs/toolkit";
import {  State } from "./Interface";

const initialState: State = {
  loading: false,
  error: null,
  data: {
    token: "",
    data: {count:0,data: []},
    status: false,
    statusCode: 0,
    message: "",
  },
  notify: false,
  notifymsg: "",
};

export const kycSlice = createSlice({
  name: "kyc",
  initialState,
  reducers: {
    setUpdateKycLoading: (state) => {
      state.loading = true;
    },
    setUpdateKycSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload;
      state.notifymsg = action.payload;
      state.notify = true;
    },
    setUpdateKycFailed: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload;
      state.notifymsg = action.payload;
      state.notify = true;
    },   
    setNotifyFalse: (state, action) => {
      state.notifymsg = "";
      state.notify = false;
      state.error = null;
    }
  },
});

export const {
  setUpdateKycLoading,
  setUpdateKycSuccess,
  setUpdateKycFailed,
  setNotifyFalse
} = kycSlice.actions;

export default kycSlice.reducer;
