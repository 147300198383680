import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Title,
  Button,
  Tabs,
  Box,
  TextInput,
} from "@mantine/core";
import DroneTable from "./DroneTable";
import { IconCircleX, IconPlus } from "@tabler/icons-react";
import AddDroneModal from "./AddDroneModal";
import CustomLoader from "../../Components/CustomLoader";
import DroneAPI from "../../Redux/Drone/Services";
import { useAppSelector } from "../../Services/hooks/hooks";
import PermissionCheck from "../../Components/Permission";

export default function OperatorManagement() {
  const [addDroneModal, setAddDroneModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState({
    skip: 0,
    limit: 10,
  });
  const [pageSelection, setPageSelection] = useState(1);
  const [totalCount, setTotalCount] = useState(10);
  const [activeTab, setActiveTab] = useState<string | null>("all");
  const { data, loading, notify, notifymsg } = useAppSelector(
    (state) => state.auth
  );
  const [error, setError] = useState("");
  const permissionList = data.data.permission[0].permission_list;

  const Styles = {
    paper: {
      width: "100%",
      backgroundColor: "#FFFFFF",
      padding: 20,
      borderRadius: 20,
    },
  };

  const [isLoading, setIsLoading] = React.useState(false);

  async function DroneListFunction() {
    setIsLoading(true);
    let payload = {
      ...pagination,
      status:
        activeTab === "all" ? "all" : activeTab === "active" ? true : false,
      filter: searchText,
    };
    try {
      let data = (await DroneAPI.getDroneList(payload)).data;
      setTableData(data?.data);
      setTotalCount(data?.count ? data?.count : 0);
      setIsLoading(false);
    } catch (e) {
      setTableData([]);
      setTotalCount(0);
      setIsLoading(false);
    }
  }
  useEffect(() => {
    DroneListFunction();
  }, [pagination, searchText, activeTab]);

  const handleDrone = () => {
    setAddDroneModal(false);
    DroneListFunction();
  };

  useEffect(() => {
    setTimeout(() => {
      setError("");
    }, 10000);
  }, [error]);

  return (
    <>
      <Grid>
        <Grid.Col span={12}>
          <Paper style={Styles.paper}>
            <Grid>
              <Grid.Col xs={12} sm={7} md={7} lg={7}>
                <Title
                  style={{
                    fontSize: 30,
                    fontWeight: 300,
                    color: "#4C9C4F",
                  }}
                >
                  Drone Management
                </Title>
              </Grid.Col>
              {
                PermissionCheck(permissionList, "Drone_Management", "read") ?
                  <Grid.Col xs={12} sm={3} md={3} lg={3}>
                    <TextInput
                      placeholder="Filter by Drone Id"
                      value={searchText}
                      withAsterisk
                      onChange={(val) => {
                        setSearchText(val.target.value);
                      }}
                    />
                  </Grid.Col> : <></>
              }
              {
                PermissionCheck(permissionList, "Drone_Management", "write") ?
                  <Grid.Col xs={12} sm={2} md={2} lg={2}>
                    <Button
                      fullWidth
                      style={{ background: "#4CAF50" }}
                      onClick={() => {
                        setAddDroneModal(true);
                      }}
                    >
                      <IconPlus size={18} />
                      Add Drone
                    </Button>
                  </Grid.Col> : null
              }
            </Grid>


            {error !== "" ? <div
              style={{ backgroundColor: "red", color: "white", padding: "5px", marginBottom: 20, marginTop: 20, display: 'flex', fontSize: '13px' }}
            >
              <IconCircleX size="20px" style=
                {{ marginRight: '10px' }} /> {error}
            </div> : ""}

            {PermissionCheck(permissionList, "Drone_Management", "read") ? (
              <Box style={{ marginTop: 30 }}>
                <Tabs
                  variant="outline"
                  value={activeTab}
                  onTabChange={(val) => {
                    setPagination({
                      skip: 0,
                      limit: 10,
                    });
                    setPageSelection(1);
                    setActiveTab(val);
                  }}
                >
                  <Tabs.List>
                    <Tabs.Tab value="all">All</Tabs.Tab>
                    {/* <Tabs.Tab value="active">Active</Tabs.Tab>
                      <Tabs.Tab value="deactive">Deactive</Tabs.Tab> */}
                  </Tabs.List>

                  <Tabs.Panel value="all" pt="xs">
                    {isLoading ? (
                      <CustomLoader />
                    ) : (
                      <DroneTable
                        data={tableData}
                        value={activeTab}
                        setTableData={setTableData}
                        count={totalCount}
                        setPagination={setPagination}
                        pagination={pagination}
                        page={pageSelection}
                        setPage={setPageSelection}
                        searchText={searchText}
                        permissionList={permissionList}
                        setError={setError}
                      />
                    )}
                  </Tabs.Panel>
                  {/* <Tabs.Panel value="active" pt="xs">
                      {isLoading ? (
                        <CustomLoader />
                      ) : (
                        <DroneTable
                          data={tableData}
                          value={activeTab}
                          setTableData={setTableData}
                          count={totalCount}
                          setPagination={setPagination}
                          pagination={pagination}
                          page={pageSelection}
                          setPage={setPageSelection}
                          searchText={searchText}
                          permissionList= {permissionList}
                        />
                      )}
                    </Tabs.Panel> */}
                  {/* <Tabs.Panel value="deactive" pt="xs">
                      {isLoading ? (
                        <CustomLoader />
                      ) : (
                        <DroneTable
                          data={tableData}
                          value={activeTab}
                          setTableData={setTableData}
                          count={totalCount}
                          setPagination={setPagination}
                          pagination={pagination}
                          page={pageSelection}
                          setPage={setPageSelection}
                          searchText={searchText}
                          permissionList= {permissionList}
                        />
                      )}
                    </Tabs.Panel> */}
                </Tabs>
              </Box>
            ) : (
              <></>
            )}
          </Paper>
        </Grid.Col>
      </Grid>

      {addDroneModal ? (
        <AddDroneModal
          show={addDroneModal}
          type="Add"
          handleClose={handleDrone}
        />
      ) : null}
    </>
  );
}
