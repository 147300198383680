import { createContext } from "react";
import { getLocalStorageItem } from "../localstorage";

const UserContext = createContext({});

const ContextProvider = ({ children }: any) => {
  //create state needed to use in app

  return <UserContext.Provider value={{}}>{children}</UserContext.Provider>;
};

export { UserContext, ContextProvider };
