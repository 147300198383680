import apiInstance from "../../Services";
import {
  CreateDrone,
  DeleteDrone,
  GetDrone,
  GetDroneByStatus,
  UpdateDrone,
} from "./Interface";

const DroneAPI = {
  // eslint-disable-next-line class-methods-use-this

  createDrone: async (payload: CreateDrone) => {
    try {
      const res = await apiInstance.post<CreateDrone>(`/drone/create`, payload);
      return res;
    } catch (error) {
      throw error;
    }
  },

  getDroneList: async (payload: any) => {
    try {
      const res = await apiInstance.get<any>(
        `/drone/getall?skip=${payload.skip}&limit=${payload.limit}&status=${payload.status}&filter=${payload.filter}`
      );
      return res;
    } catch (error) {
      throw error;
    }
  },

  //   getUserListByAccountStatus: async (payload: GetDroneByStatus) => {
  //     try {
  //       const res = await apiInstance.get<any>(
  //         `/user/userByAccountStaus?skip=${payload.skip}&limit=${payload.limit}&status=${payload.status}&filter=${payload.filter}`
  //       );
  //       return res;
  //     } catch (error) {
  //       throw error;
  //     }
  //   },

  updateDrone: async (payload: CreateDrone) => {
    try {
      const res = await apiInstance.put<CreateDrone>(
        `/drone/update?id=${payload?.id}`,
        payload
      );
      return res;
    } catch (error) {
      throw error;
    }
  },

  deleteDrone: async (payload: DeleteDrone) => {
    try {
      const res = await apiInstance.put<DeleteDrone>(
        `/drone/delete?id=${payload.id}&status=${payload.status}`
      );
      return res;
    } catch (error) {
      throw error;
    }
  },
};

export default DroneAPI;
