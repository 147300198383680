import { IconTrash, IconEye, IconEdit, IconPlugConnected, IconPlus } from "@tabler/icons-react";
import {
  ActionIcon,
  Box,
  Table,
  ScrollArea,
  Text,
  Tooltip,
} from "@mantine/core";
import { useEffect, useState } from "react";
import ConfigViewModal from "../ConfigViewModal";
import DeleteModal from "../../../Components/DeleteModal";
import AddVarietyModal from "./addVariety";
import CustomPagination from "../../../Components/CustomPagination";
import ConfigurationVarietyAPI from "../../../Redux/Configuration/Variety/Services";
import capitalizeFirstLowercaseRest from "../../../Components/Capitalize";
import PermissionCheck from "../../../Components/Permission";
import ConfigurationCropDetailsAPI from "../../../Redux/Configuration/CropType/Services";

export default function VarietyTable(props: any) {
  const [viewVarietyModal, setViewVarietyModal] = useState(false);
  const [editVarietyModal, setEditVarietyModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [id, setId] = useState("");
  const [editableData, setEditableData] = useState([]);
  const [corpList, setCropList] = useState<any>([]);
  const [selectedStatus, setSelectedStatus] = useState(false);

  const handleVarietyModal = () => {
    setViewVarietyModal(false);
  };

  const handleEditModal = () => {
    configurationListFunction();
    setEditVarietyModal(false);
  };

  const handleDelete = () => {
    configurationListFunction();
    setShowDeleteModal(false);
  };

  async function configurationListFunction() {
    let params = {
      ...props.pagination,
      filter: props.searchText ? props.searchText : "",
    };
    try {
      let data = (await ConfigurationVarietyAPI.getVarietyList(params)).data;
      props.setTableData(data?.data ? data.data : []);
      props.setTotalCount(data?.count ? data?.count : 0);
    } catch (e) {
      props.setTableData([]);
    }
  }

  async function cropTypeListFunction() {

    try {
      let data = (await ConfigurationCropDetailsAPI.getAllCropTypeList())
        .data;
      setCropList(data);

    } catch (e) {
      setCropList([]);
    }
  }

  useEffect(() => {
    cropTypeListFunction();
  }, []);

  const rows = props.data?.map((item: any) => {
    return (
      <tr key={item?._id} style={{ color: !item?.status ? "grey" : "black" }}>
        <td>{capitalizeFirstLowercaseRest(item?.name)}</td>
        <td>{ corpList.find((res:any)=> res._id === item.crop_type)?.["name"]? corpList.find((res:any)=> res._id === item.crop_type)?.["name"] :"-"}</td>
        <td>{item?.description}</td>
        <td style={{ display: "flex", justifyContent: "space-evenly" }}>
          <ActionIcon
            variant="outline"
            onClick={() => {
              let edata = props.data?.filter(
                (e: any) => e?._id === item?._id
              );
              edata[0]["crop"] = corpList.find((res:any) => res._id === edata[0]["crop_type"])?.["name"];
              setEditableData(edata);
              setViewVarietyModal(true);
            }}
          >
            <Tooltip label="View">
              <IconEye size={20} />
            </Tooltip>
          </ActionIcon>
          {
            PermissionCheck(props.permissionList, "Configuration", "write") ?
              <ActionIcon
                variant="outline"
                onClick={() => {
                  const edata = props.data?.filter(
                    (e: any) => e?._id === item?._id
                  );
                  setEditableData(edata);
                  setEditVarietyModal(true);
                }}
              >
                <Tooltip label="Edit">
                  <IconEdit size={20} />
                </Tooltip>
              </ActionIcon> : <></>
          }
          {
            PermissionCheck(props.permissionList, "Configuration", "write") ?
              <ActionIcon
                variant="outline"
                onClick={() => {
                  setShowDeleteModal(true);
                  setId(item?._id);
                  setSelectedStatus(item.status);
                }}
              >
                 <Tooltip label={item?.status ? "De-Active" : "Active"}>
                  {item?.status ? (
                    <IconPlugConnected size={20} />
                  ) : (
                    <IconPlus size={20} />
                  )}
                </Tooltip>
              </ActionIcon> : <></>
          }
        </td>
      </tr>
    );
  });

  return (
    <>
      <ScrollArea>
        {props.data.length === 0 ? (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight:  window.innerHeight - 325
            }}
          >
            <Text>No Data</Text>
          </Box>
        ) : (
            <div style={{height: window.innerHeight - 375,overflowY:"scroll"}}>
            <Table verticalSpacing="sm" style={{tableLayout:"fixed"}}>
            <thead>
                <tr>
                  <th>Name</th>
                  <th>Crop Type</th>
                  <th>Description</th>
                  <th style={{ textAlign: "center" }}>Actions</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
            </div>
        )}
      </ScrollArea>
      {props.count > 0 ? (
        <CustomPagination
          setPage={props.setPage}
          pagination={props.pagination}
          setPagination={props.setPagination}
          page={props.page}
          count={props.count}
        />
      ) : (
        <></>
      )}
      {showDeleteModal ? (
        <DeleteModal
          id={id}
          show={showDeleteModal}
          type="Variety"
          handleClose={handleDelete}
          error={props.setError}
          status={!selectedStatus}
        />
      ) : null}

      {editVarietyModal ? (
        <AddVarietyModal
          show={editVarietyModal}
          data={editableData}
          type="Update"
          handleClose={handleEditModal}
        />
      ) : null}

      {viewVarietyModal ? (
        <ConfigViewModal
          show={viewVarietyModal}
          data={editableData}
          type="Variety"
          handleClose={handleVarietyModal}
        />
      ) : null}
    </>
  );
}
