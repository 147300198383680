import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "../../Redux/Auth/LoginSlice";
import userReducer from "../../Redux/User/UserSlice";
import operatorReducer from "../../Redux/Operator/OperatorSlice";
import bookingReducer from "../../Redux/Booking/BookingSlice";
import kycReducer from "../../Redux/Kyc/kycSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  operator: operatorReducer,
  booking: bookingReducer,
  kyc: kycReducer,
});

export type AppState = ReturnType<typeof rootReducer>;
export default rootReducer;
