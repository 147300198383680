import { DeleteUser, GetUser, UpdateUser } from "./Interface";

export const GET_USER_LIST_DETAILS = "getUserListDetails";

export interface getuserlistDetails {
  type: typeof GET_USER_LIST_DETAILS;
  data: GetUser;
}

export const UPDATE_USER_DETAILS = "updateUserDetails";

export interface updateuserDetails {
  type: typeof UPDATE_USER_DETAILS;
  data: UpdateUser;
}

export const DELETE_USER_DETAILS = "deleteUserDetails";

export interface deleteuserDetails {
  type: typeof DELETE_USER_DETAILS;
  data: DeleteUser;
}

export type UserAction =
  | getuserlistDetails
  | updateuserDetails
  | deleteuserDetails;
