import { useState } from "react";
import {
  ActionIcon,
  Box,
  Table,
  ScrollArea,
  Text,
  Tooltip,
} from "@mantine/core";
import { IconEdit, IconEye, IconPlugConnected, IconPlus } from "@tabler/icons-react";
import DeleteModal from "../../Components/DeleteModal";
import ViewModal from "../../Components/ViewModal";
import AddOperatorModal from "./AddOperatorModal";
import OperatorAPI from "../../Redux/Operator/Services";
import { useNavigate } from "react-router-dom";
import CustomPagination from "../../Components/CustomPagination";
import PermissionCheck from "../../Components/Permission";

export default function OperatorTable(props: any) {
  const [showViewModal, setShowViewModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [id, setId] = useState("");
  const [editableData, setEditableData] = useState([]);
  const navigate = useNavigate();
  const [selectedStatus, setSelectedStatus] = useState(false);

  const handleView = () => {
    setShowViewModal(false);
  };

  const handleEdit = () => {
    operatorListFunction();
    setShowEditModal(false);
  };

  const handleDelete = () => {
    operatorListFunction();
    setShowDeleteModal(false);
  };

  async function operatorListFunction() {
    let params = {
      ...props.pagination,
      filter: props.searchText,
      status: props.value,
    };

    //  dispatch(getOperatorListAction(payload));

    try {
      let data = (await OperatorAPI.getOperatorList(params)).data;

      props.setTableData(data?.data);
    } catch (e) {
      props.setTableData([]);
    }
  }
  const rows = props.data?.map((item: any) => {
    return (
      <tr key={item?._id} style={{color: !item?.status? "grey":"black"}}>
        <td>{item?.operator_id}</td>
        <td>{item?.name}</td>
        <td>{item?.phone_no}</td>
        <td>{item?.email}</td>
        {/* <td>
          {item?.account_status
            ? capitalizeFirstLowercaseRest(item?.account_status)
            : null}
        </td> */}
        {/* <td>{item?.address}</td> */}
        <td>{item?.drone?.drone_id ? item?.drone?.drone_id : "Not Assigned"}</td>
        <td style={{ display: "flex", justifyContent: "space-evenly" }}>
          <ActionIcon
            variant="outline"
            // onClick={() => {
            //   setShowViewModal(true);
            //   setId(item?._id);
            //   const edata = props.data?.filter(
            //     (e: any) => e?._id === item?._id
            //   );
            //   setEditableData(edata);
            // }}
            onClick={() => {
              let edata = props.data?.filter(
                (e: any) => e?._id === item?._id
              );

              edata = { ...edata, rating: props?.ratingList?.find((res: any) => res._id === item?._id)?.["sum"] ? props?.ratingList?.find((res: any) => res._id === item?._id)["sum"] : 0 }
              setEditableData(edata);
              navigate("operator-detail", { state: { edata: edata } });
            }}>
            <Tooltip label="View">
              <IconEye size={20} />
            </Tooltip>
          </ActionIcon>
          {
            PermissionCheck(props?.permissionList, "Operator_Management", "write") ?

              <ActionIcon
                variant="outline"
                onClick={() => {
                  setShowEditModal(true);
                  setId(item?._id);
                  const edata = props.data?.filter(
                    (e: any) => e?._id === item?._id
                  );
                  setEditableData(edata);
                }}
              >
                <Tooltip label="Update">
                  <IconEdit size={20} />
                </Tooltip>
              </ActionIcon> : <></>
          }
          {
            PermissionCheck(props?.permissionList, "Operator_Management", "write") ?
              <ActionIcon
                variant="outline"
                onClick={() => {
                  setShowDeleteModal(true);
                  setId(item?._id);
                  setSelectedStatus(item.status);
                }}
              >
                <Tooltip label={item?.status ? "De-Active" : "Active"}>
                  {item?.status ? (
                    <IconPlugConnected size={20} />
                  ) : (
                    <IconPlus size={20} />
                  )}
                </Tooltip>
              </ActionIcon> : <></>
          }
        </td>
      </tr>
    );
  });

  return (
    <>
      <ScrollArea>
        {props.data.length > 0 ? (

          <div style={{ height: window.innerHeight - 330, overflowY: "scroll" }}>
            <Table verticalSpacing="sm" style={{ tableLayout: "fixed" }}>
              <thead>
                <tr>
                  <th>Operator Id</th>
                  <th>Operator Name</th>
                  <th>Mobile</th>
                  <th>Email</th>
                  {/* <th>Status</th> */}
                  {/* <th>Location</th> */}
                  <th>Assigned Drone Id</th>
                  <th style={{ textAlign: "center" }}>Actions</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          </div>
        ) : (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: window.innerHeight - 285
            }}
          >
            <Text>No Data</Text>
          </Box>
        )}
      </ScrollArea>

      {props.count > 0 ? (
        <CustomPagination
          setPage={props.setPage}
          pagination={props.pagination}
          setPagination={props.setPagination}
          page={props.page}
          count={props.count}
        />
      ) : (
        <></>
      )}

      {showDeleteModal ? (
        <DeleteModal
          id={id}
          show={showDeleteModal}
          type="Operator"
          handleClose={handleDelete}
          error={props.setError}
          status={!selectedStatus}
        />
      ) : null}

      {showViewModal ? (
        <ViewModal
          id={id}
          show={showViewModal}
          type="Operator"
          data={editableData}
          handleClose={handleView}
        />
      ) : null}

      {showEditModal ? (
        <AddOperatorModal
          id={id}
          show={showEditModal}
          type="Update"
          data={editableData}
          handleClose={handleEdit}
        />
      ) : null}
    </>
  );
}
