import { Modal, Title, Box, ScrollArea, Button, Select } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { TextInput } from "@mantine/core";
import { Grid } from "@mantine/core";
import * as _ from "lodash";
import { useFormik } from "formik";
import { Loader } from "@mantine/core";
import * as Yup from "yup";
import ConfigurationVarietyAPI from "../../../Redux/Configuration/Variety/Services";
import ConfigurationCropDetailsAPI from "../../../Redux/Configuration/CropType/Services";
import { IconCircleX } from "@tabler/icons-react";

type Props = {
  id?: string;
  show: boolean;
  data?: any;
  type: string;
  handleClose: () => void;
};

const AddVarietyModal: React.FC<Props> = ({
  id,
  show,
  data,
  type,
  handleClose,
}) => {
  const [initialValues, setInitialValues] = useState<any>(
    _.isEmpty(data)
      ? {
        name: "",
        description: "",
        crop_type:""
      }
      : { name: data[0]?.name, description: data[0]?.description, crop_type: data[0].crop_type }
  );

  const [isloading, setloading] = useState<any>(false);
  const [cropList, setCropList] = useState<any>([]);
  const [error, setError] = useState<any>("");

  async function handleSubmit(params: any) {
    if (!isloading) {
      setloading(true);
      switch (type) {
        case "Create":
        case "variety":
          try {
            await ConfigurationVarietyAPI.createVariety(params);
            handleClose();
          } catch (e: any) {
            setError(e.message);
           }
          break;
        case "Update":
          try {
            let paramsData = {
              ...params,
              id: data[0]._id,
            };
            await ConfigurationVarietyAPI.updateVariety(paramsData);
            handleClose();
          } catch (e: any) {
            setError(e.message);
           }
          break;
      }
      setloading(false);
    }
  }

  async function cropTypeListFunction() {

    try {
      let data = (await ConfigurationCropDetailsAPI.getAllCropTypeList())
        .data;
      let tempList:  { label: any; value: any; }[] = [];
      data.map((data: any) => {
        tempList.push({ label: data.name, value: data._id });
      });
      setCropList(tempList);

    } catch (e) {
      setCropList([]);
    }
  }

  useEffect(() => {
    cropTypeListFunction();
  }, []);


  const VarietySchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    crop_type: Yup.string().required("Crop Type is required"),
    description: Yup.string().required("Description is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: VarietySchema,
    onSubmit: (values: any, { setStatus, setSubmitting }: any) => {
      handleSubmit(values);
    },
  });

  return (
    <Modal
      centered
      radius="lg"
      opened={show}
      onClose={handleClose}
      withCloseButton={true}
      scrollAreaComponent={ScrollArea.Autosize}
      title={
        <Title style={{ fontSize: 25, fontWeight: 300, color: "#4C9C4F" }}>
          {type} Variety
        </Title>
      }
    >
       {error !== "" ? (
        <div
          style={{ backgroundColor: "red", color: "white", padding: "5px", marginBottom: 5, display: 'flex', fontSize: '13px' }}
        >
          <IconCircleX size="20px" style=
            {{ marginRight: '10px' }} /> {error}
        </div>
      ) : (
        ""
      )}
      <Box>
        <form onSubmit={formik.handleSubmit}>
          <Grid>
            <Grid.Col xs={12} sm={12} md={12} lg={12}>
              <Select
                placeholder="Select Crop Type"
                searchable
                clearable
                value={initialValues?.crop_type}
                onChange={(val) => {
                  setError("");
                  setInitialValues({
                    ...initialValues,
                    crop_type: val,
                  });
                }}
                nothingFound="No options"
                data={cropList}
                maxDropdownHeight={150}
              />
              {formik.touched.crop_type &&
                formik.errors.crop_type && (
                  <span role="alert" style={{ color: "red", fontSize: 12 }}>
                    {formik.errors.crop_type.toString()}
                  </span>
                )}
            </Grid.Col>
              {/* <Autocomplete
                placeholder="Filter by Crop Type"
          //      sx={{ maxHeight: 200, overflow: "auto" }}
                data={cropList}
             //   value={selectedState}
                nothingFound="No options"
                onChange={(e) => {

                }}
              /> */}
            <Grid.Col xs={12} sm={12} md={12} lg={12}>
              <TextInput
                label="Name"
                placeholder="Name"
                value={initialValues?.name}
                withAsterisk
                onChange={(val) => {
                  setError("");
                  setInitialValues({
                    ...initialValues,
                    name: val.target.value.replace(/\s+/g, ' ').trimStart(),
                  });
                }}
              />
              {formik.touched.name && formik.errors.name && (
                <span role="alert" style={{ color: "red", fontSize: 12 }}>
                  {formik?.errors?.name.toString()}
                </span>
              )}
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={12} lg={12}>
              <TextInput
                label="Description"
                placeholder="Description"
                value={initialValues?.description}
                withAsterisk
                onChange={(val) => {
                  setError("");
                  setInitialValues({
                    ...initialValues,
                    description: val.target.value.replace(/\s+/g, ' ').trimStart(),
                  });
                }}
              />
              {formik.touched.description && formik.errors.description && (
                <span role="alert" style={{ color: "red", fontSize: 12 }}>
                  {formik?.errors?.description.toString()}
                </span>
              )}
            </Grid.Col>

            <Grid.Col span={12}>
              <Box style={{ display: "flex", justifyContent: "center" }}>
                {isloading ? (
                  <Button
                    type="submit"
                    style={{ backgroundColor: "#2C752F", width: 100 }}
                  >
                    <Loader size="xs" />
                  </Button>
                ) : (
                  <Button type="submit" style={{ backgroundColor: "#2C752F" }}>
                    {type}
                  </Button>
                )}
              </Box>
            </Grid.Col>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
};

export default AddVarietyModal;
