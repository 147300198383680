import apiInstance from "../../Services";
import { adminData, adminDelete } from "./interface";


const AdminAPI = {
  // eslint-disable-next-line class-methods-use-this

  createAdmin: async (payload: adminData) => {
    try {
      const res = await apiInstance.post<any>(`/admin/create`, payload);
      return res;
    } catch (error) {
      throw error;
    }
  },

  getAdminList: async (payload: any) => {
    try {
      const res = await apiInstance.get<any>(
        `/admin/getall?skip=${payload.skip}&limit=${payload.limit}&filter=${payload.filter}&status=${payload.status}`
      );
      return res;
    } catch (error) {
      throw error;
    }
  },

  //   getUserListByAccountStatus: async (payload: GetDroneByStatus) => {
  //     try {
  //       const res = await apiInstance.get<any>(
  //         `/user/userByAccountStaus?skip=${payload.skip}&limit=${payload.limit}&status=${payload.status}&filter=${payload.filter}`
  //       );
  //       return res;
  //     } catch (error) {
  //       throw error;
  //     }
  //   },

  updateAdmin: async (payload: adminData) => {
    try {
      const res = await apiInstance.put<any>(
        `/admin/update?id=${payload?.id}`,
        payload
      );
      return res;
    } catch (error) {
      throw error;
    }
  },

  deleteAdmin: async (payload: adminDelete) => {
    try {
      const res = await apiInstance.put<adminDelete>(
        `/admin/delete?id=${payload.id}`
      );
      return res;
    } catch (error) {
      throw error;
    }
  },
};

export default AdminAPI;
