import { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Tabs,
  Title,
  Box,
  TextInput,
  Autocomplete,
} from "@mantine/core";
import KycTable from "./KycTable";
import CustomLoader from "../../Components/CustomLoader";
import KycAPI from "../../Redux/Kyc/Services";
import { useAppSelector } from "../../Services/hooks/hooks";
import PermissionCheck from "../../Components/Permission";
import BlockAPI from "../../Redux/Configuration/Block/Services";
import DistrictAPI from "../../Redux/Configuration/District/Services";
import StateAPI from "../../Redux/Configuration/State/Services";

export default function KycDetails() {
  const [activeTab, setActiveTab] = useState<string | null>("all");
  const [userDetails, setUserDetails] = useState<any>([]);
  const [pagination, setPagination] = useState({
    skip: 0,
    limit: 10,
  });
  const [pageSelection, setPageSelection] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(10);
  const [searchText, setSearchText] = useState("");
  const { data } = useAppSelector((state) => state.auth);
  const permissionList = data.data.permission[0].permission_list;

  const Styles = {
    paper: {
      width: "100%",
      backgroundColor: "#FFFFFF",
      padding: 20,
      borderRadius: 20,
    },
  };

  const [stateList, setStateList] = useState<any>([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [districtList, setDistrictList] = useState<any>([]);
  const [blockList, setBlockList] = useState<any>([]);
  const [selectedBlock, setSelectedBlock] = useState("");

  async function getStateList() {
    let data = await StateAPI.getStateAllList();
    let tempList: { value: any; label: any }[] = [];
    data?.data.map((val: any) => {
      tempList.push({
        value: val.name,
        label: val.name,
      });
    });
    setStateList(tempList);
  }

  async function getDistrictListBasedOnState(state: any) {
    let data = await DistrictAPI.getDistrictAllList({
      state: state,
    });
    let tempList: { value: any; label: any }[] = [];
    data?.data.map((val: any) => {
      tempList.push({
        value: val.name,
        label: val.name,
      });
    });
    setDistrictList(tempList);
  }

  async function getBlockListBasedOnState(state: any, district: any) {
    let data = await BlockAPI.getBlocAllList({
      state: state,
      district: district,
    });
    let tempList: { value: any; label: any }[] = [];
    data?.data.map((val: any) => {
      tempList.push({
        value: val.name,
        label: val.name,
      });
    });
    setBlockList(tempList);
  }

  useEffect(() => {
    getStateList();
  }, []);

  async function getUserList() {
    try {
      let payload = {
        ...pagination,
        status: activeTab,
        filter: searchText,
        state: selectedState,
        district: selectedDistrict,
        block: selectedBlock,
      };
      setIsLoading(true);
      let data = (await KycAPI.getKyc(payload)).data;
      setUserDetails(data[0]?.data);
      setTotalCount(data[0]?.count?.count ? data[0]?.count?.count : 0);
      setIsLoading(false);
    } catch (e) {
      setUserDetails([]);
      setTotalCount(0);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getUserList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeTab,
    pagination,
    searchText,
    selectedState,
    selectedDistrict,
    selectedBlock,
  ]);

  // async function getUserList() {
  //   try {
  //     setIsLoading(true);
  //     let payload = {
  //       ...pagination,
  //       status: activeTab,
  //       filter: searchText,
  //     };
  //     let data = (await KycAPI.getKyc(payload)).data;
  //     setUserDetails(data[0]?.data);
  //     setTotalCount(data[0]?.count?.count ? data[0]?.count?.count : 0);
  //     setIsLoading(false);
  //   } catch (e) {
  //     setUserDetails([]);
  //     setTotalCount(0);
  //     setIsLoading(false);
  //   }
  // }

  // async function getUserList() {
  //   setLoading(true);

  //   fetch(`${API_BASE_URL}${endpoints.userListByKyc}?skip=${pagination.skip}&limit=${pagination.limit}&status=${activeTab}&filter=${searchText}`).then(res => {
  //     return res.text();
  //   }).then(res => {
  //     let tempList = JSON.parse(res);

  //     setUserDetails(tempList?.data[0]?.data.reverse());

  //     setTotalCount(tempList?.data[0]?.count?.count ? tempList?.data[0]?.count?.count : 0);

  //     setLoading(false);
  //   });
  // }

  // useEffect(() => {
  //   getUserList();
  //    // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [activeTab, pagination, searchText]);

  return (
    <div>
      <Grid>
        <Grid.Col span={12}>
          <Paper style={Styles.paper}>
            <Grid>
              <Grid.Col xs={12} sm={12} md={4} lg={4}>
                <Title
                  style={{
                    fontSize: 30,
                    fontWeight: 300,
                    color: "#4C9C4F",
                  }}
                >
                  KYC Details
                </Title>
              </Grid.Col>
              <Grid.Col xs={12} sm={12} md={2} lg={2}>
                <Autocomplete
                  placeholder="Filter by State"
                  sx={{ maxHeight: 200, overflow: "auto" }}
                  data={stateList}
                  value={selectedState}
                  nothingFound="No options"
                  onChange={(e) => {
                    setSelectedState(e);
                    setSelectedBlock("");
                    setSelectedDistrict("");
                    getDistrictListBasedOnState(e);
                  }}
                />
              </Grid.Col>
              <Grid.Col xs={12} sm={12} md={2} lg={2}>
                <Autocomplete
                  placeholder="Filter by District"
                  sx={{ maxHeight: 200, overflow: "auto" }}
                  data={districtList}
                  value={selectedDistrict}
                  nothingFound="No options"
                  onChange={(e) => {
                    setSelectedDistrict(e);
                    setSelectedBlock("");
                    getBlockListBasedOnState(selectedState, e);
                  }}
                />
              </Grid.Col>
              <Grid.Col xs={12} sm={12} md={2} lg={2}>
                <Autocomplete
                  placeholder="Filter by Block"
                  sx={{ maxHeight: 200, overflow: "auto" }}
                  data={blockList}
                  nothingFound="No options"
                  value={selectedBlock}
                  onChange={(e) => {
                    setSelectedBlock(e);
                  }}
                />
              </Grid.Col>
              <Grid.Col xs={12} sm={12} md={2} lg={2}>
                <TextInput
                  placeholder="Filter by Name"
                  value={searchText}
                  withAsterisk
                  onChange={(val) => {
                    setSearchText(val.target.value);
                  }}
                />
              </Grid.Col>
            </Grid>
            {PermissionCheck(permissionList, "KYC_Details", "read") ? (
              <Box style={{ marginTop: 30 }}>
                <Tabs
                  variant="outline" //defaultValue="all"
                  value={activeTab}
                  onTabChange={(val) => {
                    setPagination({
                      skip: 0,
                      limit: 10,
                    });
                    setPageSelection(1);
                    setActiveTab(val);
                  }}
                >
                  <Tabs.List>
                    <Tabs.Tab value="all">All</Tabs.Tab>
                    <Tabs.Tab value="pending">Pending</Tabs.Tab>
                    <Tabs.Tab value="approved">Approved</Tabs.Tab>
                    <Tabs.Tab value="rejected">Rejected</Tabs.Tab>
                  </Tabs.List>

                  <Tabs.Panel value="all" pt="xs">
                    {isLoading ? (
                      <CustomLoader />
                    ) : (
                      <KycTable
                        data={userDetails}
                        value={activeTab}
                        count={totalCount}
                        setPagination={setPagination}
                        pagination={pagination}
                        page={pageSelection}
                        setPage={setPageSelection}
                        permissionList={permissionList}
                      />
                    )}
                  </Tabs.Panel>

                  <Tabs.Panel value="pending" pt="xs">
                    {isLoading ? (
                      <CustomLoader />
                    ) : (
                      <KycTable
                        data={userDetails}
                        value={activeTab}
                        count={totalCount}
                        setPagination={setPagination}
                        pagination={pagination}
                        page={pageSelection}
                        setPage={setPageSelection}
                      />
                    )}
                  </Tabs.Panel>

                  <Tabs.Panel value="approved" pt="xs">
                    {isLoading ? (
                      <CustomLoader />
                    ) : (
                      <KycTable
                        data={userDetails}
                        value={activeTab}
                        count={totalCount}
                        setPagination={setPagination}
                        pagination={pagination}
                        page={pageSelection}
                        setPage={setPageSelection}
                      />
                    )}
                  </Tabs.Panel>

                  <Tabs.Panel value="rejected" pt="xs">
                    {isLoading ? (
                      <CustomLoader />
                    ) : (
                      <KycTable
                        data={userDetails}
                        value={activeTab}
                        count={totalCount}
                        setPagination={setPagination}
                        pagination={pagination}
                        page={pageSelection}
                        setPage={setPageSelection}
                      />
                    )}
                  </Tabs.Panel>
                </Tabs>
              </Box>
            ) : (
              <></>
            )}
          </Paper>
        </Grid.Col>
      </Grid>
    </div>
  );
}
