import {
  Button,
  Modal,
  Group,
  Text,
  Box,
  Select,
  TextInput,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import ConfigurationCropDetailsAPI from "../../Redux/Configuration/CropType/Services";
import ConfigurationCropStageAPI from "../../Redux/Configuration/CropStage/Services";
import ConfigurationPesticideAPI from "../../Redux/Configuration/Pesticide/Services";
import ConfigurationFertilizerAPI from "../../Redux/Configuration/Fertilizer/Services";
import ConfigurationVarietyAPI from "../../Redux/Configuration/Variety/Services";
import _ from "lodash";
import BookingAPI from "../../Redux/Booking/Services";

type Props = {
  show: boolean;
  type?: string;
  data?: any;
  id?: string;
  handleClose: () => void;
};
interface FertilizerItem {
  _id: string;
  name: string;
  description: string;
  status: boolean;
  type: string;
  brand_name: string;
  created_at: string;
  updated_at: string;
  __v: number;
}

const EditModal: React.FC<Props> = ({ show, data, id, type, handleClose }) => {
  const [cropData, setCropData] = useState([]);
  const [cropStage, setCropStage] = useState([]);
  const [pesticide, setPesticide] = useState([]);
  const [fertilizer, setFertilizer] = useState<FertilizerItem[]>([]);
  const [othersFertilizerItems, setOthersFertilizerItems] = useState<FertilizerItem[]>([]);
  const [nonOthersFertilizerItems, setNonOthersFertilizerItems] = useState<FertilizerItem[]>([]);
  const [selectedCropId, setSelectedCropId] = useState("");
  const [varietyOptions, setVarietyOptions] = useState<any>([]);

  const [initialValues, setInitialValues] = useState<any>(
    _.isEmpty(data)
      ? {
        farm_land_area: "",
        crop: "",
        variety: "",
        fertilizer: "",
        other_fertilizer: "",
        pesticide: "",
        fertilizer_qty: "",
        pesticide_id: "",
        age: "",
        total_tanks: "",
        id: ""
      }
      : {
        farm_land_area: data?.farm_land_area,
        crop: data?.crop,
        variety: data?.variety,
        fertilizer: data?.fertilizer,
        other_fertilizer: data?.other_fertilizer,
        pesticide: data?.pesticide,
        fertilizer_qty: data?.fertilizer_qty,
        pesticide_id: data?.pesticide_id,
        age: data?.age,
        total_tanks: data?.total_tanks,
        id: id
      }
  );

  useEffect(() => {
    if (selectedCropId) {
      varietyBasedOnId(selectedCropId);
    } else {
      setVarietyOptions([]);
    }
  }, [selectedCropId]);

  async function updateOperatorReport() {
    try {
      let payload = {
        cultivation_details: { ...initialValues }
      }
      await BookingAPI.updateOperatorReport(payload);
      handleClose();
    } catch (e: any) {

    }

  }


  useEffect(() => {
    setOthersFertilizerItems(fertilizer.filter((item) => item.type === "others"));
    setNonOthersFertilizerItems(fertilizer.filter((item) => item.type !== "others"));
  }, [fertilizer]);

  // const varieties = varietyOptions.map((item: any) => ({
  //   value: item.name,
  //   // label: item.name,
  // }));

  const cropOptions = cropData.map((item: any) => ({
    value: item.name,
    id: item._id,
    label: item.name,
  }));

  const stageOptions = cropStage.map((item: any) => ({
    value: item._id,
    label: item.duration,
  }));
  const pesticideOptions = pesticide.map((item: any) => ({
    value: item.product_name,
    label: item.product_name,
    id: item._id
  }));

  const iffcoFertilizer = nonOthersFertilizerItems.map((item) => ({
    value: item.name,
    label: item.name,
  }));

  const otherFertilizer = othersFertilizerItems.map((item) => ({
    value: item.name,
    label: item.name,
  }));

  async function varietyBasedOnId(selectedCropId: any) {
    try {
      let res = await ConfigurationVarietyAPI.getVarietybyId(selectedCropId);
      let temp: { value: any; label: any; }[] = [];
      res.data.map((item: any) => {
        temp.push({
          value: item.name,
          label: item.name,
        });
        setVarietyOptions(temp);
      });


    } catch (error) {
      console.error("Error fetching variety data:", error);
    }
  }

  async function getAllCropList() {
    try {
      let res = await ConfigurationCropDetailsAPI.getAllCropTypeList();
      setCropData(res.data);
    } catch (error) { }
  }

  async function getAllCropStageList() {
    try {
      let res = await ConfigurationCropStageAPI.getAllCropStageList();
      setCropStage(res.data);
    } catch (error) { }
  }
  async function getAllPesticide() {
    try {
      let res = await ConfigurationPesticideAPI.getAllPesticideList();
      setPesticide(res.data);
    } catch (error) { }
  }
  async function getAllFertilizerList() {
    try {
      let res = await ConfigurationFertilizerAPI.getAllFertilizerList();
      setFertilizer(res.data);
    } catch (error) { }
  }


  useEffect(() => {
    setSelectedCropId(cropOptions?.find((data: any) => data.label === initialValues.crop)?.["id"]);
  }, [initialValues.crop, cropOptions])

  useEffect(() => {
    getAllCropList();
    getAllCropStageList();
    getAllPesticide();
    getAllFertilizerList();
  }, []);

  return (
    <Modal
      radius="lg"
      padding="xl"
      opened={show}
      onClose={handleClose}
      withCloseButton={true}
      title={<Text fz={20} fw={600}>Edit Operator Report</Text>}
    >
      <form>
        <Box
          style={{
            textAlign: "left",
          }}
        >
          <Group
            style={{
              justifyContent: "center",
            }}
          >
            <Box component="form" style={{ width: "100%" }}>
              <TextInput
                type="number"
                label="Area to be sprayed"
                placeholder="Area to be sprayed"
                value={initialValues?.farm_land_area
                }
                onChange={(val) => {
                  let temp = { ...initialValues };
                  temp["farm_land_area"] = val.target.value;
                  setInitialValues(temp);
                }}
                withAsterisk
                mt="md"
              />
              <Select
                label="Crop Details"
                placeholder="Select Crop Details"
                data={cropOptions}
                value={initialValues.crop}
                onChange={(value: any) => {
                  setSelectedCropId(cropOptions.find((data: any) => data.value === value)?.["id"]);
                  let temp = { ...initialValues };
                  temp["crop"] = value;
                  temp["variety"] = "";
                  setInitialValues(temp);
                }}
                required
                style={{ marginTop: "16px" }}
              />
              <Select
                label="Crop Age/Stage"
                placeholder="Select Crop Age/Stage"
                data={stageOptions}
                value={initialValues.age}
                onChange={(value: any) => {
                  let temp = { ...initialValues };
                  temp["age"] = value;
                  setInitialValues(temp);
                }}
                required
                style={{ marginTop: "16px" }}
              />
              <Select
                label="Variety"
                placeholder="Select Variety"
                data={varietyOptions}
                value={initialValues.variety}
                onChange={(value: any) => {
                  let temp = { ...initialValues };
                  temp["variety"] = value;
                  setInitialValues(temp);
                }}
                style={{ marginTop: "16px" }}
              />
              <Select
                label="Fertilizer"
                placeholder="Select Fertilizer"
                data={iffcoFertilizer}
                value={initialValues?.fertilizer}
                required
                style={{ marginTop: "16px" }}
                onChange={(val: any) => {
                  let temp = { ...initialValues };
                  temp["fertilizer"] = val;
                  setInitialValues(temp);
                }}
              />
              <Select
                label="Other Fertilizer"
                placeholder="Select Other Fertilizer"
                data={otherFertilizer}
                value={initialValues.other_fertilizer}
                style={{ marginTop: "16px" }}
                onChange={(val: any) => {
                  let temp = { ...initialValues };
                  temp["other_fertilizer"] = val;
                  setInitialValues(temp);
                }}
              />
              <TextInput
                label="Fertilizer Quantity"
                placeholder="Fertilizer Quantity"
                type="number"
                value={initialValues.fertilizer_qty}
                required
                style={{ marginTop: "16px" }}
                onChange={(val) => {
                  let temp = { ...initialValues };
                  temp["fertilizer_qty"] = val.target.value;
                  setInitialValues(temp);
                }}
              />
              <TextInput
                label="Total no of tanks"
                placeholder="Total no of tanks"
                type="number"
                value={initialValues.total_tanks}
                required
                style={{ marginTop: "16px" }}
                onChange={(val) => {
                  let temp = { ...initialValues };
                  temp["total_tanks"] = val.target.value;
                  setInitialValues(temp);
                }}
              />
              <Select
                label="pesticide"
                placeholder="Select Pesticide"
                data={pesticideOptions}
                style={{ marginTop: "16px" }}
                value={initialValues.pesticide}
                onChange={(val: any) => {
                  let temp = { ...initialValues };
                  temp["pesticide"] = val;
                  temp["pesticide_id"] = pesticideOptions?.find((data: any) => data.label === val)?.["id"]
                  setInitialValues(temp);
                }}
              />

              <br />
              <div style={{ textAlign: "center" }}>
                <Button
                  style={{ backgroundColor: "green" }}
                  disabled={initialValues?.farm_land_area === "" || initialValues?.total_tanks === "" || initialValues.fertilizer_qty === "" || initialValues.variety === ""}
                  // type="submit"
                  onClick={() => { updateOperatorReport() }}
                >
                  Update
                </Button>
              </div>
            </Box>
          </Group>
        </Box>
      </form>
    </Modal>
  );
};

export default EditModal;
function getAllCropTypeList() {
  throw new Error("Function not implemented.");
}
