import { createSlice } from "@reduxjs/toolkit";
import { LoginState } from "./Interface";

const initialState: LoginState = {
  loading: false,
  error: null,
  data: {
    token: "",
    data: { id: "", email: "", status: false, name:"" },
    status: false,
    statusCode: 0,
    message: "",
  },
  notify: false,
  notifymsg: "",
};

export const loginSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoginDetailsLoading: (state) => {
      state.loading = true;
    },
    setLoginDetailsSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload;
      state.notify = false;
      state.notifymsg = "";
    },
    setLoginSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload;
      state.notifymsg = action.payload;
      state.notify = true;
    },
    setLoginDetailsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.notify = true;
    },
    setNotifyFalse: (state, action) => {
      state.notifymsg = "";
      state.notify = false;
      state.error = null;
    },
    app_logout: (state) => {
      return initialState;
    },
  },
});

export const {
  setLoginDetailsLoading,
  setLoginDetailsSuccess,
  setLoginSuccess,
  setLoginDetailsFailure,
  setNotifyFalse,
  app_logout,
} = loginSlice.actions;

export default loginSlice.reducer;
