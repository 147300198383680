import React, { useEffect, useState } from 'react'
import { GoogleMap, LoadScript, PolygonF, useJsApiLoader } from '@react-google-maps/api';
import { ActionIcon, Autocomplete, Grid, Group, Modal, ScrollArea, Title } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';
import DashboardApi from '../../Redux/Dashboard/Service';
import { DatePickerInput } from "@mantine/dates";
import BlockAPI from '../../Redux/Configuration/Block/Services';
import DistrictAPI from '../../Redux/Configuration/District/Services';
import StateAPI from '../../Redux/Configuration/State/Services';

const containerStyle = {
    // width: '1000px',
    height: '480px'
};

function MapModel(props: any) {
    let { isLoaded } = useJsApiLoader({
        // id: 'google-map-script',
        googleMapsApiKey: "AIzaSyABs90Wh7N3ug2WsnACJcuqlpHVtPCBWIs"
    })

    const [map, setMap] = React.useState(null)
    const [opertorReportList, setOperatorReportList] = React.useState([]);

    const onLoad = React.useCallback(function callback(map: any) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        const bounds = new window.google.maps.LatLngBounds({ lat: 52.52047739093263, lng: 13.36653284549709 });
        map.fitBounds(bounds);

        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map: any) {
        setMap(null)
    }, []);


    const [selectedLocation, setSelectedLocation] = useState<any>([]);
    const [center, setCenter] = useState<any>({ lat: 11.020507, lng: 76.992662, });
    const [DateFilterval, setDateFilterval] = useState<
        [any, any]
    >([new Date(), new Date()]);
    const [stateList, setStateList] = useState<any>([]);
    const [selectedState, setSelectedState] = useState("");
    const [selectedDistrict, setSelectedDistrict] = useState("");
    const [districtList, setDistrictList] = useState<any>([]);
    const [blockList, setBlockList] = useState<any>([]);
    const [selectedBlock, setSelectedBlock] = useState("");
    const [showPolygon, setShowPolygon] = useState(false);

    useEffect(() => {
        setSelectedLocation([]);
        if (opertorReportList.length > 0) {
            let tempLocation: any = [];
            opertorReportList.map(async (location: any) => {
                location?.location?.map(async (coordinate: any) => {
                    if (coordinate.length > 0) {
                        let tempInnerList: { lat: any; lng: any; }[] = [];
                        coordinate.map((innerData: any) => {
                            tempInnerList.push({ lat: innerData.latitude, lng: innerData.longitude })
                        })
                        tempLocation.push(tempInnerList)
                    }
                });

            });

            setSelectedLocation(tempLocation);
              setCenter(tempLocation?.[0]?.[0]);
            setShowPolygon(true);

        } else {
            setSelectedLocation([]);
            // setCenter({ lat: 11.020507, lng: 76.992662, });
            setShowPolygon(false);
        }

    }, [opertorReportList]);



    const options = {
        fillColor: "lightGreen",
        fillOpacity: 0.3,
        strokeColor: "green",
        strokeOpacity: 1,
        strokeWeight: 2,
        clickable: false,
        draggable: false,
        editable: false,
        geodesic: false,
        zIndex: 1
    }


    async function getOrderList() {
        let payload = {
            state: selectedState,
            district: selectedDistrict,
            block: selectedBlock,
            startDate: `${DateFilterval[0]?.getFullYear()}-${DateFilterval[0]?.getMonth() + 1}-${DateFilterval[0]?.getDate()}`.toString(),
            endDate: `${DateFilterval[1]?.getFullYear()}-${DateFilterval[1]?.getMonth() + 1}-${DateFilterval[1]?.getDate()}`.toString()
        }
        let data = await DashboardApi.getOrderReport(payload);
        setOperatorReportList(data?.data);
    }

    useEffect(() => {
        getOrderList();
    }, [DateFilterval, selectedState, selectedDistrict, selectedBlock]);


    async function getStateList() {
        let data = await StateAPI.getStateAllList();
        let tempList: { value: any; label: any }[] = [];
        data?.data.map((val: any) => {
            tempList.push({
                value: val.name,
                label: val.name,
            });
        });
        setStateList(tempList);
    }

    async function getDistrictListBasedOnState(state: any) {
        let data = await DistrictAPI.getDistrictAllList({
            state: state,
        });
        let tempList: { value: any; label: any }[] = [];
        data?.data.map((val: any) => {
            tempList.push({
                value: val.name,
                label: val.name,
            });
        });
        setDistrictList(tempList);
    }

    async function getBlockListBasedOnState(state: any, district: any) {
        let data = await BlockAPI.getBlocAllList({
            state: state,
            district: district,
        });
        let tempList: { value: any; label: any }[] = [];
        data?.data.map((val: any) => {
            tempList.push({
                value: val.name,
                label: val.name,
            });
        });
        setBlockList(tempList);
    }

    useEffect(() => {
        getStateList();
    }, []);


    return (
        <><Grid style={{ paddingBottom: 10 }}>
            <Grid.Col sm={12} md={4}>
                <Group>
                    <ActionIcon
                        onClick={() => {
                            props.setMapView(false);
                        }}
                    >
                        <IconArrowLeft size={50} color="black" />
                    </ActionIcon>
                    <Title
                        style={{
                            fontSize: 24,
                            fontWeight: 600,
                            color: "green",
                        }}
                    >
                        Plot View
                    </Title>
                </Group>
            </Grid.Col>
            <Grid.Col sm={12} md={8}>

                <Grid>
                    <Grid.Col xs={12} sm={12} md={3} lg={3}>
                        <DatePickerInput
                            type="range"
                            placeholder="Pick dates range"
                            value={DateFilterval}
                            onChange={setDateFilterval}
                            mx="auto"
                            maw={400}
                        />
                    </Grid.Col>
                    <Grid.Col xs={12} sm={12} md={3} lg={3}>
                        <Autocomplete
                            placeholder="Filter by State"
                            sx={{ maxHeight: 200, overflow: "auto" }}
                            data={stateList}
                            value={selectedState}
                            nothingFound="No options"
                            onChange={(e) => {
                                setSelectedState(e);
                                setSelectedBlock("");
                                setSelectedDistrict("");
                                getDistrictListBasedOnState(e);
                            }}
                        />
                    </Grid.Col>
                    <Grid.Col xs={12} sm={3} md={3} lg={3}>
                        <Autocomplete
                            placeholder="Filter by District"
                            sx={{ maxHeight: 200, overflow: "auto" }}
                            data={districtList}
                            value={selectedDistrict}
                            nothingFound="No options"
                            onChange={(e) => {
                                setSelectedDistrict(e);
                                setSelectedBlock("");
                                getBlockListBasedOnState(selectedState, e);
                            }}
                        />
                    </Grid.Col>
                    <Grid.Col xs={12} sm={3} md={3} lg={3}>
                        <Autocomplete
                            placeholder="Filter by Block"
                            sx={{ maxHeight: 200, overflow: "auto" }}
                            data={blockList}
                            nothingFound="No options"
                            value={selectedBlock}
                            onChange={(e) => {
                                setSelectedBlock(e);
                            }}
                        />
                    </Grid.Col>
                </Grid>


            </Grid.Col>
        </Grid>
            {
                isLoaded ? (

                    <GoogleMap
                        // options={{
                        //   // styles: mapStyle,
                        //   mapTypeControl: false,
                        //   mapId: "7341c6b33c1f9404"
                        // }}
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={18}
                        //onLoad={onLoad}
                        //  onUnmount={onUnmount}
                    >

                        {
                            showPolygon && selectedLocation.length > 0 && selectedLocation.map((data: any) => {
                                return <PolygonF
                                    paths={data}
                                    options={options}
                                />
                            })
                        }

                    </GoogleMap>) : <></>



            }
        </>
    )

}

export default MapModel;