import {
  Button,
  Modal,
  Title,
  Box,
  ScrollArea,
  Loader,
  Select,
  Checkbox,
  Group,
} from "@mantine/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { TextInput } from "@mantine/core";
import { Grid } from "@mantine/core";
import * as _ from "lodash";
import PermissionAPI from "../../../Redux/Permission/service";
import ModuleAPI from "../../../Redux/Module/service";
import RoleAPI from "../../../Redux/Role/service";
import { IconCircleX } from "@tabler/icons-react";

const UserSchema = Yup.object().shape({
  name: Yup.string().required("Name id is required"),
  role_id: Yup.string().required("Role is required"),
});

type Props = {
  id?: string;
  show: boolean;
  data?: any;
  type: string;
  handleClose: () => void;
};

const AddPermissionModal: React.FC<Props> = ({
  id,
  show,
  data,
  type,
  handleClose,
}) => {
  const [initialValues, setInitialValues] = useState<any>(
    _.isEmpty(data)
      ? {
        name: "",
        role_id: "",
        permission_list: [],
      }
      : {
        name: data[0].name,
        role_id: data[0].role_id,
        permission_list: data[0].permission_list,
      }
  );

  const [modulesCheck, setModuleCheck] = useState(false);
  const [modulesCheckRead, setModuleCheckRead] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: UserSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      handleSubmit(values);
    },
  });

  const [roleList, setRoleList] = useState<{ value: any; label: any }[]>([]);
  const [moduleList, setModuleList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  async function getRoleList() {
    let params = {
      skip: 0,
      limit: 0,
      filter: "",
    };

    let data = (await RoleAPI.getRoleList(params)).data;
    let tempList: { value: any; label: any }[] = [];
    data.data.map((res: any) => {
      tempList.push({
        value: res._id,
        label: res.name,
      });
      return "";
    });

    setRoleList(tempList);
  }

  async function getModuleList() {
    let params = {
      skip: 0,
      limit: 0,
      filter: "",
    };
    let data = (await ModuleAPI.getModuleList(params)).data;

    if (type === "Add") {
      setInitialValues({
        ...initialValues,
        permission_list: data.data,
      });
    } else {
      let status = true;
      initialValues.permission_list.map((data: any) => {
        if (!data.read || !data.write) {
          status = false;
        }
        return "";
      });
      setModuleCheck(status);
      setModuleCheckRead(status);
    }

    setModuleList(data.data);
  }

  useEffect(() => {
    getRoleList();
    getModuleList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [error, setError] = useState<any>("");

  async function handleSubmit(Params: any) {
    if (!isLoading) {
      setIsLoading(true);
      let payload = initialValues;
      payload = {
        ...payload,
        name: payload?.name.trimEnd(),
        role_id: payload?.role_id,
        permission_list: payload?.permission_list,
      };

      if (type === "Add") {
        try {
          await PermissionAPI.createPermission(payload);
          handleClose();
        } catch (e: any) {
          setError(e.message)
        }

      } else {
        payload = {
          ...payload,
          id: data[0]?._id,
        };
        try {
          await PermissionAPI.updatePermission(payload);
          handleClose();
        } catch (e: any) {
          setError(e.message)
        }
      }
      setIsLoading(false);
    }
  }

  function selectAllModules(status: any) {
    setModuleCheck(status.target.checked);
    setModuleCheckRead(status.target.checked);
    let tempList = [...moduleList];
    tempList.map((data: any, index: any) => {
      tempList[index]["read"] = status.target.checked;
      tempList[index]["write"] = status.target.checked;
      return "";
    });
    setInitialValues({
      ...initialValues,
      permission_list: tempList,
    });
  }

  function selectAllRead(status: any) {
    let tempList = [...moduleList];
    setModuleCheckRead(status.target.checked);
    tempList.map((data: any, index: any) => {
      tempList[index]["read"] = status.target.checked;
      if (!status.target.checked) {
        tempList[index]["write"] = false;
      }
      return "";
    });

    if (!status.target.checked) {
      setModuleCheck(false);
      setModuleCheckRead(false);
    }

    setInitialValues({
      ...initialValues,
      permission_list: tempList,
    });
  }

  return (
    <Modal
      centered
      radius="lg"
      opened={show}
      onClose={handleClose}
      withCloseButton={true}
      scrollAreaComponent={ScrollArea.Autosize}
      title={
        <Title style={{ fontSize: 25, fontWeight: 300, color: "#4C9C4F" }}>
          {type} Permission
        </Title>
      }
    >
      {error !== "" ? (
        <div
          style={{ backgroundColor: "red", color: "white", padding: "5px", marginBottom: 5, display: 'flex', fontSize: '13px' }}
        >
          <IconCircleX size="20px" style=
            {{ marginRight: '10px' }} /> {error}
        </div>
      ) : (
        ""
      )}

      <Box>
        <form onSubmit={formik.handleSubmit}>
          <Grid>
            <Grid.Col xs={12} sm={12} md={12} lg={12}>
              <TextInput
                label="Permission Name"
                placeholder="Enter the Permission Name"
                withAsterisk
                value={initialValues?.name}
                onChange={(val) => {
                  setError("");
                  setInitialValues({
                    ...initialValues,
                    name: val.target.value.replace(/\s+/g, ' ').trimStart(),
                  });
                }}
              />
              {formik.touched.name && formik.errors.name && (
                <span role="alert" style={{ color: "red", fontSize: 12 }}>
                  {formik.errors.name.toString()}
                </span>
              )}
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={12} lg={12}>
              <Select
                placeholder="Select Role"
                searchable
                value={initialValues?.role_id}
                onChange={(val) => {
                  setInitialValues({
                    ...initialValues,
                    role_id: val,
                  });
                }}
                nothingFound="No options"
                data={roleList}
              />
              {formik.touched.role_id && formik.errors.role_id && (
                <span role="alert" style={{ color: "red", fontSize: 12 }}>
                  {formik.errors.role_id.toString()}
                </span>
              )}
            </Grid.Col>

            <Grid.Col xs={12} sm={12} md={12} lg={12} style={{ width: "100%" }}>
              <Group
                position="apart"
                //style={{ marginRight: "21px" }}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <h3>Modules</h3>
                  <Checkbox
                    label="Select All"
                    checked={modulesCheck}
                    style={{ fontWeight: 550, textAlign: "left" }}
                    onChange={(e) => selectAllModules(e)}
                  />
                </div>
                <div style={{ marginRight: "0px" }}>
                  <h3>Access</h3>
                  <div style={{ display: "flex" }}>
                    <Checkbox
                      label="ReadAll"
                      style={{ fontWeight: 550 }}
                      // defaultChecked={modulesCheckRead}
                      checked={modulesCheckRead}
                      onChange={(event: any) => selectAllRead(event)}
                    // disabled={modulesCheck}
                    />
                    <Checkbox
                      style={{ paddingInline: "20px", fontWeight: 550 }}
                      checked={modulesCheck}
                      onChange={(e) => selectAllModules(e)}
                      label="WriteAll"
                    />
                  </div>
                </div>
              </Group>
            </Grid.Col>
            <Grid.Col>
              {/* {initialValues?.permission_list?.map((e: any, index: any) => {
                return (
                    <Grid>
                      <Grid.Col span={5}>{e?.name}</Grid.Col>
                      <Grid.Col span={1}>:</Grid.Col>
                      <Grid.Col span={3}>
                        <Checkbox checked={e?.read} label="Read" />
                      </Grid.Col>
                      <Grid.Col span={3}>
                        <Checkbox checked={e?.write} label="Write" />
                      </Grid.Col>
                    </Grid>
                );
              })} */}
              {moduleList.map((module: any, index: any) => {
                return (
                  <Group
                    position="apart"
                    mt="sm"
                    style={{ marginRight: "21px" }}
                  >
                    <div>
                      <Checkbox
                        checked={
                          initialValues.permission_list.find(
                            (res: any) => res.name === module.name
                          )?.read &&
                          initialValues.permission_list.find(
                            (res: any) => res.name === module.name
                          )?.write
                        }
                        label={module.name}
                        onChange={(event: any) => {
                          let idx = initialValues.permission_list.findIndex(
                            (res: any) => res.name === module.name
                          );
                          if (idx !== -1) {
                            let temp = [...initialValues.permission_list];
                            temp[idx]["read"] = event.target.checked;
                            temp[idx]["write"] = event.target.checked;
                            setInitialValues({
                              ...initialValues,
                              permission_list: temp,
                            });
                          } else {
                            let temp = [...initialValues.permission_list];
                            temp.push({
                              name: module.name,
                              read: event.target.checked,
                              write: event.target.checked,
                            });
                            setInitialValues({
                              ...initialValues,
                              permission_list: temp,
                            });
                          }
                        }}
                      />
                    </div>
                    <div style={{ display: "flex", marginRight: "4px" }}>
                      <Checkbox
                        style={{ marginRight: "2.2rem" }}
                        defaultChecked={
                          initialValues.permission_list.find(
                            (res: any) => res.name === module.name
                          )?.read
                        }
                        checked={
                          initialValues.permission_list.find(
                            (res: any) => res.name === module.name
                          )?.read
                        }
                        onChange={(event: any) => {
                          let idx = initialValues.permission_list.findIndex(
                            (res: any) => res.name === module.name
                          );
                          if (idx !== -1) {
                            let temp = [...initialValues.permission_list];
                            temp[idx]["read"] = event.target.checked;
                            if (!event.target.checked) {
                              temp[idx]["write"] = false;
                            }

                            setInitialValues({
                              ...initialValues,
                              permission_list: temp,
                            });
                          } else {
                            let temp = [...initialValues.permission_list];
                            temp.push({ name: module.name, read: true });
                            setInitialValues({
                              ...initialValues,
                              permission_list: temp,
                            });
                          }
                        }}
                        label="Read"
                      />
                      <Checkbox
                        mr="md"
                        defaultChecked={
                          initialValues.permission_list.find(
                            (res: any) => res.name === module.name
                          )?.write
                        }
                        checked={
                          initialValues.permission_list.find(
                            (res: any) => res.name === module.name
                          )?.write
                        }
                        onChange={(event: any) => {
                          let idx = initialValues.permission_list.findIndex(
                            (res: any) => res.name === module.name
                          );
                          if (idx !== -1) {
                            let temp = [...initialValues.permission_list];
                            temp[idx]["write"] = event.target.checked;
                            temp[idx]["read"] = event.target.checked;
                            setInitialValues({
                              ...initialValues,
                              permission_list: temp,
                            });
                          } else {
                            let temp = [...initialValues.permission_list];
                            temp.push({
                              name: module.name,
                              write: true,
                              read: true,
                            });
                            setInitialValues({
                              ...initialValues,
                              permission_list: temp,
                            });
                          }
                        }}
                        label="Write"
                      />
                    </div>
                  </Group>
                );
              })}
            </Grid.Col>

            <Grid.Col span={12}>
              <Box style={{ display: "flex", justifyContent: "center" }}>
                {isLoading ? (
                  <Button style={{ backgroundColor: "#2C752F", width: 100 }}>
                    <Loader size="xs" />
                  </Button>
                ) : (
                  <Button type="submit" style={{ backgroundColor: "#2C752F" }}>
                    {type}
                  </Button>
                )}
              </Box>
            </Grid.Col>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
};

export default AddPermissionModal;
