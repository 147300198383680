import { useEffect } from "react";
import { Button, Modal, Group, Text, Box, Image } from "@mantine/core";
import { useAppDispatch, useAppSelector } from "../Services/hooks/hooks";
import { useNavigate } from "react-router-dom";
import { IconAlertCircle, IconLogout } from "@tabler/icons-react";
import LogoutIcon from "../assets/Logout.svg";
import { app_logout, setNotifyFalse } from "../Redux/Auth/LoginSlice";

type Props = {
  show: boolean;
  handleClose: () => void;
};

const Logout: React.FC<Props> = ({ show, handleClose }) => {
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const logout = () => {
    handleClose();
    localStorage.clear();
    dispatch(app_logout());
    navigate("/");
  };
  //   useEffect(() => {
  //     show;
  //   });

  return (
    <Modal
      centered
      radius="lg"
      padding="xl"
      opened={show}
      onClose={handleClose}
      withCloseButton={false}
    >
      <Box
        style={{
          textAlign: "center",
        }}
      >
        <IconLogout size={50} color="#2C752F" />
        <Text style={{ textAlign: "center", paddingTop: 10 }}>
          Are you sure you want to log out?
        </Text>
        <Group
          style={{
            justifyContent: "center",
            paddingTop: 25,
          }}
        >
          <Button
            onClick={handleClose}
            style={{
              width: "25%",
              background: "#D8D8D8",
              borderRadius: "8px",
              marginRight: 20,
            }}
          >
            No
          </Button>
          <Button
            onClick={(e) => {
              logout();
            }}
            style={{
              width: "25%",
              background: "#2C752F",
              borderRadius: "8px",
            }}
          >
            Yes
          </Button>
        </Group>
      </Box>
    </Modal>
  );
};

export default Logout;
