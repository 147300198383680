import apiInstance from "../../Services";
import {
  create,
  operatorData,
} from "./Interface";

const OperatorAPI = {
  // eslint-disable-next-line class-methods-use-this
  createOperator: async (payload: create) => {
    try {
      const res = await apiInstance.post<create>(`/operator/create`, payload);
      return res;
    } catch (error) {
      throw error;
    }
  },
  getOperatorList: async (payload: any) => {
    try {
      const res = await apiInstance.get<any>(
        `/operator/list?skip=${payload.skip}&limit=${payload.limit}&filter=${payload.filter}&status=${payload.status}`
      );
      return res;
    } catch (error) {
      throw error;
    }
  },
  getOperatorListAll: async () => {
    try {
      const res = await apiInstance.get<any>(`/operator/getall`);
      return res;
    } catch (error) {
      throw error;
    }
  },
  updateOperator: async (payload: any) => {
    try {
      const res = await apiInstance.put<any>(
        `/operator/update?id=${payload._id}`,
        payload
      );
      return res;
    } catch (error) {
      throw error;
    }
  },
  deleteOperator: async (payload: any) => {
    try {
      const res = await apiInstance.put<operatorData>(
        `/operator/delete?id=${payload._id}&status=${payload.status}`
      );
      return res;
    } catch (error) {
      throw error;
    }
  },
  imageUpload: async (payload: any) => {
    try {
      const res = await apiInstance.postForm<any>(`/kyc/upload`, payload);
      return res;
    } catch (error) {
      throw error;
    }
  },
  verifyDroneLicense: async (payload: {id: string}) => {
    try {
      const res = await apiInstance.get<any>(
        `/operator/verifyLicense?id=${payload.id}`
      );
      return res;
    } catch (error) {
      throw error;
    }
  },
  bookingsBasedOperatorId: async (payload:{id: string}) => {
    try {
    const res = await apiInstance.get<any>(`/operator/bookinglistbasedOperatorId?id=${payload.id}`);
      return res;
    } catch (error) {
      throw error;
    }
  },
};

export default OperatorAPI;
