import { Box, Grid, Text } from "@mantine/core";
import Lottie from "react-lottie";
import treelogo from "../assets/lottie/treelogo.json";
import palmtree from "../assets/lottie/palmtree.json";
import bouncingball from "../assets/lottie/bouncingball.json";
// import loading from "../assets/lottie/loading.json";
import loading from "../assets/lottie/green-loading.json";

export default function CustomLoader() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <>
      <Grid
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "60vh",
        }}
      >
        <Box>
          <Lottie options={defaultOptions} width={200} />
        </Box>
      </Grid>
    </>
  );
}
