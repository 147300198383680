import apiInstance from "../../../Services";
import { create, deleteData, update } from "./Interface";

const DistrictAPI= {
  // eslint-disable-next-line class-methods-use-this
  createDistrictType: async (payload: create) => {
    try {
      const res = await apiInstance.post<any>(`/district/create`, payload);
      return res;
    } catch (error) {
      throw error;
    }
  },

  updateDistrictType: async (payload: update) => {
    try {
      const res = await apiInstance.put<any>(`/district/update?id=${payload.id}`, payload);
      return res;
    } catch (error) {
      throw error;
    }
  },

  getDistrictList: async (payload: any) => {
    try {
      const res = await apiInstance.get<any>(
        `/district/getall?skip=${payload.skip}&limit=${payload.limit}&filter=${payload.filter}&status=${payload.status}`
      );
      return res;
    } catch (error) {
      throw error;
    }
  },

  
  getDistrictAllList: async (payload: any) => {
    try {
      const res = await apiInstance.get<any>(
        `/district/getallList?state=${payload.state}`
      );
      return res;
    } catch (error) {
      throw error;
    }
  },

  deleteDistrict: async (payload: deleteData) => {
    try {
      const res = await apiInstance.put<deleteData>(
        `/district/delete?id=${payload.id}&status=${payload.status}`
      );
      return res;
    } catch (error) {
      throw error;
    }
  },
};

export default DistrictAPI;
