import { useState } from "react";
import {
  Button,
  Group,
  Box,
  Title,
  Grid,
  Paper,
  ActionIcon,
  Image,
  Rating,
} from "@mantine/core";
import ImageModal from "../../Components/ImageModal";
import { useLocation, useNavigate } from "react-router-dom";
import { IconArrowLeft } from "@tabler/icons-react";
import OperatorAPI from "../../Redux/Operator/Services";

export default function ViewOperator() {
  const [previewImage, setPreviewImage] = useState(false);
  const [previewLicenseImage, setPreviewLicenseImage] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state.edata;

  const handlePreview = () => {
    setPreviewImage(false);
  };

  const handleLicensePreview = () => {
    setPreviewLicenseImage(false);
  };

  const Styles = {
    paper: {
      width: "100%",
      backgroundColor: "#FFFFFF",
      padding: 20,
      borderRadius: 20,
    },
  };

  async function updateOperatorDetails(status: string) {
    let params = {
      _id: data[0]._id,
      account_status: status,
    };
    await OperatorAPI.updateOperator(params);
    navigate(-1);
  }

  return (
    <Grid>
      <Grid.Col span={12}>
        <Paper style={Styles.paper}>
          <Grid>
            <Grid.Col span={12}>
              <Group>
                <ActionIcon
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <IconArrowLeft size={50} color="black" />
                </ActionIcon>
                <Title
                  style={{
                    fontSize: 30,
                    fontWeight: 300,
                    color: "#4C9C4F",
                  }}
                >
                  Operator Details
                </Title>
              </Group>
            </Grid.Col>
            <Grid.Col span={8}>
              <Grid>
                <Grid.Col span={5}>Operator Name</Grid.Col>
                <Grid.Col span={1}>:</Grid.Col>
                <Grid.Col span={6}>{data[0]?.name}</Grid.Col>
                <Grid.Col span={5}>Mobile Number</Grid.Col>
                <Grid.Col span={1}>:</Grid.Col>
                <Grid.Col span={6}>{data[0]?.phone_no}</Grid.Col>
                <Grid.Col span={5}>Email</Grid.Col>
                <Grid.Col span={1}>:</Grid.Col>
                <Grid.Col span={6}>{data[0]?.email}</Grid.Col>
                <Grid.Col span={5}>Address</Grid.Col>
                <Grid.Col span={1}>:</Grid.Col>
                <Grid.Col span={6}>
                  {data[0]?.address ? "" : "-"}
                  {data[0]?.address?.street1}{" "}
                  {data[0]?.address?.street2 ? "," : ""}{" "}
                  {data[0]?.address?.street2}{" "}
                  {data[0]?.address?.panchayat ? "," : ""}
                  {data[0]?.address?.panchayat}{" "}
                  {data[0]?.address?.taluk ? "," : ""} {data[0]?.address?.taluk}{" "}
                  {data[0]?.address?.city ? "," : ""}
                  {data[0]?.address?.city}{" "}
                  {data[0]?.address?.district ? "," : ""}{" "}
                  {data[0]?.address?.district}{" "}
                  {data[0]?.address?.state ? "," : ""}
                  {data[0]?.address?.state}{" "}
                  {data[0]?.address?.pincode ? "-" : ""}{" "}
                  {data[0]?.address?.pincode}
                </Grid.Col>
                <Grid.Col span={5}>Drone license no</Grid.Col>
                <Grid.Col span={1}>:</Grid.Col>
                <Grid.Col span={6}>{data[0]?.license_no}</Grid.Col>
                <Grid.Col span={5}>KYC Document</Grid.Col>
                <Grid.Col span={1}>:</Grid.Col>
                <Grid.Col span={6}>{data[0]?.kyc_type}</Grid.Col>
                <Grid.Col span={5}>KYC Document Number</Grid.Col>
                <Grid.Col span={1}>:</Grid.Col>
                <Grid.Col span={6}>{data[0]?.kyc_no}</Grid.Col>
                <Grid.Col span={5}>Uploaded KYC Document</Grid.Col>
                <Grid.Col span={1}>:</Grid.Col>
                <Grid.Col span={6}>
                  <Button
                    variant="subtle"
                    compact
                    uppercase
                    onClick={() => {
                      setPreviewImage(true);
                    }}
                    style={{ backgroundColor: "#3A9343", color: "#fff" }}
                  >
                    Preview
                  </Button>
                </Grid.Col>
                <Grid.Col span={5}>Uploaded Drone License Document</Grid.Col>
                <Grid.Col span={1}>:</Grid.Col>
                <Grid.Col span={6}>
                  <Button
                    variant="subtle"
                    compact
                    uppercase
                    onClick={() => {
                      setPreviewLicenseImage(true);
                    }}
                    style={{ backgroundColor: "#3A9343", color: "#fff" }}
                  >
                    Preview
                  </Button>
                </Grid.Col>
                <Grid.Col span={5}>Rating</Grid.Col>
                <Grid.Col span={1}>:</Grid.Col>
                <Grid.Col span={6}>
                  <Group>
                    <Rating value={data?.rating ? data?.rating : 0} readOnly />
                  </Group>
                </Grid.Col>
              </Grid>
            </Grid.Col>
            <Grid.Col span={4}>
              <Image src={data[0]?.profile} />
            </Grid.Col>
            <Grid.Col span={12}>
              <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                {/* <Button
                  style={{ marginTop: 20, background: "#FA5252" }}
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Back
                </Button> */}
                {data[0]["account_status"] === "pending" ? (
                  <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      style={{ marginTop: 20, background: "#FA5252" }}
                      onClick={() => {
                        updateOperatorDetails("rejected");
                        // updateKycDetails("rejected");
                      }}
                    >
                      Reject
                    </Button>
                    <Button
                      style={{
                        marginLeft: 20,
                        marginTop: 20,
                        background: "#2C752F",
                      }}
                      onClick={() => {
                        updateOperatorDetails("approved");
                        // updateKycDetails("approved");
                      }}
                    >
                      Approve
                    </Button>
                  </Box>
                ) : (
                  <></>
                )}
              </Box>
            </Grid.Col>
          </Grid>
        </Paper>
      </Grid.Col>
      {previewImage ? (
        <ImageModal
          id={""}
          show={previewImage}
          url={data[0]?.image_url}
          handleClose={handlePreview}
        />
      ) : null}
      {previewLicenseImage ? (
        <ImageModal
          id={""}
          show={previewLicenseImage}
          url={data[0]?.drone_license_image_url}
          handleClose={handleLicensePreview}
        />
      ) : null}
    </Grid>
  );
}
