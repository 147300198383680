import { Button, Modal, Title, Box, ScrollArea, Loader } from "@mantine/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { TextInput } from "@mantine/core";
import { Grid } from "@mantine/core";
import * as _ from "lodash";
import RoleAPI from "../../../Redux/Role/service";
import { IconCircleX } from "@tabler/icons-react";

const UserSchema = Yup.object().shape({
  name: Yup.string().required("Role name is required"),
});

type Props = {
  id?: string;
  show: boolean;
  data?: any;
  type: string;
  handleClose: () => void;
};

const AddRoleModal: React.FC<Props> = ({
  id,
  show,
  data,
  type,
  handleClose,
}) => {
  const [initialValues, setInitialValues] = useState<any>(
    _.isEmpty(data)
      ? {
        name: "",
      }
      : {
        name: data[0]?.name,
      }
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: UserSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      handleSubmit(values);
    },
  });

  // const [operatorList, setOperatorList] = useState<
  //   { value: any; label: any }[]
  // >([]);
  const [isLoading, setIsLoading] = useState(false);

  // async function getOperatorList() {
  //   //   let data = (await OperatorAPI.getOperatorListAll()).data;
  //   let tempList: { value: any; label: any }[] = [];
  //   data.map((res: any) => {
  //     tempList.push({
  //       value: res._id,
  //       label: res.name,
  //     });
  //   });

  //   setOperatorList(tempList);
  // }

  useEffect(() => {
    // getOperatorList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [error, setError] = useState<any>("");

  async function handleSubmit(Params: any) {
    if (!isLoading) {
      setIsLoading(true);
      let payload = initialValues;
      payload = {
        ...payload,
        name: payload?.name.trimEnd(),
      };

      if (type === "Add") {
        try {
          await RoleAPI.createRole(payload);
          handleClose();
        } catch (e: any) {
          setError(e.message);
        }

      } else {
        payload = {
          ...payload,
          id: data[0]?._id,
        };
        try {
          await RoleAPI.updateRole(payload);
          handleClose();
        } catch (e: any) {
          setError(e.message);
        }
      }
      setIsLoading(false);
    }
  }

  return (
    <Modal
      centered
      radius="lg"
      opened={show}
      onClose={handleClose}
      withCloseButton={true}
      scrollAreaComponent={ScrollArea.Autosize}
      title={
        <Title style={{ fontSize: 25, fontWeight: 300, color: "#4C9C4F" }}>
          {type} Role
        </Title>
      }
    >
      {error !== "" ? (
        <div
          style={{ backgroundColor: "red", color: "white", padding: "5px", marginBottom: 5, display: 'flex', fontSize: '13px' }}
        >
          <IconCircleX size="20px" style=
            {{ marginRight: '10px' }} /> {error}
        </div>
      ) : (
        ""
      )}
      <Box>
        <form onSubmit={formik.handleSubmit}>
          <Grid>
            <Grid.Col xs={12} sm={12} md={12} lg={12}>
              <TextInput
                label="Role Name"
                placeholder="Enter the role name"
                withAsterisk
                value={initialValues?.name}
                onChange={(val) => {
                  setError("");
                  setInitialValues({
                    ...initialValues,
                    name: val.target.value.replace(/\s+/g, ' ').trimStart(),
                  });
                }}
              />
              {formik.touched.name && formik.errors.name && (
                <span role="alert" style={{ color: "red", fontSize: 12 }}>
                  {formik.errors.name.toString()}
                </span>
              )}
            </Grid.Col>

            <Grid.Col span={12}>
              <Box style={{ display: "flex", justifyContent: "center" }}>
                {isLoading ? (
                  <Button style={{ backgroundColor: "#2C752F", width: 100 }}>
                    <Loader size="xs" />
                  </Button>
                ) : (
                  <Button type="submit" style={{ backgroundColor: "#2C752F" }}>
                    {type}
                  </Button>
                )}
              </Box>
            </Grid.Col>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
};

export default AddRoleModal;
