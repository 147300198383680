import { put, all, fork, call, takeLatest } from "redux-saga/effects";
import UserAPI from "./Services";
import {
  setUserDetailsLoading,
  setUserDetailsSuccess,
  setGetUserListSuccess,
  setUpdateUserSuccess,
  setDeleteUserSuccess,
  setUserDetailsFailure,
  setNotifyFalse,
} from "./UserSlice";
import { userResponseI } from "./Interface";
import {
  getuserlistDetails,
  updateuserDetails,
  deleteuserDetails,
} from "./actionTypes";
import * as actions from "./actionTypes";

function* getUserListDetails(payload: getuserlistDetails) {
  try {
    yield put(setUserDetailsLoading());
    console.log("=====user payload=====", payload);
    const data: userResponseI = yield call(UserAPI.getUserList, payload?.data);
    yield put(setUserDetailsSuccess(data));
  } catch (error) {
    console.log("user error", error);
    yield put(setUserDetailsFailure(error));
  }
}

function* watchOnGetUserListDetails() {
  yield takeLatest(actions.GET_USER_LIST_DETAILS, getUserListDetails);
}

function* updateUserDetails(payload: updateuserDetails) {
  try {
    yield put(setUserDetailsLoading());
    console.log("=====user update payload=====", payload);
    const data: userResponseI = yield call(UserAPI.updateUser, payload?.data);
    yield put(setUpdateUserSuccess(data));
    const userdata: userResponseI = yield call(UserAPI.getUserList, {
      skip: 0,
      limit: 10,
      filter: ""
    });
    yield put(setUserDetailsSuccess(userdata));
  } catch (error) {
    console.log("user update error", error);
    yield put(setUserDetailsFailure(error));
  }
}

function* watchOnUpdateUserDetails() {
  yield takeLatest(actions.UPDATE_USER_DETAILS, updateUserDetails);
}

function* deleteUserDetails(payload: deleteuserDetails) {
  try {
    yield put(setUserDetailsLoading());
    console.log("=====user delete payload=====", payload);
    const data: userResponseI = yield call(UserAPI.deleteUser, payload?.data);
    yield put(setDeleteUserSuccess(data));
    console.log("=====getdata data=====", data);
    let getuser = { skip: 0, limit: 10, filter: "" };
    const getalldata: userResponseI = yield call(UserAPI.getUserList, getuser);
    yield put(setUserDetailsSuccess(getalldata));
  } catch (error) {
    console.log("user delete error", error);
    yield put(setUserDetailsFailure(error));
  }
}

function* watchOnDeleteUserDetails() {
  yield takeLatest(actions.DELETE_USER_DETAILS, deleteUserDetails);
}

export default function* userSaga() {
  yield all([
    fork(watchOnGetUserListDetails),
    fork(watchOnUpdateUserDetails),
    fork(watchOnDeleteUserDetails),
  ]);
}
