import apiInstance from "../../Services";
import { RoleData, RoleDelete } from "./interface";


const RoleAPI = {
  // eslint-disable-next-line class-methods-use-this

  createRole: async (payload: RoleData) => {
    try {
      const res = await apiInstance.post<any>(`/roleManagement/create`, payload);
      return res;
    } catch (error) {
      throw error;
    }
  },

  getRoleList: async (payload: any) => {
    try {
      const res = await apiInstance.get<any>(
        `/roleManagement/getallList?skip=${payload.skip}&limit=${payload.limit}&filter=${payload.filter}&status=${payload.status}`
      );
      return res;
    } catch (error) {
      throw error;
    }
  },

  updateRole: async (payload: RoleData) => {
    try {
      const res = await apiInstance.put<any>(
        `/roleManagement/update?id=${payload?.id}`,
        payload
      );
      return res;
    } catch (error) {
      throw error;
    }
  },

  deleteRole: async (payload: RoleDelete) => {
    try {
      const res = await apiInstance.put<RoleDelete>(
        `/roleManagement/delete?id=${payload.id}`
      );
      return res;
    } catch (error) {
      throw error;
    }
  },
};

export default RoleAPI;
