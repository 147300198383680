import apiInstance from "../../../Services";
import { create, deleteData, update } from "./Interface";

const ConfigurationCropDetailsAPI = {
  // eslint-disable-next-line class-methods-use-this
  createCropType: async (payload: create) => {
    try {
      const res = await apiInstance.post<any>(`/cropdetails/create`, payload);
      return res;
    } catch (error) {
      throw error;
    }
  },

  updateCropType: async (payload: update) => {
    try {
      const res = await apiInstance.put<any>(`/cropdetails/update?id=${payload.id}`, payload);
      return res;
    } catch (error) {
      throw error;
    }
  },

  getCropTypeList: async (payload: any) => {
    try {
      const res = await apiInstance.get<any>(
        `/cropdetails/getall?skip=${payload.skip}&limit=${payload.limit}&filter=${payload.filter}&status=${payload.status}`
      );
      return res;
    } catch (error) {
      throw error;
    }
  },

  

  getAllCropTypeList: async () => {
    try {
      const res = await apiInstance.get<any>("cropdetails/getallList");
      return res;
    } catch (error) {
      throw error;
    }
  },

  deleteCropDetails: async (payload: deleteData) => {
    try {
      const res = await apiInstance.put<deleteData>(
        `/cropdetails/delete?id=${payload.id}&status=${payload.status}`
      );
      return res;
    } catch (error) {
      throw error;
    }
  },
};

export default ConfigurationCropDetailsAPI;
