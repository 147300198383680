import { store } from "./store";
import { Provider } from "react-redux";
import { ContextProvider } from "./Services/context/context";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";

let persistor = persistStore(store);

function AppWrapper({ children }: any) {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ContextProvider>
          <MantineProvider withNormalizeCSS withGlobalStyles>
            <Notifications position="top-right" zIndex={2077} />
            {children}
          </MantineProvider>
        </ContextProvider>
      </PersistGate>
    </Provider>
  );
}

export default AppWrapper;
