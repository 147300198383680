import { createSlice } from "@reduxjs/toolkit";
import { UserState } from "./Interface";

const initialState: UserState = {
  loading: false,
  error: null,
  data: {
    message: "",
    data: {
      count: 0,
      data: [],
    },
    status: false,
    code: 0,
  },
  notify: false,
  notifymsg: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserDetailsLoading: (state) => {
      state.loading = true;
    },
    setUserDetailsSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload;
      state.notify = false;
      state.notifymsg = "";
    },
    setGetUserListSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload;
      state.notifymsg = action.payload;
      state.notify = false;
    },
    setUpdateUserSuccess: (state, action) => {
      state.error = null;
      state.data = action.payload;
      state.notifymsg = action.payload;
      state.notify = true;
    },
    setDeleteUserSuccess: (state, action) => {
      state.error = null;
      state.notifymsg = action.payload;
      state.notify = true;
    },
    setUserDetailsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.notify = true;
    },
    setNotifyFalse: (state, action) => {
      state.notifymsg = "";
      state.notify = false;
      state.error = null;
    },
  },
});

export const {
  setUserDetailsLoading,
  setUserDetailsSuccess,
  setGetUserListSuccess,
  setUpdateUserSuccess,
  setDeleteUserSuccess,
  setUserDetailsFailure,
  setNotifyFalse,
} = userSlice.actions;

export default userSlice.reducer;
