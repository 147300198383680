import { put, all, fork, call, takeLatest } from "redux-saga/effects";

import { loginResponseI } from "./Interface";
import * as actions from "./actionTypes";
import OperatorAPI from "./Services";
import {
  setCreateOperatorFailure,
  setCreateOperatorLoading,
  setCreateOperatorSuccess,
  setGetOperatorListFailed,
  setGetOperatorListLoading,
  setGetOperatorListSuccess,
} from "./OperatorSlice";

function* createOperator(payload: actions.createOperator) {
  try {
    yield put(setCreateOperatorLoading());
    console.log("=====Create Operator=====", payload);
    const data: loginResponseI = yield call(
      OperatorAPI.createOperator,
      payload?.data
    );
    yield put(setCreateOperatorSuccess(data));
    let getoperator = { skip: 0, limit: 50 };
    const getdata: loginResponseI = yield call(
      OperatorAPI.getOperatorList,
      getoperator
    );
    yield put(setGetOperatorListSuccess(getdata));
  } catch (error) {
    console.log("create error", error);
    yield put(setCreateOperatorFailure(error));
  }
}

function* watchOnCreateOperator() {
  yield takeLatest(actions.Create_Operator, createOperator);
}

function* getOperatorList(payload: actions.getOperatorList) {
  try {
    yield put(setGetOperatorListLoading());
    console.log("=====Operator List=====", payload);
    const data: loginResponseI = yield call(
      OperatorAPI.getOperatorList,
      payload?.data
    );
    yield put(setGetOperatorListSuccess(data));
  } catch (error) {
    console.log("create error", error);
    yield put(setGetOperatorListFailed(error));
  }
}

function* watchOnGetOperatorList() {
  yield takeLatest(actions.Get_Operator_List, getOperatorList);
}

function* updateOperator(payload: actions.updateOperator) {
  try {
    yield put(setGetOperatorListLoading());
    console.log("=====Update Operator=====", payload);
    const data: loginResponseI = yield call(
      OperatorAPI.updateOperator,
      payload?.data
    );
    yield put(setGetOperatorListSuccess(data));
    let getoperator = { skip: 0, limit: 10 };
    const getdata: loginResponseI = yield call(
      OperatorAPI.getOperatorList,
      getoperator
    );
    yield put(setGetOperatorListSuccess(getdata));
  } catch (error) {
    console.log("create error", error);
    yield put(setGetOperatorListFailed(error));
  }
}

function* watchOnUpdateOperatorList() {
  yield takeLatest(actions.Update_Operator_List, updateOperator);
}

function* watchOnDeleteOperator() {
  yield takeLatest(actions.Delete_Operator, updateOperator);
}

export default function* operatorSaga() {
  yield all([
    fork(watchOnCreateOperator),
    fork(watchOnGetOperatorList),
    fork(watchOnUpdateOperatorList),
    fork(watchOnDeleteOperator),
  ]);
}
