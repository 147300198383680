import { useAppSelector } from "../Services/hooks/hooks";
import React from "react";
import {
  ActionIcon,
  Box,
  Grid,
  Group,
  Paper,
  Text,
  Title,
} from "@mantine/core";
import img from "../assets/404.png";
import { useNavigate } from "react-router-dom";
import { IconArrowLeft } from "@tabler/icons-react";

export { PrivateRoute };

function PrivateRoute(props: any) {
  let componenetStatus = false;
  const { data, loading, error, notify, notifymsg } = useAppSelector(
    (state) => state.auth
  );

  const Styles = {
    paper: {
      width: "100%",
      backgroundColor: "#FFFFFF",
      padding: 20,
      borderRadius: 20,
    },
    title: {
      fontSize: 30,
      fontWeight: 300,
      color: "#4C9C4F",
    },
    box: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };

  const navigate = useNavigate();

  const permissionList = data?.data?.permission?.[0]?.permission_list
    ? data?.data?.permission[0]?.permission_list
    : [];
  let index = permissionList.findIndex(
    (res: any) => res.name === props.component
  );
  if (index === -1) {
    componenetStatus = false;
  } else {
    componenetStatus =
      permissionList[index]["read"] || permissionList[index]["write"];
  }

  if (!componenetStatus) {
    return (
      <React.Fragment>
        <Grid>
          <Grid.Col span={12}>
            <Paper style={Styles.paper}>
              <Grid>
                <Grid.Col xs={12} md={6} lg={6}>
                  <Group>
                    <ActionIcon
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      <IconArrowLeft size={50} color="black" />
                    </ActionIcon>
                    <Title style={Styles.title}>Access Denied</Title>
                  </Group>
                  <div style={{ paddingTop: 20, paddingLeft: 45 }}>
                    <Text>You do not have permission to access this page.</Text>
                  </div>
                </Grid.Col>

                <Grid.Col xs={12} md={6} lg={6}>
                  <Box style={Styles.box}>
                    <img width="90%" src={img} />
                  </Box>
                </Grid.Col>
              </Grid>
            </Paper>
          </Grid.Col>
        </Grid>
      </React.Fragment>
    );
  }

  // authorized so return child components
  return props.children;
}
