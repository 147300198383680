import { Modal, Title } from "@mantine/core";
import { Grid } from "@mantine/core";

type Props = {
  show: boolean;
  type: string;
  data?: any;
  handleClose: () => void;
};

const ManagementViewModal: React.FC<Props> = ({
  show,
  type,
  data,
  handleClose,
}) => {
  return (
    <Modal
      centered
      radius="lg"
      padding="xl"
      opened={show}
      onClose={handleClose}
      withCloseButton={true}
      title={
        <Title style={{ fontSize: 25, fontWeight: 300, color: "#4C9C4F" }}>
          {type} Details
        </Title>
      }
    >
      <Grid>
        <Grid.Col span={5}>Name</Grid.Col>
        <Grid.Col span={1}>:</Grid.Col>
        <Grid.Col span={6}>{data[0]?.name}</Grid.Col>
        <Grid.Col span={5}>Email</Grid.Col>
        <Grid.Col span={1}>:</Grid.Col>
        <Grid.Col span={6}>{data[0]?.email}</Grid.Col>
        <Grid.Col span={5}>Permission</Grid.Col>
        <Grid.Col span={1}>:</Grid.Col>
        <Grid.Col span={6}>{data[0]?.permission[0]?.name}</Grid.Col>
        {/* <Grid.Col span={12}>
          <Title style={{ fontSize: 25, fontWeight: 300, color: "#4C9C4F" }}>
            Permissions
          </Title>
        </Grid.Col> */}

        {/* {data[0]?.permission[0]?.permission_list?.map((e: any) => {
          return (
            <>
              <Grid.Col span={5}>{e?.name}</Grid.Col>
              <Grid.Col span={1}>:</Grid.Col>
              <Grid.Col span={2}>Read</Grid.Col>
              <Grid.Col span={1}>
                <Checkbox checked={e?.read} />
              </Grid.Col>
              <Grid.Col span={2}>Write</Grid.Col>
              <Grid.Col span={1}>
                <Checkbox checked={e?.write} />
              </Grid.Col>
            </>
          );
        })} */}
      </Grid>
    </Modal>
  );
};

export default ManagementViewModal;
