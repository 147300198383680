import apiInstance from "../../Services";
import { Landcount, Cropcount, OrderReport } from "./Interface";

const DashboardApi = {
  getLandcount: async (payload: Landcount) => {
    try {
      const res = await apiInstance.get<any>(
        `overalldetail/landCount?startDate=${payload.start_date}&endDate=${payload.end_date}&state=${payload.state}&district=${payload.district}&block=${payload.block}`
      );
      return res;
    } catch (err) {
      throw err;
    }
  },

  getCropcount: async (payload: Cropcount) => {
    try {
      const res = await apiInstance.get<any>(
        `overalldetail/cropcountperDay?from=${payload.start_date}&to=${payload.end_date}&state=${payload.state}&district=${payload.district}&block=${payload.block}`
      );
      return res;
    } catch (err) {
      throw err;
    }
  },

  getOveralldata: async (payload: any) => {
    try {
      const res = await apiInstance.get<any>(`/overalldetail/data?startDate=${payload.from}&toDate=${payload.to}&state=${payload.state}&district=${payload.district}&block=${payload.block}`);
      return res;
    } catch (err) {
      throw err;
    }
  },

  getFertilizercount: async (payload: Landcount) => {
    try {
      const res = await apiInstance.get<any>(
        `overalldetail/fertilizerCountByDate?fromDate=${payload.start_date}&toDate=${payload.end_date}&state=${payload.state}&district=${payload.district}&block=${payload.block}`
      );
      return res;
    } catch (err) {
      throw err;
    }
  },

  getOrderReport: async (payload: OrderReport) => {
    try {
      const res = await apiInstance.get<any>(
        `overalldetail/orderreportlist?startDate=${payload.startDate}&endDate=${payload.endDate}&state=${payload.state}&district=${payload.district}&block=${payload.block}`
      );
      return res;
    } catch (err) {
      throw err;
    }
  },

};

export default DashboardApi;
