import { IconTrash, IconEdit, IconPlugConnected, IconPlus } from "@tabler/icons-react";
import {
  ActionIcon,
  Box,
  Table,
  ScrollArea,
  Group,
  Text,
  Tooltip,
} from "@mantine/core";
import { useState } from "react";
import DeleteModal from "../../../Components/DeleteModal";
import DistrictAPI from "../../../Redux/Configuration/District/Services";
import AddCropTypeModal from "./addDistrictModal";
import CustomPagination from "../../../Components/CustomPagination";
import capitalizeFirstLowercaseRest from "../../../Components/Capitalize";
import PermissionCheck from "../../../Components/Permission";

export default function DistrictTable(props: any) {
  const [editCropModal, setEditCropModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [id, setId] = useState("");
  const [editableData, setEditableData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(false);

  const handleEditModal = () => {
    DistrictListFunction();
    setEditCropModal(false);
  };

  const handleDelete = () => {
    DistrictListFunction();
    setShowDeleteModal(false);
  };

  async function DistrictListFunction() {
    let params = {
      ...props.pagination,
      filter: props.searchText ? props.searchText : "",
      status: true,
    };
    try {
      let data = (await DistrictAPI.getDistrictList(params)).data;
      props.setTableData(data?.data ? data.data : []);
      props.setTotalCount(data?.count ? data?.count : 0);
    } catch (e) {
      props.setTableData([]);
    }
  }

  const rows = props.data?.map((item: any) => {
    return (
      <tr key={item?._id}  style={{ color: !item?.status ? "grey" : "black" }}>
        <td>{capitalizeFirstLowercaseRest(item?.state)}</td>
        <td>{capitalizeFirstLowercaseRest(item?.name)}</td>
        <td style={{ display: "flex", justifyContent: "space-evenly" }}>
          <div style={{ justifyContent: "space-between", display: "flex" }}>
            {
              PermissionCheck(props.permissionList, "Configuration", "write") ?
                <ActionIcon
                  variant="outline"
                  onClick={() => {
                    const edata = props.data?.filter(
                      (e: any) => e?._id === item?._id
                    );
                    setEditableData(edata);
                    setEditCropModal(true);
                  }}
                >
                  <Tooltip label="Edit">
                    <IconEdit size={20} />
                  </Tooltip>
                </ActionIcon> : <></>
            }
            {
              PermissionCheck(props.permissionList, "Configuration", "write") ?

                <ActionIcon
                  style={{ marginLeft: "20px" }}
                  variant="outline"
                  onClick={() => {
                    setShowDeleteModal(true);
                    setId(item?._id);
                    setSelectedStatus(item.status);
                  }}
                >
                  <Tooltip label={item?.status ? "De-Active" : "Active"}>
                  {item?.status ? (
                    <IconPlugConnected size={20} />
                  ) : (
                    <IconPlus size={20} />
                  )}
                </Tooltip>
                </ActionIcon> : <></>
            }
          </div>
        </td>
      </tr>
    );
  });

  return (
    <>
      <ScrollArea>
        {props.data.length === 0 ? (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: window.innerHeight - 325
            }}
          >
            <Text>No Data</Text>
          </Box>
        ) : (
         
            <div style={{height: window.innerHeight - 375,overflowY:"scroll"}}>
            <Table verticalSpacing="sm" style={{tableLayout:"fixed"}}>
              <thead>
                <tr>
                  <th>State</th>
                  <th>District</th>
                  <th style={{ textAlign: "center" }}>Actions</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
            </div>
        )}
      </ScrollArea>
      {props.count > 0 ? (
        <CustomPagination
          setPage={props.setPage}
          pagination={props.pagination}
          setPagination={props.setPagination}
          page={props.page}
          count={props.count}
        />
      ) : (
        <></>
      )}
      {showDeleteModal ? (
        <DeleteModal
          id={id}
          show={showDeleteModal}
          type="District"
          handleClose={handleDelete}
          error={props.setError}
          status={!selectedStatus}
        />
      ) : null}

      {editCropModal ? (
        <AddCropTypeModal
          show={editCropModal}
          data={editableData}
          type="Update"
          handleClose={handleEditModal}
        />
      ) : null}

      {/* {viewCropModal ? (
        <ConfigViewModal
          show={viewCropModal}
          data={editableData}
          type="Crop Type"
          handleClose={handleCropModal}
        />
      ) : null} */}
    </>
  );
}
