import { useEffect, useRef, useState } from "react";
import {
  Autocomplete,
  Button,
  Group,
  Text,
  Box,
  Title,
  Grid,
  Paper,
  ActionIcon,
  Tabs,
  Rating,
  Tooltip,
} from "@mantine/core";
import { useLocation, useNavigate } from "react-router-dom";
import ApproveModal from "../../Components/ApproveModal";
import OperatorAPI from "../../Redux/Operator/Services";
import { IconArrowLeft, IconClock, IconEdit } from "@tabler/icons-react";
import ImageModal from "../../Components/ImageModal";
import DroneAPI from "../../Redux/Drone/Services";
import { DateInput } from "@mantine/dates";
import { TimeInput } from "@mantine/dates";
import dayjs from "dayjs";
import PermissionCheck from "../../Components/Permission";
import MapModal from "./MapModal";
import EditModal from "./EditModal";
import ConfigurationPesticideAPI from "../../Redux/Configuration/Pesticide/Services";
import ConfigurationCropStageAPI from "../../Redux/Configuration/CropStage/Services";
import BookingAPI from "../../Redux/Booking/Services";

export default function OrderVerify(props: any) {
  const [id, setId] = useState("");
  const navigate = useNavigate();
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const location = useLocation();
  const [data, setData] = useState(location.state.item);
  const permissionList = location.state.permissionList;
  const rawDate = data?.date;
  const formattedDate = new Date(rawDate);
  const [previewImage, setPreviewImage] = useState(false);
  const [previewImageUrl, setPreviewImageUrl] = useState("");
  const [previewPlotImage, setPreviewPlotImage] = useState(false);
  const [selectDate, setSelectDate] = useState<Date | null>(null);
  const [selectTime, setSelectTime] = useState<Date | null | any>(null);
  const [showMap, setShowMap] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const handleEditModalClose = () => {
    setShowEditModal(false);
  };
  const date = formattedDate.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  });

  const handleApprove = () => {
    setShowApproveModal(false);
  };

  const handleCancel = () => {
    setShowCancelModal(false);
  };

  const handleReject = () => {
    setShowRejectModal(false);
  };

  const handleComplete = () => {
    setShowCompleteModal(false);
  };

  const handlePreview = () => {
    setPreviewImage(false);
    setPreviewImageUrl("");
  };

  const handlePlotPreview = () => {
    setPreviewPlotImage(false);
  };

  const Styles = {
    paper: {
      width: "100%",
      backgroundColor: "#FFFFFF",
      padding: 20,
      borderRadius: 20,
    },
  };
  const [operatorList, setOperatorList] = useState<
    { value: any; label: any }[]
  >([]);
  const [selectedOperator, setSelectedOperator] = useState<string | any>("");
  const [allOperator, setAllOperator] = useState([]);
  const [pesticideData, setPesticideData] = useState<any>({});
  const [cropStageData, setCropStageData] = useState<any>({});
  const [operatorPesticideData, setOperatorPesticideData] = useState<any>({});
  async function getOperatorList() {
    let data = (await OperatorAPI.getOperatorListAll()).data;
    setAllOperator(data);
    let tempList: { value: any; label: any; id: any }[] = [];
    data.map((res: any) => {
      tempList.push({
        value: res.name,
        label: res.name,
        id: res._id,
      });
    });
    setOperatorList(tempList);
  }
  const [droneDetails, setDroneDetails] = useState([]);
  const ref = useRef<HTMLInputElement | any>();
  async function DroneListFunction() {
    let payload = {
      skip: 0,
      limit: 0,
      status: true,
      filter: "",
    };
    try {
      let data = (await DroneAPI.getDroneList(payload)).data;
      setDroneDetails(data?.data);
    } catch (e) {
      setDroneDetails([]);
    }
  }

  async function getPesticideById() {
    try {
      let result = await ConfigurationPesticideAPI.getPesticideById({
        id: data?.cultivateData?.pesticide_id,
      });
      setPesticideData(result.data[0]);
    } catch (e) {
      setPesticideData({});
    }

    if (data?.operatorReport.length > 0) {
      try {
        let result = await ConfigurationPesticideAPI.getPesticideById({
          id: data?.operatorReport[0]?.cultivation_details?.pesticide_id,
        });
        setOperatorPesticideData(result.data[0]);
      } catch (e) {
        setOperatorPesticideData({});
      }
    }
  }

  async function getCropStageById() {
    try {
      let result = await ConfigurationCropStageAPI.getCropStageById({
        id: data?.operatorReport[0]?.cultivation_details.age,
      });
      setCropStageData(result.data);
    } catch (e) {
      setCropStageData({});
    }
  }

  // let Modaldata: any = {
  //   landspray: {
  //     value: data?.operatorReport[0]?.cultivation_details.farm_land_area,
  //     area: data?.cultivateData?.metrics,
  //   },
  // };

  useEffect(() => {
    DroneListFunction();
    getPesticideById();
    getCropStageById();
  }, []);

  useEffect(() => {
    getOperatorList();
  }, []);

  function tConvert(time: any) {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  }

  async function getBookingData() {
    try {
      let payload: any = { id: data?._id, status: "all" };
      let bookingData = await BookingAPI.getBookingListByBookingStatus(payload);
      setData(bookingData.data[0]?.data?.[0]);
    } catch (e: any) {
    }
  }

  useEffect(() => {
    if (!showEditModal) {
      getBookingData();
    }
  }, [showEditModal])

  let url = `https://www.google.com/maps?q=${data?.farm_land_location[0]},${data?.farm_land_location[1]}`;
  return showMap ? (
    <MapModal
      location={data?.operatorReport[0]?.location}
      setShowMap={setShowMap}
    />
  ) : (
    <Grid>
      <Grid.Col span={12}>
        <Paper style={Styles.paper}>
          <Grid>
            <Grid.Col span={12}>
              <Group>
                <ActionIcon
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <IconArrowLeft size={50} color="black" />
                </ActionIcon>
                <Title
                  style={{
                    fontSize: 30,
                    fontWeight: 300,
                    color: "#4C9C4F",
                  }}
                >
                  Order Details
                </Title>
              </Group>
            </Grid.Col>

            <Tabs defaultValue="order_details">
              <Tabs.List>
                <Tabs.Tab value="order_details" style={{ fontSize: 16 }}>
                  Order Details
                </Tabs.Tab>
                {data?.operatorReport.length > 0 ? (
                  <Tabs.Tab value="operator_report" style={{ fontSize: 16 }}>
                    Operator Report
                  </Tabs.Tab>
                ) : (
                  <></>
                )}
                {data?.operatorReport[0]?.sos?.length > 0 ? (
                  <Tabs.Tab value="sos_history" style={{ fontSize: 16 }}>
                    SOS History
                  </Tabs.Tab>
                ) : (
                  <></>
                )}
                {data?.operatorReport[0]?.interruption?.length > 0 ? (
                  <Tabs.Tab
                    value="interruption_history"
                    style={{ fontSize: 16 }}
                  >
                    Interruption History
                  </Tabs.Tab>
                ) : (
                  <></>
                )}
              </Tabs.List>
              <Tabs.Panel value="order_details" pt="xs">
                <Grid>
                  <Grid.Col span={12}>
                    <Text style={{ fontSize: 20, fontWeight: 600 }}>
                      Farmer Details
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={5}>Farmer Id</Grid.Col>
                  <Grid.Col span={1}>:</Grid.Col>
                  <Grid.Col span={6}>{data?.user?.farmer_id}</Grid.Col>
                  <Grid.Col span={5}>Farmer Name</Grid.Col>
                  <Grid.Col span={1}>:</Grid.Col>
                  <Grid.Col span={6}>{data?.user?.name}</Grid.Col>
                  <Grid.Col span={5}>Mobile Number</Grid.Col>
                  <Grid.Col span={1}>:</Grid.Col>
                  <Grid.Col span={6}>{data?.user?.phone_no}</Grid.Col>
                  <Grid.Col span={5}>Address</Grid.Col>
                  <Grid.Col span={1}>:</Grid.Col>
                  <Grid.Col span={6}>{data?.user?.address}</Grid.Col>
                  <Grid.Col span={12}>
                    <Text style={{ fontSize: 20, fontWeight: 600 }}>
                      Cultivation Details
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={5}>Area to be sprayed</Grid.Col>
                  <Grid.Col span={1}>:</Grid.Col>
                  <Grid.Col span={6}>
                    {data?.cultivateData?.cultivation_area}{" "}
                    {data?.cultivateData?.metrics}
                  </Grid.Col>
                  {/* <Grid.Col span={5}>Metrics</Grid.Col>
                  <Grid.Col span={1}>:</Grid.Col>
                  <Grid.Col span={6}>{data?.cultivateData?.metrics}</Grid.Col> */}
                  <Grid.Col span={5}>Crop Type</Grid.Col>
                  <Grid.Col span={1}>:</Grid.Col>
                  <Grid.Col span={6}>{data?.cultivateData?.crop_type}</Grid.Col>
                  <Grid.Col span={5}>Variety</Grid.Col>
                  <Grid.Col span={1}>:</Grid.Col>
                  <Grid.Col span={6}>
                    {data?.cultivateData?.variety
                      ? data?.cultivateData?.variety
                      : "-"}
                  </Grid.Col>
                  <Grid.Col span={5}>Fertilizer</Grid.Col>
                  <Grid.Col span={1}>:</Grid.Col>
                  <Grid.Col span={6}>
                    {data?.cultivateData?.fertilizer_used}
                  </Grid.Col>
                  <Grid.Col span={5}>Other Fertilizer</Grid.Col>
                  <Grid.Col span={1}>:</Grid.Col>
                  <Grid.Col span={6}>
                    {data?.other_fertilizer ? data?.other_fertilizer : "-"}
                  </Grid.Col>
                  <Grid.Col span={5}>Pesticide Type</Grid.Col>
                  <Grid.Col span={1}>:</Grid.Col>
                  <Grid.Col span={6}>
                    {/* {data?.cultivateData?.pesticide_used
                      ? data?.cultivateData?.pesticide_used
                      : "-"} */}
                    {pesticideData?.type ? pesticideData?.type : "-"}
                  </Grid.Col>
                  <Grid.Col span={5}>Pesticide Brand Name</Grid.Col>
                  <Grid.Col span={1}>:</Grid.Col>
                  <Grid.Col span={6}>
                    {/* {data?.cultivateData?.pesticide_used
                      ? data?.cultivateData?.pesticide_used
                      : "-"} */}
                    {pesticideData?.brand_name
                      ? pesticideData?.brand_name
                      : "-"}
                  </Grid.Col>
                  <Grid.Col span={5}>Pesticide Product Name</Grid.Col>
                  <Grid.Col span={1}>:</Grid.Col>
                  <Grid.Col span={6}>
                    {/* {data?.cultivateData?.pesticide_used
                      ? data?.cultivateData?.pesticide_used
                      : "-"} */}
                    {pesticideData?.product_name
                      ? pesticideData?.product_name
                      : "-"}
                  </Grid.Col>
                  <Grid.Col span={5}>Pesticide Chemical Name</Grid.Col>
                  <Grid.Col span={1}>:</Grid.Col>
                  <Grid.Col span={6}>
                    {/* {data?.cultivateData?.pesticide_used
                      ? data?.cultivateData?.pesticide_used
                      : "-"} */}
                    {pesticideData?.chemical_name
                      ? pesticideData?.chemical_name
                      : "-"}
                  </Grid.Col>
                  <Grid.Col span={5}>Farm Location</Grid.Col>
                  <Grid.Col span={1}>:</Grid.Col>
                  <Grid.Col span={6}>
                    {" "}
                    <a href={url} target="_blank">
                      {" "}
                      <Button
                        variant="subtle"
                        compact
                        uppercase
                        style={{ backgroundColor: "#3A9343", color: "#fff" }}
                      >
                        View Location
                      </Button>
                    </a>
                  </Grid.Col>
                  <Grid.Col span={5}>Remarks</Grid.Col>
                  <Grid.Col span={1}>:</Grid.Col>
                  <Grid.Col span={6}>
                    {data?.remarks ? data?.remarks : "-"}
                  </Grid.Col>
                  <Grid.Col span={5}>Date</Grid.Col>
                  <Grid.Col span={1}>:</Grid.Col>
                  <Grid.Col span={6}>
                    {data?.open_slot && data?.booking_status === "pending" ? (
                      <DateInput
                        value={selectDate}
                        onChange={(val) => {
                          setSelectDate(val);
                        }}
                        placeholder="Select Schedule Date"
                        sx={{ maxHeight: 200, overflow: "auto" }}
                        style={{ width: 300 }}
                        minDate={dayjs(new Date()).toDate()}
                      />
                    ) : (
                      new Date(data?.date).getDate() +
                      "-" +
                      (new Date(data?.date).getMonth() + 1) +
                      "-" +
                      new Date(data?.date).getFullYear()
                    )}
                  </Grid.Col>
                  <Grid.Col span={5}>Time</Grid.Col>
                  <Grid.Col span={1}>:</Grid.Col>
                  <Grid.Col span={6}>
                    {data?.open_slot && data?.booking_status === "pending" ? (
                      <TimeInput
                        ref={ref}
                        onChange={(val) => {
                          setSelectTime(val.target.value.toString());
                        }}
                        rightSection={
                          <ActionIcon onClick={() => ref.current.showPicker()}>
                            <IconClock size="1rem" stroke={1.5} />
                          </ActionIcon>
                        }
                        sx={{ maxHeight: 200, overflow: "auto" }}
                        style={{ width: 300 }}
                      />
                    ) : (
                      tConvert(data?.time)
                    )}
                  </Grid.Col>
                  {/* <Grid.Col span={5}>Payment Mode</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}>{data?.payment_mode}</Grid.Col> */}
                  {data?.booking_status === "pending" ? (
                    <Grid.Col span={5}>Assign Operator</Grid.Col>
                  ) : (
                    <></>
                  )}
                  {data?.booking_status === "pending" ? (
                    <Grid.Col span={1}>:</Grid.Col>
                  ) : (
                    <></>
                  )}
                  {data?.booking_status === "pending" ? (
                    <Grid.Col span={6}>
                      <Autocomplete
                        placeholder="Select Operator"
                        sx={{ maxHeight: 200, overflow: "auto" }}
                        style={{ width: 300 }}
                        //   value={value}
                        //   onChange={setValue}

                        onChange={(val) => {
                          setSelectedOperator(val);
                        }}
                        data={operatorList.filter((f: any) =>
                          droneDetails.some(
                            (d: any) => d.assigned_operator == f.id
                          )
                        )}
                      />
                    </Grid.Col>
                  ) : (
                    <></>
                  )}
                  {data?.booking_status !== "pending" ? (
                    <>
                      <Grid.Col span={12}>
                        <Text style={{ fontSize: 20, fontWeight: 600 }}>
                          Operator Details
                        </Text>
                      </Grid.Col>
                      <Grid.Col span={5}>Name</Grid.Col>
                      <Grid.Col span={1}>:</Grid.Col>
                      <Grid.Col span={6}>
                        {data?.operator?.name ? data?.operator?.name : "-"}
                      </Grid.Col>
                      <Grid.Col span={5}>Email</Grid.Col>
                      <Grid.Col span={1}>:</Grid.Col>
                      <Grid.Col span={6}>
                        {data?.operator?.email ? data?.operator?.email : "-"}{" "}
                      </Grid.Col>
                      <Grid.Col span={5}>Mobile Number</Grid.Col>
                      <Grid.Col span={1}>:</Grid.Col>
                      <Grid.Col span={6}>
                        {data?.operator?.phone_no
                          ? data?.operator?.phone_no
                          : "-"}{" "}
                      </Grid.Col>
                      <Grid.Col span={5}>Work Status</Grid.Col>
                      <Grid.Col span={1}>:</Grid.Col>
                      <Grid.Col span={6}>
                        {data?.booking_status
                          ? data.booking_status === "request_cancel"
                            ? "Requested for Cancel"
                            : data.booking_status
                          : "Not Started"}{" "}
                      </Grid.Col>
                    </>
                  ) : (
                    <></>
                  )}
                  {data?.booking_status === "completed" ? (
                    <>
                      <Grid.Col span={12}>
                        <Text style={{ fontSize: 20, fontWeight: 600 }}>
                          Feedback Details
                        </Text>
                      </Grid.Col>
                      <Grid.Col span={5}>Rating</Grid.Col>
                      <Grid.Col span={1}>:</Grid.Col>
                      <Grid.Col span={6}>
                        <Group>
                          {data?.rating ? (
                            <Rating value={data?.rating} readOnly />
                          ) : (
                            "-"
                          )}
                        </Group>
                      </Grid.Col>

                      <Grid.Col span={5}>Feedback</Grid.Col>
                      <Grid.Col span={1}>:</Grid.Col>
                      <Grid.Col span={6}>
                        {data?.feedback ? data?.feedback : "-"}
                      </Grid.Col>
                    </>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Tabs.Panel>
              <Tabs.Panel value="operator_report" pt="xs">
                <Grid>
                  {data?.operatorReport.length > 0 ? (
                    <>
                      <Grid.Col span={12}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Text style={{ fontSize: 20, fontWeight: 600 }}>
                            Operator Report
                          </Text>
                          <div style={{ marginLeft: "25px" }}>
                            <ActionIcon
                              variant="outline"
                              onClick={() => setShowEditModal(true)}
                            >
                              <Tooltip label="Edit">
                                <IconEdit size={20} />
                              </Tooltip>
                            </ActionIcon>
                          </div>
                        </div>
                      </Grid.Col>
                      {showEditModal ? (
                        <EditModal
                          // id={id}
                          show={showEditModal}
                          data={data?.operatorReport?.[0]?.cultivation_details}
                          // type={selectedDelete ? "PermanentDelete" : "Farmer"}
                          handleClose={handleEditModalClose}
                          id={data?.operatorReport?.[0]?._id}
                        />
                      ) : null}
                      <Grid.Col span={5}>Work Start Date</Grid.Col>
                      <Grid.Col span={1}>:</Grid.Col>
                      <Grid.Col span={6}>
                        {new Date(
                          data?.operatorReport[0]?.work_start_date
                        ).getDate() +
                          "-" +
                          (new Date(
                            data?.operatorReport[0]?.work_start_date
                          ).getMonth() +
                            1) +
                          "-" +
                          new Date(
                            data?.operatorReport[0]?.work_start_date
                          ).getFullYear() +
                          " " +
                          new Date(
                            data?.operatorReport[0]?.work_start_date
                          ).getHours() +
                          ":" +
                          new Date(
                            data?.operatorReport[0]?.work_start_date
                          ).getMinutes()}
                      </Grid.Col>
                      <Grid.Col span={5}>Work End Date</Grid.Col>
                      <Grid.Col span={1}>:</Grid.Col>
                      <Grid.Col span={6}>
                        {data?.operatorReport[0]?.work_end_date ? (
                          <>
                            {" "}
                            {new Date(
                              data?.operatorReport[0]?.work_end_date
                            ).getDate() +
                              "-" +
                              (new Date(
                                data?.operatorReport[0]?.work_end_date
                              ).getMonth() +
                                1) +
                              "-" +
                              new Date(
                                data?.operatorReport[0]?.work_end_date
                              ).getFullYear() +
                              " " +
                              new Date(
                                data?.operatorReport[0]?.work_end_date
                              ).getHours() +
                              ":" +
                              new Date(
                                data?.operatorReport[0]?.work_end_date
                              ).getMinutes()}
                          </>
                        ) : (
                          "-"
                        )}
                      </Grid.Col>
                      <Grid.Col span={5}>Farm Land Area Sprayed</Grid.Col>
                      <Grid.Col span={1}>:</Grid.Col>
                      <Grid.Col span={6}>
                        {
                          data?.operatorReport[0]?.cultivation_details
                            .farm_land_area
                        }{" "}
                        {data?.cultivateData?.metrics}
                      </Grid.Col>

                      <Grid.Col span={5}>Crop Type</Grid.Col>
                      <Grid.Col span={1}>:</Grid.Col>
                      <Grid.Col span={6}>
                        {data?.operatorReport[0]?.cultivation_details.crop}
                      </Grid.Col>
                      <Grid.Col span={5}>Crop Stage / Age</Grid.Col>
                      <Grid.Col span={1}>:</Grid.Col>
                      <Grid.Col span={6}>
                        {cropStageData
                          ? `${cropStageData?.name} (${cropStageData?.duration})`
                          : "-"}
                      </Grid.Col>
                      <Grid.Col span={5}>Variety</Grid.Col>
                      <Grid.Col span={1}>:</Grid.Col>
                      <Grid.Col span={6}>
                        {data?.operatorReport[0]?.cultivation_details.variety}
                      </Grid.Col>
                      <Grid.Col span={5}>Fertilizer</Grid.Col>
                      <Grid.Col span={1}>:</Grid.Col>
                      <Grid.Col span={6}>
                        {
                          data?.operatorReport[0]?.cultivation_details
                            .fertilizer
                        }
                      </Grid.Col>
                      <Grid.Col span={5}>Other Fertilizer</Grid.Col>
                      <Grid.Col span={1}>:</Grid.Col>
                      <Grid.Col span={6}>
                        {data?.operatorReport[0]?.cultivation_details
                          .other_fertilizer
                          ? data?.operatorReport[0]?.cultivation_details
                            .other_fertilizer
                          : "-"}
                      </Grid.Col>
                      <Grid.Col span={5}>Pesticide Type</Grid.Col>
                      <Grid.Col span={1}>:</Grid.Col>
                      <Grid.Col span={6}>
                        {/* {data?.cultivateData?.pesticide_used
                      ? data?.cultivateData?.pesticide_used
                      : "-"} */}
                        {operatorPesticideData?.type
                          ? operatorPesticideData?.type
                          : "-"}
                      </Grid.Col>
                      <Grid.Col span={5}>Pesticide Brand Name</Grid.Col>
                      <Grid.Col span={1}>:</Grid.Col>
                      <Grid.Col span={6}>
                        {/* {data?.cultivateData?.pesticide_used
                      ? data?.cultivateData?.pesticide_used
                      : "-"} */}
                        {operatorPesticideData?.brand_name
                          ? operatorPesticideData?.brand_name
                          : "-"}
                      </Grid.Col>
                      <Grid.Col span={5}>Pesticide Product Name</Grid.Col>
                      <Grid.Col span={1}>:</Grid.Col>
                      <Grid.Col span={6}>
                        {/* {data?.cultivateData?.pesticide_used
                      ? data?.cultivateData?.pesticide_used
                      : "-"} */}
                        {operatorPesticideData?.product_name
                          ? operatorPesticideData?.product_name
                          : "-"}
                      </Grid.Col>
                      <Grid.Col span={5}>Pesticide Chemical Name</Grid.Col>
                      <Grid.Col span={1}>:</Grid.Col>
                      <Grid.Col span={6}>
                        {/* {data?.cultivateData?.pesticide_used
                      ? data?.cultivateData?.pesticide_used
                      : "-"} */}
                        {operatorPesticideData?.chemical_name
                          ? operatorPesticideData?.chemical_name
                          : "-"}
                      </Grid.Col>
                      <Grid.Col span={5}>Fertilizer Quantity</Grid.Col>
                      <Grid.Col span={1}>:</Grid.Col>
                      <Grid.Col span={6}>
                        {data?.operatorReport[0]?.cultivation_details
                          .fertilizer_qty !== ""
                          ? data?.operatorReport[0]?.cultivation_details
                            .fertilizer_qty + " Bottle"
                          : "-"}
                      </Grid.Col>
                      <Grid.Col span={5}>No of Tanks</Grid.Col>
                      <Grid.Col span={1}>:</Grid.Col>
                      <Grid.Col span={6}>
                        {data?.operatorReport[0]?.cultivation_details
                          .total_tanks
                          ? data?.operatorReport[0]?.cultivation_details
                            .total_tanks
                          : 0}
                      </Grid.Col>
                      <Grid.Col span={5}>Plot Area</Grid.Col>
                      <Grid.Col span={1}>:</Grid.Col>
                      <Grid.Col span={6}>
                        {data?.operatorReport[0]?.plot_image_url === "" ||
                          !data?.operatorReport[0]?.plot_image_url ? (
                          "-"
                        ) : (
                          <Button
                            variant="subtle"
                            compact
                            uppercase
                            onClick={() => {
                              setPreviewPlotImage(true);
                            }}
                            style={{
                              backgroundColor: "#3A9343",
                              color: "#fff",
                            }}
                          >
                            Preview
                          </Button>
                        )}
                      </Grid.Col>

                      <Grid.Col span={5}>Map View</Grid.Col>
                      <Grid.Col span={1}>:</Grid.Col>
                      <Grid.Col span={6}>
                        {data?.operatorReport[0]?.location.length > 0 ? (
                          <Button
                            variant="subtle"
                            compact
                            uppercase
                            onClick={() => {
                              setShowMap(true);
                            }}
                            style={{
                              backgroundColor: "#3A9343",
                              color: "#fff",
                            }}
                          >
                            Preview
                          </Button>
                        ) : (
                          "-"
                        )}
                      </Grid.Col>

                      <Grid.Col span={5}>Remarks</Grid.Col>
                      <Grid.Col span={1}>:</Grid.Col>
                      <Grid.Col span={6}>
                        {data?.operatorReport[0]?.["remarks"]
                          ? data?.operatorReport[0]?.["remarks"]
                          : "-"}
                      </Grid.Col>

                      {data?.operatorReport[0]?.next_spray ? (
                        <>
                          <Grid.Col span={5}>Next Spray</Grid.Col>
                          <Grid.Col span={1}>:</Grid.Col>
                          <Grid.Col span={6}>Yes</Grid.Col>

                          <Grid.Col span={5}>Next Spray Date</Grid.Col>
                          <Grid.Col span={1}>:</Grid.Col>
                          <Grid.Col span={6}>
                            {" "}
                            {new Date(
                              data?.operatorReport[0]?.next_spray_date
                            ).getDate() +
                              "-" +
                              (new Date(
                                data?.operatorReport[0]?.next_spray_date
                              ).getMonth() +
                                1) +
                              "-" +
                              new Date(
                                data?.operatorReport[0]?.next_spray_date
                              ).getFullYear()}
                          </Grid.Col>
                        </>
                      ) : (
                        <>
                          <Grid.Col span={5}>Next Spray</Grid.Col>
                          <Grid.Col span={1}>:</Grid.Col>
                          <Grid.Col span={6}>No</Grid.Col>
                        </>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                  {/* {data?.operatorReport[0]?.sos?.reason ? (
                    <>
                      <Grid.Col span={12}>
                        <Text style={{ fontSize: 18, fontWeight: 600 }}>
                          SOS Status
                        </Text>
                      </Grid.Col>
                      <Grid.Col span={5}>Reason</Grid.Col>
                      <Grid.Col span={1}>:</Grid.Col>
                      <Grid.Col span={6}>
                        {data?.operatorReport[0]?.sos?.reason
                          ? data?.operatorReport[0]?.sos?.reason
                          : "-"}
                      </Grid.Col>
                      <Grid.Col span={5}>Pause Date</Grid.Col>
                      <Grid.Col span={1}>:</Grid.Col>
                      <Grid.Col span={6}>
                        {data?.operatorReport[0]?.pause_details?.date ? (
                          <>
                            {" "}
                            {new Date(
                              data?.operatorReport[0]?.pause_details?.date
                            ).getDate() +
                              "-" +
                              (new Date(
                                data?.operatorReport[0]?.pause_details?.date
                              ).getMonth() +
                                1) +
                              "-" +
                              new Date(
                                data?.operatorReport[0]?.pause_details?.date
                              ).getFullYear() +
                              " " +
                              new Date(
                                data?.operatorReport[0]?.pause_details?.date
                              ).getHours() +
                              ":" +
                              new Date(
                                data?.operatorReport[0]?.pause_details?.date
                              ).getMinutes()}
                          </>
                        ) : (
                          "-"
                        )}
                      </Grid.Col>
                      {data?.booking_status !== "pause" &&
                        data?.operatorReport[0]?.resume_details?.date ? (
                        <>
                          <Grid.Col span={5}>Resume Date</Grid.Col>
                          <Grid.Col span={1}>:</Grid.Col>
                          <Grid.Col span={6}>
                            {data?.operatorReport[0]?.resume_details?.date ? (
                              <>
                                {" "}
                                {new Date(
                                  data?.operatorReport[0]?.resume_details?.date
                                ).getDate() +
                                  "-" +
                                  (new Date(
                                    data?.operatorReport[0]?.resume_details?.date
                                  ).getMonth() +
                                    1) +
                                  "-" +
                                  new Date(
                                    data?.operatorReport[0]?.resume_details?.date
                                  ).getFullYear() +
                                  " " +
                                  new Date(
                                    data?.operatorReport[0]?.resume_details?.date
                                  ).getHours() +
                                  ":" +
                                  new Date(
                                    data?.operatorReport[0]?.resume_details?.date
                                  ).getMinutes()}
                              </>
                            ) : (
                              "-"
                            )}
                          </Grid.Col>
                        </>
                      ) : (
                        <></>
                      )}
                      <Grid.Col span={5}>Image</Grid.Col>
                      <Grid.Col span={1}>:</Grid.Col>
                      <Grid.Col span={6}>
                        {data?.operatorReport[0]?.sos?.image_url ? (
                          <Button
                            variant="subtle"
                            compact
                            uppercase
                            onClick={() => {
                              setPreviewImage(true);
                            }}
                            style={{
                              backgroundColor: "#3A9343",
                              color: "#fff",
                            }}
                          >
                            Preview
                          </Button>
                        ) : (
                          <>-</>
                        )}
                      </Grid.Col>
                    </>
                  ) : (
                    <></>
                  )} */}
                </Grid>
              </Tabs.Panel>
              <Tabs.Panel value="sos_history" pt="xs">
                <Grid>
                  {data?.operatorReport[0]?.sos?.length > 0 ? (
                    <>
                      {data?.operatorReport[0]?.sos?.map(
                        (sosData: any, index: any) => {
                          let pause_details =
                            data?.operatorReport[0]?.pause_details.find(
                              (data: any) => data.id === sosData.id
                            );
                          let resume_details =
                            data?.operatorReport[0]?.resume_details.find(
                              (data: any) => data.id === sosData.id
                            );
                          return (
                            <>
                              <Grid.Col span={12}>
                                <Text style={{ fontSize: 20, fontWeight: 600 }}>
                                  SOS ID : {sosData.id}{" "}
                                </Text>
                              </Grid.Col>
                              <Grid.Col span={5}>Reason</Grid.Col>
                              <Grid.Col span={1}>:</Grid.Col>
                              <Grid.Col span={6}>
                                {sosData?.reason ? sosData?.reason : "-"}
                              </Grid.Col>
                              <Grid.Col span={5}>Pause Date</Grid.Col>
                              <Grid.Col span={1}>:</Grid.Col>
                              <Grid.Col span={6}>
                                {pause_details ? (
                                  <>
                                    {" "}
                                    {new Date(pause_details?.date).getDate() +
                                      "-" +
                                      (new Date(
                                        pause_details?.date
                                      ).getMonth() +
                                        1) +
                                      "-" +
                                      new Date(
                                        pause_details?.date
                                      ).getFullYear() +
                                      " " +
                                      new Date(pause_details?.date).getHours() +
                                      ":" +
                                      new Date(
                                        pause_details?.date
                                      ).getMinutes()}
                                  </>
                                ) : (
                                  "-"
                                )}
                              </Grid.Col>
                              {resume_details ? (
                                <>
                                  <Grid.Col span={5}>Resume Date</Grid.Col>
                                  <Grid.Col span={1}>:</Grid.Col>
                                  <Grid.Col span={6}>
                                    {resume_details?.date ? (
                                      <>
                                        {" "}
                                        {new Date(
                                          resume_details?.date
                                        ).getDate() +
                                          "-" +
                                          (new Date(
                                            resume_details?.date
                                          ).getMonth() +
                                            1) +
                                          "-" +
                                          new Date(
                                            resume_details?.date
                                          ).getFullYear() +
                                          " " +
                                          new Date(
                                            resume_details?.date
                                          ).getHours() +
                                          ":" +
                                          new Date(
                                            resume_details?.date
                                          ).getMinutes()}
                                      </>
                                    ) : (
                                      "-"
                                    )}
                                  </Grid.Col>
                                </>
                              ) : (
                                <></>
                              )}
                              <Grid.Col span={5}>Image</Grid.Col>
                              <Grid.Col span={1}>:</Grid.Col>
                              <Grid.Col span={6}>
                                {sosData.image_url ? (
                                  <Button
                                    variant="subtle"
                                    compact
                                    uppercase
                                    onClick={() => {
                                      setPreviewImage(true);
                                      setPreviewImageUrl(sosData["image_url"]);
                                    }}
                                    style={{
                                      backgroundColor: "#3A9343",
                                      color: "#fff",
                                    }}
                                  >
                                    Preview
                                  </Button>
                                ) : (
                                  <>-</>
                                )}
                              </Grid.Col>
                            </>
                          );
                        }
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Tabs.Panel>
              <Tabs.Panel value="interruption_history" pt="xs">
                <Grid>
                  {data?.operatorReport[0]?.interruption?.length > 0 ? (
                    <>
                      {data?.operatorReport[0]?.interruption?.map(
                        (interruptionData: any, index: any) => {
                          let pause_details =
                            data?.operatorReport[0]?.pause_details.find(
                              (data: any) => data.id === interruptionData.id
                            );
                          let resume_details =
                            data?.operatorReport[0]?.resume_details.find(
                              (data: any) => data.id === interruptionData.id
                            );
                          return (
                            <>
                              <Grid.Col span={12}>
                                <Text style={{ fontSize: 18, fontWeight: 600 }}>
                                  Interruption ID : {interruptionData.id}{" "}
                                </Text>
                              </Grid.Col>
                              <Grid.Col span={5}>Reason</Grid.Col>
                              <Grid.Col span={1}>:</Grid.Col>
                              <Grid.Col span={6}>
                                {interruptionData?.reason
                                  ? interruptionData?.reason
                                  : "-"}
                              </Grid.Col>
                              <Grid.Col span={5}>Description</Grid.Col>
                              <Grid.Col span={1}>:</Grid.Col>
                              <Grid.Col span={6}>
                                {interruptionData?.description
                                  ? interruptionData?.description
                                  : "-"}
                              </Grid.Col>
                              <Grid.Col span={5}>Pause Date</Grid.Col>
                              <Grid.Col span={1}>:</Grid.Col>
                              <Grid.Col span={6}>
                                {pause_details ? (
                                  <>
                                    {" "}
                                    {new Date(pause_details?.date).getDate() +
                                      "-" +
                                      (new Date(
                                        pause_details?.date
                                      ).getMonth() +
                                        1) +
                                      "-" +
                                      new Date(
                                        pause_details?.date
                                      ).getFullYear() +
                                      " " +
                                      new Date(pause_details?.date).getHours() +
                                      ":" +
                                      new Date(
                                        pause_details?.date
                                      ).getMinutes()}
                                  </>
                                ) : (
                                  "-"
                                )}
                              </Grid.Col>
                              {resume_details ? (
                                <>
                                  <Grid.Col span={5}>Resume Date</Grid.Col>
                                  <Grid.Col span={1}>:</Grid.Col>
                                  <Grid.Col span={6}>
                                    {resume_details?.date ? (
                                      <>
                                        {" "}
                                        {new Date(
                                          resume_details?.date
                                        ).getDate() +
                                          "-" +
                                          (new Date(
                                            resume_details?.date
                                          ).getMonth() +
                                            1) +
                                          "-" +
                                          new Date(
                                            resume_details?.date
                                          ).getFullYear() +
                                          " " +
                                          new Date(
                                            resume_details?.date
                                          ).getHours() +
                                          ":" +
                                          new Date(
                                            resume_details?.date
                                          ).getMinutes()}
                                      </>
                                    ) : (
                                      "-"
                                    )}
                                  </Grid.Col>
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          );
                        }
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Tabs.Panel>
            </Tabs>
            <Grid.Col span={12}>
              <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                <Box style={{ display: "flex", justifyContent: "flex-start" }}>
                  {data?.booking_status === "pending" &&
                    PermissionCheck(
                      permissionList,
                      "Order_Management",
                      "write"
                    ) ? (
                    <Button
                      style={{ marginTop: 20, background: "#FA5252" }}
                      onClick={() => {
                        setShowRejectModal(true);
                        setId(data?._id);
                      }}
                    >
                      Reject
                    </Button>
                  ) : (
                    <></>
                  )}
                  {data?.booking_status === "pending" &&
                    PermissionCheck(
                      permissionList,
                      "Order_Management",
                      "write"
                    ) ? (
                    <Button
                      disabled={
                        selectedOperator == "" ||
                        (data.open_slot && (!selectDate || !selectTime))
                      }
                      style={{
                        marginLeft: 20,
                        marginTop: 20,
                        background: "#2C752F",
                      }}
                      onClick={() => {
                        setShowApproveModal(true);
                        setId(data?._id);
                      }}
                    >
                      Assign
                    </Button>
                  ) : (
                    <></>
                  )}

                  {data?.booking_status === "request_cancel" &&
                    PermissionCheck(
                      permissionList,
                      "Order_Management",
                      "write"
                    ) ? (
                    <Button
                      style={{
                        marginLeft: 20,
                        marginTop: 20,
                        background: "red",
                      }}
                      onClick={() => {
                        setShowCancelModal(true);
                        setId(data?._id);
                      }}
                    >
                      Cancel
                    </Button>
                  ) : (
                    <></>
                  )}
                </Box>
              </Box>
            </Grid.Col>
          </Grid>
        </Paper>
      </Grid.Col>
      {showApproveModal ? (
        <ApproveModal
          id={id}
          show={showApproveModal}
          type="Order"
          payload={{
            operator_id: allOperator.find((data) => {
              return data["name"] === selectedOperator;
            })?.["_id"],
            date: data.open_slot ? selectDate : data.date,
            time: data.open_slot ? selectTime : data?.time,
          }}
          statusType="assign"
          handleClose={handleApprove}
        />
      ) : null}
      {showCancelModal ? (
        <ApproveModal
          id={id}
          show={showCancelModal}
          type="Order"
          payload={{}}
          statusType="cancel"
          handleClose={handleCancel}
        />
      ) : null}
      {showRejectModal ? (
        <ApproveModal
          id={id}
          show={showRejectModal}
          type="Order"
          payload={{}}
          statusType="reject"
          handleClose={handleReject}
        />
      ) : null}
      {showCompleteModal ? (
        <ApproveModal
          id={id}
          show={showCompleteModal}
          type="Order"
          payload={{ operator_id: selectedOperator }}
          statusType="complete"
          handleClose={handleComplete}
        />
      ) : null}
      {previewImage ? (
        <ImageModal
          id={id}
          show={previewImage}
          url={previewImageUrl}
          handleClose={handlePreview}
        />
      ) : null}
      {previewPlotImage ? (
        <ImageModal
          id={id}
          show={previewPlotImage}
          url={data?.operatorReport[0]?.plot_image_url}
          handleClose={handlePlotPreview}
        />
      ) : null}
      {/* {showMap ? (
        <MapModal
          show={showMap}
          handleClose={handleMap}
          location={data?.operatorReport[0]?.location}
        />
      ) : null} */}
    </Grid>
  );
}
