import { io } from "socket.io-client";
import { API_SOCKET_URL } from "../../Config";
//import { socket } from "../../Wrappers/AdminWrapper";
import { MapModal } from "./MapModel";
import { useEffect, useState } from "react";

export default function LiveTracking(props: any) {
  const [droneData, setDroneData] = useState(props.droneData);


  useEffect(()=>{
    setDroneData(props.droneData);
  },[props.droneData]);

  return (
    <div>
      <h2 style={{ color: "green" }}>Live Tracking</h2>

      <div style={{ marginTop: 20 }}>
        {" "}
        <MapModal dronData={droneData} />
      </div>
    </div>
  );
}
