import { Routes, Route } from "react-router-dom";
import { AppShell } from "@mantine/core";
import Sidebar from "../Components/Sidebar";
import TopBar from "../Components/TopBar";
import Dashboard from "../Views/Dashboard1";
// import Dashboard from "../Views/Dashboard";
import UserManagement from "../Views/UserManagement";
import OperatorManagement from "../Views/OperatorManagement";
import BookingManagement from "../Views/BookingManagement/BookingManagement";
import KycDetails from "../Views/KycDetails/KycDetails";
import KycVerification from "../Views/KycDetails/KycVerification";
import UserVerify from "../Views/UserManagement/UserVerify";
import OrderVerify from "../Views/BookingManagement/OrderVerify";
import { useMediaQuery } from "@mantine/hooks";
import ViewOperator from "../Views/OperatorManagement/ViewOperator";
import CropType from "../Views/Configuration/CropType";
import Fertilizer from "../Views/Configuration/Fertilizer";
import Pesticide from "../Views/Configuration/Pesticide";
import LiveTracking from "../Views/LiveTracking";
import DroneManagement from "../Views/DroneManagement";
import Configuration from "../Views/Configuration";
import Report from "../Views/Report";
import Management from "../Views/Management";
import { PrivateRoute } from "./PrivateRouter";
import { io } from "socket.io-client";
import { API_SOCKET_URL } from "../Config";
import { useEffect, useState } from "react";
import DealersManagement from "../Views/Dealers";
import DealerView from "../Views/Dealers/dealerView";




function AdminWrapper(props: any) {
  const mobile = useMediaQuery("(max-width: 780px)");

  return (
    <AppShell
      header={<TopBar />}
      navbar={mobile ? <></> : <Sidebar />}
      style={{ backgroundColor: "#f1f2f7" }}
    >
      <Routes>
        <Route path="dashboard" element={<PrivateRoute children={<Dashboard />} component="Dashboard" />} />
        <Route path="farmer-management" element={<PrivateRoute children={<UserManagement />} component="Farmer_Management" />} />
        <Route path="operator-management" element={<PrivateRoute children={<OperatorManagement />} component="Operator_Management" />} />
        <Route path="booking-management" element={<PrivateRoute children={<BookingManagement />} component="Order_Management" />} />
        <Route path="kyc-details" element={<PrivateRoute children={<KycDetails />} component="KYC_Details" />} />
        <Route
          path="kyc-details/kyc-verification"
          element={<PrivateRoute children={<KycVerification />} component="KYC_Details" />}
        />
        <Route path="farmer-management/user-verify" element={<PrivateRoute children={<UserVerify />} component="Farmer_Management" />} />
        <Route
          path="operator-management/operator-detail"
          element={<PrivateRoute children={<ViewOperator />} component="Operator_Management" />}
        />
        <Route
          path="booking-management/order-verify"
          element={<PrivateRoute children={<OrderVerify />} component="Operator_Management" />}
        />
        <Route path="Configuration" element={<PrivateRoute children={<Configuration />} component="Configuration" />} />
        <Route path="configuration/croptype" element={<PrivateRoute children={<CropType />} component="Configuration" />} />
        <Route path="configuration/fertilizer" element={<PrivateRoute children={<Fertilizer />} component="Configuration" />} />
        <Route path="live-track" element={<PrivateRoute children={<LiveTracking droneData={props.droneData} />} component="Configuration" />} />
        <Route path="configuration/pesticide" element={<PrivateRoute children={<Pesticide />} component="Operator_Management" />} />
        <Route path="drone-management" element={<PrivateRoute children={<DroneManagement />} component="Drone_Management" />} />
        <Route path="user-management" element={<PrivateRoute children={<Management />} component="User_Management" />} />
        <Route path="report-management" element={<PrivateRoute children={<Report />} component="Report_Management" />} />
        <Route path="dealer-management" element={<PrivateRoute children={<DealersManagement />} component="Dealer_Management" />} />
        <Route path="dealer-management/dealer" element={<PrivateRoute children={<DealerView />} component="Dealer_Management" />} />
      </Routes>
    </AppShell>
  );
}

export default AdminWrapper;
