import { Box, Pagination } from "@mantine/core";

export default function CustomPagination(props: any) {
  const tableValuesLength = [10, 20, 50, 100];
  const paginationTabCount = Math.ceil(props.count / props.pagination["limit"]);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <label>Size</label>
        <select
          value={props.pagination["limit"]}
          style={{
            borderRadius: "5px",
            padding: "5px",
            width: "70px",
            fontSize: 14,
            textAlign: "center",
            border: "1px solid grey",
            margin: "10px",
          }}
          onChange={(e) => {
            props.setPage(1);
            let temp = { ...props.pagination };
            temp["skip"] = 0;
            temp["limit"] = parseInt(e.target.value);
            props.setPagination(temp);
          }}
        >
          {tableValuesLength.map((element, index) => {
            return (
              <option key={index} value={element}>
                {element}
              </option>
            );
          })}
        </select>
      </Box>
      <Pagination
        onChange={(val) => {
          props.setPage(val);
          let temp = { ...props.pagination };
          temp["skip"] = temp["limit"] * (val - 1);
          props.setPagination(temp);
        }}
        value={props.page}
        total={paginationTabCount}
        color="green"
        size="sm"
        radius="md"
      />
    </div>
  );
}
