import apiInstance from "../../../Services";
import { create, deleteData, update } from "./Interface";

const ConfigurationCropStageAPI = {
  // eslint-disable-next-line class-methods-use-this
  createCropStage: async (payload: create) => {
    try {
      const res = await apiInstance.post<any>(`/cropStage/create`, payload);
      return res;
    } catch (error) {
      throw error;
    }
  },

  updateCropStage: async (payload: update) => {
    try {
      const res = await apiInstance.put<any>(
        `/cropStage/update?id=${payload.id}`,
        payload
      );
      return res;
    } catch (error) {
      throw error;
    }
  },

  getCropStageList: async (payload: any) => {
    try {
      const res = await apiInstance.get<any>(
        `/cropStage/getall?skip=${payload.skip}&limit=${payload.limit}&filter=${payload.filter}&status=${payload.status}`
      );
      return res;
    } catch (error) {
      throw error;
    }
  },
  
  getAllCropStageList: async () => {
    try {
      const res = await apiInstance.get<any>(
        `/cropStage/getallList`
      );
      return res;
    } catch (error) {
      throw error;
    }
  },

  deleteCropStage: async (payload: deleteData) => {
    try {
      const res = await apiInstance.put<deleteData>(
        `/cropStage/delete?id=${payload.id}&status=${payload.status}`
      );
      return res;
    } catch (error) {
      throw error;
    }
  },

  getCropStageById: async (payload: any) => {
    try {
      const res = await apiInstance.get<any>(
        `/cropStage/cropStageBasedonId?id=${payload.id}`
      );
      return res;
    } catch (error) {
      throw error;
    }
  },
};

export default ConfigurationCropStageAPI;
