import { utils, write } from "xlsx";

export default function NestedHeaderToCSV(workers: any) {
  const data = workers.map((worker:any) => Object.values(worker));

  const ws = utils.json_to_sheet(data, { skipHeader: true });

  // Fill out the headers manually
  ws.A1 = { t: "s", v: "Booking Date" };
  ws.B1 = { t: "s", v: "Farmer Name" };
  ws.C1 = { t: "s", v: "Mobile Number" };
  ws.D1 = { t: "s", v: "Address" };
  ws.E1 = { t: "s", v: "Date of spraying" };
  ws.F1 = { t: "s", v: "Crop" };
  ws.G1 = { t: "s", v: "Variety" };
  ws.H1 = { t: "s", v: "Crop stage" };
  ws.I1 = { t: "s", v: "Total area sprayed (Acres)" };
  ws.J1 = { t: "s", v: "Fertilizer" };
  ws.M1 = { t: "s", v: "Pesticide" };
  ws.Q1= { t: "s", v: "Require next spray" };
  ws.R1= { t: "s", v: "Tentative date of next spray" };

  ws.J2 = { t: "s", v: "IFFCO nano fertilizer" };
  ws.K2 = { t: "s", v: "Other fertilizer" };

  ws.K3 = { t: "s", v: "Product Name" };
  ws.L3 = { t: "s", v: "Brand Name" };

  ws.M3 = { t: "s", v: "Type" };
  ws.N3 = { t: "s", v: "Brand name" };
  ws.O3 = { t: "s", v: "Commercial name" };
  ws.P3 = { t: "s", v: "Chemical name" };

  // Merge the cells
  const merges = [
    { s: { r: 0, c: 0 }, e: { r: 2, c: 0 } }, 
    { s: { r: 0, c: 1 }, e: { r: 2, c: 1 } }, // Booking Date
    { s: { r: 0, c: 2 }, e: { r: 2, c: 2 } }, // Mobile Number
    { s: { r: 0, c: 3 }, e: { r: 2, c: 3 } }, // Address
    { s: { r: 0, c: 4 }, e: { r: 2, c: 4 } }, // Date of spraying
    { s: { r: 0, c: 5 }, e: { r: 2, c: 5 } }, // Crop
    { s: { r: 0, c: 6 }, e: { r: 2, c: 6 } }, // Variety
    { s: { r: 0, c: 7 }, e: { r: 2, c: 7 } }, // Crop stage
    { s: { r: 0, c: 8 }, e: { r: 2, c: 8 } }, // Total area sprayed (Acres)
    { s: { r: 0, c: 9 }, e: { r: 0, c: 11 } }, // Fertilizer
    { s: { r: 1, c: 9 }, e: { r: 2, c: 9 } }, // Fertilizer product
    { s: { r: 1, c: 10 }, e: { r: 1, c: 11 } }, //Other fertilizer
    { s: { r: 0, c: 12 }, e: { r: 1, c: 15 } }, // Pesticide
    { s: { r: 0, c: 16 }, e: { r: 2, c: 16 } }, // Require next spray
    { s: { r: 0, c: 17 }, e: { r: 2, c: 17 } }, // Tentative date of next spray
  ];
  ws["!merges"] = merges;

  const headingWidths: number[] = [
    "Booking Date",
    "Farmer Name",
    "Mobile Number",
    "Address",
    "Date of spraying",
    "Crop",
    "Variety",
    "Crop stage",
    "Total area sprayed (Acres)",
    "IFFCO nano fertilizer",
    "Product Name",
    "Brand Name",
    "Type",
    "Brand name",
    "Commercial name",
    "Chemical name",
    "Require next spray",
    "Tentative date of next spray",
  ].map((heading) => heading.length + 11);

  ws["!cols"] = headingWidths.map((width) => ({ width }));

  const rowHeight = 20;
  const rowCount = data.length;
  const rowHeights = Array(rowCount).fill({ hpx: rowHeight });
  ws["!rows"] = rowHeights;

  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, ws, "Sheet1");

  const wbout = write(workbook, { type: "array", bookType: "xlsx" });
  const blob = new Blob([wbout], { type: "application/octet-stream" });

  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute(
    "download",
    `Report - (${new Date()?.toLocaleDateString("en-GB")}).xlsx`
  );
  link.click();
}
