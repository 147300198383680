import {
  IconBrandBooking,
  IconBrandOpenvpn,
  IconDental,
  IconDrone,
  IconFingerprint,
  IconHome,
  IconLiveView,
  IconReport,
  IconSettings,
  IconShieldLock,
  IconUsers,
  IconUsersPlus,
} from "@tabler/icons-react";

const Tabdata = [
  {
    link: "/admin/dashboard",
    label: "Dashboard",
    icon: IconHome,
    children: [],
    componentName: "Dashboard",
  },
  {
    link: "/admin/live-track",
    label: "Live Track",
    icon: IconLiveView,
    children: [],
    componentName: "Live_Track",
  },
  {
    link: "/admin/farmer-management",
    label: "Farmer Management",
    icon: IconUsers,
    children: [],
    componentName: "Farmer_Management",
  },
  {
    link: "/admin/operator-management",
    label: "Operator Management",
    icon: IconBrandOpenvpn,
    children: [],
    componentName: "Operator_Management",
  },
  {
    link: "/admin/drone-management",
    label: "Drone Management",
    icon: IconDrone,
    children: [],
    componentName: "Drone_Management",
  },
  {
    link: "/admin/booking-management",
    label: "Order Management",
    icon: IconBrandBooking,
    children: [],
    componentName: "Order_Management",
  },
  {
    link: "/admin/kyc-details",
    label: "KYC Details",
    icon: IconFingerprint,
    children: [],
    componentName: "KYC_Details",
  },
  {
    link: "/admin/configuration",
    label: "Configuration",
    icon: IconSettings,
    componentName: "Configuration",
    children: [],
  },
  {
    link: "/admin/user-management",
    label: "User Management",
    icon: IconShieldLock,
    children: [],
    componentName: "User_Management",
  },
  {
    link: "/admin/report-management",
    label: "Report Management",
    icon: IconReport,
    children: [],
    componentName: "Report_Management",
  },
  {
    link: "/admin/dealer-management",
    label: "Dealer Management",
    icon: IconUsersPlus,
    children: [],
    componentName: "Dealer_Management",
  },
];

export default Tabdata;
