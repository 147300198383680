import { DeleteUser, GetUser, UpdateUser } from "./Interface";
import * as actions from "./actionTypes";

export function getUserListDetailsAction(
  payload: GetUser
): actions.getuserlistDetails {
  return {
    type: actions.GET_USER_LIST_DETAILS,
    data: payload,
  };
}

export function updateUserDetailsAction(
  payload: UpdateUser
): actions.updateuserDetails {
  return {
    type: actions.UPDATE_USER_DETAILS,
    data: payload,
  };
}

export function deleteUserDetailsAction(
  payload: DeleteUser
): actions.deleteuserDetails {
  return {
    type: actions.DELETE_USER_DETAILS,
    data: payload,
  };
}
