import React, { useState } from "react";
import { Menu, Button, Text, Avatar } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import Logout from "../../Components/Logout";
import { IconLogout, IconSettings, IconUserCircle } from "@tabler/icons-react";
import { useAppSelector } from "../../Services/hooks/hooks";

export default function Settings({ children }: any) {
  const [opened, setOpened] = useState(false);
  const [showLogout, setShowLogout] = useState(false);
  const { data, loading, error, notify, notifymsg } = useAppSelector(
    (state) => state.auth
  );

  const handleLogout = () => {
    setShowLogout(false);
  };

  return (
    <>
      <Menu
        trigger="click"
        openDelay={100}
        closeDelay={400}
        opened={opened}
        onChange={setOpened}
        shadow="md"
        width={250}
      >
        <Menu.Target>{children}</Menu.Target>

        <Menu.Dropdown>
          <Menu.Item
            icon={<Avatar radius="xl" color="green" />}
            // onClick={() => {
            //   navigate("myProfile");
            // }}
          >
            <Text>{data?.data?.name}</Text>
            <Text>{data?.data?.email}</Text>
          </Menu.Item>
          <Menu.Divider mx={10} />
          {/* <Menu.Item
            icon={<IconSettings size={20} />}
            onClick={() => {
              setShowLogout(true);
            }}
          >
            Settings
          </Menu.Item> */}
          <Menu.Item
            icon={<IconLogout size={20} />}
            onClick={() => {
              setShowLogout(true);
            }}
          >
            Logout
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      <Logout show={showLogout} handleClose={handleLogout} />
    </>
  );
}
