import apiInstance from "../../Services";
import { updateKycData } from "./Interface";
import { getKycData } from "./Interface";
const KycAPI = {
  updateKyc: async (payload: updateKycData) => {
    try {
      const res = await apiInstance.put<updateKycData>(
        `/kyc/update?id=${payload._id}`,
        payload
      );

      return res;
    } catch (error) {
      throw error;
    }
  },
  getKyc: async (payload: any) => {
    try {
      const res = await apiInstance.get<any>(
        `/user/getbykyc?skip=${payload.skip}&limit=${payload.limit}&status=${payload.status}&filter=${payload.filter}&state=${payload.state}&district=${payload.district}&block=${payload.block}`
      );
      return res;
    } catch (error) {
      throw error;
    }
  },
};

export default KycAPI;
