import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./Views/Login/Login";
import AdminWrapper from "./Wrappers/AdminWrapper";
import {io } from "socket.io-client";
import { API_SOCKET_URL } from "./Config";
import { useEffect, useState } from "react";
import { DefaultEventsMap } from "@socket.io/component-emitter";

function App() {

  // const socket = io(API_SOCKET_URL, {});

  const [droneData, setDroneData] = useState([]);




  // useEffect(() => {

  //   socket.on("connect", () => {
  //     console.log("--------->>> connected 111");

  //     socket.on("drone_cords_view", (data) => {
  //       console.log("-------------->> connected 2");
  //       console.log("----------------->>>>>>>>>>>>>>>>>>>>>  main : --------------------------->>>>>", socket.id);
  //       localStorage.setItem('socketId', socket.id);
  //       console.log("---------->>> drone list", data);
  //       setDroneData(data);
  //     });

  //   });

  //  // localStorage.setItem('socketId', socket);



  //   //return () => {socket.disconnect()};




  //   // return () => {
  //   //   socket.off("connect");
  //   // };

  // }, []);



  // useEffect(() => {
  //   console.log("----------------->> loading");
  //   // const handleBeforeUnload = (event: any) => {
  //   //   // Perform any necessary cleanup or execute your function here
  //   //   // This function will be executed when the page is reloaded or unloaded

  //   //   // You can also customize the confirmation message shown to the user
  //   //   event.preventDefault();
  //   // //  event.returnValue = 'Are you sure you want to leave this page?';
  //   // };
  //   // Event listener for page load event
  //   // window.addEventListener('load', () => {
  //   //   // Retrieving socket connection information from localStorage
  //   //   const storedSocketId = localStorage.getItem('socketId');
  //   // console.log("----------->>> on loading : ", storedSocketId);
  //   //   // Restoring socket connection using the stored socket ID
  //   //   const restoredSocket = io({ query: { socketId: storedSocketId } });
  //   // console.log("------->>> restoredSocket : ", restoredSocket);
  //   //   // Handling the restored socket connection
  //   //   // ...
  //   // });


  //   const storedSocketId = localStorage.getItem('socketId');
  //   let socket1: Socket<DefaultEventsMap, DefaultEventsMap>;
  //   if (storedSocketId) {
  //     console.log("----------->>> on loading : ", storedSocketId);
  //     // Restoring socket connection using the stored socket ID
  //     const restoredSocket = io({ query: { socketId: storedSocketId } });
  //     console.log("------->>> restoredSocket : ", restoredSocket);

  // const socketOptions = {
  //   transports: ['websocket'], // Use only websocket transport
  // };



  // socket1 = io(API_SOCKET_URL, {
  //   ...socketOptions,
  //   query: { storedSocketId },
  // });
  //   } else {
  //     socket1 = io(API_SOCKET_URL);
  //   }

  //   socket1.on("connect", () => {
  //     console.log("--------->>> connected 2222222222");

  //     socket1.on("drone_cords_view", (data) => {
  //       console.log("-------------->> connected 456789");
  //       console.log("----------------->>>>>>>>>>>>>>>>>>>>>  main2 : --------------------------->>>>>", socket1.id);
  //       localStorage.setItem('socketId', socket1.id);
  //       console.log("---------->>> drone list", data);
  //       setDroneData(data);
  //     });

  //   });


  //   socket1.on("disconnect", () => {
  //     console.log("---------->> dis");
  //   });

  //   socket1.close();



  //   // localStorage.setItem('socketId', socket);





  //   // // window.addEventListener('beforeunload', handleBeforeUnload);
  //   // window.addEventListener('load', handleLoad);

  //   // return () => {
  //   //   // window.removeEventListener('beforeunload', handleBeforeUnload);
  //   //   window.removeEventListener('load', handleLoad);
  //   // };


  // }, []);

  useEffect(() => {
    const socket_id = localStorage.getItem("socket_id");
    if (false) {
      // console.log("Old socket id ==> ", socket_id)
      // const socketOptions = {
      //   transports: ['websocket'],
      // };
      // try {
      //   const restoredSocket = io(API_SOCKET_URL, {
      //     ...socketOptions,
      //     query: { socket_id },
      //   });


      //   restoredSocket.on('oldDataEvent',(data) =>{
      //     console.log("---------------->>>>>> old data", data);
      //   })

      //   restoredSocket.on("connect", () => { 
          
      //     console.log("Restored socket connected ... ", restoredSocket);

         

      //     // restoredSocket.on("drone_cords_view", (data) => {  
      //     //         console.log("---------->>> drone list restored", data);
      //     //         setDroneData(data);
      //     //       });
      //    })
      // } catch (e) {
      //   localStorage.removeItem("socket_id");
      //   console.log("Error while connecting to the Old socket...");

      // }
    } else {
      const socket = io(API_SOCKET_URL);

      socket.on("connect", () => {

        socket.emit("web", true)  
       // localStorage.setItem("socket_id", socket.id); 
        
       // console.log("New socket connection ...", socket);

        socket.on("drone_cords_view", (data) => {  
          setDroneData(data);
        });
      })
    }
  }, [])

  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/admin/*" element={<AdminWrapper droneData={droneData} />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
