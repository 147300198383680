import { Modal, Title } from "@mantine/core";
import { Grid } from "@mantine/core";

type Props = {
  show: boolean;
  type: string;
  data?: any;
  handleClose: () => void;
};

const ConfigViewModal: React.FC<Props> = ({
  show,
  type,
  data,
  handleClose,
}) => {
  return (
    <Modal
      centered
      radius="lg"
      padding="xl"
      opened={show}
      onClose={handleClose}
      withCloseButton={true}
      title={
        <Title style={{ fontSize: 25, fontWeight: 300, color: "#4C9C4F" }}>
          {type} Details
        </Title>
      }
    >
      <Grid>
        {type === "Pesticide" ? (
          <>
            <Grid.Col span={5}> Type</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}>{data[0]?.type}</Grid.Col>
            <Grid.Col span={5}>Brand Name</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}>{data[0]?.brand_name}</Grid.Col>
            <Grid.Col span={5}>Product Name</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}>{data[0]?.product_name}</Grid.Col>
            <Grid.Col span={5}>Chemical Name</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}>{data[0]?.chemical_name}</Grid.Col>
            <Grid.Col span={5}>Description</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}>{data[0]?.description}</Grid.Col>
          </>
        ) : 
        type === "Crop Stage"? (
          <>
            <Grid.Col span={5}>Name</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}>{data[0]?.name}</Grid.Col>
            <Grid.Col span={5}>Duration</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}>{data[0]?.duration}</Grid.Col>
            <Grid.Col span={5}>Description</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}>{data[0]?.description}</Grid.Col>
          </>
        ):
        type === "Variety"?
        (
          <>
           <Grid.Col span={5}>Crop Type</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}>{data[0]?.crop}</Grid.Col>
            <Grid.Col span={5}>Name</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}>{data[0]?.name}</Grid.Col>
            <Grid.Col span={5}>Description</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}>{data[0]?.description}</Grid.Col>
          </>
        ):
         (
          <>
            <Grid.Col span={5}>Name</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}>{data[0]?.name}</Grid.Col>
            <Grid.Col span={5}>Description</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}>{data[0]?.description}</Grid.Col>
          </>
        )}
      </Grid>
    </Modal>
  );
};

export default ConfigViewModal;
