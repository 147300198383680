import apiInstance from "../../../Services";
import { create, deleteData, update } from "./Interface";

const ConfigurationPesticideAPI = {
  // eslint-disable-next-line class-methods-use-this
  createPesticide: async (payload: create) => {
    try {
      const res = await apiInstance.post<any>(`/pesticide/create?`, payload);
      return res;
    } catch (error) {
      throw error;
    }
  },

  getPesticideList: async (payload: any) => {
    try {
      const res = await apiInstance.get<any>(
        `/pesticide/getall?skip=${payload.skip}&limit=${payload.limit}&filter=${payload.filter}`
      );
      return res;
    } catch (error) {
      throw error;
    }
  },
  getAllPesticideList: async () => {
    try {
      const res = await apiInstance.get<any>(`/pesticide/getallList`);
      return res;
    } catch (error) {
      throw error;
    }
  },

  getPesticideById: async (payload: any) => {
    try {
      const res = await apiInstance.get<any>(`/pesticide/get?id=${payload.id}`);
      return res;
    } catch (error) {
      throw error;
    }
  },

  updatePesticide: async (payload: update) => {
    try {
      const res = await apiInstance.put<any>(
        `/pesticide/update?id=${payload.id}`,
        payload
      );
      return res;
    } catch (error) {
      throw error;
    }
  },

  deletePesticide: async (payload: deleteData) => {
    try {
      const res = await apiInstance.put<deleteData>(
        `/pesticide/delete?id=${payload.id}&status=${payload.status}`
      );
      return res;
    } catch (error) {
      throw error;
    }
  },
};

export default ConfigurationPesticideAPI;
