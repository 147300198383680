import { Button, Modal, Group, Text, Box, Title } from "@mantine/core";
import { Grid } from "@mantine/core";
type Props = {
  id: string;
  show: boolean;
  type: string;
  data?: any;
  handleClose: () => void;
};
const ViewModal: React.FC<Props> = ({ id, show, type, data, handleClose }) => {
  return (
    <Modal
      centered
      radius="lg"
      padding="xl"
      opened={show}
      onClose={handleClose}
      withCloseButton={false}
      title={
        <Title style={{ fontSize: 25, fontWeight: 300, color: "#4C9C4F" }}>
          {type} Details
        </Title>
      }
    >
      <Grid style={{ width: "100%" }}>
        {type === "Operator" ? (
          <>
            <Grid.Col span={5}>OperatorName</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}>
              {data[0]?.first_name} {data[0]?.last_name}
            </Grid.Col>
            <Grid.Col span={5}>Mobile</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}> {data[0]?.phone_no}</Grid.Col>
            <Grid.Col span={5}>Email</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}> {data[0]?.email}</Grid.Col>
            <Grid.Col span={5}>License No</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}> {data[0]?.license_no}</Grid.Col>
            <Grid.Col span={5}>Address</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}>
              {data[0]?.address?.street1} {data[0]?.address?.street2}{" "}
              {data[0]?.address?.panchayat} {data[0]?.address?.taluk}{" "}
              {data[0]?.address?.city} {data[0]?.address?.district}{" "}
              {data[0]?.address?.state} {data[0]?.address?.pincode}
            </Grid.Col>
            <Grid.Col span={5}>KYC Name</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}> {data[0]?.kyc_type}</Grid.Col>
            <Grid.Col span={5}>KYC Number</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}> {data[0]?.kyc_no}</Grid.Col>
          </>
        ) : type === "Drone" ? (
          <>
            <Grid.Col span={5}>Drone ID</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}>{data[0]?.drone_id}</Grid.Col>
            <Grid.Col span={5}>Modal</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}> {data[0]?.modal}</Grid.Col>
            <Grid.Col span={5}>MFG No</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}> {data[0]?.mfg_no}</Grid.Col>
            <Grid.Col span={5}>MFG By</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}> {data[0]?.mfg_by}</Grid.Col>
            <Grid.Col span={5}>Assigned Operator</Grid.Col>
            <Grid.Col span={1}>:</Grid.Col>
            <Grid.Col span={6}>{data[0]?.operator?.name}</Grid.Col>
          </>
        ) : (
          <></>
        )}

        <Grid.Col
          span={12}
          style={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button
            style={{ backgroundColor: "green" }}
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
        </Grid.Col>
      </Grid>
    </Modal>
  );
};

export default ViewModal;
