import { Button, Modal, Title, Box, ScrollArea, Loader } from "@mantine/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { IconChevronDown, IconCircleX, IconLocation } from "@tabler/icons-react";
import React, { useEffect, useRef, useState } from "react";
import { TextInput } from "@mantine/core";
import { Grid } from "@mantine/core";
import * as _ from "lodash";
import { CreateDrone } from "../../Redux/Drone/Interface";
import DroneAPI from "../../Redux/Drone/Services";
import MapModal from "./MapModal";
import OperatorAPI from "../../Redux/Operator/Services";

const OperatorSchema = Yup.object().shape({
  drone_id: Yup.string().required("Drone id is required"),
  modal: Yup.string().required("Modal is required"),
  mfg_no: Yup.string().required("MFG No is required"),
  mfg_by: Yup.string().required("MFG By is required"),
  // location: Yup.array().of(Yup.string().required("Location is required")),
  // tracker_id: Yup.string().required("Tracker id is required"),
  // assigned_operator: Yup.string().required("Operator name is required"),
});

type Props = {
  id?: string;
  show: boolean;
  data?: any;
  type: string;
  handleClose: () => void;
};

const AddDroneModal: React.FC<Props> = ({
  id,
  show,
  data,
  type,
  handleClose,
}) => {
  const [initialValues, setInitialValues] = useState<CreateDrone>(
    _.isEmpty(data)
      ? {
        drone_id: "",
        modal: "",
        //  tracker_id: "",
        mfg_no: "",
        mfg_by: "",
      }
      : {
        drone_id: data[0]?.drone_id,
        modal: data[0]?.modal,
        //   tracker_id: data[0]?.tracker_id,
        mfg_no: data[0]?.mfg_no,
        mfg_by: data[0]?.mfg_by,
        assigned_operator: data[0]?.assigned_operator,
        assigned: data[0]?.assigned
      }
  );

  const handleMap = () => {
    setShowMap(false);
  };

  const [selectedLocation, setSelectedLocation] = useState({
    lat: 0,
    lng: 0,
  });

  const latitude = selectedLocation.lat;
  const longitude = selectedLocation.lng;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: OperatorSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      handleSubmit(values);
    },
  });

  const [searchValue, onSearchChange] = useState("");
  const [showMap, setShowMap] = useState(false);
  const [address, setAddress] = useState("");
  const [location, setLocation] = useState<any>([]);
  const [operatorList, setOperatorList] = useState<
    { value: any; label: any }[]
  >([]);
  const [selectedOperator, setSelectedOperator] = useState<string | any>("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>("");

  async function getOperatorList() {
    let data = (await OperatorAPI.getOperatorListAll()).data;
    let tempList: { value: any; label: any }[] = [];
    data.map((res: any) => {
      tempList.push({
        value: res._id,
        label: res.name,
      });
    });

    setOperatorList(tempList);
  }

  useEffect(() => {
    getOperatorList();
  }, []);

  async function getMarkedLocation(lat: any, lng: any) {
    let markedAddress = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyABs90Wh7N3ug2WsnACJcuqlpHVtPCBWIs`
    )
      .then((res) => {
        return res.text();
      })
      .then((res) => {
        return JSON.parse(res)["results"]?.[0]?.["formatted_address"];
      });
    setAddress(markedAddress);
    return markedAddress;
  }

  useEffect(() => {
    getMarkedLocation(latitude, longitude);
  }, [address, latitude, longitude]);

  async function handleSubmit(Params: any) {
    if (!isLoading) {
      setIsLoading(true);
      let payload = initialValues;
      payload = {
        ...payload,
        drone_id: payload?.drone_id?.trimEnd(),
        modal: payload?.modal?.trimEnd(),
        mfg_no: payload?.mfg_no?.trimEnd(),
        mfg_by: payload?.mfg_by?.trimEnd(),
        //location: location,
      //  tracker_id: payload?.tracker_id?.trimEnd(),
        //  assigned_operator: selectedOperator,
      };

      if (type === "Add") {
        try {
          await DroneAPI.createDrone(payload);
          handleClose();
        } catch (e: any) {
          setError(e?.status? "" : e.message);
        }
      } else {
        payload = {
          ...payload,
          id: data[0]?._id,
        };
        try {
          await DroneAPI.updateDrone(payload);
          handleClose();
        } catch (e: any) {
          setError(e?.status? "" : e.message);
        }
      }
      setIsLoading(false);
    }
  }

  return (
    <Modal
      centered
      radius="lg"
      opened={show}
      onClose={handleClose}
      withCloseButton={true}
      scrollAreaComponent={ScrollArea.Autosize}
      title={
        <Title style={{ fontSize: 25, fontWeight: 300, color: "#4C9C4F" }}>
          {type} Drone
        </Title>
      }
    >
      {error !== "" ? (
        <div
          style={{ backgroundColor: "red", color: "white", padding: "5px", marginBottom: 5, display: 'flex', fontSize: '13px' }}
        >
          <IconCircleX size="20px" style=
            {{ marginRight: '10px' }} /> {error}
        </div>
      ) : (
        ""
      )}
      <Box>
        <form onSubmit={formik.handleSubmit}>
          <Grid>
            <Grid.Col xs={12} sm={12} md={12} lg={12}>
              <TextInput
                label="Drone ID"
                placeholder="Drone ID"
                withAsterisk
                value={initialValues?.drone_id}
                onChange={(val) => {
                  setError("");
                  setInitialValues({
                    ...initialValues,
                    drone_id: val.target.value.replace(/\s+/g, ' ').trimStart(),
                  });
                }}
              />
              {formik.touched.drone_id && formik.errors.drone_id && (
                <span role="alert" style={{ color: "red", fontSize: 12 }}>
                  {formik.errors.drone_id}
                </span>
              )}
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={12} lg={12}>
              <TextInput
                label="Modal"
                placeholder="Modal"
                withAsterisk
                value={initialValues?.modal}
                onChange={(val) => {
                  setError("");
                  setInitialValues({
                    ...initialValues,
                    modal: val.target.value.replace(/\s+/g, ' ').trimStart(),
                  });
                }}
              />
              {formik.touched.modal && formik.errors.modal && (
                <span role="alert" style={{ color: "red", fontSize: 12 }}>
                  {formik.errors.modal}
                </span>
              )}
            </Grid.Col>
            {/* <Grid.Col xs={12} sm={12} md={12} lg={12}>
              <TextInput
                label="Location"
                placeholder="Location"
                withAsterisk
                value={address}
                contentEditable={false}
                onChange={(val) => {
                  setInitialValues({
                    ...initialValues,
                    location: location,
                  });
                }}
                rightSection={
                  <ActionIcon
                    onClick={(e) => {
                      setShowMap(true);
                    }}
                    style={{
                      backgroundColor: "#5cb85c",
                      height: 35,
                      width: 50,
                    }}
                  >
                    <IconLocation color="#FFFFFF" size={18} />
                  </ActionIcon>
                }
              />
              {formik.touched.location && formik.errors.location && (
                <span role="alert" style={{ color: "red", fontSize: 12 }}>
                  {formik.errors.location}
                </span>
              )}
            </Grid.Col> */}
            {/* <Grid.Col xs={12} sm={12} md={12} lg={12}>
              <TextInput
                label="Tracker ID"
                placeholder="Tracker ID"
                withAsterisk
                value={initialValues?.tracker_id}
                onChange={(val) => {
                  setInitialValues({
                    ...initialValues,
                    tracker_id: val.target.value,
                  });
                }}
              />
              {formik.touched.tracker_id && formik.errors.tracker_id && (
                <span role="alert" style={{ color: "red", fontSize: 12 }}>
                  {formik.errors.tracker_id}
                </span>
              )}
            </Grid.Col> */}
            <Grid.Col xs={12} sm={12} md={12} lg={12}>
              <TextInput
                label="MFG No"
                placeholder="MFG No"
                withAsterisk
                value={initialValues?.mfg_no}
                onChange={(val) => {
                  setError("");
                  setInitialValues({
                    ...initialValues,
                    mfg_no: val.target.value.replace(/\s+/g, ' ').trimStart(),
                  });
                }}
              />
              {formik.touched.mfg_no && formik.errors.mfg_no && (
                <span role="alert" style={{ color: "red", fontSize: 12 }}>
                  {formik.errors.mfg_no}
                </span>
              )}
            </Grid.Col>
            <Grid.Col xs={12} sm={12} md={12} lg={12}>
              <TextInput
                label="MFG By"
                placeholder="MFG By"
                withAsterisk
                value={initialValues?.mfg_by}
                onChange={(val) => {
                  setError("");
                  setInitialValues({
                    ...initialValues,
                    mfg_by: val.target.value.replace(/\s+/g, ' ').trimStart(),
                  });
                }}
              />
              {formik.touched.mfg_by && formik.errors.mfg_by && (
                <span role="alert" style={{ color: "red", fontSize: 12 }}>
                  {formik.errors.mfg_by}
                </span>
              )}
            </Grid.Col>
            {/* <Grid.Col xs={12} sm={12} md={12} lg={12}>
              <Select
                label="Operator Name"
                placeholder="Select Operator"
                withAsterisk
                rightSection={<IconChevronDown size="1rem" />}
                rightSectionWidth={30}
                searchable
                value={initialValues?.assigned_operator}
                onChange={(val) => {
                  setSelectedOperator(val);
                  setInitialValues({
                    ...initialValues,
                    assigned_operator: val,
                  });
                }}
                onSearchChange={onSearchChange}
                searchValue={searchValue}
                nothingFound="No options"
                data={operatorList}
              />
              {formik.touched.assigned_operator &&
                formik.errors.assigned_operator && (
                  <span role="alert" style={{ color: "red", fontSize: 12 }}>
                    {formik.errors.assigned_operator}
                  </span>
                )}
            </Grid.Col> */}

            <Grid.Col span={12}>
              <Box style={{ display: "flex", justifyContent: "center" }}>
                {isLoading ? (
                  <Button style={{ backgroundColor: "#2C752F", width: 100 }}>
                    <Loader size="xs" />
                  </Button>
                ) : (
                  <Button type="submit" style={{ backgroundColor: "#2C752F" }}>
                    {type}
                  </Button>
                )}
              </Box>
            </Grid.Col>
          </Grid>
        </form>
      </Box>
      {showMap ? (
        <MapModal
          show={showMap}
          handleClose={handleMap}
          address={address}
          setAddress={setAddress}
          location={location}
          setLocation={setLocation}
        // selectedLocation={selectedLocation}
        // setSelectedLocation={setSelectedLocation}
        />
      ) : null}
    </Modal>
  );
};

export default AddDroneModal;
