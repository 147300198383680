import { Modal, Title, Box, ScrollArea, Button, Select } from "@mantine/core";
import React, { useState } from "react";
import { TextInput } from "@mantine/core";
import { Grid } from "@mantine/core";
import * as _ from "lodash";
import { useFormik } from "formik";
import * as Yup from "yup";
import ConfigurationFertilizerAPI from "../../../Redux/Configuration/Fertilizer/Services";
import { Loader } from "@mantine/core";
import { IconChevronDown, IconCircleX } from "@tabler/icons-react";
type Props = {
  id?: string;
  show: boolean;
  data?: any;
  type: string;
  handleClose: () => void;
};

const AddCropTypeModal: React.FC<Props> = ({
  id,
  show,
  data,
  type,
  handleClose,
}) => {
  const [height, setHeight] = useState("100%");
  const [initialValues, setInitialValues] = useState<any>(
    _.isEmpty(data)
      ? {
          type: "",
          name: "",
          brand_name: "",
          description: "",
        }
      : {
          type: data[0]?.type,
          name: data[0]?.name,
          brand_name: data[0]?.brand_name,
          description: data[0]?.description,
        }
  );

  const [isloading, setloading] = useState<any>(false);
  const [error, setError] = useState<any>("");

  async function handleSubmit(params: any) {
    if (!isloading) {
      setloading(true);
      switch (type) {
        case "Create":
          try {
            await ConfigurationFertilizerAPI.createFertilizer(params);
            handleClose();
          } catch (e: any) {
            setError(e?.status? "" : e?.message);
          }
          break;
        case "Update":
          try {
            let paramsData = {
              ...params,
              id: data[0]._id,
            };
            await ConfigurationFertilizerAPI.updateFertilizer(paramsData);
            handleClose();
          } catch (e) {}
          break;
      }
      setloading(false);
    }
  }
  const FertilizerSchema = Yup.object().shape({
    type: Yup.string().required("Type is required"),
    name: Yup.string().required("Name is required"),
    description: Yup.string().required("Description is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: FertilizerSchema,
    onSubmit: (values: any, { setStatus, setSubmitting }: any) => {
      handleSubmit(values);
    },
  });

  return (
    <Modal
      centered
      radius="lg"
      opened={show}
      onClose={handleClose}
      withCloseButton={true}
      scrollAreaComponent={ScrollArea.Autosize}
      title={
        <Title style={{ fontSize: 25, fontWeight: 300, color: "#4C9C4F" }}>
          {type} Fertilizer
        </Title>
      }
    >
        {error !== "" ? (
            <div
              style={{ backgroundColor: "red", color: "white", padding:"5px", marginBottom: 5, display:'flex' , fontSize:'13px' }}
            >
              <IconCircleX size="20px" style=
              {{marginRight:'10px'}} /> {error}
            </div>
          ) : (
            ""
          )}
      <Box style={{ height: height }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid>
            <Grid.Col xs={12} sm={12} md={12} lg={12}>
              <Select
                clearable
                label="Type"
                placeholder="Select type"
                withAsterisk
                rightSection={<IconChevronDown size="1rem" />}
                rightSectionWidth={30}
                styles={{ rightSection: { pointerEvents: "none" } }}
                dropdownPosition="bottom"
                onClickCapture={(val) => {
                  setHeight("150px");
                }}
                onDropdownClose={() => {
                  setHeight("100%");
                }}
                data={[
                  { value: "iffco product", label: "IFFCO Product" },
                  { value: "others", label: "Others" },
                ]}
                value={initialValues.type}
                onChange={(val) => {
                  setError("");
                  setInitialValues({
                    ...initialValues,
                    type: val,
                    name: "",
                    brand_name: "",
                    description: "",
                  });
                }}
              />
              {formik.touched.type && formik.errors.type && (
                <span role="alert" style={{ color: "red", fontSize: 12 }}>
                  {formik.errors.type.toString()}
                </span>
              )}
            </Grid.Col>
            {initialValues.type === "iffco product" ? (
              <>
                <Grid.Col xs={12} sm={12} md={12} lg={12}>
                  <Select
                    clearable
                    label="Name"
                    placeholder="Select name"
                    withAsterisk
                    rightSection={<IconChevronDown size="1rem" />}
                    rightSectionWidth={30}
                    styles={{ rightSection: { pointerEvents: "none" } }}
                    data={[
                      { value: "IFFCO Nano Urea", label: "IFFCO Nano Urea" },
                      { value: "IFFCO NDAP", label: "IFFCO NDAP" },
                      { value: "IFFCO N-Urea and N-DAP", label: "IFFCO N-Urea and N-DAP"}
                    ]}
                    value={
                      initialValues.type === "iffco product"
                        ? initialValues?.name
                        : ""
                    }
                    onChange={(val) => {
                      setError("");
                      setInitialValues({
                        ...initialValues,
                        name: val,
                      });
                    }}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <span role="alert" style={{ color: "red", fontSize: 12 }}>
                      {formik.errors.name.toString()}
                    </span>
                  )}
                </Grid.Col>
                <Grid.Col xs={12} sm={12} md={12} lg={12}>
                  <TextInput
                    label="Description"
                    placeholder="Description"
                    withAsterisk
                    value={
                      initialValues.type === "iffco product"
                        ? initialValues?.description
                        : ""
                    }
                    onChange={(val) => {
                      setError("");
                      setInitialValues({
                        ...initialValues,
                        description: val.target.value.replace(/\s+/g, ' ').trimStart(),
                      });
                    }}
                  />
                  {formik.touched.description && formik.errors.description && (
                    <span role="alert" style={{ color: "red", fontSize: 12 }}>
                      {formik.errors.description.toString()}
                    </span>
                  )}
                </Grid.Col>
              </>
            ) : initialValues.type === "others" ? (
              <>
                <Grid.Col xs={12} sm={12} md={12} lg={12}>
                  <TextInput
                    label="Name"
                    placeholder="Name"
                    value={
                      initialValues.type === "others" ? initialValues?.name : ""
                    }
                    withAsterisk
                    onChange={(val) => {
                      setError("");
                      setInitialValues({
                        ...initialValues,
                        name: val.target.value.replace(/\s+/g, ' ').trimStart(),
                      });
                    }}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <span role="alert" style={{ color: "red", fontSize: 12 }}>
                      {formik.errors.name.toString()}
                    </span>
                  )}
                </Grid.Col>
                <Grid.Col xs={12} sm={12} md={12} lg={12}>
                  <TextInput
                    label="Brand name"
                    placeholder="Brand name"
                    value={
                      initialValues.type === "others"
                        ? initialValues?.brand_name
                        : ""
                    }
                    onChange={(val) => {
                      setError("");
                      setInitialValues({
                        ...initialValues,
                        brand_name: val.target.value.replace(/\s+/g, ' ').trimStart(),
                      });
                    }}
                  />
                </Grid.Col>
                <Grid.Col xs={12} sm={12} md={12} lg={12}>
                  <TextInput
                    label="Description"
                    placeholder="Description"
                    withAsterisk
                    value={
                      initialValues.type === "others"
                        ? initialValues?.description
                        : ""
                    }
                    onChange={(val) => {
                      setError("");
                      setInitialValues({
                        ...initialValues,
                        description: val.target.value.replace(/\s+/g, ' ').trimStart(),
                      });
                    }}
                  />
                  {formik.touched.description && formik.errors.description && (
                    <span role="alert" style={{ color: "red", fontSize: 12 }}>
                      {formik.errors.description.toString()}
                    </span>
                  )}
                </Grid.Col>
              </>
            ) : (
              <></>
            )}

            <Grid.Col span={12}>
              <Box style={{ display: "flex", justifyContent: "center" }}>
                {isloading ? (
                  <Button
                    type="submit"
                    style={{ backgroundColor: "#2C752F", width: 100 }}
                  >
                    <Loader size="xs" />
                  </Button>
                ) : (
                  <Button type="submit" style={{ backgroundColor: "#2C752F" }}>
                    {type}
                  </Button>
                )}
              </Box>
            </Grid.Col>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
};

export default AddCropTypeModal;
