import { put, all, fork, call, takeLatest } from "redux-saga/effects";

import { loginResponseI } from "./Interface";
import * as actions from "./actionTypes";
// import { setGetBookingListFailed, setGetBookingListLoading, setGetBookingListSuccess, setUpdateBookingFailed, setUpdateBookingLoading, setUpdateBookingSuccess } from "./BookingSlice";
import {
  setOrderDetailsLoading,
  setOrderDetailsSuccess,
  setGetOrderListSuccess,
  setUpdateOrderSuccess,
  setOrderDetailsFailure,
  setNotifyFalse,
} from "./BookingSlice";
import BookingAPI from "./Services";

function* getBookingList(payload: actions.getBookingList) {
  try {
    yield put(setOrderDetailsLoading());
    console.log("=====Booking List=====", payload);
    const data: loginResponseI = yield call(
      BookingAPI.getBookingList,
      payload?.data
    );
    yield put(setOrderDetailsSuccess(data));
  } catch (error) {
    console.log("create error", error);
    yield put(setOrderDetailsFailure(error));
  }
}

function* watchOnGetBookingList() {
  yield takeLatest(actions.Get_booking_List, getBookingList);
}

function* updateBooking(payload: actions.updateBooking) {
  try {
    yield put(setOrderDetailsLoading());
    console.log("=====Update Booking=====", payload);
    const data: loginResponseI = yield call(
      BookingAPI.updateBooking,
      payload?.data
    );
    yield put(setUpdateOrderSuccess(data));
  } catch (error) {
    console.log("create error", error);
    yield put(setOrderDetailsFailure(error));
  }
}

function* watchOnUpdateBooking() {
  yield takeLatest(actions.Update_Booking_List, updateBooking);
}

export default function* bookingSaga() {
  yield all([fork(watchOnGetBookingList), fork(watchOnUpdateBooking)]);
}
