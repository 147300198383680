import { IconEye, IconEdit, IconPlus, IconPlugConnected } from "@tabler/icons-react";
import {
  ActionIcon,
  Box,
  Table,
  ScrollArea,
  Text,
  Tooltip,
} from "@mantine/core";
import { useState } from "react";
import DeleteModal from "../../../Components/DeleteModal";
import CustomPagination from "../../../Components/CustomPagination";
import capitalizeFirstLowercaseRest from "../../../Components/Capitalize";
import AdminAPI from "../../../Redux/Admin/service";
import ManagementViewModal from "../Modals/ManagementViewModal";
import AddUserModal from "../Modals/addUserModal";
import PermissionCheck from "../../../Components/Permission";

export default function UserTable(props: any) {
  const [viewUserModal, setViewUserModal] = useState(false);
  const [editUserModal, setEditUserModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [id, setId] = useState("");
  const [editableData, setEditableData] = useState([]);

  const handleViewModal = () => {
    setViewUserModal(false);
  };

  const handleEditModal = () => {
    adminListFunction();
    setEditUserModal(false);
  };

  const handleDelete = () => {
    adminListFunction();
    setShowDeleteModal(false);
  };

  async function adminListFunction() {
    let params = {
      ...props.pagination,
      filter: props.searchText ? props.searchText : "",
      status: props.value,
    };
    try {
      let data = (await AdminAPI.getAdminList(params)).data;
      props.setTableData(data?.data ? data.data : []);
      props.setTotalCount(data?.count ? data?.count : 0);
    } catch (e) {
      props.setTableData([]);
    }
  }

  const rows = props.data?.map((item: any) => {
    return (
      <tr key={item?._id}>
        <td>{capitalizeFirstLowercaseRest(item?.name)}</td>
        <td>{item?.email}</td>
        <td style={{ display: "flex", justifyContent: "space-evenly" }}>
          <ActionIcon
            variant="outline"
            onClick={() => {
              const edata = props.data?.filter(
                (e: any) => e?._id === item?._id
              );
              setEditableData(edata);
              setViewUserModal(true);
            }}
          >
            <Tooltip label="View">
              <IconEye size={20} />
            </Tooltip>
          </ActionIcon>
          {
            PermissionCheck(props?.permissionList, "User_Management", "write") ?
              <ActionIcon
                variant="outline"
                onClick={() => {
                  const edata = props.data?.filter(
                    (e: any) => e?._id === item?._id
                  );
                  setEditableData(edata);
                  setEditUserModal(true);
                }}
              >
                <Tooltip label="Edit">
                  <IconEdit size={20} />
                </Tooltip>
              </ActionIcon> : <></>
          }
          {
            PermissionCheck(props?.permissionList, "User_Management", "write") ?
              <ActionIcon
                variant="outline"
                onClick={() => {
                  setShowDeleteModal(true);
                  setId(item?._id);
                }}
              >
                <Tooltip label={props.value === "active" ? "De-Active" : "Active"}>
                  {
                    props.value === "active" ? <IconPlugConnected size={20} /> : <IconPlus size={20} />
                  }
                </Tooltip>
              </ActionIcon> : <></>
          }
        </td>
      </tr>
    );
  });

  return (
    <>
      <ScrollArea>
        {props.data.length === 0 ? (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "50vh",
            }}
          >
            <Text>No Data</Text>
          </Box>
        ) : (
          <div style={{ height: window.innerHeight - 380, overflowY: "scroll" }}>
            <Table verticalSpacing="sm" style={{ tableLayout: "fixed" }}>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th style={{ textAlign: "center" }}>Actions</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          </div>
        )}
      </ScrollArea>
      {props.count > 0 ? (
        <CustomPagination
          setPage={props.setPage}
          pagination={props.pagination}
          setPagination={props.setPagination}
          page={props.page}
          count={props.count}
        />
      ) : (
        <></>
      )}
      {showDeleteModal ? (
        <DeleteModal
          id={id}
          show={showDeleteModal}
          type="User"
          handleClose={handleDelete}
        />
      ) : null}

      {editUserModal ? (
        <AddUserModal
          show={editUserModal}
          data={editableData}
          type="Update"
          handleClose={handleEditModal}
        />
      ) : null}

      {viewUserModal ? (
        <ManagementViewModal
          show={viewUserModal}
          data={editableData}
          type="User"
          handleClose={handleViewModal}
        />
      ) : null}
    </>
  );
}
