import {
  create,
  listQuery,
  operatorData,
  operatorEditableData,
} from "./Interface";

export const Create_Operator = "createOperator";

export interface createOperator {
  type: typeof Create_Operator;
  data: create;
}

export const Get_Operator_List = "getOperatorList";

export interface getOperatorList {
  type: typeof Get_Operator_List;
  data: listQuery;
}

export const Update_Operator_List = "updateOperator";

export interface updateOperator {
  type: typeof Update_Operator_List;
  data: operatorEditableData;
}

export const Delete_Operator = "deleteOperator";

export interface deleteOperator {
  type: typeof Delete_Operator;
  data: operatorData;
}

export type AuthAction = createOperator | getOperatorList | updateOperator;
