import { useState } from "react";
import {
  IconEye,
  IconPlugConnected,
  IconPlus,
  IconTrash,
} from "@tabler/icons-react";
import {
  ActionIcon,
  Box,
  Table,
  ScrollArea,
  Text,
  Tooltip,
} from "@mantine/core";
import DeleteModal from "../../Components/DeleteModal";
import { useNavigate } from "react-router-dom";
import UserAPI from "../../Redux/User/Services";
import CustomPagination from "../../Components/CustomPagination";
import PermissionCheck from "../../Components/Permission";

export default function UserTable(props: any) {
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [id, setId] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(false);
  const [selectedDelete, setSelectedDelete] = useState(false);

  async function getUserList() {
    try {
      let params = {
        ...props.pagination,
        status: props.value,
        filter: props.searchText,
        state: props.selectedState,
        district: props.selectedDistrict,
        block: props.selectedBlock,
      };
      let data = (await UserAPI.getUserListByAccountStatus(params)).data;
      props.setUserData(data?.data[0].data);
      props.setTotalCount(
        data?.data[0]?.count?.count ? data?.data[0]?.count?.count : 0
      );
      props.setSparyedCount(data?.SprayedCounts);
    } catch (e) {
      props.setUserData([]);
      props.setTotalCount(0);
      props.setSparyedCount([]);
    }
  }

  const handleDelete = () => {
    getUserList();
    setShowDeleteModal(false);
  };

  const rows = props.data?.map((item: any) => {
    return (
      <tr key={item?._id} style={{ color: !item?.status ? "grey" : "black" }}>
        <td> {item?.farmer_id}</td>
        <td>{item?.name}</td>
        <td>{item?.farm_land_area}</td>
        <td>
          {props?.sparyedCount?.find((res: any) => res._id === item?._id)?.[
            "sum"
          ]
            ? props?.sparyedCount?.find((res: any) => res._id === item?._id)?.[
                "sum"
              ]
            : 0}
        </td>
        {/* <td>{capitalizeFirstLowercaseRest(item?.account_status)}</td> */}
        <td style={{ display: "flex", justifyContent: "space-evenly" }}>
          <ActionIcon
            variant="outline"
            onClick={() => {
              props.setError(""); 
              props.setSuccess(""); 
              let tempItem = {
                ...item,
                sparyCount: props?.sparyedCount?.find(
                  (res: any) => res._id === item?._id
                )?.["sum"]
                  ? props?.sparyedCount?.find(
                      (res: any) => res._id === item?._id
                    )?.["sum"]
                  : 0,
              };
              navigate("user-verify", { state: { item: tempItem } });
            }}
          >
            <Tooltip label="View">
              <IconEye size={20} />
            </Tooltip>
          </ActionIcon>

          {PermissionCheck(
            props.permissionList,
            "Farmer_Management",
            "write"
          ) ? (
            <ActionIcon
              variant="outline"
              onClick={() => {
                setShowDeleteModal(true);
                props.setError(""); 
                props.setSuccess(""); 
                
                setId(item?._id);
                setSelectedStatus(item.status);  
                 setSelectedDelete(false);
              }}
            >
              <Tooltip label={item?.status ? "De-Active" : "Active"}>
                {item?.status ? (
                  <IconPlugConnected size={20} />
                ) : (
                  <IconPlus size={20} />
                )}
              </Tooltip>
            </ActionIcon>
          ) : (
            <></>
          )}
          <ActionIcon
            variant="outline"
            onClick={() => {
              setShowDeleteModal(true); 
              console.log(item.status,"12345");
              setSelectedDelete(true);
              props.setError(""); 
              props.setSuccess(""); 

              setId(item?._id);
              // setSelectedDelete(item.status);
            }}
          >
            <Tooltip label="Delete">
              <IconTrash size={20} />
            </Tooltip>
          </ActionIcon>
        </td>
      </tr>
    );
  });

  return (
    <>
      <ScrollArea>
        {props.data.length === 0 ? (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: window.innerHeight - 285,
            }}
          >
            <Text>No Data</Text>
          </Box>
        ) : (
          <div
            style={{ height: window.innerHeight - 330, overflowY: "scroll" }}
          >
            <Table verticalSpacing="sm" style={{ tableLayout: "fixed" }}>
              <thead>
                <tr>
                  <th>Farmer Id</th>
                  <th>Name</th>
                  <th>Farm Land Registered (Acres)</th>
                  <th>Farm Land Sprayed (Acres)</th>
                  <th style={{ textAlign: "center" }}>Actions</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          </div>
        )}
      </ScrollArea>
      {props.count > 0 ? (
        <CustomPagination
          setPage={props.setPage}
          pagination={props.pagination}
          setPagination={props.setPagination}
          page={props.page}
          count={props.count}
        />
      ) : (
        <></>
      )}

      {showDeleteModal ? (
        <DeleteModal
          id={id}
          show={showDeleteModal}
          type={selectedDelete ? "PermanentDelete" : "Farmer"}
          handleClose={handleDelete}
          error={props.setError}
          setSuccess={props.setSuccess}
          status={!selectedStatus}
          deleteStatus={selectedDelete}
        />
      ) : null}
    </>
  );
}
