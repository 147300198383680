import { bookingData, listQuery } from "./Interface";

export const Get_booking_List = "getBookingList";

export interface getBookingList {
  type: typeof Get_booking_List;
  data: listQuery;
}

export const Update_Booking_List = "updateBooking";
export interface updateBooking {
  type: typeof Update_Booking_List;
  data: bookingData;
}

export type AuthAction = getBookingList | updateBooking;
