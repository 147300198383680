import { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Title,
  Tabs,
  Box,
  TextInput,
  Autocomplete,
} from "@mantine/core";
import DealerTable from "./dealerTable";
import CustomLoader from "../../Components/CustomLoader";
import { useAppSelector } from "../../Services/hooks/hooks";
import PermissionCheck from "../../Components/Permission";
import StateAPI from "../../Redux/Configuration/State/Services";
import DistrictAPI from "../../Redux/Configuration/District/Services";
import BlockAPI from "../../Redux/Configuration/Block/Services";
import DealerApi from "../../Redux/Dealer/Service";
import { IconCircleX } from "@tabler/icons-react";

export default function DealersManagement() {
  const [activeTab, setActiveTab] = useState<any>("active");
  const [pagination, setPagination] = useState({
    skip: 0,
    limit: 10,
  });
  const [pageSelection, setPageSelection] = useState(1);
  const [totalCount, setTotalCount] = useState(10);
  const [searchText, setSearchText] = useState("");

  const Styles = {
    paper: {
      width: "100%",
      backgroundColor: "#FFFFFF",
      padding: 20,
      borderRadius: 20,
    },
  };

  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [stateList, setStateList] = useState<any>([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [districtList, setDistrictList] = useState<any>([]);
  const [blockList, setBlockList] = useState<any>([]);
  const [selectedBlock, setSelectedBlock] = useState("");

  async function getStateList() {
    let data = await StateAPI.getStateAllList();
    let tempList: { value: any; label: any }[] = [];
    data?.data.map((val: any) => {
      tempList.push({
        value: val.name,
        label: val.name,
      });
    });
    setStateList(tempList);
  }

  async function getDistrictListBasedOnState(state: any) {
    let data = await DistrictAPI.getDistrictAllList({
      state: state,
    });
    let tempList: { value: any; label: any }[] = [];
    data?.data.map((val: any) => {
      tempList.push({
        value: val.name,
        label: val.name,
      });
    });
    setDistrictList(tempList);
  }

  async function getBlockListBasedOnState(state: any, district: any) {
    let data = await BlockAPI.getBlocAllList({
      state: state,
      district: district,
    });
    let tempList: { value: any; label: any }[] = [];
    data?.data.map((val: any) => {
      tempList.push({
        value: val.name,
        label: val.name,
      });
    });
    setBlockList(tempList);
  }

  useEffect(() => {
    getStateList();
  }, []);

  async function getDealerList() {
    try {
      let params = {
        ...pagination,
        filter: searchText,
        state: selectedState,
        district: selectedDistrict,
        block: selectedBlock,
        status: activeTab,
      };
      setIsLoading(true);
      let data = (await DealerApi.getDealerList(params)).data;
      setUserData(data[0].data);
      setTotalCount(data[0]?.count?.count ? data[0]?.count?.count : 0);
      setIsLoading(false);
    } catch (e) {
      setUserData([]);
      setTotalCount(0);
      setIsLoading(false);
    }
  }
  const [error, setError] = useState("");
  const { data } = useAppSelector((state) => state.auth);
  const permissionList = data?.data?.permission?.[0]?.permission_list
    ? data.data.permission[0].permission_list
    : [];

  useEffect(() => {
    getDealerList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pagination,
    searchText,
    selectedState,
    selectedDistrict,
    selectedBlock,
    activeTab,
  ]);

  useEffect(() => {
    setTimeout(() => {
      setError("");
    }, 10000);
  }, [error]);

  const handleTabChange = (val: any) => {

    setPagination({
      skip: 0,
      limit: 10,
    });
    setPageSelection(1);
    setActiveTab(val);
  };

  return (
    <div>
      <Grid>
        <Grid.Col span={12}>
          <Paper style={Styles.paper}>
            <Grid>
              <Grid.Col xs={12} sm={4} md={4} lg={4}>
                <Title
                  style={{
                    fontSize: 30,
                    fontWeight: 300,
                    color: "#4C9C4F",
                  }}
                >
                  Dealer Management
                </Title>
              </Grid.Col>
              <Grid.Col xs={12} sm={2} md={2} lg={2}>
                <Autocomplete
                  placeholder="Filter by State"
                  sx={{ maxHeight: 200, overflow: "auto" }}
                  data={stateList}
                  value={selectedState}
                  nothingFound="No options"
                  onChange={(e) => {
                    setSelectedState(e);
                    setSelectedBlock("");
                    setSelectedDistrict("");
                    getDistrictListBasedOnState(e);
                  }}
                />
              </Grid.Col>
              <Grid.Col xs={12} sm={2} md={2} lg={2}>
                <Autocomplete
                  placeholder="Filter by District"
                  sx={{ maxHeight: 200, overflow: "auto" }}
                  data={districtList}
                  value={selectedDistrict}
                  nothingFound="No options"
                  onChange={(e) => {
                    setSelectedDistrict(e);
                    setSelectedBlock("");
                    getBlockListBasedOnState(selectedState, e);
                  }}
                />
              </Grid.Col>
              <Grid.Col xs={12} sm={2} md={2} lg={2}>
                <Autocomplete
                  placeholder="Filter by Block"
                  sx={{ maxHeight: 200, overflow: "auto" }}
                  data={blockList}
                  nothingFound="No options"
                  value={selectedBlock}
                  onChange={(e) => {
                    setSelectedBlock(e);
                  }}
                />
              </Grid.Col>
              <Grid.Col xs={12} sm={2} md={2} lg={2}>
                <TextInput
                  placeholder="Filter by Name"
                  value={searchText}
                  withAsterisk
                  onChange={(val) => {
                    setSearchText(val.target.value);
                  }}
                />
              </Grid.Col>
            </Grid>
            {error !== "" ? <div
              style={{ backgroundColor: "red", color: "white", padding: "5px", marginBottom: 20, marginTop: 20, display: 'flex', fontSize: '13px' }}
            >
              <IconCircleX size="20px" style=
                {{ marginRight: '10px' }} /> {error}
            </div> : ""}
            
            {PermissionCheck(permissionList, "Dealer_Management", "read") ? (
              <div>
                <Tabs
                  variant="outline"
                  value={activeTab}
                  onTabChange={handleTabChange}
                >
                  <Tabs.List>
                    <Tabs.Tab value="active">Active</Tabs.Tab>
                    <Tabs.Tab value="deactive">De-Active</Tabs.Tab>
                  </Tabs.List>
                </Tabs>

                <Box style={{ marginTop: 30 }}>
                  {isLoading ? (
                    <CustomLoader />
                  ) : (
                    <DealerTable
                      data={userData}
                      count={totalCount}
                      setPagination={setPagination}
                      pagination={pagination}
                      page={pageSelection}
                      setPage={setPageSelection}
                      setUserData={setUserData}
                      setTotalCount={setTotalCount}
                      searchText={searchText}
                      permissionList={permissionList}
                      state={selectedState}
                      district={selectedDistrict}
                      block={selectedBlock}
                      value={activeTab}
                      setError={setError}
                    />
                  )}
                </Box>
              </div>
            ) : (
              <></>
            )}
          </Paper>
        </Grid.Col>
      </Grid>
    </div>
  );
}
