import { Modal, Title, Box, ScrollArea, Button } from "@mantine/core";
import React, { useState } from "react";
import { TextInput } from "@mantine/core";
import { Grid } from "@mantine/core";
import * as _ from "lodash";
import StateAPI from "../../../Redux/Configuration/State/Services";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Loader } from "@mantine/core";
import { get } from "lodash";
import { IconCircleX } from "@tabler/icons-react";

type Props = {
  id?: string;
  show: boolean;
  data?: any;
  type: string;
  handleClose: () => void;
};

const AddStateModal: React.FC<Props> = ({
  id,
  show,
  data,
  type,
  handleClose,
}) => {
  const [initialValues, setInitialValues] = useState<any>(
    _.isEmpty(data)
      ? {
          name: "",
        }
      : { name: data[0]?.name }
  );

  const [isloading, setloading] = useState<any>(false);
  const [isError, setError] = useState<any>(false);
  const [isErrorMsg, setErrorMsg] = useState<any>("");

  async function handleSubmit(params: any) {

    if(!isloading) {

      setloading(true);
    
    switch (type) {
      case "Add":
      case "state":
        try {
        let data =  await StateAPI.createState(params);

          if (get(data, "code") === 400) {
            setError(true);
            setErrorMsg("State already exits");
          } else {
            setError(false);
            handleClose();
          }
        } catch (e) {}
        break;
      case "Update":
        try {
          let paramsData = {
            ...params,
            id: data[0]._id,
          };
          await StateAPI.updateState(paramsData);
          handleClose();
        } catch (e) {}
        break;
    }
    setloading(false);
  }
  }

  const CropSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: CropSchema,
    onSubmit: (values: any, { setStatus, setSubmitting }: any) => {
      handleSubmit(values);
    },
  });

  return (
    <Modal
      centered
      radius="lg"
      opened={show}
      onClose={handleClose}
      withCloseButton={true}
      scrollAreaComponent={ScrollArea.Autosize}
      title={
        <Title style={{ fontSize: 25, fontWeight: 300, color: "#4C9C4F" }}>
          {type} State
        </Title>
      }
    >
      <Box>
        <form onSubmit={formik.handleSubmit}>
        {isError ? (
            <div
              style={{ backgroundColor: "red", color: "white", padding:"5px", marginBottom: 5, display:'flex' , fontSize:'13px' }}
            >
              <IconCircleX size="20px" style=
              {{marginRight:'10px'}} /> {isErrorMsg}
            </div>
          ) : (
            ""
          )}
          <Grid>
            <Grid.Col xs={12} sm={12} md={12} lg={12}>
              <TextInput
                label="State"
                placeholder="State"
                value={initialValues?.name}
                withAsterisk
                onChange={(val) => {
                  setError(false);
                  setInitialValues({
                    ...initialValues,
                    name: val.target.value.replace(/\s+/g, ' ').trimStart(),
                  });
                }}
              />
              {formik.touched.name && formik.errors.name && (
                <span role="alert" style={{ color: "red", fontSize: 12 }}>
                  {formik?.errors?.name.toString()}
                </span>
              )}
            </Grid.Col>

            <Grid.Col span={12}>
              <Box style={{ display: "flex", justifyContent: "center" }}>
                {isloading ? (
                  <Button type="submit" style={{ backgroundColor: "#2C752F" ,  width: 100 }}>
                    <Loader size="xs" />
                  </Button>
                ) : (
                  <Button type="submit" style={{ backgroundColor: "#2C752F" }}>
                    {type}
                  </Button>
                )}
              </Box>
            </Grid.Col>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
};

export default AddStateModal;
