import { UserLogin } from "./Interface";

export const GET_LOGIN_DETAILS = "getLoginDetails";

export interface getloginDetails {
  type: typeof GET_LOGIN_DETAILS;
  data: UserLogin;
}

export type AuthAction = getloginDetails;
