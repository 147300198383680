import { useState } from "react";
import { Box, Tabs } from "@mantine/core";
import CropType from "./CropType";
import Fertilizer from "./Fertilizer";
import Pesticide from "./Pesticide";
import State from "./State";
import District from "./District";
import Block from "./Block";
import CustomLoader from "../../Components/CustomLoader";
import Variety from "./Variety";
import CropStage from "./cropStage";

export default function ConfigurationTab() {
  const [activeTab, setActiveTab] = useState<string | null>("crop-type");

  const [loading, setLoading] = useState(false);
  const Page = () => {
    switch (activeTab) {
      case "crop-type":
        return (
          <Tabs.Panel value="crop-type" pt="xs">
            {loading ? <CustomLoader /> : <CropType />}
          </Tabs.Panel>
        );
      case "variety":
        return (
          <Tabs.Panel value="variety" pt="xs">
            {loading ? <CustomLoader /> : <Variety />}
          </Tabs.Panel>
        );
      case "fertilizer":
        return (
          <Tabs.Panel value="fertilizer" pt="xs">
            {loading ? <CustomLoader /> : <Fertilizer />}
          </Tabs.Panel>
        );
      case "pesticide":
        return (
          <Tabs.Panel value="pesticide" pt="xs">
            {loading ? <CustomLoader /> : <Pesticide />}
          </Tabs.Panel>
        );
      case "state":
        return (
          <Tabs.Panel value="state" pt="xs">
            {loading ? <CustomLoader /> : <State />}
          </Tabs.Panel>
        );
      case "district":
        return (
          <Tabs.Panel value="district" pt="xs">
            {loading ? <CustomLoader /> : <District />}
          </Tabs.Panel>
        );
      case "block":
        return (
          <Tabs.Panel value="block" pt="xs">
            {loading ? <CustomLoader /> : <Block />}
          </Tabs.Panel>
        );
        case "crop-stage":
          return (
            <Tabs.Panel value="crop-stage" pt="xs">
              {loading ? <CustomLoader /> : <CropStage />}
            </Tabs.Panel>
          );
    }
  };

  return (
    <Box>
      <Tabs
        variant="pills"
        value={activeTab}
        onTabChange={(val) => {
          setActiveTab(val);
        }}
      >
        <Tabs.List>
          <Tabs.Tab
            style={{
              backgroundColor:
                activeTab === "crop-type" ? "#4CAF50" : "#FFFFFF",
              color: activeTab === "crop-type" ? "#FFFFFF" : "#4CAF50",
            }}
            value="crop-type"
          >
            Crop Type
          </Tabs.Tab>
          <Tabs.Tab
            style={{
              backgroundColor: activeTab === "crop-stage" ? "#4CAF50" : "#FFFFFF",
              color: activeTab === "crop-stage" ? "#FFFFFF" : "#4CAF50",
            }}
            value="crop-stage"
          >
            Crop Stage
          </Tabs.Tab>
          <Tabs.Tab
            style={{
              backgroundColor: activeTab === "variety" ? "#4CAF50" : "#FFFFFF",
              color: activeTab === "variety" ? "#FFFFFF" : "#4CAF50",
            }}
            value="variety"
          >
            Variety
          </Tabs.Tab>
          <Tabs.Tab
            style={{
              backgroundColor:
                activeTab === "fertilizer" ? "#4CAF50" : "#FFFFFF",
              color: activeTab === "fertilizer" ? "#FFFFFF" : "#4CAF50",
            }}
            value="fertilizer"
          >
            Fertilizer
          </Tabs.Tab>
          <Tabs.Tab
            style={{
              backgroundColor:
                activeTab === "pesticide" ? "#4CAF50" : "#FFFFFF",
              color: activeTab === "pesticide" ? "#FFFFFF" : "#4CAF50",
            }}
            value="pesticide"
          >
            Pesticide
          </Tabs.Tab>
          <Tabs.Tab
            style={{
              backgroundColor: activeTab === "state" ? "#4CAF50" : "#FFFFFF",
              color: activeTab === "state" ? "#FFFFFF" : "#4CAF50",
            }}
            value="state"
          >
            State
          </Tabs.Tab>
          <Tabs.Tab
            style={{
              backgroundColor: activeTab === "district" ? "#4CAF50" : "#FFFFFF",
              color: activeTab === "district" ? "#FFFFFF" : "#4CAF50",
            }}
            value="district"
          >
            District
          </Tabs.Tab>
          <Tabs.Tab
            style={{
              backgroundColor: activeTab === "block" ? "#4CAF50" : "#FFFFFF",
              color: activeTab === "block" ? "#FFFFFF" : "#4CAF50",
            }}
            value="block"
          >
            Block
          </Tabs.Tab>
        </Tabs.List>
        {Page()}
      </Tabs>
    </Box>
  );
}
