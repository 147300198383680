import { Button, Modal, Group, Text, Box, Grid } from "@mantine/core";
import { IconUserCancel, IconUserCheck } from "@tabler/icons-react";
import { useAppDispatch } from "../Services/hooks/hooks";
import { updateUserDetailsAction } from "../Redux/User/UserActions";
import { useNavigate } from "react-router-dom";
import BookingAPI from "../Redux/Booking/Services";
import { TextInput } from "@mantine/core";
import { useRef, useState } from "react";
import KycAPI from "../Redux/Kyc/Services";
import { Loader } from "@mantine/core";
type Props = {
  id: string;
  show: boolean;
  type: string;
  payload: any;
  statusType: string;
  handleClose: () => void;
};
const ApproveModal: React.FC<Props> = ({
  id,
  show,
  type,
  payload,
  statusType,
  handleClose,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [error, setError] = useState("");
  const [otp1, setOtp1] = useState<any>(["", "", "", ""]);
  const inputRef = useRef<any>([]);
  const [isloading, setloading] = useState<any>(false);
  async function handleSubmit() {
    let check = true;
    setloading(true)
    if (type === "User") {

      if (statusType === "approve") {
        let params = {
          _id: id,
          account_status: "approved",
        };
        dispatch(updateUserDetailsAction(params));
      } else if (statusType === "reject") {
        let params = {
          _id: id,
          account_status: "rejected",
        };
        dispatch(updateUserDetailsAction(params));
      }
      setloading(false)
      setTimeout(() => {
        handleClose();
      }, 1500);
      navigate("/admin/farmer-management");
    } else if (type === "Order") {
      setloading(true);

      if (statusType === "assign") {
        let params = {
          id: id,
          booking_status: "approved",
          operator_id: payload?.operator_id,
          date: new Date(payload?.date).toDateString(),
          time: payload?.time,
        };
        await BookingAPI.updateBooking(params);
      } else if (statusType === "reject") {
        let params = {
          id: id,
          booking_status: "rejected",
        };
        await BookingAPI.updateBooking(params);
        // dispatch(updateBookingAction(payload));
      } else if (statusType === "complete") {
        let tempOTP = `${otp[0]}${otp[1]}${otp[2]}${otp[3]}`;

        let params = {
          id: id,
          booking_status: "completed",
          otp: parseInt(tempOTP),
        };
        try {
          await BookingAPI.updateBooking(params);
          setError("");
        } catch (e: any) {
          check = false;
          setError(e.message);
        }
      }
      else if (statusType === "cancel") {
        let params = {
          id: id,
          booking_status: "cancel",
        };
        await BookingAPI.updateBooking(params);
      }
      if (check) {
        setTimeout(() => {
          handleClose();
        }, 1500);
        navigate("/admin/booking-management");
      }
    } else if (type === "KYC") {
      await KycAPI.updateKyc(payload);
      navigate(-1);
    }
    setloading(false);
  }
  const handleChange = (index: any, event: any) => {
    const value = event.target.value;
    if (isNaN(value)) {
      return;
    }
    const otpArray = otp.slice();
    otpArray[index] = value;
    setOtp(otpArray);

    if (index < 3 && value) {
      inputRef.current[index + 1].focus();
    } else if (index === 3 && value) {
      inputRef.current[index].blur();
      // add your code to automatically shift to next tab here
    }
  };

  return (
    <Modal
      centered
      radius="lg"
      padding="xl"
      opened={show}
      onClose={handleClose}
      withCloseButton={false}
    >
      <Box
        style={{
          textAlign: "center",
        }}
      >
        {statusType === "approve" ||
        statusType === "assign" ||
        statusType === "complete" ? (
          <IconUserCheck size={50} color="#2C752F" />
        ) : (
          <IconUserCancel size={50} color="#FA5252" />
        )}

        {statusType === "complete" ? (
          <>
            <Grid
              style={{
                width: "100%",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              <Grid.Col span={2}>
                <TextInput
                  type="text"
                  maxLength={1}
                  value={otp[0]}
                  onChange={(e) => handleChange(0, e)}
                  ref={(el) => (inputRef.current[0] = el)}
                  withAsterisk
                />
              </Grid.Col>
              <Grid.Col span={2}>
                <TextInput
                  type="text"
                  maxLength={1}
                  value={otp[1]}
                  onChange={(e) => handleChange(1, e)}
                  ref={(el) => (inputRef.current[1] = el)}
                />
              </Grid.Col>
              <Grid.Col span={2}>
                <TextInput
                  type="text"
                  maxLength={1}
                  value={otp[2]}
                  onChange={(e) => handleChange(2, e)}
                  ref={(el) => (inputRef.current[2] = el)}
                />
              </Grid.Col>

              <Grid.Col span={2}>
                <TextInput
                  type="text"
                  maxLength={1}
                  value={otp[3]}
                  onChange={(e) => handleChange(3, e)}
                  ref={(el) => (inputRef.current[3] = el)}
                />
              </Grid.Col>
            </Grid>

            {/* <Grid style={{ width: "100%", textAlign: "center", justifyContent: "center" }} >

                <Grid.Col span={2}>
                  <TextInput
                    onChange={(val) => {
                      let temp = [...otp];
                      temp[0] = val.target.value;
                      setOtp( temp);
                    }}
                    withAsterisk
                  />
                </Grid.Col>
                <Grid.Col span={2}>
                  <TextInput
                    onChange={(val) => {
                      let temp = [...otp];
                      temp[1] = val.target.value;
                      setOtp( temp);
                    }}
                    withAsterisk
                  />
                </Grid.Col>
                <Grid.Col span={2}>
                  <TextInput
                    onChange={(val) => {
                      let temp = [...otp];
                      temp[2] = val.target.value;
                      setOtp( temp);
                    }}
                    withAsterisk
                  />
                </Grid.Col>
                <Grid.Col span={2}>
                  <TextInput
                    onChange={(val) => {
                      let temp = [...otp];
                      temp[3] = val.target.value;
                      setOtp( temp);
                    }}
                    withAsterisk
                  />
                </Grid.Col>
              </Grid> */}

            <div style={{ color: "red" }}>{error}</div>
          </>
        ) : (
          <></>
        )}
        <Text style={{ textAlign: "center", paddingTop: 10 }}>
          Are you sure you want to {statusType} this {type}?
        </Text>
        <Group
          style={{
            justifyContent: "center",
            paddingTop: 25,
          }}
        >
          <Button
            onClick={handleClose}
            style={{
              width: "30%",
              background: "#D8D8D8",
              borderRadius: "8px",
              marginRight: 20,
            }}
          >
            { statusType === "cancel"
                ? "No" : "Cancel"}
          </Button>

          {isloading ? (
            <Button style={{backgroundColor:"green"}} >
              <Loader size="xs"  />
            </Button>
          ) : (
            <Button
              onClick={() => {
                handleSubmit();
              }}
              style={{
                width: "30%",
                background:
                  statusType === "approve" ||
                  statusType === "assign" ||
                  statusType === "complete" ||
                  statusType === "cancel"
                    ? "#2C752F"
                    : "#FA5252",
                borderRadius: "8px",
              }}>
              {statusType === "approve"
                ? "Approve"
                : statusType === "assign"
                ? "Assign"
                : statusType === "complete"
                ? "Complete"
                : statusType === "cancel"
                ? "Yes"
                : "Reject"}
            </Button>
          )}
        </Group>
      </Box>
    </Modal>
  );
};
export default ApproveModal;
