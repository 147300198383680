/* eslint-disable arrow-body-style */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
/* eslint no-param-reassign: "error" */

export const requestInterceptor = (config: any) => {
  let STORAGE_KEY = "auth";
  let token = localStorage.getItem(STORAGE_KEY);
  if (token) {
    config.headers[`Authorization`] = `${token}`;
  }
  return config;
};

export const responseInterceptor = (response: any) => {
  return response.data;
};
