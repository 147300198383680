import axios from "axios";
import { API_BASE_URL } from "../Config";
import { requestInterceptor, responseInterceptor } from "./interceptor";
// import AsyncStorage from '@react-native-async-storage/async-storage';

const apiInstance = axios.create({
  baseURL: API_BASE_URL,
});
apiInstance.interceptors.request.use(requestInterceptor);
apiInstance.interceptors.response.use(
  function (response: any) {
    return responseInterceptor(response);
  },
  async function (error) {
    if (error.response.status === 401) {
      localStorage.clear();
      localStorage.setItem("token_status", "false");
      window.location.replace("/");
    }
    throw error?.response?.data ? error?.response?.data : error;
  }
);
export default apiInstance;
