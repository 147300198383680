import apiInstance from "../../Services";
import { UserLogin } from "./Interface";

const LoginAPI = {
  // eslint-disable-next-line class-methods-use-this
  userLogin: async (payload: UserLogin) => {
    try {
      const res = await apiInstance.get<UserLogin>(
        `/admin/login?email=${payload.email}&password=${payload.password}`
      );
      return res;
    } catch (error) {
      throw error;
    }
  },
};

export default LoginAPI;
