import apiInstance from "../../../Services";
import { create, deleteData, update } from "./Interface";

const ConfigurationVarietyAPI = {
  // eslint-disable-next-line class-methods-use-this
  createVariety: async (payload: create) => {
    try {
      const res = await apiInstance.post<any>(`/variety/create`, payload);
      return res;
    } catch (error) {
      throw error;
    }
  },

  updateVariety: async (payload: update) => {
    try {
      const res = await apiInstance.put<any>(
        `/variety/update?id=${payload.id}`,
        payload
      );
      return res;
    } catch (error) {
      throw error;
    }
  },
  getVarietybyId: async (payload: any) => {
    try { 
      console.log("8979879879",payload)
      const res = await apiInstance.get<any>(
        `/variety/getallType?id=${payload}`
      );
      return res;
    } catch (error) {
      throw error;
    }
  },

  getVarietyList: async (payload: any) => {
    try {
      const res = await apiInstance.get<any>(
        `/variety/getallList?skip=${payload.skip}&limit=${payload.limit}&filter=${payload.filter}`
      );
      return res;
    } catch (error) {
      throw error;
    }
  },

  deleteVariety: async (payload: deleteData) => {
    try {
      const res = await apiInstance.put<deleteData>(
        `/variety/delete?id=${payload.id}&status=${payload.status}`
      );
      return res;
    } catch (error) {
      throw error;
    }
  },
};

export default ConfigurationVarietyAPI;
