import apiInstance from "../../Services";
import { DeleteUser, GetUser, GetUserByStatus, UpdateUser } from "./Interface";

const UserAPI = {
  // eslint-disable-next-line class-methods-use-this
  getUserList: async (payload: GetUser) => {
    try {
      const res = await apiInstance.get<any>(
        `/user/getall?skip=${payload.skip}&limit=${payload.limit}&filter=${payload.filter}`
      );
      return res;
    } catch (error) {
      throw error;
    }
  },

  getUserListByAccountStatus: async (payload: GetUserByStatus) => {
    try {
      const res = await apiInstance.get<any>(
        `/user/userByAccountStaus?skip=${payload.skip}&limit=${payload.limit}&status=${payload.status}&filter=${payload.filter}&state=${payload.state}&district=${payload.district}&block=${payload.block}`
      );
      return res;
    } catch (error) {
      throw error;
    }
  },

  updateUser: async (payload: UpdateUser) => {
    try {
      const res = await apiInstance.put<UpdateUser>(
        `/user/update?id=${payload._id}`,
        payload
      );
      return res;
    } catch (error) {
      throw error;
    }
  },

  deleteUser: async (payload: DeleteUser) => {
    try {
      const res = await apiInstance.put<DeleteUser>(
        `/user/delete?id=${payload._id}&status=${payload.status}`
      );
      return res;
    } catch (error) {
      throw error;
    }
  },

  permanentDeleteUser: async (payload: { id: string }) => {
    try {
      const res = await apiInstance.delete<DeleteUser>(
        `/user/delete?id=${payload.id}`
      );
      return res;
    } catch (error) {
      throw error;
    }
  },
};

export default UserAPI;
