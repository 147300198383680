import { useEffect, useState } from "react";
import { Grid, Paper, Title, Button, Select, MultiSelect, Autocomplete, TextInput, Loader } from "@mantine/core";
import { useAppSelector } from "../../Services/hooks/hooks";
import PermissionCheck from "../../Components/Permission";
import { DateInput } from "@mantine/dates";
import { IconChevronDown, IconCircleX } from "@tabler/icons-react";
import { useFormik } from "formik";
import * as Yup from "yup";
import BookingAPI from "../../Redux/Booking/Services";
import NestedHeaderToCSV from "./PrintReport";
import ConfigurationCropStageAPI from "../../Redux/Configuration/CropStage/Services";
import ConfigurationFertilizerAPI from "../../Redux/Configuration/Fertilizer/Services";
import BlockAPI from "../../Redux/Configuration/Block/Services";
import DistrictAPI from "../../Redux/Configuration/District/Services";
import StateAPI from "../../Redux/Configuration/State/Services";

const ReportSchema = Yup.object().shape({
  drone_id: Yup.array().min(1, "Drone Id is required"),
  from: Yup.string().required("From date is required"),
  to: Yup.string().required("To date is required"),
});

interface generate {
  drone_id?: string[];
  from?: string;
  to?: string;
  operator_id?: string[];
}

export default function Report() {
  const { data } = useAppSelector((state) => state.auth);
  const permissionList = data.data.permission[0].permission_list;
  const [initialValues, setInitialValues] = useState<generate>({
    drone_id: [],
    from: "",
    to: "",
    operator_id: []
  });
  const [droneList, setDroneList] = useState<{ value: any; label: any }[]>([]);
  const [error, setError] = useState<any>("");
  const [cropAgeList, setCropAgeList] = useState([]);
  const [stateList, setStateList] = useState<any>([]);
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [districtList, setDistrictList] = useState<any>([]);
  const [blockList, setBlockList] = useState<any>([]);
  const [selectedBlock, setSelectedBlock] = useState("");
  const [operatorList, setOperatorList] = useState<any>([]);

  async function getStateList() {
    let data = await StateAPI.getStateAllList();
    let tempList: { value: any; label: any }[] = [];
    data?.data.map((val: any) => {
      tempList.push({
        value: val.name,
        label: val.name,
      });
    });
    setStateList(tempList);
  }

  async function getDistrictListBasedOnState(state: any) {
    let data = await DistrictAPI.getDistrictAllList({
      state: state,
    });
    let tempList: { value: any; label: any }[] = [];
    data?.data.map((val: any) => {
      tempList.push({
        value: val.name,
        label: val.name,
      });
    });
    setDistrictList(tempList);
  }

  async function getBlockListBasedOnState(state: any, district: any) {
    let data = await BlockAPI.getBlocAllList({
      state: state,
      district: district
    });
    let tempList: { value: any; label: any }[] = [];
    data?.data.map((val: any) => {
      tempList.push({
        value: val.name,
        label: val.name,
      });
    });
    setBlockList(tempList);
  }

  useEffect(() => {
    getStateList();
  }, []);

  async function getDroneList() {
    let data = (await BookingAPI.getAllDroneList()).data;
    let tempList: { value: any; label: any }[] = [];
    data.map((res: any) => {
      tempList.push({
        value: res._id,
        label: res.drone_id,
      });
    });
    setDroneList(tempList);
  }

  async function getOperatorList(operator_id: string[]) {
    try{
    let data = (await BookingAPI.getOperatorListBasedOnDroneId({id:operator_id})).data;
    let tempList: { value: any; label: any }[] = [];
    data.map((res: any)=>{
      res?.operator_details ?.map((operatorData: any) =>{
        tempList.push({
              value: operatorData._id,
              label: operatorData.name,
            });
      });
    });
  
    setOperatorList(tempList);
  }catch(e: any){
    setOperatorList([]);
  }

    // data.map((res: any) => {
    //   tempList.push({
    //     value: res._id,
    //     label: res.drone_id,
    //   });
    // });
    // setDroneList(tempList);
  }

  useEffect(() => {
    getDroneList();
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: ReportSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      // exportToCsv(values);
    },
  });

  //Download
  const [downloadLoading, setDownloadLoading] = useState(false);


  async function getCropAge() {
    try {
      let cropAgeDetails = await ConfigurationCropStageAPI.getAllCropStageList();
      setCropAgeList(cropAgeDetails.data);
    } catch (e) {
      setCropAgeList([]);
    }
  }

  useEffect(() => {
    getCropAge();
  }, []);


  const [fertilizerList, setFertilizerList] = useState([]);
  async function getFertilizer() {    
    const brand = (await ConfigurationFertilizerAPI.getAllFertilizerList()).data;
    setFertilizerList(brand);
    return brand
  }

  useEffect(()=>{
    getFertilizer();
  },[])



  const generateReport = async (values: any) => {
    if (initialValues?.drone_id && initialValues?.drone_id?.length > 0 && initialValues.from !== "" && initialValues.to !== "") {
      setDownloadLoading(true);

      let payload = {
        id: initialValues?.drone_id,
        fromDate: initialValues.from,
        toDate: initialValues.to,
        state: selectedState,
        district: selectedDistrict,
        block: selectedBlock,
        operator_id: initialValues?.operator_id
      };

      await BookingAPI.generateReport(payload)
        .then(async (res: any) => {

          const mappedData = [
            {},
            {},
            {},
            ...res.data.map((item: any) => ({
              bookingDate: new Date(
                item?.date
              )?.toLocaleDateString("en-GB") || "-",
              farmerName: item?.farmer?.name || "-",
              mobileNumber: item?.farmer?.phone_no || "-",
              address: item?.farm_land_address || "-",
              dateOfSpraying:
                new Date(
                  item?.orderreports?.work_start_date
                )?.toLocaleDateString("en-GB") || "-",
              crop: item?.orderreports?.cultivation_details?.crop || "-",
              variety: item?.orderreports?.cultivation_details?.variety || "-",
              cropStage: cropAgeList?.find((data: any) => data._id === item?.orderreports?.cultivation_details?.age)?.["name"] || "-",
              totalAreaSprayed:
                item?.orderreports?.cultivation_details?.farm_land_area || "-",
              fertilizer:
                item?.orderreports?.cultivation_details?.fertilizer || "-",
              otherFertilizer:
                item?.orderreports?.cultivation_details?.other_fertilizer ||
                "-",
                 otherFertilizerBrandName:
              item?.orderreports?.cultivation_details?.other_fertilizer ? fertilizerList.find((data: any)=> data.name === item?.orderreports?.cultivation_details?.other_fertilizer)?.["brand_name"] || "-" :  "-",
              pesticideType: item?.pesticide?.type || "-",
              pesticideBrandName: item?.pesticide?.brand_name || "-",
              pesticideCommercialName: item?.pesticide?.product_name || "-",
              pesticideChemicalName: item?.pesticide?.chemical_name || "-",
              requireNextSpray: item?.orderreports?.next_spray ? "Yes" : "No",
              tentativeDateOfNextSpray: item?.orderreports?.next_spray
                ? new Date(item?.orderreports?.next_spray_date
                )?.toLocaleDateString("en-GB") ||
                "-"
                : "-",
            })),
          ];

          NestedHeaderToCSV(mappedData);
          // fileDownload(dataString, `${"Order-report"}-${".csv"}`);
          // setSnackBar({
          //   ...snackbarState,
          //   open: true,
          //   messege: "Reports Download Successfully",
          //   severity: "success",
          // });
          setDownloadLoading(false);
        })
        .catch((error: any) => {
          setDownloadLoading(false);
          setError(error.message);
          // setSnackBar({
          //   ...snackbarState,
          //   open: true,
          //   messege: "Something went wrong while Downloading",
          //   severity: "error",
          // });
        });
    }
  };

  const Styles = {
    paper: {
      width: "100%",
      height: "100%",
      backgroundColor: "#FFFFFF",
      padding: 20,
      borderRadius: 20,
    },
  };


  return (
    <div>
      <Grid style={{ minHeight: "40vh" }}>
        <Grid.Col span={12}>
          <Paper style={Styles.paper}>
            <Grid>
              <Grid.Col xs={12} sm={12} md={12} lg={12}>
                <Title
                  style={{
                    fontSize: 30,
                    fontWeight: 300,
                    color: "#4C9C4F",
                    marginBottom: "2%",
                  }}
                >
                  Report Management
                </Title>
              </Grid.Col>
             
            </Grid>
            {error !== "" ? <div
              style={{ backgroundColor: "red", color: "white", padding: "5px", marginBottom: 20, display: 'flex', fontSize: '13px' }}
            >
              <IconCircleX size="20px" style=
                {{ marginRight: '10px' }} /> {error}
            </div> : ""}
            {PermissionCheck(permissionList, "Report_Management", "read") ? (
              <form onSubmit={formik.handleSubmit}>
                <Grid>
                  <Grid.Col xs={12} sm={12} md={3} lg={3}>
                    <MultiSelect
                      label="Drone Id"
                      placeholder="Select Drone Id"
                      withAsterisk
                      searchable
                      clearable
                      multiple
                      rightSection={<IconChevronDown size="1rem" />}
                      rightSectionWidth={30}
                      styles={{ rightSection: { pointerEvents: "none" } }}
                      nothingFound="No data"
                      data={droneList}
                      maxDropdownHeight={200}
                      onChange={(val) => {
                        //setSelectedDrone(val);
                        setError("");
                        getOperatorList(val);
                        setInitialValues({
                          ...initialValues,
                          drone_id: val ? val : [],
                          operator_id: []
                        });
                        // setDroneId(val);
                      }}
                    />
                    {formik.touched.drone_id && formik.errors.drone_id && (
                      <span role="alert" style={{ color: "red", fontSize: 12 }}>
                        {formik.errors.drone_id}
                      </span>
                    )}
                  </Grid.Col>
                  <Grid.Col xs={12} sm={12} md={3} lg={3}>
                    <MultiSelect
                      label="Operator Id"
                      placeholder="Select Operator ID"
                      searchable
                      clearable
                      multiple
                      rightSection={<IconChevronDown size="1rem" />}
                      rightSectionWidth={30}
                      styles={{ rightSection: { pointerEvents: "none" } }}
                      nothingFound="No data"
                      value={initialValues?.operator_id ?? []}
                      data={operatorList}
                      maxDropdownHeight={200}
                      onChange={(val) => {
                        //setSelectedDrone(val);
                        setError("");
                        setInitialValues({
                          ...initialValues,
                          operator_id: val ? val : [],
                        });
                        // setDroneId(val);
                      }}
                    />
                  
                  </Grid.Col>

                  <Grid.Col xs={12} sm={12} md={3} lg={3}>
                    <DateInput
                      valueFormat="DD-MM-YYYY"
                      label="From Date"
                      placeholder="Select From Date"
                      withAsterisk
                      onChange={(val) => {
                        var date = new Date(val ? val : "");
                        date.setDate(date.getDate());
                        setInitialValues({
                          ...initialValues,
                          from: date.toDateString(),
                        });
                        setError("");
                      }}
                    />
                    {formik.touched.from && formik.errors.from && (
                      <span role="alert" style={{ color: "red", fontSize: 12 }}>
                        {formik.errors.from}
                      </span>
                    )}
                  </Grid.Col>

                  <Grid.Col xs={12} sm={12} md={3} lg={3}>
                    <DateInput
                      valueFormat="DD-MM-YYYY"
                      label="To Date"
                      placeholder="Select To Date"
                      withAsterisk
                      onChange={(val) => {
                        var date = new Date(val ? val : "");
                        date.setDate(date.getDate() + 1);
                        setInitialValues({
                          ...initialValues,
                          to: date?.toDateString(),
                        });
                        setError("");
                      }}
                    />

                    {formik.touched.to && formik.errors.to && (
                      <span role="alert" style={{ color: "red", fontSize: 12 }}>
                        {formik.errors.to}
                      </span>
                    )}
                  </Grid.Col>
                  <Grid.Col xs={12} sm={12} md={3} lg={3} >
                <Autocomplete
                  label="State"
                  placeholder="Filter by State"
                  sx={{ maxHeight: 200, overflow: "auto" }}
                  data={stateList}
                  rightSection={<IconChevronDown size="1rem" />}
                  value={selectedState}
                  nothingFound="No options"
                  onChange={(e) => {
                    setSelectedState(e);
                    setSelectedBlock("");
                    setSelectedDistrict("");
                    getDistrictListBasedOnState(e);
                  }}
                />
              </Grid.Col>
              <Grid.Col xs={12} sm={12} md={3} lg={3} >
                <Autocomplete
                 label="District"
                  placeholder="Filter by District"
                  sx={{ maxHeight: 200, overflow: "auto" }}
                  data={districtList}
                  value={selectedDistrict}
                  rightSection={<IconChevronDown size="1rem" />}
                  nothingFound="No options"
                  onChange={(e) => {
                    setSelectedDistrict(e);
                    setSelectedBlock("");
                    getBlockListBasedOnState(selectedState, e);
                  }}
                />
              </Grid.Col>
              <Grid.Col xs={12} sm={12} md={3} lg={3} >
                <Autocomplete
                label="Block"
                  placeholder="Filter by Block"
                  sx={{ maxHeight: 200, overflow: "auto" }}
                  data={blockList}
                  nothingFound="No options"
                  value={selectedBlock}
                  rightSection={<IconChevronDown size="1rem" />}
                  onChange={(e) => {
                    setSelectedBlock(e);
                  }}
                //   value={value}
                //   onChange={setValue}
                />
              </Grid.Col>
                  <Grid.Col xs={12} sm={12} md={3} lg={3}>
                    <Button
                      type="submit"
                      disabled={downloadLoading}
                      fullWidth
                      style={{ background: "#4CAF50", marginTop: "25px" }}
                      onClick={generateReport}
                    >
                      {
                        downloadLoading?  <Loader size="xs" /> : <>Generate Report</>
                      }
                      
                    </Button>
                  </Grid.Col>
                </Grid>
              </form>
            ) : (
              <></>
            )}
          </Paper>
        </Grid.Col>
      </Grid>
    </div>
  );
}
