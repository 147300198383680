
import apiInstance from "../../../Services";
import { create, deleteData, update } from "./Interface";

const BlockAPI = {
  // eslint-disable-next-line class-methods-use-this
  createBlock: async (payload: create) => {
    try {
      const res = await apiInstance.post<any>(`/Block/create`, payload);
      return res;
    } catch (error) {
      throw error;
    }
  },

  updateBlock: async (payload: update) => {
    try {
      const res = await apiInstance.put<any>(`/Block/update?id=${payload.id}`, payload);
      return res;
    } catch (error) {
      throw error;
    }
  },
  
  getBlockList: async (payload: any) => {
    try {
      const res = await apiInstance.get<any>(
        `/Block/getall?skip=${payload.skip}&limit=${payload.limit}&filter=${payload.filter}&status=${payload.status}`
      );
      return res;
    } catch (error) {
      throw error;
    }
  },

  getBlocAllList: async (payload: any) => {
    try {
      const res = await apiInstance.get<any>(
        `/block/getallList?state=${payload.state}&district=${payload.district}`
      );
      return res;
    } catch (error) {
      throw error;
    }
  },
  
  deleteBlock: async (payload: deleteData) => {
    try {
      const res = await apiInstance.put<deleteData>(
        `/Block/delete?id=${payload.id}&status=${payload.status}`
      );
      return res;
    } catch (error) {
      throw error;
    }
  },
};

export default BlockAPI;
