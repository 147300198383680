import apiInstance from "../../Services";
import { GetList } from "./Interface";

const DealerApi = {
  getDealerList: async (payload: GetList) => {
    try {
      const res = await apiInstance.get<any>(
        `dealer/list?limit=${payload.limit}&skip=${payload.skip}&state=${payload.state}&district=${payload.district}&block=${payload.block}&filter=${payload.filter}&status=${payload.status}`
      );
      return res;
    } catch (err) {
      throw err;
    }
  },

  getDealer: async (payload: GetList) => {
    try {
      const res = await apiInstance.get<any>(
        `dealer/list?limit=${payload.limit}&skip=${payload.skip}&state=${payload.state}&district=${payload.district}&block=${payload.block}&filter=${payload.filter}`
      );
      return res;
    } catch (err) {
      throw err;
    }
  },

  deleteDealer: async (payload: {id: string, status: any}) => {
    try {
      const res = await apiInstance.put<any>(
        `dealer/delete?id=${payload.id}&status=${payload.status}`
      );
      return res;
    } catch (err) {
      throw err;
    }
  },


};

export default DealerApi;
