import React, { useEffect, useState } from "react";
import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import { Button, Modal, Box, ScrollArea, Text } from "@mantine/core";
import * as _ from "lodash";
import CustomLoader from "../../Components/CustomLoader";

type Props = {
  id?: string;
  show: boolean;
  handleClose: () => void;
  address: string;
  setAddress: any;
  location: any;
  setLocation: any;
};

const MapModal: React.FC<Props> = ({
  id,
  show,
  handleClose,
  address,
  setAddress,
  location,
  setLocation,
}) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyABs90Wh7N3ug2WsnACJcuqlpHVtPCBWIs",
  });

  const [selectedLocation, setSelectedLocation] = useState({
    lat: 0,
    lng: 0,
  });

  const latitude = selectedLocation.lat;
  const longitude = selectedLocation.lng;

  async function getMarkedLocation(lat: any, lng: any) {
    let markedAddress = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyABs90Wh7N3ug2WsnACJcuqlpHVtPCBWIs`
    )
      .then((res) => {
        return res.text();
      })
      .then((res) => {
        return JSON.parse(res)["results"][0]["formatted_address"];
      });
    setAddress(markedAddress);
    return markedAddress;
  }

  useEffect(() => {
    getMarkedLocation(latitude, longitude);
  }, [address, latitude, longitude]);

  // const geocoder = new window.google.maps.Geocoder();

  // const latLng = new window.google.maps.LatLng(
  //   selectedLocation.lat,
  //   selectedLocation.lng
  // );

  // geocoder.geocode({ location: latLng }, (results, status) => {
  //   if (status === "OK") {
  //     if (results) {
  //       setAddress(results[0].formatted_address);
  //     } else {
  //       console.log("No results found");
  //     }
  //   } else {
  //     console.log(`Geocoder failed: ${status}`);
  //   }
  // });

  const containerStyle = {
    height: "450px",
  };

  const [center, setCenter] = useState({
    lat: 11.020507,
    lng: 76.992662,
  });

  function handleMapClick(event: any) {
    setSelectedLocation({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
    const coordinates = [event.latLng.lat(), event.latLng.lng()];
    setCenter({ lat: event.latLng.lat(), lng: event.latLng.lng() });
    setLocation(coordinates);
  }

  async function handleSubmit(data: any) {
    handleClose();
  }

  return (
    <Modal
      centered
      size="100%"
      radius="lg"
      opened={show}
      onClose={handleClose}
      withCloseButton={true}
      scrollAreaComponent={ScrollArea.Autosize}
    >
      <Box>
        {isLoaded ? (
          <>
            <GoogleMap
              options={{
                mapTypeControl: false,
                mapId: "7341c6b33c1f9404",
              }}
              mapContainerStyle={containerStyle}
              center={center}
              zoom={11}
              onClick={handleMapClick}
            >
              <MarkerF position={selectedLocation} />
            </GoogleMap>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 20,
              }}
            >
              <Text>{address}</Text>
              <Button onClick={handleSubmit}>Confirm</Button>
            </Box>
          </>
        ) : (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "50vh",
            }}
          >
            <CustomLoader />
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default MapModal;
