import { createSlice } from "@reduxjs/toolkit";
import { State } from "./Interface";

const initialState: State = {
  loading: false,
  error: null,
  data: {
    message: "",
    data: { count: 0, data: [] },
    status: false,
    code: 0,
  },
  notify: false,
  notifymsg: "",
};

export const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    setOrderDetailsLoading: (state) => {
      state.loading = true;
    },
    setOrderDetailsSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload;
      state.notify = false;
      state.notifymsg = "";
    },
    setGetOrderListSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload;
      state.notifymsg = action.payload;
      state.notify = false;
    },
    setUpdateOrderSuccess: (state, action) => {
      state.error = null;
      state.notifymsg = action.payload;
      state.notify = true;
    },
    // setGetBookingListSuccess: (state, action) => {
    //   state.loading = false;
    //   state.error = null;
    //   state.data = action.payload;
    //   state.notifymsg = action.payload;
    //   state.notify = true;
    // },
    // setGetBookingListFailed: (state, action) => {
    //   state.loading = false;
    //   state.error = null;
    //   state.data = action.payload;
    //   state.notifymsg = action.payload;
    //   state.notify = true;
    // },
    // setUpdateBookingLoading: (state) => {
    //   state.loading = true;
    // },
    // setUpdateBookingSuccess: (state, action) => {
    //   state.loading = false;
    //   state.error = null;
    //   state.data = action.payload;
    //   state.notifymsg = action.payload;
    //   state.notify = true;
    // },
    // setUpdateBookingFailed: (state, action) => {
    //   state.loading = false;
    //   state.error = null;
    //   state.data = action.payload;
    //   state.notifymsg = action.payload;
    //   state.notify = true;
    // },
    setOrderDetailsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.notify = true;
    },
    setNotifyFalse: (state, action) => {
      state.notifymsg = "";
      state.notify = false;
      state.error = null;
    },
  },
});

export const {
  // setGetBookingListLoading,
  // setGetBookingListSuccess,
  // setGetBookingListFailed,
  // setUpdateBookingLoading,
  // setUpdateBookingSuccess,
  // setUpdateBookingFailed,
  setOrderDetailsLoading,
  setOrderDetailsSuccess,
  setGetOrderListSuccess,
  setUpdateOrderSuccess,
  setOrderDetailsFailure,
  setNotifyFalse,
} = bookingSlice.actions;

export default bookingSlice.reducer;
