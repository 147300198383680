import { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Title,
  Tabs,
  Box,
  TextInput,
  Autocomplete,
} from "@mantine/core";
import UserTable from "./UserTable";
import CustomLoader from "../../Components/CustomLoader";
import UserAPI from "../../Redux/User/Services";
import { useAppSelector } from "../../Services/hooks/hooks";
import PermissionCheck from "../../Components/Permission";
import StateAPI from "../../Redux/Configuration/State/Services";
import DistrictAPI from "../../Redux/Configuration/District/Services";
import BlockAPI from "../../Redux/Configuration/Block/Services";
import { IconCircleX, IconCheck } from "@tabler/icons-react";

export default function UserManagement() {
  const [activeTab, setActiveTab] = useState<string | null>("all");
  const [pagination, setPagination] = useState({
    skip: 0,
    limit: 10,
  });
  const [pageSelection, setPageSelection] = useState(1);
  const [totalCount, setTotalCount] = useState(10);
  const [searchText, setSearchText] = useState("");

  const Styles = {
    paper: {
      width: "100%",
      backgroundColor: "#FFFFFF",
      padding: 20,
      borderRadius: 20,
    },
  };

  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [stateList, setStateList] = useState<any>([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [districtList, setDistrictList] = useState<any>([]);
  const [blockList, setBlockList] = useState<any>([]);
  const [selectedBlock, setSelectedBlock] = useState("");
  const [sparyedCount, setSparyedCount] = useState<any>([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  async function getStateList() {
    let data = await StateAPI.getStateAllList();
    let tempList: { value: any; label: any }[] = [];
    data?.data.map((val: any) => {
      tempList.push({
        value: val.name,
        label: val.name,
      });
    });
    setStateList(tempList);
  }

  async function getDistrictListBasedOnState(state: any) {
    let data = await DistrictAPI.getDistrictAllList({
      state: state,
    });
    let tempList: { value: any; label: any }[] = [];
    data?.data.map((val: any) => {
      tempList.push({
        value: val.name,
        label: val.name,
      });
    });
    setDistrictList(tempList);
  }

  async function getBlockListBasedOnState(state: any, district: any) {
    console.log(state, " district", district);
    let data = await BlockAPI.getBlocAllList({
      state: state,
      district: district,
    });
    let tempList: { value: any; label: any }[] = [];
    data?.data.map((val: any) => {
      tempList.push({
        value: val.name,
        label: val.name,
      });
    });
    setBlockList(tempList);
  }

  useEffect(() => {
    getStateList();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setError("");
      setSuccess("");
    }, 10000);
  }, [error, success]);

  async function getUserList() {
    try {
      let params = {
        ...pagination,
        status: activeTab,
        filter: searchText,
        state: selectedState,
        district: selectedDistrict,
        block: selectedBlock,
      };
      setIsLoading(true);
      let data = (await UserAPI.getUserListByAccountStatus(params)).data;
      setUserData(data?.data[0].data);
      setTotalCount(
        data?.data[0]?.count?.count ? data?.data[0]?.count?.count : 0
      );
      setSparyedCount(data?.SprayedCounts);
      setIsLoading(false);
    } catch (e) {
      setUserData([]);
      setTotalCount(0);
      setSparyedCount([]);
      setIsLoading(false);
    }
  }

  const { data } = useAppSelector((state) => state.auth);
  const permissionList = data?.data?.permission?.[0]?.permission_list
    ? data.data.permission[0].permission_list
    : [];

  useEffect(() => {
    getUserList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeTab,
    pagination,
    searchText,
    selectedState,
    selectedDistrict,
    selectedBlock,
  ]);

  return (
    <div>
      <Grid>
        <Grid.Col span={12}>
          <Paper style={Styles.paper}>
            <Grid>
              <Grid.Col xs={12} sm={4} md={4} lg={4}>
                <Title
                  style={{
                    fontSize: 30,
                    fontWeight: 300,
                    color: "#4C9C4F",
                  }}
                >
                  Farmer Management
                </Title>
              </Grid.Col>
              <Grid.Col xs={12} sm={2} md={2} lg={2}>
                <Autocomplete
                  placeholder="Filter by State"
                  sx={{ maxHeight: 200, overflow: "auto" }}
                  data={stateList}
                  value={selectedState}
                  nothingFound="No options"
                  onChange={(e) => {
                    console.log(e);
                    setSelectedState(e);
                    setSelectedBlock("");
                    setSelectedDistrict("");
                    getDistrictListBasedOnState(e);
                  }}
                />
              </Grid.Col>
              <Grid.Col xs={12} sm={2} md={2} lg={2}>
                <Autocomplete
                  placeholder="Filter by District"
                  sx={{ maxHeight: 200, overflow: "auto" }}
                  data={districtList}
                  value={selectedDistrict}
                  nothingFound="No options"
                  onChange={(e) => {
                    console.log(e);
                    setSelectedDistrict(e);
                    setSelectedBlock("");
                    getBlockListBasedOnState(selectedState, e);
                  }}
                />
              </Grid.Col>
              <Grid.Col xs={12} sm={2} md={2} lg={2}>
                <Autocomplete
                  placeholder="Filter by Block"
                  sx={{ maxHeight: 200, overflow: "auto" }}
                  data={blockList}
                  nothingFound="No options"
                  value={selectedBlock}
                  onChange={(e) => {
                    console.log(e);
                    setSelectedBlock(e);
                  }}
                  //   value={value}
                  //   onChange={setValue}
                />
              </Grid.Col>
              <Grid.Col xs={12} sm={2} md={2} lg={2}>
                <TextInput
                  placeholder="Filter by Name"
                  value={searchText}
                  withAsterisk
                  onChange={(val) => {
                    setSearchText(val.target.value);
                  }}
                />
              </Grid.Col>
            </Grid>

            {error !== "" ? (
              <div
                style={{
                  backgroundColor: "red",
                  color: "white",
                  padding: "5px",
                  marginBottom: 20,
                  marginTop: 20,
                  display: "flex",
                  fontSize: "13px",
                }}
              >
                <IconCircleX size="20px" style={{ marginRight: "10px" }} />{" "}
                {error}
              </div>
            ) : (
              ""
            )}

            {success !== "" ? (
              <div
                style={{
                  backgroundColor: "green",
                  color: "white",
                  padding: "5px",
                  marginBottom: 20,
                  marginTop: 20,
                  display: "flex",
                  fontSize: "13px",
                }}
              >
                <IconCheck size="20px" style={{ marginRight: "10px" }} />{" "}
                {success}
              </div>
            ) : (
              ""
            )}

            {PermissionCheck(permissionList, "Farmer_Management", "read") ? (
              <Box style={{ marginTop: 30 }}>
                <Tabs
                  variant="outline"
                  value={activeTab}
                  onTabChange={(val) => {
                    setPagination({
                      skip: 0,
                      limit: 10,
                    });
                    setPageSelection(1);
                    setActiveTab(val);
                  }}
                >
                  <Tabs.List>
                    <Tabs.Tab value="all">All</Tabs.Tab>
                    <Tabs.Tab value="pending">Pending</Tabs.Tab>
                    <Tabs.Tab value="approved">Approved</Tabs.Tab>
                    <Tabs.Tab value="rejected">Rejected</Tabs.Tab>
                  </Tabs.List>

                  <Tabs.Panel value="all" pt="xs">
                    {isLoading ? (
                      <CustomLoader />
                    ) : (
                      <UserTable
                        data={userData}
                        value={activeTab}
                        count={totalCount}
                        setPagination={setPagination}
                        pagination={pagination}
                        page={pageSelection}
                        setPage={setPageSelection}
                        setUserData={setUserData}
                        setTotalCount={setTotalCount}
                        searchText={searchText}
                        permissionList={permissionList}
                        setSparyedCount={setSparyedCount}
                        sparyedCount={sparyedCount}
                        selectedState={selectedState}
                        selectedDistrict={selectedDistrict}
                        selectedBlock={selectedBlock}
                        setError={setError}
                        setSuccess={setSuccess}
                      />
                    )}
                  </Tabs.Panel>

                  <Tabs.Panel value="pending" pt="xs">
                    {isLoading ? (
                      <CustomLoader />
                    ) : (
                      <UserTable
                        data={userData}
                        value={activeTab}
                        count={totalCount}
                        setPagination={setPagination}
                        pagination={pagination}
                        page={pageSelection}
                        setPage={setPageSelection}
                        setUserData={setUserData}
                        setTotalCount={setTotalCount}
                        searchText={searchText}
                        permissionList={permissionList}
                        setSparyedCount={setSparyedCount}
                        sparyedCount={sparyedCount}
                        selectedState={selectedState}
                        selectedDistrict={selectedDistrict}
                        selectedBlock={selectedBlock}
                        setError={setError}
                        setSuccess={setSuccess}
                      />
                    )}
                  </Tabs.Panel>

                  <Tabs.Panel value="approved" pt="xs">
                    {isLoading ? (
                      <CustomLoader />
                    ) : (
                      <UserTable
                        data={userData}
                        value={activeTab}
                        count={totalCount}
                        setPagination={setPagination}
                        pagination={pagination}
                        page={pageSelection}
                        setPage={setPageSelection}
                        setUserData={setUserData}
                        setTotalCount={setTotalCount}
                        searchText={searchText}
                        permissionList={permissionList}
                        setSparyedCount={setSparyedCount}
                        sparyedCount={sparyedCount}
                        selectedState={selectedState}
                        selectedDistrict={selectedDistrict}
                        selectedBlock={selectedBlock}
                        setError={setError}
                        setSuccess={setSuccess}
                      />
                    )}
                  </Tabs.Panel>

                  <Tabs.Panel value="rejected" pt="xs">
                    {isLoading ? (
                      <CustomLoader />
                    ) : (
                      <UserTable
                        data={userData}
                        value={activeTab}
                        count={totalCount}
                        setPagination={setPagination}
                        pagination={pagination}
                        page={pageSelection}
                        setPage={setPageSelection}
                        setUserData={setUserData}
                        setTotalCount={setTotalCount}
                        searchText={searchText}
                        permissionList={permissionList}
                        setSparyedCount={setSparyedCount}
                        sparyedCount={sparyedCount}
                        selectedState={selectedState}
                        selectedDistrict={selectedDistrict}
                        selectedBlock={selectedBlock}
                        setError={setError}
                        setSuccess={setSuccess}
                      />
                    )}
                  </Tabs.Panel>
                </Tabs>
              </Box>
            ) : (
              <></>
            )}
          </Paper>
        </Grid.Col>
      </Grid>
    </div>
  );
}
