import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Title,
  Button,
  Tabs,
  Box,
  TextInput,
} from "@mantine/core";
import OperatorTable from "./OperatorTable";
import { IconCircleX, IconPlus } from "@tabler/icons-react";
import AddOperatorModal from "./AddOperatorModal";
import CustomLoader from "../../Components/CustomLoader";
import OperatorAPI from "../../Redux/Operator/Services";
import PermissionCheck from "../../Components/Permission";
import { useAppSelector } from "../../Services/hooks/hooks";

export default function OperatorManagement() {
  const [addOperatorModal, setAddOperatorModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [pagination, setPagination] = useState({
    skip: 0,
    limit: 10,
  });
  const [pageSelection, setPageSelection] = useState(1);
  const [totalCount, setTotalCount] = useState(10);
  const [activeTab, setActiveTab] = useState<string | null>("all");
  const { data, loading, notify, notifymsg } = useAppSelector(
    (state) => state.auth
  );
  const [error, setError] = useState("");
  const permissionList = data?.data?.permission?.[0]?.permission_list ? data?.data?.permission?.[0]?.permission_list : [];
  const [ratingList, setRatingList] = useState<any>([]);
  const Styles = {
    paper: {
      width: "100%",
      backgroundColor: "#FFFFFF",
      padding: 20,
      borderRadius: 20,
    },
  };

  const [isLoading, setIsLoading] = React.useState(false);

  async function operatorListFunction() {
    setIsLoading(true);
    let payload = {
      ...pagination,
      filter: searchText,
      status: activeTab,
    };
    try {
      let data = (await OperatorAPI.getOperatorList(payload)).data;

      setTableData(data.data);
      setTotalCount(data?.count ? data?.count : 0);
      setRatingList(data?.ratingCounts ? data?.ratingCounts : [])
      setIsLoading(false);
    } catch (e) {
      setTableData([]);
      setTotalCount(0);
      setRatingList([]);
      setIsLoading(false);
    }
  }
  useEffect(() => {
    operatorListFunction();
  }, [pagination, searchText, activeTab]);

  const handleOperator = () => {
    setAddOperatorModal(false);
    operatorListFunction();
  };

  useEffect(() => {
    setTimeout(() => {
      setError("");
    }, 10000);
  }, [error]);

  return (
    <>
      <Grid>
        <Grid.Col span={12}>
          <Paper style={Styles.paper}>
            <Grid>
              <Grid.Col xs={12} sm={7} md={7} lg={7}>
                <Title
                  style={{
                    fontSize: 30,
                    fontWeight: 300,
                    color: "#4C9C4F",
                  }}
                >
                  Operator Management
                </Title>
              </Grid.Col>
              {
                PermissionCheck(permissionList, "Operator_Management", "read") ?

                  <Grid.Col xs={12} sm={3} md={3} lg={3}>
                    <TextInput
                      placeholder="Filter by Name"
                      value={searchText}
                      withAsterisk
                      onChange={(val) => {
                        setSearchText(val.target.value);
                      }}
                    />
                  </Grid.Col> : <></>
              }
              {
                PermissionCheck(permissionList, "Operator_Management", "write") ?
                  <Grid.Col xs={12} sm={2} md={2} lg={2}>
                    <Button
                      fullWidth
                      style={{ background: "#4CAF50" }}
                      onClick={() => {
                        setAddOperatorModal(true);
                      }}
                    >
                      <IconPlus size={18} />
                      Add Operator
                    </Button>
                  </Grid.Col> : <Grid.Col xs={0}></Grid.Col>
              }
            </Grid>

            {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Title
                style={{
                  fontSize: 30,
                  fontWeight: 300,
                  color: "#4C9C4F",
                }}
              >
                Operator Management
              </Title>
              <div style={{ display: "flex" }}>
                <Autocomplete placeholder="Filter by Name" data={[]} />
                <Button
                  style={{ background: "#4CAF50", marginLeft: 20 }}
                  onClick={() => {
                    setAddOperatorModal(true);
                  }}
                >
                  <IconPlus size={18} />
                  Add Operator
                </Button>
              </div>
            </div> */}

            {/* <Divider my="xl" />
            <OperatorTable data={data} /> */}

            {error !== "" ? <div
              style={{ backgroundColor: "red", color: "white", padding: "5px", marginBottom: 20, marginTop: 20, display: 'flex', fontSize: '13px' }}
            >
              <IconCircleX size="20px" style=
                {{ marginRight: '10px' }} /> {error}
            </div> : ""}

            {
              PermissionCheck(permissionList, "Operator_Management", "read") ?

                <Box style={{ marginTop: 30 }}>
                  <Tabs
                    variant="outline"
                    value={activeTab}
                    onTabChange={(val) => {
                      setPagination({
                        skip: 0,
                        limit: 10,
                      });
                      setPageSelection(1);
                      setActiveTab(val);
                    }}
                  >
                    <Tabs.List>
                      <Tabs.Tab value="all">All</Tabs.Tab>
                      {/* <Tabs.Tab value="pending">Pending</Tabs.Tab>
                  <Tabs.Tab value="approved">Approved</Tabs.Tab>
                  <Tabs.Tab value="rejected">Rejected</Tabs.Tab> */}
                    </Tabs.List>

                    <Tabs.Panel value="all" pt="xs">
                      {isLoading ? (
                        <CustomLoader />
                      ) : (
                        <OperatorTable
                          data={tableData}
                          value={activeTab}
                          setTableData={setTableData}
                          count={totalCount}
                          setPagination={setPagination}
                          pagination={pagination}
                          page={pageSelection}
                          setPage={setPageSelection}
                          searchText={searchText}
                          permissionList={permissionList}
                          setRatingList={setRatingList}
                          ratingList={ratingList}
                          setError={setError}
                        />
                      )}
                    </Tabs.Panel>
                    {/* <Tabs.Panel value="pending" pt="xs">
                  {loading ? (
                    <CustomLoader />
                  ) : (
                    <OperatorTable
                      data={tableData}
                      value={activeTab}
                      setTableData={setTableData}
                      count={totalCount}
                      setPagination={setPagination}
                      pagination={pagination}
                      page={pageSelection}
                      setPage={setPageSelection}
                      searchText={searchText}
                    />
                  )}
                </Tabs.Panel> */}
                    {/* <Tabs.Panel value="approved" pt="xs">
                  {loading ? (
                    <CustomLoader />
                  ) : (
                    <OperatorTable
                      data={tableData}
                      value={activeTab}
                      setTableData={setTableData}
                      count={totalCount}
                      setPagination={setPagination}
                      pagination={pagination}
                      page={pageSelection}
                      setPage={setPageSelection}
                      searchText={searchText}
                    />
                  )}
                </Tabs.Panel> */}
                    {/* <Tabs.Panel value="rejected" pt="xs">
                  {loading ? (
                    <CustomLoader />
                  ) : (
                    <OperatorTable
                      data={tableData}
                      value={activeTab}
                      setTableData={setTableData}
                      count={totalCount}
                      setPagination={setPagination}
                      pagination={pagination}
                      page={pageSelection}
                      setPage={setPageSelection}
                      searchText={searchText}
                    />
                  )}
                </Tabs.Panel> */}
                  </Tabs>
                </Box> : <></>
            }
          </Paper>
        </Grid.Col>
      </Grid>

      {addOperatorModal ? (
        <AddOperatorModal
          show={addOperatorModal}
          type="Create"
          handleClose={handleOperator}
        />
      ) : null}
    </>
  );
}
