import {
  Button,
  Modal,
  Group,
  Image,
  Title,
  Box,
  Avatar,
  ScrollArea,
  Select,
  Loader,
  NumberInput,
} from "@mantine/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { IconCheck, IconChevronDown, IconCircleX, IconX } from "@tabler/icons-react";
import React, { useEffect, useRef, useState } from "react";
import { TextInput } from "@mantine/core";
import { Grid } from "@mantine/core";
import * as _ from "lodash";
import OperatorAPI from "../../Redux/Operator/Services";
import { operatorEditableData } from "../../Redux/Operator/Interface";

const OperatorSchema = Yup.object().shape({
  profile: Yup.string().required("Upload profile image"),
  first_name: Yup.string()
    .min(2, "First name is too short")
    .max(50, "First name is too long")
    .required("First name is required"),
  phone_no: Yup.string()
    .matches(/^[6-9]\d{9}$/, "Invalid mobile number")
    .required("Mobile number is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  license_no: Yup.string()
    // .matches(/^[A-Za-z0-9]{16}$/, "Invalid drone license number") // RD17DD4B1B2D8A4F - drone license format
    .required("Drone license number is required"),
  address: Yup.object().shape({
    street1: Yup.string().required("Street line 1 is required"),
    // panchayat: Yup.string().required("Panchayat is required"),
    // taluk: Yup.string().required("Taluk is required"),
    city: Yup.string().required("City is required"),
    district: Yup.string().required("District is required"),
    state: Yup.string().required("State is required"),
    pincode: Yup.string()
      .matches(/^[1-9][0-9]{5}$/, "Invalid pincode")
      .required("Pincode is required"),
  }),
  image_url: Yup.string().required("Upload KYC document"),
  drone_license_image_url: Yup.string().required(
    "Upload Drone License document"
  ),
  kyc_no: Yup.string()
    .matches(/^\d{12}$/, "Invalid KYC document number")
    .required("KYC document number is required"),
  kyc_type: Yup.string().required("KYC document name is required"),
});

type Props = {
  id?: string;
  show: boolean;
  data?: any;
  type: string;
  handleClose: () => void;
};

const AddOperatorModal: React.FC<Props> = ({
  id,
  show,
  data,
  type,
  handleClose,
}) => {
  const [initialValues, setInitialValues] = useState<operatorEditableData>(
    _.isEmpty(data)
      ? {
        profile: "",
        first_name: "",
        last_name: "",
        name: "",
        phone_no: "",
        email: "",
        license_no: "",
        address: {
          street1: "",
          street2: "",
          panchayat: "",
          taluk: "",
          city: "",
          district: "",
          state: "",
          pincode: "0",
        },
        image_url: "",
        drone_license_image_url: "",
        kyc_no: "",
        kyc_type: "",
        kyc_verification_status: true,
      }
      : {
        name: data[0]?.name,
        profile: data[0]?.profile,
        first_name: data[0]?.first_name,
        last_name: data[0]?.last_name,
        phone_no: data[0]?.phone_no.split("+91")[1],
        email: data[0]?.email,
        license_no: data[0]?.license_no,
        address: {
          street1: data[0]?.address?.street1 ? data[0]?.address?.street1 : "",
          street2: data[0]?.address?.street2 ? data[0]?.address?.street2 : "",
          panchayat: data[0]?.address?.panchayat
            ? data[0]?.address?.panchayat
            : "",
          taluk: data[0]?.address?.taluk ? data[0]?.address?.taluk : "",
          city: data[0]?.address?.city ? data[0]?.address?.city : "",
          district: data[0]?.address?.district
            ? data[0]?.address?.district
            : "",
          state: data[0]?.address?.state ? data[0]?.address?.state : "",
          pincode: data[0]?.address?.pincode ? data[0]?.address?.pincode : "",
        },
        image_url: data[0]?.image_url,
        drone_license_image_url: data[0]?.drone_license_image_url
          ? data[0]?.drone_license_image_url
          : "",
        kyc_no: data[0]?.kyc_no,
        kyc_type: data[0]?.kyc_type,
        kyc_verification_status: true,
      }
  );
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    if (type === "Update") {
      handleDroneLicense(data[0]?.license_no);
    }
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: OperatorSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      let arr = values;
      handleSubmit(values);
    },
  });

  const [searchValue, onSearchChange] = useState("");
  const [isLicenseValid, setIsLicenseValid] = useState<any>(false);
  const [licenseLoading, setLicenseLoading] = useState(false);
  const [droneLicenseStatus, setDroneLicenseStatus] = useState("");
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [profileUrl, setProfileUrl] = useState("");
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleProfileChange = async (e: any) => {
    const image = e.target.files[0];
    setSelectedProfile(image);
    let formData: any = new FormData();
    formData.append("image", image);
    const data = await OperatorAPI.imageUpload(formData);
    setProfileUrl(data?.data?.url);
    let temp = { ...initialValues };
    temp["profile"] = data?.data?.url;
    setInitialValues(temp);
  };

  const [Document, setDocument] = useState(null);
  const [LicenseDocument, setLicenseDocument] = useState(null);
  const [docUrl, setDocUrl] = useState("");
  const [licenseDocUrl, setLicenseDocUrl] = useState("");
  const [error, setError] = useState<any>("");
  const DocInputRef = useRef<HTMLInputElement>(null);
  const handleUploadClick = () => {
    if (DocInputRef.current) {
      DocInputRef.current.click();
    }
  };

  const LicenseDocInputRef = useRef<HTMLInputElement>(null);
  const handleUploadLicenseClick = () => {
    if (LicenseDocInputRef.current) {
      LicenseDocInputRef.current.click();
    }
  };

  const DocName = docUrl.split("/").pop();
  const handleDocChange = async (e: any) => {
    const image = e.target.files[0];
    setDocument(image);
    let formData: any = new FormData();
    formData.append("image", image);
    const data = await OperatorAPI.imageUpload(formData);
    setDocUrl(data?.data?.url);
    let temp = { ...initialValues };
    temp["image_url"] = data?.data?.url;
    setInitialValues(temp);
  };

  const handleDocLicenseChange = async (e: any) => {
    const image = e.target.files[0];
    setLicenseDocument(image);
    let formData: any = new FormData();
    formData.append("image", image);
    const data = await OperatorAPI.imageUpload(formData);
    setLicenseDocUrl(data?.data?.url);
    let temp = { ...initialValues };
    temp["drone_license_image_url"] = data?.data?.url;
    setInitialValues(temp);
  };

  async function handleSubmit(data: any) {
    if (isLicenseValid) {
      if (!isLoading) {
        setIsLoading(true);
        let payload = initialValues;
        payload = {
          ...payload,
          _id: id,
          name: `${payload.first_name} ${payload.last_name}`.trimEnd(),
          phone_no: "+91" + payload.phone_no,
          account_status: "approved",
        };
        if (type === "Create") {
          try {
            //await dispatch(createOperatorAction(payload));
            await OperatorAPI.createOperator(payload);
            handleClose();
          } catch (e: any) {
            setError(e.message);
          }
        } else {
          try {
            //await dispatch(updateOperatorAction(payload));
            await OperatorAPI.updateOperator(payload);
            handleClose();
          } catch (e: any) {
            setError(e.message);
          }
        }

        setIsLoading(false);
      }
    }
  }

  async function handleDroneLicense(id: string) {
    setDroneLicenseStatus("");
    setLicenseLoading(true);
    try {
      let data = await OperatorAPI.verifyDroneLicense({ id: id });
      setLicenseLoading(false);
      if (data.status) {
        setIsLicenseValid(data.status);
        setDroneLicenseStatus("");
      } else {
        setIsLicenseValid(data?.status);
        setDroneLicenseStatus(data?.data);
      }
    } catch (e: any) {
      setLicenseLoading(false);
      setIsLicenseValid(e?.status);
      setDroneLicenseStatus(e?.data);
    }
  }

  return (
    <Modal
      centered
      size="xl"
      radius="lg"
      opened={show}
      onClose={handleClose}
      withCloseButton={true}
      scrollAreaComponent={ScrollArea.Autosize}
      title={
        <Title style={{ fontSize: 25, fontWeight: 300, color: "#4C9C4F" }}>
          {type} Operator
        </Title>
      }
    >
      {error !== "" ? (
        <div
          style={{ backgroundColor: "red", color: "white", padding: "5px", marginBottom: 5, display: 'flex', fontSize: '13px' }}
        >
          <IconCircleX size="20px" style=
            {{ marginRight: '10px' }} /> {error}
        </div>
      ) : (
        ""
      )}

      <Box>
        <form onSubmit={formik.handleSubmit}>
          <Grid>
            <Grid.Col xs={12} sm={4} md={4} lg={4}>
              <Grid.Col span={12}>
                <Group position="center">
                  {selectedProfile ? (
                    selectedProfile && (
                      <Avatar
                        size="xl"
                        radius={90}
                        color="green"
                        src={profileUrl}
                        alt="Selected"
                      />
                    )
                  ) : (
                    <Avatar
                      size="xl"
                      radius={90}
                      src={initialValues?.profile}
                      color="green"
                      alt="Selected"
                    />
                  )}
                </Group>
              </Grid.Col>
              <Grid.Col span={12}>
                <Group position="center">
                  <Button
                    onClick={handleButtonClick}
                    style={{ backgroundColor: "#5cb85c" }}
                  >
                    Upload Profile
                  </Button>
                  <input
                    accept="image/*"
                    type="file"
                    id="select-image"
                    ref={fileInputRef}
                    style={{
                      display: "none",
                    }}
                    onChange={(val) => {
                      handleProfileChange(val);
                    }}
                  />
                </Group>
                {formik.touched.profile && formik.errors.profile && (
                  <center>
                    <span role="alert" style={{ color: "red", fontSize: 12 }}>
                      {formik.errors.profile}
                    </span>
                  </center>
                )}
              </Grid.Col>
            </Grid.Col>

            <Grid.Col xs={12} sm={8} md={8} lg={8}>
              <Grid>
                <Grid.Col xs={12} sm={6} md={6} lg={6}>
                  <TextInput
                    label="First Name"
                    placeholder="First Name"
                    value={initialValues?.first_name}
                    withAsterisk
                    onChange={(val) => {
                      setInitialValues({
                        ...initialValues,
                        first_name: val.target.value.replace(/\s+/g, ' ').trimStart(),
                      });
                    }}
                  />
                  {formik.touched.first_name && formik.errors.first_name && (
                    <span role="alert" style={{ color: "red", fontSize: 12 }}>
                      {formik.errors.first_name}
                    </span>
                  )}
                </Grid.Col>
                <Grid.Col xs={12} sm={6} md={6} lg={6}>
                  <TextInput
                    label="Last Name"
                    placeholder="Last Name"
                    value={initialValues?.last_name}
                    onChange={(val) => {
                      setInitialValues({
                        ...initialValues,
                        last_name: val.target.value.replace(/\s+/g, ' ').trimStart(),
                      });
                    }}
                  />
                </Grid.Col>
                <Grid.Col xs={12} sm={6} md={6} lg={6}>
                  <TextInput
                    label="Mobile"
                    placeholder="Mobile"
                    maxLength={10}
                    value={initialValues?.phone_no}
                    withAsterisk
                    onChange={(val) => {
                      setError("");
                      setInitialValues({
                        ...initialValues,
                        phone_no: val.target.value.replace(/\s+/g, ' ').trimStart(),
                      });
                    }}
                  />
                  {formik.touched.phone_no && formik.errors.phone_no && (
                    <span role="alert" style={{ color: "red", fontSize: 12 }}>
                      {formik.errors.phone_no}
                    </span>
                  )}
                </Grid.Col>
                <Grid.Col xs={12} sm={6} md={6} lg={6}>
                  <TextInput
                    label="Email"
                    placeholder="Email"
                    withAsterisk
                    value={initialValues?.email}
                    onChange={(val) => {
                      setError("");
                      setInitialValues({
                        ...initialValues,
                        email: val.target.value.trimStart(),
                      });
                    }}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <span role="alert" style={{ color: "red", fontSize: 12 }}>
                      {formik.errors.email}
                    </span>
                  )}
                </Grid.Col>
              </Grid>
            </Grid.Col>
            <Grid.Col xs={12} sm={4} md={4} lg={4}>
              <TextInput
                label="Drone license_no"
                placeholder="Drone license_no"
                withAsterisk
                value={initialValues?.license_no}
                onChange={(val) => {
                  setInitialValues({
                    ...initialValues,
                    license_no: val.target.value.replace(/\s+/g, ' ').trimStart(),
                  });
                  handleDroneLicense(val.target.value.replace(/\s+/g, ' ').trimStart());
                }}
                rightSection={
                  initialValues.license_no && (
                    <div>
                      {licenseLoading ? (
                        <Loader size="sm" style={{ display: "block" }} />
                      ) : isLicenseValid ? (
                        <IconCheck
                          color="green"
                          size={22}
                          style={{ display: "block" }}
                        />
                      ) : (
                        <IconX
                          color="red"
                          size={22}
                          style={{ display: "block" }}
                        />
                      )}
                    </div>
                  )
                }
              />
              {!isLicenseValid ? (
                <span role="alert" style={{ color: "red", fontSize: 12 }}>
                  {droneLicenseStatus}
                </span>
              ) : (
                <></>
              )}
              {formik.touched.license_no && formik.errors.license_no && (
                <span role="alert" style={{ color: "red", fontSize: 12 }}>
                  {formik.errors.license_no}
                </span>
              )}
            </Grid.Col>
            <Grid.Col xs={12} sm={4} md={4} lg={4}>
              <TextInput
                label="Street line 1"
                placeholder="Street line 1"
                withAsterisk
                value={initialValues?.address?.street1}
                onChange={(val) => {
                  let temp = { ...initialValues };
                  temp.address["street1"] = val.target.value.replace(/\s+/g, ' ').trimStart();
                  setInitialValues(temp);
                }}
              />
              {formik.touched.address?.street1 &&
                formik.errors.address?.street1 && (
                  <span role="alert" style={{ color: "red", fontSize: 12 }}>
                    {formik.errors.address?.street1}
                  </span>
                )}
            </Grid.Col>
            <Grid.Col xs={12} sm={4} md={4} lg={4}>
              <TextInput
                label="Street line 2"
                placeholder="Street line 2"
                value={initialValues?.address?.street2}
                onChange={(val) => {
                  let temp = { ...initialValues };
                  temp.address["street2"] = val.target.value.replace(/\s+/g, ' ').trimStart();
                  setInitialValues(temp);
                }}
              />
            </Grid.Col>
            <Grid.Col xs={12} sm={4} md={4} lg={4}>
              <TextInput
                label="Panchayat"
                placeholder="Panchayat"
                
                value={initialValues?.address?.panchayat}
                onChange={(val) => {
                  let temp = { ...initialValues };
                  temp.address["panchayat"] = val.target.value.replace(/\s+/g, ' ').trimStart();
                  setInitialValues(temp);
                }}
              />
              {formik.touched.address?.panchayat &&
                formik.errors.address?.panchayat && (
                  <span role="alert" style={{ color: "red", fontSize: 12 }}>
                    {formik.errors.address?.panchayat}
                  </span>
                )}
            </Grid.Col>
            <Grid.Col xs={12} sm={4} md={4} lg={4}>
              <TextInput
                label="Taluk"
                placeholder="Taluk"
                
                value={initialValues?.address?.taluk}
                onChange={(val) => {
                  let temp = { ...initialValues };
                  temp.address["taluk"] = val.target.value.replace(/\s+/g, ' ').trimStart();
                  setInitialValues(temp);
                }}
              />
              {formik.touched.address?.taluk &&
                formik.errors.address?.taluk && (
                  <span role="alert" style={{ color: "red", fontSize: 12 }}>
                    {formik.errors.address?.taluk}
                  </span>
                )}
            </Grid.Col>
            <Grid.Col xs={12} sm={4} md={4} lg={4}>
              <TextInput
                label="City"
                placeholder="City"
                withAsterisk
                value={initialValues?.address?.city}
                onChange={(val) => {
                  let temp = { ...initialValues };
                  temp.address["city"] = val.target.value.replace(/\s+/g, ' ').trimStart();
                  setInitialValues(temp);
                }}
              />
              {formik.touched.address?.city && formik.errors.address?.city && (
                <span role="alert" style={{ color: "red", fontSize: 12 }}>
                  {formik.errors.address?.city}
                </span>
              )}
            </Grid.Col>
            <Grid.Col xs={12} sm={4} md={4} lg={4}>
              <TextInput
                label="District"
                placeholder="District"
                withAsterisk
                value={initialValues?.address?.district}
                onChange={(val) => {
                  let temp = { ...initialValues };
                  temp.address["district"] = val.target.value.replace(/\s+/g, ' ').trimStart();
                  setInitialValues(temp);
                }}
              />
              {formik.touched.address?.district &&
                formik.errors.address?.district && (
                  <span role="alert" style={{ color: "red", fontSize: 12 }}>
                    {formik.errors.address?.district}
                  </span>
                )}
            </Grid.Col>
            <Grid.Col xs={12} sm={4} md={4} lg={4}>
              <TextInput
                label="State"
                placeholder="State"
                withAsterisk
                value={initialValues?.address?.state}
                onChange={(val) => {
                  let temp = { ...initialValues };
                  temp.address["state"] = val.target.value.replace(/\s+/g, ' ').trimStart();
                  setInitialValues(temp);
                }}
              />
              {formik.touched.address?.state &&
                formik.errors.address?.state && (
                  <span role="alert" style={{ color: "red", fontSize: 12 }}>
                    {formik.errors.address?.state}
                  </span>
                )}
            </Grid.Col>
            <Grid.Col xs={12} sm={4} md={4} lg={4}>
              <NumberInput
                label="Pincode"
                placeholder="Pincode"
                withAsterisk
                maxLength={6}
                value={parseInt(initialValues?.address?.pincode?.toString() ?? "")}
                onChange={(val) => {
                  let temp = { ...initialValues };
                  temp.address["pincode"] = val === "" ? "0" : val.toString().replace(/\s+/g, ' ').trimStart();
                  setInitialValues(temp);
                }}
              />
              {formik.touched.address?.pincode &&
                formik.errors.address?.pincode && (
                  <span role="alert" style={{ color: "red", fontSize: 12 }}>
                    {formik.errors.address?.pincode}
                  </span>
                )}
            </Grid.Col>
            <Grid.Col xs={12} sm={4} md={4} lg={4}>
              <Select
                label="KYC Document Name"
                placeholder="Select the Document"
                withAsterisk
                rightSection={<IconChevronDown size="1rem" />}
                rightSectionWidth={30}
                searchable
                value={initialValues?.kyc_type}
                onChange={(val) => {
                  setInitialValues({
                    ...initialValues,
                    kyc_type: val,
                  });
                }}
                onSearchChange={onSearchChange}
                searchValue={searchValue}
                nothingFound="No options"
                data={[
                  "AADHAR CARD",
                  // "PAN CARD",
                  // "PASSPORT",
                  // "VOTER ID",
                  // "DRIVING LICENCE",
                  // "NREGA CARD",
                ]}
              />
              {formik.touched.kyc_type && formik.errors.kyc_type && (
                <span role="alert" style={{ color: "red", fontSize: 12 }}>
                  {formik.errors.kyc_type}
                </span>
              )}
            </Grid.Col>
            <Grid.Col xs={12} sm={4} md={4} lg={4}>
              <TextInput
                label="KYC Document Number"
                placeholder="KYC Document Number"
                withAsterisk
                value={initialValues?.kyc_no}
                onChange={(val) => {
                  setInitialValues({
                    ...initialValues,
                    kyc_no: val.target.value.replace(/\s+/g, ' ').trimStart(),
                  });
                }}
              />
              {formik.touched.kyc_no && formik.errors.kyc_no && (
                <span role="alert" style={{ color: "red", fontSize: 12 }}>
                  {formik.errors.kyc_no}
                </span>
              )}
            </Grid.Col>
            <Grid.Col xs={12} sm={4} md={4} lg={4}>
              <div style={{ display: "flex" }}>
                <TextInput
                  label="Upload KYC Document"
                  withAsterisk
                  value={
                    Document
                      ? docUrl?.split("/").pop()
                      : initialValues?.image_url?.split("/").pop()
                  }
                  contentEditable={false}
                  rightSection={
                    <>
                      <Button
                        onClick={handleUploadClick}
                        style={{ backgroundColor: "#5cb85c" }}
                      >
                        Browse
                      </Button>
                      <input
                        accept="image/*"
                        type="file"
                        id="select-image"
                        ref={DocInputRef}
                        style={{
                          display: "none",
                        }}
                        onChange={(val) => {
                          handleDocChange(val);
                        }}
                      />
                    </>
                  }
                />
              </div>
              {formik.touched.image_url && formik.errors.image_url && (
                <span role="alert" style={{ color: "red", fontSize: 12 }}>
                  {formik.errors.image_url}
                </span>
              )}
            </Grid.Col>
            <Grid.Col xs={12} sm={4} md={4} lg={4}>
              <div style={{ display: "flex" }}>
                <TextInput
                  label="Upload Drone License"
                  withAsterisk
                  value={
                    Document
                      ? licenseDocUrl?.split("/").pop()
                      : initialValues?.drone_license_image_url?.split("/").pop()
                  }
                  contentEditable={false}
                  rightSection={
                    <>
                      <Button
                        onClick={handleUploadLicenseClick}
                        style={{ backgroundColor: "#5cb85c" }}
                      >
                        Browse
                      </Button>
                      <input
                        accept="image/*"
                        type="file"
                        id="select-image"
                        ref={LicenseDocInputRef}
                        style={{
                          display: "none",
                        }}
                        onChange={(val) => {
                          handleDocLicenseChange(val);
                        }}
                      />
                    </>
                  }
                />
              </div>
              {formik.touched.drone_license_image_url &&
                formik.errors.drone_license_image_url && (
                  <span role="alert" style={{ color: "red", fontSize: 12 }}>
                    {formik.errors.drone_license_image_url}
                  </span>
                )}
            </Grid.Col>
            <Grid.Col xs={12} sm={8} md={8} lg={8}></Grid.Col>
            <Grid.Col span={6}>
              <Group position="center">
                {Document ? (
                  Document && <Image src={docUrl} />
                ) : (
                  <Image src={initialValues?.image_url} />
                )}
              </Group>
            </Grid.Col>

            <Grid.Col span={6}>
              <Group position="center">
                {LicenseDocument ? (
                  LicenseDocument && <Image src={licenseDocUrl} />
                ) : (
                  <Image src={initialValues?.drone_license_image_url} />
                )}
              </Group>
            </Grid.Col>

            <Grid.Col span={12}>
              <Box style={{ display: "flex", justifyContent: "center" }}>
                {isLoading ? (
                  <Button style={{ backgroundColor: "#2C752F", width: 100 }}>
                    <Loader size="xs" />
                  </Button>
                ) : (
                  <Button type="submit" style={{ backgroundColor: "#2C752F" }}>
                    {type}
                  </Button>
                )}
              </Box>
            </Grid.Col>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
};

export default AddOperatorModal;
