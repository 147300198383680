import { useState } from "react";
import { IconEye, IconPlugConnected, IconPlus } from "@tabler/icons-react";
import {
  ActionIcon,
  Box,
  Table,
  ScrollArea,
  Text,
  Tooltip,
} from "@mantine/core";
import DeleteModal from "../../Components/DeleteModal";
import { useNavigate } from "react-router-dom";
import CustomPagination from "../../Components/CustomPagination";
import PermissionCheck from "../../Components/Permission";
import DealerApi from "../../Redux/Dealer/Service";

export default function DealerTable(props: any) {
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(false);

  const [id, setId] = useState("");

  async function getDealerList() {
    try {
      let params = {
        ...props.pagination,
        filter: props.searchText,
        state: props.state,
        block: props.block,
        district: props.district,
        status: props.value,
      };
      let data = (await DealerApi.getDealerList(params)).data;
      props.setUserData(data[0].data);
      props.setTotalCount(data[0]?.count?.count ? data[0]?.count?.count : 0);
    } catch (e) {
      props.setUserData([]);
      props.setTotalCount(0);
    }
  }

  const handleDelete = () => {
    getDealerList();
    setShowDeleteModal(false);
  };

  const rows = props.data?.map((item: any) => {
    return (
      <tr key={item?._id}>
        <td> {item?.dealer_id}</td>
        <td>{item?.name}</td>
        <td>{item?.phone_no}</td>
        <td style={{ display: "flex", justifyContent: "space-evenly" }}>
          <ActionIcon
            variant="outline"
            onClick={() => {
              navigate("dealer", { state: item });
            }}
          >
            <Tooltip label="View">
              <IconEye size={20} />
            </Tooltip>
          </ActionIcon>

          {PermissionCheck(
            props.permissionList,
            "Dealer_Management",
            "write"
          ) ? (
            <ActionIcon
              variant="outline"
              onClick={() => {
                setShowDeleteModal(true);
                setId(item?._id);
                setSelectedStatus(item.status);
              }}
            >
              <Tooltip label={item?.status ? "De-Active" : "Active"}>
                {item?.status ? (
                  <IconPlugConnected size={20} />
                ) : (
                  <IconPlus size={20} />
                )}
              </Tooltip>
            </ActionIcon>
          ) : (
            <></>
          )}
        </td>
      </tr>
    );
  });

  return (
    <>
      <ScrollArea>
        {props.data.length === 0 ? (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: window.innerHeight - 240,
            }}
          >
            <Text>No Data</Text>
          </Box>
        ) : (
          <div
            style={{ height: window.innerHeight - 290, overflowY: "scroll" }}
          >
            <Table verticalSpacing="sm" style={{ tableLayout: "fixed" }}>
              <thead>
                <tr>
                  <th>Dealer Id</th>
                  <th>Name</th>
                  <th>Phone No</th>
                  <th style={{ textAlign: "center" }}>Actions</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          </div>
        )}
      </ScrollArea>
      {props.count > 0 ? (
        <CustomPagination
          setPage={props.setPage}
          pagination={props.pagination}
          setPagination={props.setPagination}
          page={props.page}
          count={props.count}
        />
      ) : (
        <></>
      )}

      {showDeleteModal ? (
        <DeleteModal
          id={id}
          show={showDeleteModal}
          type="Dealer"
          handleClose={handleDelete}
          error={props.setError}
          status={!selectedStatus}
        />
      ) : null}
    </>
  );
}
