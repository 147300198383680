import { async } from "q";
import apiInstance from "../../Services";
import { bookingData, listQuery, listQueryForStatus } from "./Interface";

const BookingAPI = {
  // eslint-disable-next-line class-methods-use-this
  getBookingList: async (payload: listQuery) => {
    try {
      const res = await apiInstance.get<bookingData>(
        `/booking/getall?skip=${payload.skip}&limit=${payload.limit}`
      );
      return res;
    } catch (error) {
      throw error;
    }
  },
  getBookingListByBookingStatus: async (payload: listQueryForStatus) => {
    try {
      const res = await apiInstance.get<any>(
        `/booking/listbyBookingStatus?skip=${payload.skip? payload.skip : 0}&id=${payload.id? payload.id : ""}&limit=${payload.limit? payload.limit : 99999}&status=${payload.status? payload.status : ""}&filter=${payload.filter? payload.filter : ""}&state=${payload.state? payload.state : ""}&district=${payload.district? payload.district : ""}&block=${payload.block? payload.block : ""}&from=${payload.from? payload.from : ""}&to=${payload.to? payload.to : ""}`
      );
      return res;
    } catch (error) {
      throw error;
    }
  },
  updateBooking: async (payload: any) => {
    try {
      const res = await apiInstance.put<any>(
        `/booking/update?id=${payload.id}`,
        payload
      );
      return res;
    } catch (error) {
      throw error;
    }
  },
  deleteBooking: async (payload: any) => {
    try {
      const res = await apiInstance.put<any>(
        `/booking/delete?id=${payload.id}`
      );
      return res;
    } catch (error) {
      throw error;
    }
  },
  cropCount: async () => {
    try {
      const res = await apiInstance.get<any>(`/booking/cropCount`);
      return res;
    } catch (error) {
      throw error;
    }
  },
  totalFarmLand: async () => {
    try {
      const res = await apiInstance.get<any>(`/booking/farmCount`);
      return res;
    } catch (error) {
      throw error;
    }
  },
  totalFertilizerQuantity: async (payload: any) => {
    try {
      const res = await apiInstance.get<any>(`/orderreport/fertilizercount?startDate=${payload.from}&toDate=${payload.to}&state=${payload.state}&district=${payload.district}&block=${payload.block}`);
      return res;
    } catch (error) {
      throw error;
    }
  },

  generateReport: async (payload: any) => {
    try {
      const res = await apiInstance.get<any>(
        `booking/report?drone_id=${payload?.id}&status=true&from=${payload?.fromDate}&to=${payload?.toDate}&operator_id=${payload.operator_id}&state=${payload.state}&district=${payload.district}&block=${payload.block}`
      );
      return res;
    } catch (error) {
      throw error;
    }
  },

  getOperatorListBasedOnDroneId: async (payload: any) => {
    try {
      const res = await apiInstance.get<any>(
        `orderreport/operatorid?id=${payload?.id}`
      );
      return res;
    } catch (error) {
      throw error;
    }
  },


  getAllDroneList: async () => {
    try {
      const res = await apiInstance.get<any>(`drone/droneList`);
      return res;
    } catch (error) {
      throw error;
    }
  },

  getAllSosList: async () => {
    try {
      const res = await apiInstance.get<any>(`booking/activeSosList`);
      return res;
    } catch (error) {
      throw error;
    }
  },

  updateOperatorReport: async (payload: any) => {
    try {
      const res = await apiInstance.put<any>(`orderreport?id=${payload.cultivation_details.id}`, payload);
      return res;
    } catch (error) {
      throw error;
    }
  },
};

export default BookingAPI;
