import { Button, Modal, Group, Text, Box } from "@mantine/core";
import { IconUserX } from "@tabler/icons-react";
import { useAppDispatch } from "../Services/hooks/hooks";
import UserAPI from "../Redux/User/Services";
import OperatorAPI from "../Redux/Operator/Services";
import BookingAPI from "../Redux/Booking/Services";
import ConfigurationCropDetailsAPI from "../Redux/Configuration/CropType/Services";
import ConfigurationFertilizerAPI from "../Redux/Configuration/Fertilizer/Services";
import ConfigurationPesticideAPI from "../Redux/Configuration/Pesticide/Services";
import StateAPI from "../Redux/Configuration/State/Services";
import DistrictAPI from "../Redux/Configuration/District/Services";
import DroneAPI from "../Redux/Drone/Services";
import BlockAPI from "../Redux/Configuration/Block/Services";
import { useState } from "react";
import { Loader } from "@mantine/core";
import ConfigurationVarietyAPI from "../Redux/Configuration/Variety/Services";
import RoleAPI from "../Redux/Role/service";
import ModuleAPI from "../Redux/Module/service";
import PermissionAPI from "../Redux/Permission/service";
import AdminAPI from "../Redux/Admin/service";
import ConfigurationCropStageAPI from "../Redux/Configuration/CropStage/Services";
import DealerApi from "../Redux/Dealer/Service";
type Props = {
  id: string;
  show: boolean;
  type?: string;
  typeDelete?: string;
  handleClose: () => void;
  error?: any;
  setSuccess?: any;
  status?: boolean;
  deleteStatus?: boolean;
};
const DeleteModal: React.FC<Props> = ({
  id,
  show,
  type,
  handleClose,
  error,
  setSuccess,
  status,
  deleteStatus,
}) => {
  const [isloading, setloading] = useState<any>(false);
  async function handleSubmit() {
    console.log(deleteStatus, "123123");
    try {
      if (!isloading) {
        setloading(true);
        if (type === "Farmer") {
          let payload = {
            _id: id,
            status: status,
          };
          await UserAPI.deleteUser(payload);
        } else if (type === "PermanentDelete") {
          let payload = {
            id: id,
          };
          const res = await UserAPI.permanentDeleteUser(payload);
          console.log(res.status, "12312312");
          setSuccess("Farmer Deleted successfully");
        } else if (type === "Operator") {
          let payload = {
            _id: id,
            status: status,
          };
          await OperatorAPI.deleteOperator(payload);
        } else if (type === "Order") {
          let payload = {
            id: id,
          };
          await BookingAPI.deleteBooking(payload);
        } else if (type === "Crop Type") {
          let payload = {
            id: id,
            status: status,
          };
          await ConfigurationCropDetailsAPI.deleteCropDetails(payload);
        } else if (type === "Crop Stage") {
          let payload = {
            id: id,
            status: status,
          };
          await ConfigurationCropStageAPI.deleteCropStage(payload);
        } else if (type === "Fertilizer") {
          let payload = {
            id: id,
            status: status,
          };
          await ConfigurationFertilizerAPI.deleteFertilizer(payload);
        } else if (type === "Variety") {
          let payload = {
            id: id,
            status: status,
          };
          await ConfigurationVarietyAPI.deleteVariety(payload);
        } else if (type === "Pesticide") {
          let payload = {
            id: id,
            status: status,
          };
          await ConfigurationPesticideAPI.deletePesticide(payload);
        } else if (type === "State") {
          let payload = {
            id: id,
            status: status,
          };
          await StateAPI.deleteState(payload);
        } else if (type === "District") {
          let payload = {
            id: id,
            status: status,
          };
          await DistrictAPI.deleteDistrict(payload);
        } else if (type === "Block") {
          let payload = {
            id: id,
            status: status,
          };
          await BlockAPI.deleteBlock(payload);
        } else if (type === "Drone") {
          let payload = {
            id: id,
            status: status,
          };
          await DroneAPI.deleteDrone(payload);
        } else if (type === "Module") {
          let payload = {
            id: id,
          };
          await ModuleAPI.deleteModule(payload);
        } else if (type === "Role") {
          let payload = {
            id: id,
          };
          await RoleAPI.deleteRole(payload);
        } else if (type === "Permission") {
          let payload = {
            id: id,
          };
          await PermissionAPI.deletePermission(payload);
        } else if (type === "User") {
          let payload = {
            id: id,
          };
          await AdminAPI.deleteAdmin(payload);
        } else if (type === "Dealer") {
          let payload = {
            id: id,
            status: status,
          };
          await DealerApi.deleteDealer(payload);
        }
        handleClose();
        setloading(false);
      }
    } catch (e: any) {
      handleClose();
      setloading(false);
      error(e.message);
    }
  }

  return (
    <Modal
      centered
      radius="lg"
      padding="xl"
      opened={show}
      onClose={handleClose}
      withCloseButton={false}
    >
      <Box
        style={{
          textAlign: "center",
        }}
      >
        <IconUserX size={50} color="#ED2455" />
        {deleteStatus ? (
          <Text style={{ textAlign: "center", paddingTop: 10 }}>
            Are you sure you want to Delete this Farmer
          </Text>
        ) : (
          <Text style={{ textAlign: "center", paddingTop: 10 }}>
            Are you sure you want to {status ? "active" : "deactivate"} this{" "}
            {type} ?
          </Text>
        )}

        <Group
          style={{
            justifyContent: "center",
            paddingTop: 25,
          }}
        >
          {isloading ? (
            <Button
              onClick={handleClose}
              style={{
                width: "25%",
                background: "#D8D8D8",
                borderRadius: "8px",
                marginRight: 20,
              }}
            >
              No
            </Button>
          ) : (
            <Button
              onClick={handleClose}
              style={{
                width: "25%",
                background: "#D8D8D8",
                borderRadius: "8px",
                marginRight: 20,
              }}
            >
              No
            </Button>
          )}

          {isloading ? (
            <Button
              onClick={() => {
                handleSubmit();
              }}
              style={{
                width: "25%",
                background: "#ED2455",
                borderRadius: "8px",
              }}
            >
              <Loader size="xs" />
            </Button>
          ) : (
            <Button
              onClick={() => {
                handleSubmit();
              }}
              style={{
                width: "25%",
                background: "#ED2455",
                borderRadius: "8px",
              }}
            >
              Yes
            </Button>
          )}
        </Group>
      </Box>
    </Modal>
  );
};

export default DeleteModal;
