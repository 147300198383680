import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  MarkerF,
  InfoWindowF,
  useJsApiLoader,
} from "@react-google-maps/api";
import dron from "../../assets/dron.png";
import DroneAPI from "../../Redux/Drone/Services";
import OperatorAPI from "../../Redux/Operator/Services";
import { Text } from "@mantine/core";
import BookingAPI from "../../Redux/Booking/Services";

type Props = {
  dronData?: any;
};

const MapModal: React.FC<Props> = ({ dronData }) => {
  const containerStyle = {
    // width: "1000px",
    height: "500px",
  };

  const mapStyle = [
    {
      elementType: "geometry",
      stylers: [
        {
          color: "#f5f5f5",
        },
      ],
    },
    {
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#616161",
        },
      ],
    },
    {
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#f5f5f5",
        },
      ],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#bdbdbd",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575",
        },
      ],
    },
    {
      featureType: "poi.business",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [
        {
          color: "#e5e5e5",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [
        {
          color: "#ffffff",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "labels",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [
        {
          color: "#dadada",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "labels",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#616161",
        },
      ],
    },
    {
      featureType: "road.local",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "labels",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
    {
      featureType: "transit.line",
      elementType: "geometry",
      stylers: [
        {
          color: "#e5e5e5",
        },
      ],
    },
    {
      featureType: "transit.station",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          color: "#c9c9c9",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
  ];

  const [center, setCenter] = useState({
    lat: 11.020507,
    lng: 76.992662,
  });

  const Location = [
    {
      id: 1,
      latitude: 11.020507,
      longitude: 76.992662,
      uin: "UA005KEDOTC",
      modal: "SYENA-H10",
      mfg_sno: "THSNH23021000120",
      mfg_by: "THANOS TECHNOLOGIES PVT LTD",
      address: "Udayam Palayam, Coimbatore 641037, Tamil Nadu",
    },
    {
      id: 2,
      latitude: 11.029967,
      longitude: 77.013379,
      uin: "UA005KEDOTC",
      modal: "SYENA-H10",
      mfg_sno: "THSNH23021000121",
      mfg_by: "THANOS TECHNOLOGIES PVT LTD",
      address: "Peelamedu, Coimbatore 641004, Tamil Nadu",
    },
    {
      id: 3,
      latitude: 11.012549,
      longitude: 77.016439,
      uin: "UA005KEDOTC",
      modal: "SYENA-H10",
      mfg_sno: "THSNH2302100012",
      mfg_by: "THANOS TECHNOLOGIES PVT LTD",
      address: "GV Residency Colony, Coimbatore 641004, Tamil Nadu",
    },
  ];

  const [staticDroneData, setStaticDronData] = useState({
    latitude: 11.020507,
    longitude: 76.992662,
    operator_id: "",
    uin: "UA005KEDOTC",
    modal: "SYENA-H10",
    mfg_sno: "THSNH23021000120",
    mfg_by: "THANOS TECHNOLOGIES PVT LTD",
    address: "Udayam Palayam, Coimbatore 641037, Tamil Nadu",
    status: "offline",
  });

  // const [connectedDronList, setConnectedDronList] = useState<any>([]);
  const [operatorList, setOperatorList] = useState([]);
  const [activeAddress, setActiveAddress] = useState<any>("");

  // useEffect(()=>{
  //   console.log("------------->>> temp updated");
  //   setTemp(connectedDronList);
  // },[connectedDronList]);


  // async function setLocation() {
  //   let tempDrone = connectedDronList;

  //   let result = await dronData.filter((f: any) =>
  //     tempDrone.some((d : any) => d.operator_id == f.operator_id)
  //   );

  //   if (tempDrone.length !== result.length) {
  //     tempDrone = [...result];
  //     //setConnectedDronList(tempDrone);
  //   }


  //   if (dronData.length > 0) {
  //     await dronData.map(async (deviceData: any) => {
  //       const index = tempDrone.findIndex((res: any) => { return res.operator_id === deviceData.operator_id });

  //       if (index === -1) {

  //         let tempList = { ...staticDroneData };
  //         tempList["latitude"] = deviceData.lat;
  //         tempList["operator_id"] = deviceData.operator_id;
  //         tempList["longitude"] = deviceData.lon;
  //         //setCenter({ lat: data.lat, lng: data.lon });
  //         let address = await getCurrentLocation(deviceData.lat, deviceData.lon).then(
  //           (res) => {
  //             tempList["address"] = res.toString();

  //             if (tempList["status"] !== "Available") {
  //               tempList["status"] = "Available";
  //               setActiveMarker(null);
  //             }

  //             tempDrone.push(tempList);

  //             setConnectedDronList(tempDrone);
  //           }
  //         );

  //       } else {
  //         tempDrone[index]["latitude"] = deviceData.lat;
  //         tempDrone[index]["operator_id"] = deviceData.operator_id;
  //         tempDrone[index]["longitude"] = deviceData.lon;
  //         //setCenter({ lat: data.lat, lng: data.lon });
  //         let address = await getCurrentLocation(deviceData.lat, deviceData.lon).then(
  //           (res) => {
  //             //return res;
  //             tempDrone[index]["address"] = res.toString();
  //             setConnectedDronList(tempDrone);
  //           }
  //         );

  //       }
  //       //setConnectedDronList(tempDrone);
  //     });
  //    // setConnectedDronList(tempDrone);

  //   }
  //   else {
  //     setConnectedDronList([]);
  //   }

  //   // if(dronData.length > 0){
  //   //   let tempConnection: { id: number; latitude: number; longitude: number; uin: string; modal: string; mfg_sno: string; mfg_by: string; address: string; status: string; }[] = [];
  //   // dronData.map(async (data: any) => {

  //   //   console.log("------>> data", data)

  //   //   let tempList = { ...staticDroneData };
  //   //   tempList["latitude"] = data.lat;
  //   //   tempList["longitude"] = data.lon;
  //   //   //setCenter({ lat: data.lat, lng: data.lon });
  //   //   let address = await getCurrentLocation(data.lat, data.lon).then(
  //   //     (res) => {
  //   //       return res;
  //   //     }
  //   //   );
  //   //   tempList["address"] = address.toString();

  //   //   if (tempList["status"] !== "Available") {
  //   //     tempList["status"] = "Available";
  //   //     setActiveMarker(null);
  //   //   }
  //   //   //setStaticDronData(tempList);

  //   //   tempConnection.push(tempList);
  //   //  console.log(tempConnection);
  //   //  setConnectedDronList(tempConnection);
  //   // });

  //   // } else {
  //   //   let tempList = { ...staticDroneData };

  //   //   if (tempList["status"] !== "offline") {
  //   //     tempList["status"] = "offline";
  //   //     setActiveMarker(null);
  //   //   }
  //   //   let address = await getCurrentLocation(
  //   //     tempList.latitude,
  //   //     tempList.longitude
  //   //   );
  //   //   setCenter({ lat: tempList.latitude, lng: tempList.longitude });
  //   //   tempList["address"] = address.toString();
  //   //  // setStaticDronData(tempList);
  //   //   setConnectedDronList([tempList]);
  //   // }

  // }


  async function getAddress(lat: any, lon: any) {
    await getCurrentLocation(lat, lon).then(
      (res) => {
        setActiveAddress(res);
      }
    );
  }


  const [droneDetails, setDroneDetails] = useState([]);
  async function DroneListFunction() {

    try {
      let data = (await BookingAPI.getAllDroneList()).data;
      setDroneDetails(data);
    } catch (e) {
      setDroneDetails([]);
    }
  }


  async function getOperatorList() {
    let data = (await OperatorAPI.getOperatorListAll()).data;
    setOperatorList(data);
  }

  useEffect(() => {
    DroneListFunction();
    getOperatorList();
  }, []);

  useEffect(() => {
    // setLocation();
  }, [dronData]);

  // const libraries = ['places']
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyABs90Wh7N3ug2WsnACJcuqlpHVtPCBWIs",
  });

  const mapRef = React.useRef<any>(null);

  const onLoad = React.useCallback(
    (mapInstance: any) => {
      const bounds = new google.maps.LatLngBounds();
      dronData?.forEach((res: any) => {
        bounds.extend(new google.maps.LatLng(res.latitude, res.longitude));
      });
      mapRef.current = mapInstance;
      mapInstance.fitBounds(bounds);
    },
    [dronData]
  );

  const [activeMarker, setActiveMarker] = useState<undefined | any>(null);
  const [selectedDrone, setSelectedDrone] = useState<any>({});

  const onClickMarker = (operator_id: string) => {
    setActiveMarker(
      dronData.find((data: any) => data?.operator_id.toString() === operator_id)
    );
    console.log("check --------->>>", droneDetails.find((res: any) => { console.log("------->> assigned_operator : ", res.assigned_operator); return res.assigned_operator === operator_id }), "operator_id : ", operator_id, "assigned_operator : ");
    setSelectedDrone(droneDetails.find((res: any) => res.assigned_operator === operator_id));

  };

  async function getCurrentLocation(lat: any, lng: any) {
    let address = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyABs90Wh7N3ug2WsnACJcuqlpHVtPCBWIs`
    )
      .then((res) => {
        return res.text();
      })
      .then((res) => {
        return JSON.parse(res)["results"][0]["formatted_address"];
      });
    return address;
  }

  const [error, setError] = useState("");
  const [load, setLoad] = useState<any>(false);

  useEffect(() => {
    if (dronData.length === 0) {
      setError("No Operator found at this time.");
    } else {
      setError("");
    }
    setLoad(!load);
  }, [dronData]);


  return (
    <>
      {true ? (
        <div>
          {
            error !== "" ? <Text>{error}</Text> : <></>
          }
          {
            isLoaded ?
              <GoogleMap
                options={{
                  // styles: mapStyle,
                  mapTypeControl: false,
                  mapId: "7341c6b33c1f9404"
                }}

                mapContainerStyle={containerStyle}
                center={center}
                zoom={11}
              //onLoad={onLoad}
              >
                {
                  dronData?.map((marker: any, key: any) => {
                    console.log("----------->>> 1");
                    return <MarkerF
                      key={key}
                      position={{
                        lat: parseFloat(marker.lat),
                        lng: parseFloat(marker.lon),
                      }}
                      icon={dron}
                      onClick={(e) => {
                        onClickMarker(marker?.operator_id.toString());
                        getAddress(marker.lat, marker.lon)
                      }}
                    >
                      {activeMarker !== undefined &&
                        activeMarker !== null &&
                        activeMarker?.operator_id.toString() === marker?.operator_id.toString() ? (
                        <InfoWindowF
                          onCloseClick={() => {
                            setActiveMarker(null);
                            setActiveAddress("");
                          }}
                        >
                          <div style={{ padding: 10 }}>
                            <div style={{ fontWeight: 800, fontSize: 16 }}>
                              Drone Details
                            </div>
                            <br />
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                paddingLeft: 5,
                                fontWeight: 500,
                              }}
                            >
                              {" "}
                              UIN : {selectedDrone?.drone_id ? selectedDrone?.drone_id : "-"}
                            </div>{" "}
                            <br />
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                paddingLeft: 5,
                                fontWeight: 500,
                              }}
                            >
                              {" "}
                              MODAL : {selectedDrone?.["modal"] ? selectedDrone?.["modal"] : "-"}{" "}
                            </div>
                            <br />
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                paddingLeft: 5,
                                fontWeight: 500,
                              }}
                            >
                              {" "}
                              MFG S/N : {selectedDrone?.mfg_no ? selectedDrone?.mfg_no : "-"}
                            </div>{" "}
                            <br />
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                paddingLeft: 5,
                                fontWeight: 500,
                              }}
                            >
                              {" "}
                              MFG BY : {selectedDrone?.mfg_by ? selectedDrone?.mfg_by : "-"}{" "}
                            </div>
                            <br />
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                paddingLeft: 5,
                                fontWeight: 500,
                              }}
                            >
                              {" "}
                              ADDRESS : { activeAddress !==""? activeAddress : "-"}{" "}
                            </div>
                            <br />
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                paddingLeft: 5,
                                fontWeight: 500,
                              }}
                            >
                              {" "}
                              Assigned Operator : {operatorList.find((res: any) => res._id === selectedDrone?.assigned_operator)?.["name"] ? operatorList.find((res: any) => res._id === selectedDrone?.assigned_operator)?.["name"] : "-"}
                            </div>{" "}
                            <br />
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                paddingLeft: 5,
                                fontWeight: 500,
                              }}
                            >
                              STATUS :{" "}
                              <div
                                style={{
                                  height: 10,
                                  width: 10,
                                  borderRadius: 10,
                                  marginLeft: 5,
                                  marginRight: 5,
                                  backgroundColor:
                                    activeMarker?.status === "offline"
                                      ? "red"
                                      : "green",
                                }}
                              ></div>{" "}
                              {activeMarker?.status ? activeMarker?.status : "-"}
                            </div>
                          </div>
                        </InfoWindowF>
                      ) : null}
                    </MarkerF>
                  })}
              </GoogleMap>
              : (
                <></>
              )}
        </div>
      ) : (
        <div>No records Found</div>
      )}
    </>
  );
};
export { MapModal };
