import { put, all, fork, call, takeLatest } from "redux-saga/effects";
import LoginAPI from "./Services";
import {
  setLoginDetailsLoading,
  setLoginDetailsSuccess,
  setLoginSuccess,
  setLoginDetailsFailure,
} from "./LoginSlice";
import { loginResponseI } from "./Interface";
import { getloginDetails } from "./actionTypes";
import * as actions from "./actionTypes";

function* getLoginDetails(payload: getloginDetails) {
  try {
    yield put(setLoginDetailsLoading());
    console.log("=====login payload=====", payload);
    const data: loginResponseI = yield call(LoginAPI.userLogin, payload?.data);
    yield put(setLoginSuccess(data));
  } catch (error) {
    console.log("login error", error);
    yield put(setLoginDetailsFailure(error));
  }
}

function* watchOnGetLoginDetails() {
  yield takeLatest(actions.GET_LOGIN_DETAILS, getLoginDetails);
}

export default function* authSaga() {
  yield all([fork(watchOnGetLoginDetails)]);
}
